import React from 'react'
import { Box, Typography } from '@mui/material'
import { Lock } from '@mui/icons-material'

import ToolbarMain from 'src/layouts/Main/ToolbarMain'
import { AcessDiniedContent } from './styles'

const AccessDenied = () => {
  return (
    <>
      <ToolbarMain />
      <AcessDiniedContent>
        <Box width={180} height={180} className="icon-fade" textAlign="center">
          <Lock
            style={{
              fontSize: 140,
            }}
          />
        </Box>
        <Typography variant="h2" className="subtitle">
          Access Denied
        </Typography>
        <Typography color="textSecondary">
          <Box component="span" fontSize={20}>
            You don't have permissions to access this page
          </Box>
        </Typography>
      </AcessDiniedContent>
    </>
  )
}

export default AccessDenied
