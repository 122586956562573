import React from 'react'
import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import DynamicTable, { cellDate } from 'src/components/DynamicTable'

const nameHistoryColumns = [
  { Header: 'Start Date', accessor: 'startDateUtc', Cell: cellDate },
  { Header: 'End Date', accessor: 'endDateUtc', Cell: cellDate },
  { Header: 'Name', accessor: 'name' },
]

const nameHistorySchema = Yup.object().shape({
  newName: Yup.string().required('Required'),
})

interface NameHistoryColumns {
  startDateUtc: Date
  endDateUtc?: Date
  name: string
}

interface NameHistoryProps {
  nameHistoryData?: NameHistoryColumns[]
  onSubmit: (newName: string) => void | Promise<any>
  nameHistoryLoading?: boolean
  changeNameLoading?: boolean
}

const NameHistory = ({
  nameHistoryData,
  onSubmit,
  nameHistoryLoading,
  changeNameLoading,
}: NameHistoryProps) => {
  const nameHistoryForm = useFormik({
    initialValues: {
      newName: '',
    },
    validationSchema: nameHistorySchema,
    onSubmit: ({ newName }, { setSubmitting }) => {
      onSubmit(newName)
      setSubmitting(false)
      nameHistoryForm.resetForm()
    },
  })

  return (
    <Box>
      <form onSubmit={nameHistoryForm.handleSubmit}>
        <Box display="flex" alignItems="end" justifyContent="space-between">
          <Box>
            <TextField
              label="New Name"
              name="newName"
              fullWidth
              onChange={nameHistoryForm.handleChange}
              value={nameHistoryForm.values.newName}
              error={!!nameHistoryForm.errors.newName}
              helperText={nameHistoryForm.errors.newName}
              style={{ width: '300px' }}
            />
          </Box>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={changeNameLoading}
            startIcon={changeNameLoading && <CircularProgress size={16} />}
          >
            Change Name
          </Button>
        </Box>
      </form>
      <Box py={2}>
        <DynamicTable
          id="tbNameHistory"
          loading={nameHistoryLoading}
          data={nameHistoryData ?? []}
          columns={nameHistoryColumns}
          pageSize={25}
        />
      </Box>
    </Box>
  )
}

NameHistory.defaultProps = {
  nameHistoryLoading: false,
  changeNameLoading: false,
  nameHistoryData: [],
}

export default NameHistory
