import React, { useEffect, useRef, useState } from 'react'
import { AccountCircle } from '@mui/icons-material'
import { useAuth } from 'src/context/AuthenticationContext'
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material'
import {
  AppBarStyle,
  Logo,
  Root,
  SectionDesktop,
  ToolbarStyles,
} from './styles'

interface Props {
  badgeNotify?: number
}

const userId = window.localStorage.getItem('userId')
const { origin } = window.location
const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`

const ToolbarMain: React.FC<Props> = ({ badgeNotify }: Props) => {
  const authenticationObj = useAuth()

  // const mediaMatches = useMediaQuery('(min-width: 738px)')
  // const mediaMatchesMin1138 = useMediaQuery('(max-width: 1138px)')
  const [openUser, setOpenUser] = useState(false)
  const anchorRefUser = useRef<HTMLButtonElement>(null)

  const handleUserMenuToggle = (): void => {
    setOpenUser((prevOpen) => !prevOpen)
  }

  const handleUserClose = (event: any): void => {
    if (
      anchorRefUser.current &&
      anchorRefUser.current.contains(event.target as HTMLElement)
    ) {
      return
    }
    setOpenUser(false)
  }

  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
    setOpenUser(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent): void {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpenUser(false)
    }
  }

  const handleOnLogout = (): void => {
    authenticationObj.logout()
  }

  const prevOpen = useRef(openUser)

  useEffect(() => {
    if (prevOpen.current === true && openUser === false) {
      anchorRefUser.current!.focus()
    }

    prevOpen.current = openUser
  }, [openUser])

  return (
    <Root>
      <AppBarStyle position="fixed" elevation={0}>
        <ToolbarStyles>
          <Box
            alignItems="center"
            justifyContent="space-between"
            display="flex"
            flexDirection="row"
            width="100%"
            pl={5}
          >
            <Logo
              data-testid="debttrader-logo"
              src="/img/ec-white-logo.svg"
              width={266}
              alt="EverChain"
            />
            <SectionDesktop>
              <>
                <Button
                  ref={anchorRefUser}
                  style={{ color: 'white' }}
                  aria-controls={openUser ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  data-cy="user-menu"
                  onClick={handleUserMenuToggle}
                  startIcon={<AccountCircle />}
                >
                  {
                    authenticationObj.user?.profile[
                      'http://debttrader.com/claims/display_name'
                    ]
                  }
                </Button>
                <Popper
                  open={openUser}
                  anchorEl={anchorRefUser.current}
                  role={undefined}
                  transition
                  data-cy="popper-menu"
                  disablePortal
                >
                  {({ TransitionProps, placement }): React.ReactNode => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleUserClose}>
                          <MenuList
                            autoFocusItem={openUser}
                            id="menu-list-grow"
                            // eslint-disable-next-line react/jsx-no-bind
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={handleChangepassword}
                              role="menu-item"
                            >
                              Change Password
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </>
              <Button
                variant="text"
                style={{ color: 'white' }}
                onClick={handleOnLogout}
              >
                Logout
              </Button>
            </SectionDesktop>
          </Box>
        </ToolbarStyles>
      </AppBarStyle>
    </Root>
  )
}

ToolbarMain.defaultProps = {
  badgeNotify: 2,
}
export default ToolbarMain
