/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  Chip,
  Button,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { MoreVert } from '@mui/icons-material'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { splitName, dynamicColors } from 'src/utils/common'
import DynamicTable from 'src/components/DynamicTable'
import { GET_ALL_USERS_ASSOCIATE } from 'src/graphql/operations/queries/managePermissions'
import { UserAssociateListResponse } from 'src/graphql/models/managePermissions'
import { useDeleteRoleMember } from 'src/graphql/operations/mutations/managePermissions'
import { notistackOptions } from 'src/configs/notistackOptions'
import { USER_ROLES } from 'src/routes'

interface RoleMembersTableProps {
  roleId: string
  selectedTenantId?: string
  onAddNewUsers: () => void
}

const pageSize = 25

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

function RoleMembersTable({
  roleId,
  selectedTenantId,
  onAddNewUsers,
}: RoleMembersTableProps) {
  const { enqueueSnackbar } = useSnackbar()

  const deleteRoleMemberCompleted = (): void => {
    enqueueSnackbar('Member deleted from role', notifySuccess)
  }
  const {
    deleteRoleMember,
    loading: deleteRoleMemberLoading,
    error: deleteRoleMemberError,
  } = useDeleteRoleMember(deleteRoleMemberCompleted)

  const usersColumns = [
    {
      Header: 'Name',
      accessor: 'userName',
      Cell: (props: any) => {
        return (
          props.cell.value && (
            <Button
              component={Link}
              to={
                props.row.original.isInternal
                  ? `${USER_ROLES}/${props.row.original.id}/permission/internal/${selectedTenantId}`
                  : `${USER_ROLES}/${props.row.original.id}/permission/${selectedTenantId}`
              }
            >
              <Box display="flex" alignItems="center">
                <Avatar
                  style={{
                    backgroundColor: dynamicColors({
                      min: 0,
                      max: 190,
                      opacity: 1,
                    }),
                  }}
                >
                  {splitName(props.cell.value)}
                </Avatar>
                <Box ml={2}>
                  <Typography>{props.cell.value}</Typography>
                </Box>
              </Box>
            </Button>
          )
        )
      },
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: (props: any): React.ReactNode => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
        const open = Boolean(anchorEl)

        const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
          setAnchorEl(event.currentTarget)
        }

        const handleClose = (): void => {
          setAnchorEl(null)
        }
        const menus = useMemo(
          () => (
            <div>
              <IconButton
                aria-label="more"
                aria-controls="action-menus"
                aria-haspopup="true"
                onClick={handleClick}
                size="small"
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disabled={deleteRoleMemberLoading}
                  onClick={() => {
                    handleClose()
                    deleteRoleMember({
                      variables: {
                        roleId,
                        userIds: [props.row.original.id],
                      },
                      refetchQueries: ['GetAllUsersAssociates'],
                    })
                  }}
                >
                  Remove from role
                </MenuItem>
              </Menu>
            </div>
          ),
          [anchorEl, open, props.row.original.id]
        )
        return <>{menus}</>
      },
    },
  ]

  const {
    data,
    loading: usersMembersRoleLoading,
    fetchMore,
  } = useQuery<UserAssociateListResponse>(GET_ALL_USERS_ASSOCIATE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      userAssociateListRequest: {
        roleIdFilter: roleId,
        tenantIdFilter: selectedTenantId,
        pagination: {
          pageNumber: 0,
          pageSize,
        },
      },
    },
    skip: !roleId,
  })

  const usersMembersRoleData = data?.userAssociateListResponse

  useEffect(() => {
    if (deleteRoleMemberError) {
      enqueueSnackbar("Wasn't possible to do your request.", notifyError)
    }
  }, [deleteRoleMemberError, enqueueSnackbar])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={4}
      >
        <Box display="flex" alignItems="center">
          <Typography>Total</Typography>
          <Box pl={2}>
            <Chip label={usersMembersRoleData?.totalCount || 0} size="small" />
          </Box>
        </Box>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={onAddNewUsers}
        >
          Add Member
        </Button>
      </Box>
      <Box p={2}>
        <DynamicTable
          id="tbRoleMembers"
          data={usersMembersRoleData?.users || []}
          columns={usersColumns}
          loading={usersMembersRoleLoading}
          totalItems={usersMembersRoleData?.totalCount || 0}
          onChangePagination={(_, pageNumber: number) => {
            const newPageNumber = pageNumber - 1
            if (fetchMore) {
              fetchMore({
                variables: {
                  userListRequest: {
                    roleIdFilter: roleId,
                    pagination: {
                      pageNumber: newPageNumber,
                      pageSize,
                    },
                  },
                },
                updateQuery: (
                  prev,
                  { fetchMoreResult }
                ): UserAssociateListResponse => {
                  if (!fetchMoreResult) return prev
                  return fetchMoreResult
                },
              })
            }
          }}
        />
      </Box>
    </>
  )
}

export default RoleMembersTable
