import { httpClient } from '../../axios-wrapper/httpClient'
import * as urls from '../../urls/fileUnmask'
import * as types from '../../models/fileUnmask'

export const getSellerUploadTemplateFileUnmaskBySeller = async (
  sellerId: string | undefined,
  kendoPagination: string | undefined
): Promise<types.SellerUploadTemplateFileUnmaskDataResponse> => {
  const response = await httpClient.get(urls.FileUnmaskGetBySeller, {
    params: { sellerId, kendoPagination },
  })

  return response.data
}

export const addOrRemoveFileUnmaskWithColumns = async (
  request: types.FileUnmaskWithColumnsRequest
): Promise<void> => {
  await httpClient.post(urls.FileUnmaskAddWithColumns, request)
}
