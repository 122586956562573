import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { GET_COMMENTS } from 'src/graphql/operations/queries/clientProfiles'
import { GetComments } from 'src/graphql/models/clientProfiles'
import { useAddCommentClientProfile } from 'src/graphql/operations/mutations/clientProfiles'
import { useSnackbar } from 'notistack'
import { Grid, Typography } from '@mui/material'
import CommentForm from './CommentForm'
import CommentList from './CommentList'

interface CommentsProps {
  clientId?: number | undefined
  businessId?: string | undefined
}

const Comments = ({ clientId, businessId }: CommentsProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const pageSize = 25
  const [page, setPage] = useState(1)

  const {
    data: getCommentsData,
    loading: commentsLoading,
    fetchMore,
  } = useQuery<GetComments>(GET_COMMENTS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      request: {
        clientIdFilter: Number(clientId),
        businessIdFilter: businessId,
        pagination: {
          pageNumber: 0,
          pageSize,
        },
      },
    },
    skip: !clientId && !businessId,
  })

  const addCommentOnCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Add comment successful', {
        variant: 'success',
      })
    }
  }
  const totalComments = getCommentsData?.getComments.total

  const {
    AddComment,
    loading: addCommentLoading,
    error: addCommentError,
  } = useAddCommentClientProfile(addCommentOnCompleted)

  useEffect(() => {
    if (addCommentError && addCommentError?.graphQLErrors.length) {
      addCommentError.graphQLErrors.forEach(({ message }) => {
        enqueueSnackbar(message, {
          variant: 'error',
        })
      })
    }
  }, [addCommentError, enqueueSnackbar])

  return (
    <CommentForm
      loading={addCommentLoading}
      onAddComment={(comment: string) => {
        AddComment({
          variables: {
            comment: {
              clientId: Number(clientId),
              businessId,
              comment,
            },
          },
          refetchQueries: ['GetComments'],
        })
      }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          {(!getCommentsData?.getComments.total ||
            getCommentsData?.getComments.total === 0) && (
            <Typography>
              <strong>No Comments</strong>
            </Typography>
          )}
        </Grid>
        {getCommentsData?.getComments.total &&
          getCommentsData?.getComments.total > 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                <strong>
                  {' '}
                  {`Comments: ${getCommentsData?.getComments.total} `}
                </strong>
              </Typography>
              <CommentList
                commentsData={getCommentsData?.getComments.getComment}
                totalItems={totalComments}
                clientId={clientId}
                businessId={businessId}
                page={page}
                isLoading={commentsLoading}
                pageSize={pageSize}
                onChangePagination={(_, value: number): void => {
                  const pageNumber = value - 1
                  setPage(value)
                  if (fetchMore) {
                    fetchMore({
                      variables: {
                        request: {
                          clientIdFilter: clientId,
                          businessIdFilter: businessId,
                          pagination: {
                            pageNumber,
                            pageSize,
                          },
                        },
                      },
                      updateQuery: (prev, { fetchMoreResult }): GetComments => {
                        if (!fetchMoreResult) return prev
                        return fetchMoreResult
                      },
                    })
                  }
                }}
              />
            </Grid>
          )}
      </Grid>
    </CommentForm>
  )
}

Comments.defaultProps = {
  clientId: null,
  businessId: null,
}

export default Comments
