/* eslint-disable max-len */
import { useQuery } from '@apollo/client'
import {
  Box,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material'
import React, { useState } from 'react'
import { RevenuesSummaryResponse } from 'src/graphql/models/commissions'
import { GET_REVENUE_SUMMARY } from 'src/graphql/operations/queries/commissions'
import { toMonthName } from 'src/utils/date'
import { numberCurrencyDollar } from 'src/utils/numbers'
import { RevenueSummaryCell } from '../styles'

interface RevenueSummaryProps {
  revenueYear: number
}

const months = [
  { value: 1, month: 'January' },
  { value: 2, month: 'February' },
  { value: 3, month: 'March' },
  { value: 4, month: 'April' },
  { value: 5, month: 'May' },
  { value: 6, month: 'June' },
  { value: 7, month: 'July' },
  { value: 8, month: 'August' },
  { value: 9, month: 'September' },
  { value: 10, month: 'October' },
  { value: 11, month: 'November' },
  { value: 12, month: 'December' },
]

const MonthlyRevenueSummary: React.FC<RevenueSummaryProps> = ({
  revenueYear,
}: RevenueSummaryProps) => {
  const [revenuePeriodQuery, setRevenuePeriodQuery] = useState<any>(null)

  const {
    data: getRevenuesSummary,
    loading: revenueSummaryLoading,
    refetch,
  } = useQuery<RevenuesSummaryResponse>(GET_REVENUE_SUMMARY, {
    variables: {
      revenueYear,
      revenuePeriod: 3,
      revenuePeriodQuery,
    },
  })

  const handleRevenuePeriodQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRevenuePeriodQuery(Number(event.target.value))
    refetch()
  }

  return (
    <Box>
      <Grid xs={1} sm={1} md={1} style={{ margin: 10, paddingBottom: 10 }}>
        <TextField
          fullWidth
          label="Month"
          name="revenuePeriodMonth"
          select
          onChange={handleRevenuePeriodQueryChange}
        >
          {months.map((option: any) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.month}
              </MenuItem>
            )
          })}
        </TextField>
      </Grid>
      {revenueSummaryLoading ? (
        <Skeleton variant="rectangular" height={150} width="100%" />
      ) : (
        <Box>
          <Table>
            <TableBody>
              {getRevenuesSummary &&
              getRevenuesSummary?.getRevenueSummary?.revenueSummaryDataResponse
                .length > 0 ? (
                getRevenuesSummary?.getRevenueSummary?.revenueSummaryDataResponse?.map(
                  (item, index) => (
                    <Box key={index}>
                      <TableRow>
                        <Box gap={2.5} style={{ margin: 5 }}>
                          <Typography variant="h6">
                            {toMonthName(item.month)}
                          </Typography>
                          <RevenueSummaryCell>
                            <Typography
                              variant="body1"
                              style={{ display: 'flex', gap: 10 }}
                            >
                              <b>Projected:</b>{' '}
                              {item.revenueSummaryResponse.projected !== 0
                                ? numberCurrencyDollar(
                                    item.revenueSummaryResponse.projected
                                  )
                                : 'N/A'}
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ display: 'flex', gap: 10 }}
                            >
                              <b>Actual:</b>{' '}
                              {item.revenueSummaryResponse.actual !== 0
                                ? numberCurrencyDollar(
                                    item.revenueSummaryResponse.actual
                                  )
                                : 'N/A'}
                            </Typography>
                            <Typography
                              variant="body1"
                              style={{ display: 'flex', gap: 10 }}
                            >
                              <b>Remaining:</b>{' '}
                              {item.revenueSummaryResponse.remaining !== 0
                                ? numberCurrencyDollar(
                                    item.revenueSummaryResponse.remaining
                                  )
                                : 'N/A'}
                            </Typography>
                          </RevenueSummaryCell>
                        </Box>
                      </TableRow>
                    </Box>
                  )
                )
              ) : revenuePeriodQuery ? (
                <Box style={{ margin: 5 }}>
                  <Typography variant="body1">
                    No records for the selected period
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  )
}
export default MonthlyRevenueSummary
