/* eslint-disable react/require-default-props */
import React, { useState, useMemo, memo } from 'react'
import { Tabs, Tab, Paper, Box, Divider } from '@mui/material'

import TabPanel from 'src/components/TabPanel'
import PermissionBuilder from 'src/components/PermissionBuilder'

import { User } from 'src/graphql/models/managePermissions'

import RoleInUserTable from './RoleInUserTable'

enum TabControls {
  PERMISSIONS = 'permission',
  ROLES = 'roles',
}

interface UserTabsProps {
  onOpenAddNewRoleToUser: () => void
  userId: string
  user?: User | undefined
  userLoading?: boolean
  tabType?: string
  selectedTenantId?: string
}
const UserTabs: React.FC<UserTabsProps> = ({
  userId,
  tabType,
  user,
  userLoading,
  selectedTenantId,
  onOpenAddNewRoleToUser,
}: UserTabsProps) => {
  const [value, setValue] = useState(tabType || String(TabControls.PERMISSIONS))
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Paper>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Permission and Roles"
      >
        <Tab label="Permissions" value={TabControls.PERMISSIONS} />
        <Tab label="Roles" value={TabControls.ROLES} />
      </Tabs>
      <Divider />
      <TabPanel value={value} index={TabControls.PERMISSIONS}>
        <Box p={2}>
          {useMemo(
            () => (
              <PermissionBuilder
                permissionType="USER"
                entityId={userId}
                permissionArguments={{
                  isSeller: user?.isSeller || false,
                  isBuyer: user?.isBuyer || false,
                  isInternal: user?.isInternal || false,
                  isCreditor: user?.isCreditor || false,
                  isVendor: user?.isVendor || false,
                  isAgency: user?.isAgency || false,
                  isServicer: user?.isServicer || false,
                }}
                loading={userLoading}
              />
            ),
            [user, userId, userLoading]
          )}
        </Box>
      </TabPanel>
      <TabPanel value={value} index={TabControls.ROLES}>
        <Box p={2}>
          {useMemo(
            () => (
              <RoleInUserTable
                userId={userId}
                onOpenAddNewRoleToUser={onOpenAddNewRoleToUser}
                selectedTenantId={selectedTenantId}
              />
            ),
            [onOpenAddNewRoleToUser, userId, selectedTenantId]
          )}
        </Box>
      </TabPanel>
    </Paper>
  )
}

UserTabs.defaultProps = {
  user: {
    id: '',
    tenantId: '',
    userName: '',
    clientName: '',
    roleCount: 0,
    isBuyer: false,
    isSeller: false,
    isInternal: false,
    isCreditor: false,
    isVendor: false,
    isAgency: false,
    isServicer: false,
    clientId: 0,
  },
  userLoading: false,
}
UserTabs.defaultProps = {
  tabType: '',
}

export default memo(UserTabs)
