import React, { useEffect, useMemo, useState, useCallback } from 'react'
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Autocomplete,
} from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { notistackOptions } from 'src/configs/notistackOptions'
import { UserListResponse } from 'src/graphql/models/managePermissions'
import { GET_ALL_USERS } from 'src/graphql/operations/queries/managePermissions'
import {
  useAddOrUpdateRole,
  useAddRoleMember,
} from 'src/graphql/operations/mutations/managePermissions'
import DrawerRight from 'src/components/DrawerRight'

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

interface FormGroupPermissionsProps {
  open: boolean
  onClose: () => void
  selectedTenantId: string
  roleId: string | null | undefined
  roleName: string | null | undefined
  roleDescription: string | null | undefined
  roleTenantId: string | null | undefined
  actionType: string | null | undefined
}

const initialRoleForm = {
  name: '',
  members: [],
  description: '',
}

function FormGroupPermissions({
  open,
  onClose,
  selectedTenantId,
  roleId,
  roleName,
  roleDescription,
  roleTenantId,
  actionType,
}: FormGroupPermissionsProps) {
  const [roleForm, setRoleForm] = useState<any>((prevState: any) => ({
    ...prevState,
    name: (roleName || '') + (actionType === 'clone' ? ' (Clone)' : ''),
    description: roleDescription,
  }))
  const [formErrors, setFormErrors] = useState<{ [x: string]: any }>({
    name: '',
  })
  const [openAutoComple, setOpenAutoComple] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const [isDirty, setIsDirty] = useState(false)

  const [getUserByTenantId, { data: allUsersData, loading: loadingUsers }] =
    useLazyQuery<UserListResponse>(GET_ALL_USERS, {
      fetchPolicy: 'cache-and-network',
      variables: {
        userListPermissionRequest: {
          tenantIdFilter: selectedTenantId,
        },
      },
    })

  const [
    getUsersMembers,
    { data: usersMembersRoleData, loading: usersMembersRoleLoading },
  ] = useLazyQuery<UserListResponse>(GET_ALL_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userListPermissionRequest: {
        roleIdFilter: roleId,
      },
    },
  })

  const addRoleMemberCompleted = (): void => {
    enqueueSnackbar('Member added successfully', notifySuccess)
    onClose()
  }

  const { addRoleMember } = useAddRoleMember(addRoleMemberCompleted)

  const addOrUpdateRoleCompleted = (data: any): void => {
    enqueueSnackbar('Role saved successfully', notifySuccess)
    AddRoleMembers(data)
    onClose()
  }

  const {
    addOrUpdateRole,
    loading: addOrUpdateRoleLoading,
    error: addOrUpdateRoleError,
  } = useAddOrUpdateRole(addOrUpdateRoleCompleted)

  const handleChangeRoleForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setRoleForm((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
    setIsDirty(true)
  }

  const AddRoleMembers = (data: any): void => {
    if (roleForm.members && roleForm.members.length) {
      const userIds = roleForm.members.map((item: any) => item.id)
      addRoleMember({
        variables: {
          roleId: data.addOrUpdateRole.id,
          userIds,
        },
      })
    }
  }

  const allUsersToMember = useMemo(
    () => allUsersData?.userListResponse.users || [],
    [allUsersData]
  )

  const allUsersMemoized = useMemo(
    () => allUsersToMember || [],
    [allUsersToMember]
  )

  const handleAddOrUpdate = () => {
    const { name, description } = roleForm

    if (name) {
      addOrUpdateRole({
        variables: {
          role: {
            id: actionType === 'edit' ? roleId : null,
            active: true,
            name,
            description,
            tenantId: selectedTenantId,
          },
        },
        refetchQueries: ['GetAllRoles'],
      })
      setFormErrors((prevState) => ({ ...prevState, name: '' }))
    } else {
      setFormErrors((prevState) => ({ ...prevState, name: 'Required' }))
    }
  }

  const canEditName =
    actionType !== 'edit' || (actionType === 'edit' && roleTenantId !== null)

  useEffect(() => {
    if (actionType && roleId) {
      getUsersMembers()
    }
  }, [actionType, roleId, getUsersMembers])

  useEffect(() => {
    if (open) {
      getUserByTenantId()
    }
    return () => {
      setRoleForm({ ...initialRoleForm })
      setFormErrors((prevState) => ({ ...prevState, name: '' }))
    }
  }, [getUserByTenantId, open])

  useEffect(() => {
    if (addOrUpdateRoleError) {
      enqueueSnackbar('There was an error, try again later', notifyError)
    }
  }, [addOrUpdateRoleError, enqueueSnackbar])

  const userMembersOfRole = useMemo(
    () => usersMembersRoleData?.userListResponse.users || [],
    [usersMembersRoleData]
  )

  const handleSetRoleForm = useCallback(() => {
    setRoleForm((prevState: any) => ({
      ...prevState,
      members: userMembersOfRole,
      name: (roleName || '') + (actionType === 'clone' ? ' (Clone)' : '') || '',
      description: roleDescription || '',
      id: roleId,
    }))
  }, [roleName, roleDescription, roleId, actionType, userMembersOfRole])

  useEffect(() => {
    if (userMembersOfRole.length) {
      handleSetRoleForm()
    }
  }, [handleSetRoleForm, userMembersOfRole])

  return (
    <DrawerRight
      title={actionType === 'edit' ? 'Edit role' : 'Create a new role'}
      open={open}
      onClose={onClose}
      width={330}
    >
      <Box px={4} py={6} component="div" display="flex" flexDirection="column">
        <Box mb={3} width="100%" hidden={canEditName}>
          <Typography variant="subtitle2">
            Name and description of built-in roles can't be edited.
          </Typography>
        </Box>
        <Box mb={3} width="100%">
          <TextField
            fullWidth
            label="Name"
            placeholder="Group name"
            onChange={handleChangeRoleForm}
            value={roleForm.name ? roleForm.name : ''}
            error={Boolean(formErrors.name)}
            helperText={formErrors.name}
            name="name"
            disabled={!canEditName}
          />
        </Box>
        <Box mb={3} width="100%">
          <TextField
            fullWidth
            label="Description"
            placeholder="Add a description to your group"
            multiline
            InputProps={{
              maxRows: 2,
            }}
            onChange={handleChangeRoleForm}
            value={roleForm.description}
            name="description"
            disabled={!canEditName}
          />
        </Box>
        <Box mb={3} width="100%">
          <Autocomplete
            open={openAutoComple}
            multiple
            onOpen={(): void => setOpenAutoComple(true)}
            onClose={(): void => setOpenAutoComple(false)}
            options={allUsersMemoized}
            getOptionLabel={(option: any) => option.userName}
            value={roleForm.members || []}
            loading={loadingUsers || usersMembersRoleLoading}
            onChange={(ev, values) => {
              setRoleForm({
                ...roleForm,
                members: values,
              })
              setIsDirty(true)
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                label="Users"
                placeholder="Select some users to this group"
                margin="normal"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loadingUsers ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box mt={4} mb={3} display="flex" flex={1} justifyContent="flex-end">
          <Button onClick={onClose}>Cancel</Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            disabled={loadingUsers || addOrUpdateRoleLoading || !isDirty}
            onClick={handleAddOrUpdate}
            startIcon={
              (loadingUsers || addOrUpdateRoleLoading) && (
                <CircularProgress color="primary" size={16} />
              )
            }
          >
            {actionType === 'edit' ? 'Save' : 'Create'}
          </Button>
        </Box>
      </Box>
    </DrawerRight>
  )
}

export default FormGroupPermissions
