import { gql } from '@apollo/client'

export const GET_CLIENT_PROFILES = gql`
  query GetClients(
    $clientListRequest: ClientListRequest
    $kendoPagination: String
  ) {
    getClients(
      clientListRequest: $clientListRequest
      kendoPagination: $kendoPagination
    ) {
      totalOfAccounts
      clientList {
        id
        name
        status
        category
        customerServicePhoneNumber
        corpHQPhoneNumber
        lastUpdatedByUserName
        customerServiceEmail
        city
        stateCode
        countryCode
        primaryContact_FirstName
        primaryContact_LastName
      }
    }
  }
`

export const GET_COMMENTS = gql`
  query GetComments($request: CommentListRequest!) {
    getComments: getComments(request: $request) {
      getComment {
        id
        comment
        lastUpdatedByUserName
        userId
        createdUTC
      }
      total
    }
  }
`

export const GET_CLIENT = gql`
  query GetClient($clientId: Long!, $kendoPagination: String) {
    getClient(clientId: $clientId, kendoPagination: $kendoPagination) {
      category
      city
      clientAssociations {
        id
        name
      }
      businessSummaryResponse {
        businesses {
          businessType
          id
          name
          status
        }
        numberOfItems
        totalCount
      }
      corpHQPhoneNumber
      countryCode
      customerServiceEmail
      customerServicePhoneNumber
      id
      importFromId
      lastUpdatedByUserId
      lastUpdatedByUserName
      lastUpdatedUTCDate
      name
      postalCode
      primaryContact_EMail
      primaryContact_FirstName
      primaryContact_LastName
      primaryContact_MobilePhone
      primaryContact_OfficePhone
      stateCode
      status
      street1
      street2
      tenantId
      whitelistEnabled
      recoverSuccessManagerTitle
      salesDirectorTitle
      digitalSellerSurveySignerTitle
      digitalSellerSurveySignerName
      enableDigitalSellerSurvey
      eSignatureEnabledBOS
      eSignatureEnabledPSA
      isReseller
    }
  }
`
export const GET_BANK_ACCOUNTS = gql`
  query GetBankAccounts($request: BankAccountListRequest!) {
    getBankAccounts(request: $request) {
      accountNumber
      accountTypeCode
      bankName
      bankPhone
      city
      clientId
      countryCode
      id
      importFromId
      lastUpdatedByUserId
      lastUpdatedByUserName
      lastUpdatedUTCDate
      postalCode
      routingNumber
      nameOnAccount
      stateCode
      status
      street1
      street2
    }
  }
`

export const GET_CLIENT_ASSOCIATIONS = gql`
  query GetClientAssociation($clientId: Long!) {
    getClientAssociation(clientId: $clientId) {
      id
      name
    }
  }
`

export const GET_PLACEMENT_SETTING = gql`
  query GetPlacementSettings($clientId: Long!) {
    getPlacementSettings(clientId: $clientId) {
      id
      clientId
      enabled
      dormantAccountNotificationEnabled
      dormantAccountPercentage
      dormantAccountLenght
    }
  }
`

export const GET_APPROVED_AGENCIES = gql`
  query GetApprovedAgencies($clientId: Long!) {
    getApprovedAgencies(clientId: $clientId) {
      approvedAgencyBusinessId
      id
      name
    }
  }
`

export const GET_AGENCIES = gql`
  query GetAgencies {
    getAgencies {
      businesses {
        id
        name
        status
      }
      numberOfItems
      totalCount
    }
  }
`

export const GET_USER_DETAIL = gql`
  query GetUserDetail($userId: String!) {
    getUserDetail(userId: $userId) {
      displayName
      id
      firstName
      emailAddress
      enableTextMessaging
      isCustomerSuccess
      isCompliance
      isSales
      lastName
      lastUpdated
      lastUpdatedSqlId
      lastUpdatedUserId
      lastUpdatedUserName
      profilePictureUrl
      status
      tenantId
      textMessagingPhoneNumber
      title
      voicePhoneNumber
      hasPassword
      lockoutEnd
      passwordChangeDate
      isTFAEnabled
      department
    }
  }
`

export const GET_USERS = gql`
  query GetUsers($userListRequest: UserListRequest!, $kendoPagination: String) {
    userListResponse: getUsers(
      userListRequest: $userListRequest
      kendoPagination: $kendoPagination
    ) {
      totalCount
      numberOfItems
      userResponse {
        displayName
        id
        firstName
        emailAddress
        enableTextMessaging
        isCustomerSuccess
        isSales
        isCompliance
        lastName
        lastUpdated
        lastUpdatedSqlId
        lastUpdatedUserId
        lastUpdatedUserName
        profilePictureUrl
        status
        tenantId
        textMessagingPhoneNumber
        title
        voicePhoneNumber
        department
      }
    }
  }
`

export const GET_COUNTRY = gql`
  query GetCountry {
    countryDataResponse: getCountry {
      countryDescription
      countryCode
      stateInfo {
        code
        description
      }
    }
  }
`

export const GET_IP_ADDRESSES_WHITELISTED = gql`
  query GetWhitelistedIpAddresses($clientId: Long!) {
    addresses: getWhitelistedIpAddresses(clientId: $clientId) {
      name
      id
      startIpAddress
      endIpAddress
    }
  }
`

export const GET_NOTIFICATION_TEMPLATE = gql`
  query GetNotificationTemplate {
    notificationTemplate: getNotificationTemplate {
      id
      subject
      textContent
      htmlContent
    }
  }
`

export const GET_CLIENT_NAME_HISTORY = gql`
  query GetClientNameHistory($clientId: Long!) {
    getClientNameHistory(clientId: $clientId) {
      endDateUtc
      startDateUtc
      name
      id
      clientId
    }
  }
`
export const GET_WEBHOOK_REGISTER = gql`
  query GetWebhookRegister($businessId: String!, $eventType: String!) {
    getWebhookRegister(businessId: $businessId, eventType: $eventType) {
      businessId
      eventType
      url
    }
  }
`
