import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Tooltip, Theme } from '@mui/material'

import Comment from 'src/components/Comment'
import DefaultBankAccount from 'src/components/DefaultBankAccount'
import { BusinessBuyerRequestType } from 'src/graphql/models/buyer'
import { useQuery } from '@apollo/client'
import { GetBankAccounts, GetClient } from 'src/graphql/models/clientProfiles'
import {
  GET_BANK_ACCOUNTS,
  GET_CLIENT,
} from 'src/graphql/operations/queries/clientProfiles'
import BusinessLicensesList from 'src/components/License/LicenseList'
import { Info } from '@mui/icons-material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TENANT_TYPE } from 'src/utils/constants'
import WebHooksNotification from 'src/pages/WebHooks/WebHooksNotification'
import BuyerBusinessInformation from './BuyerBusinessInformation'
import BuyerCompliance from './BuyerCompliance'
// import BuyerDefaultBankAccount from './BuyerDefaultBankAccount'
import BuyerNotification from './BuyerNotification'
import BuyerPortoflioTypes from './BuyerPortoflioTypes'
import BusinessAssetTypes from '../../BusinessManage/components/BusinessAssetTypes'
import BusinessDeliveryStructure from '../../BusinessManage/components/BusinessDeliveryStructure'
import BusinessRecoverySettings from '../../BusinessManage/components/BusinessRecoverySettings'
import shadows from '@mui/material/styles/shadows'
import { makeStyles } from '@mui/styles'
import CustomerAuditInfo from 'src/components/Audit/CustomerAuditInfo'
import { useHistory, useLocation } from 'react-router-dom'
import BusinessFeeList from 'src/components/BusinessFee/BusinessFeeList'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getBusinessFeeEnabled } from 'src/infra/api/services/business'
import { Grid, Skeleton, Typography } from 'everchain-uilibrary'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: shadows[1],
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 250,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
}))

interface BuyerBusinessTabProps {
  clientId: number
  buyerBusinessId?: string | any
  buyerData?: BusinessBuyerRequestType | any
  buyerLoading?: boolean
}
const BuyerBusinessTab: React.FC<BuyerBusinessTabProps> = ({
  clientId,
  buyerBusinessId,
  buyerData,
  buyerLoading,
}: BuyerBusinessTabProps) => {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const location = useLocation()
  const history = useHistory()

  const showRecoveryTabs = !process.env.REACT_APP_HIDE_RECOVERY_TABS
  const indexSubtract = showRecoveryTabs ? 0 : 2

  const tabIndices: { [key: string]: number } = {
    'business-information': 0,
    'default-bank-account': 1,
    notifications: 2,
    'asset-types': 3,
    'delivery-structure': showRecoveryTabs ? 4 : -1,
    'business-recovery-settings': showRecoveryTabs ? 5 : -1,
    licenses: 6 - indexSubtract,
    comments: 7 - indexSubtract,
    compliance: 8 - indexSubtract,
    'portfolio-types': 9 - indexSubtract,
    webhooks: 10 - indexSubtract,
    audit: 11 - indexSubtract,
    'service-fee': 12 - indexSubtract,
  }
  useEffect(() => {
    const tabText =
      location.pathname.split('/').pop()?.replace('tab=', '')?.trim() ||
      'business-information'
    const tabIndex = tabIndices[tabText]
    if (tabIndex !== undefined) {
      setValue(tabIndex)
    } else {
      setValue(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const { data: getClientData } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, { variables: { clientId: Number(clientId) } })

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    const tabText =
      Object.keys(tabIndices).find((key) => tabIndices[key] === newValue) ||
      'business-information'

    const currentPathname = location.pathname
    const newSearch = `/tab=${tabText}`
    const newUrl = currentPathname.includes('tab=')
      ? currentPathname.replace(/\/tab=[^&]+/, newSearch)
      : currentPathname + newSearch
    history.push(newUrl)
  }
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'

  const defaultAccountDisable = () => {
    return !clientId || !buyerBusinessId
  }
  const defaultAccontIsFilled = () => {
    return (
      defaultAccountDisable() ||
      (buyerData?.defaultBankAccountId && getBankAccountData?.getBankAccounts)
    )
  }
  const { data: getBankAccountData } = useQuery<GetBankAccounts>(
    GET_BANK_ACCOUNTS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        request: {
          clientIdFilter: Number(clientId),
        },
      },
      skip: !clientId,
    }
  )

  const { data: businessFeeEnabled, isFetching: feeEnabledLoading } =
    useCustomQuery<boolean>(
      ['getBusinessFeeEnabled', buyerBusinessId],
      async () => {
        return getBusinessFeeEnabled(buyerBusinessId)
      },
      { enabled: !!buyerBusinessId, cacheTime: 0 }
    )

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Buyer Business Profile"
        className={classes.tabs}
      >
        <Tab label="Business information (Buyer)" {...a11yProps(0)} />
        <Tab
          style={{
            color: defaultAccontIsFilled() ? undefined : 'red',
          }}
          label="Default Bank Account"
          icon={
            <Tooltip title="Default bank is required">
              <Info
                style={{
                  display: defaultAccontIsFilled() ? 'none' : 'block',
                  position: 'absolute',
                  right: 8,
                  marginBottom: 0,
                }}
              />
            </Tooltip>
          }
          {...a11yProps(1)}
          disabled={defaultAccountDisable()}
        />
        <Tab
          label="Notifications"
          {...a11yProps(2)}
          disabled={!clientId || !buyerBusinessId}
        />
        <Tab
          label="Asset Types"
          {...a11yProps(3)}
          disabled={!clientId || !buyerBusinessId}
        />
        {showRecoveryTabs && (
          <Tab
            label="Delivery Structure"
            {...a11yProps(4)}
            disabled={!clientId || !buyerBusinessId}
            hidden={!!process.env.REACT_APP_HIDE_RECOVERY_TABS}
          />
        )}
        {showRecoveryTabs && (
          <Tab
            label="Business Recovery Settings"
            {...a11yProps(5)}
            disabled={!clientId || !buyerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Licenses"
            {...a11yProps(6 - indexSubtract)}
            disabled={!clientId || !buyerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Comments"
            {...a11yProps(7 - indexSubtract)}
            disabled={!clientId || !buyerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Compliance"
            {...a11yProps(8 - indexSubtract)}
            disabled={!clientId || !buyerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Portfolio Types"
            {...a11yProps(9 - indexSubtract)}
            disabled={!clientId || !buyerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Webhooks"
            disabled={!clientId || !buyerBusinessId}
            {...a11yProps(10 - indexSubtract)}
          />
        )}
        {isInternal && (
          <Tab
            label="Audit"
            {...a11yProps(11 - indexSubtract)}
            disabled={!buyerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Service Fee"
            {...a11yProps(12 - indexSubtract)}
            disabled={!buyerBusinessId}
          />
        )}
        {/* <Tab
          label="Branches"
          {...a11yProps(10 - indexSubtract)}
          disabled={!clientId || !buyerBusinessId}
        /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        <BuyerBusinessInformation
          clientId={clientId}
          buyerBusinessId={buyerBusinessId}
          buyerData={buyerData}
          client={getClientData?.getClient}
          buyerLoading={buyerLoading}
          businessFeeEnabled={businessFeeEnabled}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {clientId && buyerBusinessId && (
          <DefaultBankAccount
            businessId={buyerBusinessId}
            clientId={clientId}
            defaultId={buyerData?.defaultBankAccountId}
            isSeller={false}
            client={getClientData?.getClient}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {buyerBusinessId && <BuyerNotification businessId={buyerBusinessId} />}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {buyerBusinessId && <BusinessAssetTypes businessId={buyerBusinessId} />}
      </TabPanel>
      {showRecoveryTabs && (
        <TabPanel value={value} index={4}>
          {buyerBusinessId && (
            <BusinessDeliveryStructure businessId={buyerBusinessId} />
          )}
        </TabPanel>
      )}
      {showRecoveryTabs && (
        <TabPanel value={value} index={5}>
          {buyerBusinessId && (
            <BusinessRecoverySettings
              businessId={buyerBusinessId}
              client={getClientData?.getClient}
            />
          )}
        </TabPanel>
      )}
      {isInternal && (
        <>
          <TabPanel value={value} index={6 - indexSubtract}>
            {buyerBusinessId && (
              <BusinessLicensesList
                businessId={buyerBusinessId}
                clientCountry={getClientData?.getClient.countryCode}
                businessCountry={buyerData?.countryCode}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={7 - indexSubtract}>
            {clientId && buyerBusinessId && (
              <Comment clientId={clientId} businessId={buyerBusinessId} />
            )}
          </TabPanel>
          <TabPanel value={value} index={8 - indexSubtract}>
            {buyerLoading && (
              <>
                <Box mb={6}>
                  <Grid container spacing={4}>
                    <Grid item xs={6} sm={4} md={4}>
                      <Typography variant="h6">
                        Buyer Compliance Overview
                      </Typography>
                    </Grid>
                  </Grid>
                  <Skeleton
                    style={{ marginTop: '-2px' }}
                    height={300}
                    width={600}
                    isLoading={buyerLoading}
                  ></Skeleton>
                </Box>
              </>
            )}
            {buyerBusinessId && !buyerLoading && (
              <BuyerCompliance
                buyerBusinessId={buyerBusinessId}
                clientCountry={getClientData?.getClient.countryCode}
                buyerData={buyerData}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={9 - indexSubtract}>
            <BuyerPortoflioTypes buyerData={buyerData} />
          </TabPanel>
          <TabPanel value={value} index={10 - indexSubtract}>
            <WebHooksNotification sellerBusinessId={buyerBusinessId} />
          </TabPanel>
          <TabPanel value={value} index={11 - indexSubtract}>
            {!!buyerBusinessId && (
              <CustomerAuditInfo id={buyerBusinessId} tableName="business" />
            )}
          </TabPanel>
          <TabPanel value={value} index={12 - indexSubtract}>
            {!!buyerBusinessId && (
              <BusinessFeeList
                businessId={buyerBusinessId}
                businessFeeEnabled={businessFeeEnabled}
                feeEnabledLoading={feeEnabledLoading}
              />
            )}
          </TabPanel>
        </>
      )}
      {/* <TabPanel value={value} index={10 - indexSubtract}>
        {buyerBusinessId && <Branch businessId={buyerBusinessId} />}
      </TabPanel> */}
    </div>
  )
}

BuyerBusinessTab.defaultProps = {
  buyerBusinessId: null,
  buyerData: null,
  buyerLoading: false,
}

export default BuyerBusinessTab
