import { isString } from 'formik'

export const capitalize = (str: string | undefined): string => {
  return str
    ? str?.replace(/(?:^|\s)\S/g, (a) => {
        return a.toUpperCase()
      })
    : ''
}

export const convertToTrueOrFalse = (
  value: number | boolean | string
): string => {
  let option = ''
  if (!isString(value)) option = value ? 'Yes' : 'No'
  else option = value === 'false' ? 'No' : 'Yes'

  return option
}

export const regexEmail =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const emailValidate = (email = '') => {
  return regexEmail.test(String(email).toLowerCase())
}
