import React from 'react'
import { MenuItem, TextField } from '@mui/material'

export interface ItemRecipientDropdown {
  id: any
  name: string
  email: string
}

export interface NotificationRecipientDropdownProps {
  isLoading: boolean
  items?: ItemRecipientDropdown[]
  name: string
  disabled: boolean
  value: any
  onChange: (event: any) => void
}

const NotificationRecipientDropdown: React.FC<
  NotificationRecipientDropdownProps
> = ({ isLoading, items, name, disabled, value, onChange }) => {
  const getDisplayValue = (fieldName: string, fieldEmail: string): string => {
    return fieldName === fieldEmail ? fieldName : `${fieldName} - ${fieldEmail}`
  }

  const menuItemRecipient = () => {
    if (isLoading) {
      return <MenuItem value="">Loading</MenuItem>
    }

    return items?.length && !isLoading ? (
      items.map((field) => (
        <MenuItem value={field.id || field.email} key={field.id || field.email}>
          {getDisplayValue(field.name, field.email)}
        </MenuItem>
      ))
    ) : (
      <MenuItem value="">Empty</MenuItem>
    )
  }

  return (
    <TextField
      label="Choose a recipient"
      name={name}
      select
      fullWidth
      disabled={disabled}
      value={value}
      onChange={onChange}
    >
      {menuItemRecipient()}
    </TextField>
  )
}

export default NotificationRecipientDropdown
