import { Box, Typography } from '@mui/material'
import React from 'react'

export interface GroupBoxProps {
  children: React.ReactNode
  title: string
}

const GroupBox: React.FC<GroupBoxProps> = ({ children, title, ...rest }) => {
  return (
    <Box
      p={3}
      border="1px solid gray"
      borderRadius={5}
      position="relative"
      {...rest}
    >
      <Typography
        style={{
          position: 'absolute',
          left: '10px',
          top: '-10px',
          background: 'white',
          padding: '0 5px',
        }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  )
}

export default GroupBox
