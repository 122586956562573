import React, { useContext } from 'react'
import TransferList from 'src/components/TransferList'
import { AssetType } from 'src/graphql/models/businessRecovery'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  AddBusinessAssetType,
  DeleteBusinessAssetType,
  GetBusinessAssetType,
} from 'src/infra/api/services/assetType'
import {
  BusinessAssetTypeRequest,
  BusinessAssetTypeResponse,
} from 'src/infra/api/models/assetType'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
interface BusinessAssetTypesProps {
  businessId: string
}

const BusinessAssetTypes: React.FC<BusinessAssetTypesProps> = ({
  businessId,
}: BusinessAssetTypesProps) => {
  const ability = useContext(AbilityContext)
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')
  const reactQueryClient = useQueryClient()

  const { data: getAssetTypes } = useCustomQuery<BusinessAssetTypeResponse>(
    ['GetBusinessAssetTypes'],
    async () => {
      if (!businessId === undefined) {
        return
      }
      return GetBusinessAssetType(businessId).then((result: any) => {
        return result
      })
    },
    {
      enabled: true,
      cacheTime: 0,
    }
  )

  const mapItems = (array: AssetType[]) =>
    array.map((e) => ({ id: e.id, name: e.name, assetTypeFee: e.assetTypeFee }))

  const addBusinessAssetType = useMutation({
    mutationFn: (request: BusinessAssetTypeRequest) =>
      AddBusinessAssetType(request),
    onSuccess: (data: any) => {
      enqueueSnackbar('Asset types updated successfully', notistackSucces)
      reactQueryClient.refetchQueries({
        queryKey: ['GetBusinessAssetTypes'],
      })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        'Error while trying to update the Asset types',
        notistackWarning
      )
    },
  })

  const removeBusinessAssetType = useMutation({
    mutationFn: (request: BusinessAssetTypeRequest) =>
      DeleteBusinessAssetType(request),
    onSuccess: (data: any) => {
      enqueueSnackbar('Asset types updated successfully', notistackSucces)
      reactQueryClient.refetchQueries({
        queryKey: ['GetBusinessAssetTypes'],
      })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        'Error while trying to update the Asset types',
        notistackWarning
      )
    },
  })

  const handleSendLeftColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    removeBusinessAssetType.mutate({
      businessId: businessId,
      AssetTypeId: ids,
    })
  }

  const handleSendRightColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    addBusinessAssetType.mutate({
      businessId: businessId,
      AssetTypeId: ids,
    })
  }

  return (
    <TransferList
      leftData={mapItems(getAssetTypes?.availableAssetTypes || [])}
      rightData={mapItems(getAssetTypes?.assetTypes || [])}
      onSendLeftColumn={handleSendLeftColumn}
      onSendRightColumn={handleSendRightColumn}
      readonly={
        !ability.can(PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION, 'any')
      }
    />
  )
}

export default BusinessAssetTypes
