import { httpClient } from '../../axios-wrapper/httpClient'
import { BusinessLawFirmResponseType } from '../../models/lawfirm'
import { LawFirmDetails, LawFirmAddOrUpdate } from '../../urls/lawfirm'

export const getLawFirmDetails = async (
  lawFirmId: string | undefined
): Promise<BusinessLawFirmResponseType> => {
  const response = await httpClient.get(
    `${LawFirmDetails}?lawFirmId=${lawFirmId}`
  )

  return response.data
}

export const AddOrUpdateLawFirm = async (
  request: BusinessLawFirmResponseType
): Promise<BusinessLawFirmResponseType> => {
  const response = await httpClient.post(LawFirmAddOrUpdate, request)
  return response.data
}
