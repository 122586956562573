import { httpClient } from '../../axios-wrapper/httpClient'
import { BusinessAgencyResponseType } from '../../models/agency'
import { AgencyDetails } from '../../urls/agency'

export const getAgencyDetails = async (
  agencyId: string | undefined
): Promise<BusinessAgencyResponseType> => {
  const response = await httpClient.get(`${AgencyDetails}?agencyId=${agencyId}`)

  return response.data
}
