import { useQuery, QueryClient, UseQueryOptions } from '@tanstack/react-query'

export const useCustomQuery = <TData = unknown, TError = unknown>(
  key: any[],
  queryFn: () => Promise<TData | undefined>,
  options?: UseQueryOptions<TData | undefined, TError>
) => {
  const queryOptions: UseQueryOptions<TData | undefined, TError> = {
    ...options,
  }

  const query = useQuery<TData | undefined, TError>({
    queryKey: key,
    queryFn: () => {
      return queryFn()
    },
    ...queryOptions,
  })

  return {
    ...query,
    refetchWithParams: (newParams: any) => query.refetch(newParams),
  }
}

export const useCustomQueryClient = async <TData, TError = unknown>(
  key: string[],
  queryFn: () => Promise<TData | undefined>,
  queryClient: QueryClient,
  options?: UseQueryOptions<TData | undefined, TError>
) => {
  const queryOptions: UseQueryOptions<TData | undefined, TError> = {
    ...options,
  }

  const result = queryClient.fetchQuery<TData | undefined, TError>(
    key,
    () => {
      return queryFn()
    },
    queryOptions
  )

  return result
}

export const refetchQueriesWrapper = (
  queryKeys: any[],
  queryClient: QueryClient
) => {
  queryKeys.forEach((queryKey) => {
    queryClient.refetchQueries({ queryKey: [queryKey] })
  })
}
