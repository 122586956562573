/* eslint-disable react/require-default-props */
import React, { useContext } from 'react'
import DynamicTable from 'src/components/DynamicTable'
import { Box, Button } from '@mui/material'

import { FileIcon, defaultStyles } from 'react-file-icon'
import { BusinessFileLink, BusinessFileUri } from 'src/graphql/models/buyer'
import { cellDateTime } from 'src/components/DynamicTable/DynamicTableUltis'
import { useLazyQuery } from '@apollo/client'
import { GET_BUSINESS_FILE_URI } from 'src/graphql/operations/queries/buyer'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

interface BcoFilesTableProps {
  data: BusinessFileLink[] | []
  buyerId: string
}

const BcoFilesTable: React.FC<BcoFilesTableProps> = ({
  data,
  buyerId,
}: BcoFilesTableProps) => {
  const [fileType, setFileType] = React.useState<string>('')
  const [fileName, setFileName] = React.useState<string>('')
  const ability = useContext(AbilityContext)

  const GetFileIcon = (
    contentType: string
  ): { extension: string; style: any } => {
    switch (contentType) {
      case 'text/csv':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return { extension: 'xlsx', style: defaultStyles.xls }
      case 'application/pdf':
        return { extension: 'pdf', style: defaultStyles.pdf }
      case 'application/zip':
        return { extension: 'zip', style: defaultStyles.zip }
      default:
        return { extension: 'none', style: defaultStyles.txt }
    }
  }

  const fileLinkColumns = [
    {
      Header: 'File Name',
      accessor: 'blobUri',
      Cell: (props: any) => {
        const iconInfo = GetFileIcon(props.row.original.contentType)
        return ability.can(
          PermissionCodeAccess.CLIENT_BUSINESS_COMPLIANCE_PERMISSION,
          'any'
        ) ? (
          <Button
            disableRipple={true}
            style={{ backgroundColor: 'transparent' }}
            startIcon={
              <Box width={20} height={20}>
                <FileIcon extension={iconInfo.extension} {...iconInfo.style} />
              </Box>
            }
            onClick={() => {
              onDownload(
                props.row.original.fileType,
                props.row.original.blobUri
              )
            }}
          >
            {props.cell.value}
          </Button>
        ) : (
          <Box display="flex" flexDirection="row">
            <Box width={20} height={20}>
              <FileIcon extension={iconInfo.extension} {...iconInfo.style} />
            </Box>
            <Box ml={2}>{props.cell.value}</Box>
          </Box>
        )
      },
    },
    {
      Header: 'Upload Date',
      accessor: 'uploadedUtc',
      Cell: cellDateTime,
    },
  ]

  const [getUri, { data: getBusinessFileUri }] = useLazyQuery<BusinessFileUri>(
    GET_BUSINESS_FILE_URI,
    {
      variables: {
        businessId: buyerId,
        fileType,
        outputFileName: fileName,
      },
    }
  )

  const onDownload = (type: any, name: any) => {
    setFileType(type as string)
    setFileName(name as string)
    getUri()
  }

  const downloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }

  React.useEffect(() => {
    const uri = getBusinessFileUri?.getBusinessFileTempUri ?? ''
    if (uri !== '') {
      downloadFile(uri)
    }
  }, [getBusinessFileUri])

  return (
    <>
      <DynamicTable
        id="tbBcoFiles"
        data={data}
        pageSize={25}
        columns={fileLinkColumns}
      />
    </>
  )
}

BcoFilesTable.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  data: [],
}

export default BcoFilesTable
