/* eslint-disable react/require-default-props */
import React, { useReducer, useState, useEffect } from 'react'
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
} from '@mui/material'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import DrawerRight from 'src/components/DrawerRight'
import { useAddRoleMember } from 'src/graphql/operations/mutations/managePermissions'
import { GET_USERS_OUT_ROLE } from 'src/graphql/operations/queries/managePermissions'
import { UserAssociateListResponse } from 'src/graphql/models/managePermissions'
import { notistackOptions } from 'src/configs/notistackOptions'

interface FormMemberRoleProps {
  open: boolean
  onClose: () => void
  roleId: string
  selectedTenantId?: string
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const initialMembers = {
  members: [],
}

const roleFormReduce = (
  state: { [x: string]: any },
  action: { [x: string]: any }
) => ({
  ...state,
  ...action,
})

function FormMemberRole({
  open,
  onClose,
  roleId,
  selectedTenantId,
}: FormMemberRoleProps) {
  const [roleForm, setRoleForm] = useReducer(roleFormReduce, initialMembers)
  const [openAutoComple, setOpenAutoComple] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const addRoleMemberCompleted = (): void => {
    enqueueSnackbar('Users added successfully', notifySuccess)
    onClose()
  }

  const {
    addRoleMember,
    data: roleMemberData,
    loading: addRoleMemberLoading,
    error: addRoleMemberError,
  } = useAddRoleMember(addRoleMemberCompleted)

  const { data, loading: usersOutRoleLoading } =
    useQuery<UserAssociateListResponse>(GET_USERS_OUT_ROLE, {
      fetchPolicy: 'cache-and-network',
      variables: {
        userAssociateListRequest: {
          roleIdFilter: roleId,
          tenantIdFilter: selectedTenantId,
        },
      },
    })

  const usersOutRole = data?.userAssociateListResponse

  useEffect(() => {
    if (!open) {
      setRoleForm({
        members: [],
      })
    }
  }, [open])

  useEffect(() => {
    if (addRoleMemberError && !roleMemberData) {
      enqueueSnackbar('There was an error, try again later', notifyError)
    }
  }, [addRoleMemberError, roleMemberData, enqueueSnackbar])

  return (
    <DrawerRight
      title="Add users into role"
      open={open}
      onClose={onClose}
      width={330}
    >
      <Box px={4} py={6} component="div" display="flex" flexDirection="column">
        <Box component="div">
          <Autocomplete
            open={openAutoComple}
            multiple
            onOpen={(): void => setOpenAutoComple(true)}
            onClose={(): void => setOpenAutoComple(false)}
            options={usersOutRole?.users || []}
            getOptionLabel={(option: any) => option.userName}
            loading={usersOutRoleLoading}
            onChange={(ev, values) => {
              setRoleForm({
                members: values,
              })
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                label="Users"
                placeholder="Select users"
                margin="normal"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {usersOutRoleLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box mt={4} mb={3} display="flex" flex={1} justifyContent="flex-end">
          <Button onClick={onClose}>Cancel</Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            disabled={addRoleMemberLoading}
            onClick={() => {
              if (roleForm.members && roleForm.members.length) {
                const userIds = roleForm.members.map((item: any) => item.id)
                addRoleMember({
                  variables: {
                    roleId,
                    userIds,
                  },
                  refetchQueries: ['GetAllUsersAssociates'],
                })
              }
            }}
            startIcon={
              addRoleMemberLoading && (
                <CircularProgress color="primary" size={16} />
              )
            }
          >
            Add
          </Button>
        </Box>
      </Box>
    </DrawerRight>
  )
}

export default FormMemberRole
