/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React, { useContext, useState } from 'react'
import {
  GET_ASSET_TYPES_BY_OPERATING_PLACEMENT_STAGE,
  GET_OPERATING_PLACEMENT_STAGE,
} from 'src/graphql/operations/queries/business'
import {
  AssetType,
  BusinessAssetTypeResponse,
  BusinessOperatingPlacementStageType,
} from 'src/graphql/models/businessRecovery'
import {
  useAddBusinessOperatingPlacementStageAssetType,
  useChangeBusinessAssetTypeFee,
  useDeleteBusinessOperatingPlacementStageAssetType,
} from 'src/graphql/operations/mutations/businesses'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useLazyQuery, useQuery } from '@apollo/client'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { Box, MenuItem, TextField } from '@mui/material'
import InterchangebleTransferList from 'src/components/TransferList/InterchangebleTransferList'

interface BusinessAssetTypesProps {
  businessId: string
  inputAppend?: string | any
}

const BusinessOperatingPlacementStageAssetTypes: React.FC<
  BusinessAssetTypesProps
> = ({ businessId, inputAppend }: BusinessAssetTypesProps) => {
  const [selectedOperatingStage, setSelectedOperatingStage] = useState<any>()
  const [placementStageAssetType, setPlacementStageAssetType] = useState<any>()
  const [
    placementStageAvailableAssetType,
    setPlacementStageAvailableAssetType,
  ] = useState<any>()
  const ability = useContext(AbilityContext)
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')

  const [getAssetTypes, { loading: loadingGetAssetTypes }] =
    useLazyQuery<BusinessAssetTypeResponse>(
      GET_ASSET_TYPES_BY_OPERATING_PLACEMENT_STAGE,
      {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          setPlacementStageAvailableAssetType(
            mapItems(data.businessAssetTypes?.availableAssetTypes || [])
          )
          setPlacementStageAssetType(
            mapItems(data.businessAssetTypes?.assetTypes || [])
          )
        },
        onError: () => {
          enqueueSnackbar(
            'Error fetching Asset Types for this placement stage',
            notistackWarning
          )
        },
      }
    )

  const { data: getBusinessOperatingPlacementStage } =
    useQuery<BusinessOperatingPlacementStageType>(
      GET_OPERATING_PLACEMENT_STAGE,
      {
        variables: {
          businessId,
        },
        skip: !businessId,
      }
    )

  const mapItems = (array: AssetType[]) =>
    array.map((e) => ({ id: e.id, name: e.name, assetTypeFee: e.assetTypeFee }))

  const {
    deleteBusinessOperatingPlacementStageAssetType,
    loading: loadingDeleteOperatingPlacementStageAssetType,
  } = useDeleteBusinessOperatingPlacementStageAssetType((data: any) => {
    if (data) {
      enqueueSnackbar('Asset types updated successfully', notistackSucces)
    } else {
      enqueueSnackbar(
        'Error while trying to update the asset types',
        notistackWarning
      )
    }
  })

  const {
    addBusinessOperatingPlacementStageAssetType,
    loading: loadingAddOperatingPlacementStageAssetType,
  } = useAddBusinessOperatingPlacementStageAssetType((data: any) => {
    if (data) {
      enqueueSnackbar('Asset types updated successfully', notistackSucces)
    } else {
      enqueueSnackbar(
        'Error while trying to update the Asset types',
        notistackWarning
      )
    }
  })

  const { changeBusinessAssetTypeFee } = useChangeBusinessAssetTypeFee(
    (data: any) => {
      if (data) {
        enqueueSnackbar('Asset type fee updated successfully', notistackSucces)
      } else {
        enqueueSnackbar(
          'Error while trying to update the Asset type fee',
          notistackWarning
        )
      }
    }
  )

  const handleSendLeftColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    deleteBusinessOperatingPlacementStageAssetType({
      variables: {
        request: {
          businessId,
          assetTypeId: ids,
          businessOperatingPlacementStage: selectedOperatingStage,
        },
      },
      refetchQueries: [
        'GetBusinessAssetTypesByBusinessOperatingPlacementStage',
      ],
    })
  }

  const handleSendRightColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    addBusinessOperatingPlacementStageAssetType({
      variables: {
        request: {
          businessId,
          assetTypeId: ids,
          businessOperatingPlacementStage: selectedOperatingStage,
        },
      },
      refetchQueries: [
        'GetBusinessAssetTypesByBusinessOperatingPlacementStage',
      ],
    })
  }

  const handleBlurInputValue = (id: any, value: any) => {
    // eslint-disable-next-line no-console
    changeBusinessAssetTypeFee({
      variables: {
        request: {
          businessId,
          assetTypeId: id,
          assetTypeFee: Number(value),
          businessOperatingPlacementStage: selectedOperatingStage,
        },
      },
      refetchQueries: ['GetBusinessAssetTypes'],
    })
  }

  const handleSelectOperatingStage = (event: any) => {
    setSelectedOperatingStage(event?.target?.value)
    getAssetTypes({
      variables: {
        businessId,
        businessOperatingPlacementStage: event?.target?.value,
      },
    })
  }

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        select
        label="Operating Placement Stage"
        onChange={handleSelectOperatingStage}
        value={selectedOperatingStage}
        style={{ alignSelf: 'flex-start', margin: '30px', width: '200px' }}
      >
        {getBusinessOperatingPlacementStage?.businessOperatingPlacementStage?.operatingPlacementStages.map(
          (option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          )
        )}
      </TextField>
      {selectedOperatingStage && (
        // eslint-disable-next-line react/jsx-no-undef
        <InterchangebleTransferList
          loading={
            loadingAddOperatingPlacementStageAssetType ||
            loadingDeleteOperatingPlacementStageAssetType ||
            loadingGetAssetTypes
          }
          leftData={placementStageAvailableAssetType}
          rightData={placementStageAssetType}
          onSendLeftColumn={handleSendLeftColumn}
          onSendRightColumn={handleSendRightColumn}
          readonly={
            !ability.can(
              PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
              'any'
            )
          }
          rightSideInput={inputAppend}
          onBlurInputValue={handleBlurInputValue}
          selectedOperatingStage={selectedOperatingStage}
          setPlacementStageAssetType={setPlacementStageAssetType}
          setPlacementStageAvailableAssetType={
            setPlacementStageAvailableAssetType
          }
        />
      )}
    </Box>
  )
}

export default BusinessOperatingPlacementStageAssetTypes
