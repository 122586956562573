import { httpClient } from '../../axios-wrapper/httpClient'
import * as urls from '../../urls/assetType'
import * as types from '../../models/assetType'

export const AddBusinessAssetType = async (
  request: types.BusinessAssetTypeRequest
): Promise<void> => {
  await httpClient.post(urls.AddBusinessAssetType, request)
}

export const DeleteBusinessAssetType = async (
  request: types.BusinessAssetTypeRequest
): Promise<void> => {
  await httpClient.post(urls.DeleteBusinessAssetType, request)
}

export const GetBusinessAssetType = async (
  businessId: string
): Promise<types.BusinessAssetTypeResponse> => {
  const response = await httpClient.get(
    `${urls.GetBusinessAssetTypes}?businessId=${businessId}`
  )
  return response.data
}
