import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

import Header from 'src/components/Header'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getServicerDetails } from 'src/infra/api/services/servicer'
import ServicerBusinessTab from './components/ServicerBusinessTab'
import { CLIENT_PROFILES_MANAGE } from 'src/routes'
import { BusinessServicerResponseType } from 'src/infra/api/models/servicer'

const ServicerBusinessManage = () => {
  const { servicerBusinessId, clientId } = useParams<any>()

  const { data: getServicerData, isFetching: servicerLoading } =
    useCustomQuery<BusinessServicerResponseType>(
      ['getServicerDetails', servicerBusinessId],
      async () => {
        return getServicerDetails(servicerBusinessId)
      },
      {
        enabled: !!servicerBusinessId,
      }
    )

  return (
    <>
      <Box p={5}>
        <Header
          title={
            getServicerData ? getServicerData.name : 'Create a New Servicer'
          }
          customBackUrl={`${CLIENT_PROFILES_MANAGE}/${clientId}`}
        />
      </Box>
      <Box p={5}>
        <ServicerBusinessTab
          clientId={clientId}
          servicerBusinessId={servicerBusinessId}
          servicerData={getServicerData}
          servicerLoading={servicerLoading}
        />
      </Box>
    </>
  )
}

export default ServicerBusinessManage
