import React, { useState, useMemo } from 'react'
import { Tabs, Tab, Paper, Divider } from '@mui/material'

import TabPanel from 'src/components/TabPanel'
import RoleTable from './RoleTable'
import UsersTable from './UsersTable'

enum TabControls {
  ROLES = 'roles',
  USERS = 'users',
}

interface ControlTabsProps {
  onAddOrUpdateRole: (
    roleId: string | null | undefined,
    roleName: string | null | undefined,
    roleDescription: string | null | undefined,
    roleTenantId: string | null | undefined,
    actionType: string
  ) => void
  selectedTenantId: string
}
const ControlTabs: React.FC<ControlTabsProps> = ({
  onAddOrUpdateRole,
  selectedTenantId,
}: ControlTabsProps) => {
  const [value, setValue] = useState(String(TabControls.ROLES))
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Paper>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Roles and Users"
      >
        <Tab label="Roles" value={TabControls.ROLES} />
        <Tab label="Users" value={TabControls.USERS} />
      </Tabs>
      <Divider />
      <TabPanel value={value} index={TabControls.ROLES}>
        {useMemo(
          () => (
            <RoleTable
              onAddOrUpdateRole={onAddOrUpdateRole}
              selectedTenantId={selectedTenantId}
            />
          ),
          [onAddOrUpdateRole, selectedTenantId]
        )}
      </TabPanel>
      <TabPanel value={value} index={TabControls.USERS}>
        {useMemo(
          () => (
            <UsersTable selectedTenantId={selectedTenantId} />
          ),
          [selectedTenantId]
        )}
      </TabPanel>
    </Paper>
  )
}

export default ControlTabs
