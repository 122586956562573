import { gql, useMutation, MutationUpdaterFn } from '@apollo/client'

export const ADD_OR_UPDATE_BUYER = gql`
  mutation AddOrUpdateBuyer($buyer: BusinessBuyerRequestType!) {
    addOrUpdateBuyer(buyer: $buyer) {
      id
      name
      bankName
      clientId
    }
  }
`

export const UPDATE_BUYER_BCO_DATA = gql`
  mutation UpdateBuyerBcoParameters($request: BuyerBcoUpdateRequest!) {
    updateBuyerBcoParameters(request: $request) {
      buyerId
      bcoBuyerType
      bcoAgenciesCertified
      bcoOfficerBackgroundChecks
      bcoAttestation
      bcoFinancials
      bcoComplianceNotes
      bcoEnabled
      hasNoAgencyInformation
    }
  }
`

export const UPLOAD_BUYER_BCO_FILE = gql`
  mutation uploadBuyerBcoFile(
    $businessId: String!
    $fileType: String!
    $inputFileName: String!
    $file: Upload!
  ) {
    uploadBuyerBcoFile(
      businessId: $businessId
      fileType: $fileType
      inputFileName: $inputFileName
      file: $file
    )
  }
`

export const DELETE_BUYER_BCO_FILE = gql`
  mutation deleteBuyerBcoFile($businessId: String!, $fileType: String!) {
    deleteBuyerBcoFile(businessId: $businessId, fileType: $fileType)
  }
`

interface Params {
  onCompleted?: (data: any) => void
}

export function useDeleteBuyerBcoFile({ onCompleted }: Params) {
  const [deleteBuyerBcoFile, { data, error, loading }] = useMutation(
    DELETE_BUYER_BCO_FILE,
    { onCompleted }
  )
  return { deleteBuyerBcoFile, data, error, loading }
}

export function useUploadBuyerBcoFile({ onCompleted }: Params) {
  const [uploadBuyerBcoFile, { data, error, loading }] = useMutation(
    UPLOAD_BUYER_BCO_FILE,
    { onCompleted }
  )
  return { uploadBuyerBcoFile, data, error, loading }
}

export function useUpdateBuyerBco(onCompleted?: (data: any) => void) {
  const [updateBuyerBcoParameters, { data, error, loading }] = useMutation(
    UPDATE_BUYER_BCO_DATA,
    {
      onCompleted,
    }
  )
  return { updateBuyerBcoParameters, data, error, loading }
}

export function useAddOrUpdateBuyer(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addOrUpdateBuyer, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_BUYER,
    {
      update,
      onCompleted,
    }
  )
  return { addOrUpdateBuyer, data, error, loading }
}
