import { gql } from '@apollo/client'

export const GET_CLIENTS = gql`
  query GetClients(
    $clientListRequest: ClientListRequest
    $showInternal: Boolean
  ) {
    getClients(
      clientListRequest: $clientListRequest
      showInternal: $showInternal
    ) {
      clientList {
        name
        tenantId
      }
    }
  }
`

export const GET_ROLE = gql`
  query GetRole($roleId: String!, $tenantId: String) {
    getRole(roleId: $roleId, tenantId: $tenantId) {
      id
      name
      description
      isBuyer
      isInternal
      isSeller
      isCreditor
      isVendor
      numberOfMembers
      clientName
    }
  }
`

export const GET_ALL_ROLES = gql`
  query GetAllRoles($roleListRequest: RoleListRequest!) {
    getAllRoles(roleListRequest: $roleListRequest) {
      roles {
        id
        name
        numberOfMembers
        description
        tenantId
      }
      numberOfItems
      totalCount
    }
  }
`

export const GET_ALL_USERS = gql`
  query GetAllUsers($userListPermissionRequest: UserListPermissionRequest!) {
    userListResponse: getAllUsers(
      userListPermissionRequest: $userListPermissionRequest
    ) {
      users {
        id
        userName
        clientName
        roleCount
      }
      numberOfItems
      totalCount
    }
  }
`

export const GET_USERS_OUT_ROLE = gql`
  query GetUsersOutRole($userAssociateListRequest: UserAssociateListRequest!) {
    userAssociateListResponse: getUsersOutRole(
      userAssociateListRequest: $userAssociateListRequest
    ) {
      users {
        id
        userName
        clientName
      }
    }
  }
`

export const GET_USERS_IN_ROLE = gql`
  query GetUsersInRole($userListPermissionRequest: UserListPermissionRequest!) {
    userListResponse: getUsersInRole(
      userListPermissionRequest: $userListPermissionRequest
    ) {
      users {
        id
        userName
        clientName
      }
    }
  }
`

export const GET_ROLE_IN_USERS = gql`
  query GetRolesInUser($roleListRequest: RoleListRequest!) {
    getRolesInUser(roleListRequest: $roleListRequest) {
      numberOfItems
      roles {
        id
        clientName
        name
        description
        numberOfMembers
        tenantId
      }
      totalCount
    }
  }
`

export const GET_USER = gql`
  query GetUser($userId: String!) {
    getUser(userId: $userId) {
      clientName
      clientId
      id
      userName
      roleCount
      isBuyer
      isSeller
      isInternal
      isCreditor
      isVendor
      isAgency
      isServicer
    }
  }
`

export const GET_PERMISSION_LIST = gql`
  query GetPermissionList($permissionListRequest: PermissionListRequest!) {
    getPermissionList(permissionListRequest: $permissionListRequest) {
      permissionType
      modules {
        permission
        permissionValueDescription
        description
        code
        name
        inherited
        inheritedFromRole {
          roleDescription
          roleId
        }
        features {
          name
          description
          code
          inherited
          permission
          permissionValueDescription
          inheritedFromRole {
            roleId
            roleDescription
          }
          actions {
            name
            permission
            permissionValueDescription
            code
            description
            inheritedFromRole {
              roleDescription
              roleId
            }
          }
        }
      }
    }
  }
`

export const GET_ROLES_OUT_USER = gql`
  query GetRolesOutUser($roleListRequest: RoleListRequest!) {
    getRolesOutUser(roleListRequest: $roleListRequest) {
      roles {
        id
        active
        clientName
        description
        id
        name
        numberOfMembers
        tenantId
      }
      totalCount
      numberOfItems
    }
  }
`
export const GET_ALL_USERS_ASSOCIATE = gql`
  query GetAllUsersAssociates(
    $userAssociateListRequest: UserAssociateListRequest!
  ) {
    userAssociateListResponse: getAllUsersAssociates(
      userAssociateListRequest: $userAssociateListRequest
    ) {
      users {
        id
        userName
        clientName
        associatedClient
        roleCount
        isInternal
      }
      numberOfItems
      totalCount
    }
  }
`
