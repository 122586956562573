import React, { useState, useEffect, useContext } from 'react'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  GET_CLIENT_ASSOCIATIONS,
  GET_CLIENT_PROFILES,
} from 'src/graphql/operations/queries/clientProfiles'
import {
  GetClients,
  GetClientAssociation,
} from 'src/graphql/models/clientProfiles'
import TransferList from 'src/components/TransferList'
import {
  useAddClientAssociation,
  useRemoveClientAssociation,
} from 'src/graphql/operations/mutations/clientProfiles'
import { Box, Skeleton } from '@mui/material'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

interface ClientAssociationsProps {
  clientProfileId: number
}

const removeDuplicateArrayById = (
  listDuplicated: any[] = [],
  listCompare: any[] = []
) => {
  if (listCompare.length) {
    const listCompareMapper = listCompare.reduce((acc, item) => {
      const ac = { ...acc }
      if (item && item.id) {
        ac[item.id] = item.id
      }
      return ac
    }, {})
    const listDuplacatedRemoved = listDuplicated.reduce((acc, item) => {
      const ac = [...acc]
      if (!listCompareMapper[item.id]) {
        ac.push(item)
      }
      return ac
    }, [])
    return listDuplacatedRemoved
  }
  return listDuplicated
}

const ClientAssociations = ({ clientProfileId }: ClientAssociationsProps) => {
  const ability = useContext(AbilityContext)
  const [clientList, setClientList] = useState<any[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const { data: getClientsData, loading } = useQuery<GetClients>(
    GET_CLIENT_PROFILES,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        clientListRequest: {
          skipDebtTrader: true,
        },
      },
    }
  )

  const { data: getClientAssociation, loading: clientAssociationLoading } =
    useQuery<GetClientAssociation>(GET_CLIENT_ASSOCIATIONS, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      variables: {
        clientId: Number(clientProfileId),
      },
      skip: !clientProfileId,
    })

  const handleAddClientAssociationCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Add client association successful', {
        variant: 'success',
      })
    }
  }

  const handleRemoveClientAssociationCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Client association removed successful', {
        variant: 'success',
      })
    }
  }

  const { addClientAssociation } = useAddClientAssociation(
    handleAddClientAssociationCompleted
  )

  const { removeClientAssociation } = useRemoveClientAssociation(
    handleRemoveClientAssociationCompleted
  )

  const handleSendLeftColumn = (data: any[]) => {
    if (data.length) {
      const associatedClientId = data.map((item) => item.id)
      removeClientAssociation({
        variables: {
          clientAssociation: {
            clientId: Number(clientProfileId),
            associatedClientId,
          },
        },
        refetchQueries: ['GetClientAssociation'],
      })
    }
  }

  const handleonSendRightColumn = (data: any[]) => {
    if (data.length) {
      const associatedClientId = data.map((item) => item.id)
      addClientAssociation({
        variables: {
          clientAssociation: {
            clientId: Number(clientProfileId),
            associatedClientId,
          },
        },
        refetchQueries: ['GetClientAssociation'],
      })
    }
  }

  useEffect(() => {
    if (
      getClientsData?.getClients.clientList &&
      clientProfileId &&
      !clientAssociationLoading &&
      !loading
    ) {
      const clientListFiltered = getClientsData.getClients.clientList.filter(
        (client) => client.id !== Number(clientProfileId)
      )
      const clientListNormalize = removeDuplicateArrayById(
        clientListFiltered,
        getClientAssociation?.getClientAssociation
      )
      setClientList(clientListNormalize)
    }
  }, [
    getClientsData,
    clientProfileId,
    getClientAssociation,
    clientAssociationLoading,
    loading,
  ])

  if (clientList.length > 0) {
    return (
      <TransferList
        loading={loading || clientAssociationLoading}
        leftData={clientList}
        rightData={getClientAssociation?.getClientAssociation || []}
        showAllButtons={false}
        onSendLeftColumn={handleSendLeftColumn}
        onSendRightColumn={handleonSendRightColumn}
        readonly={
          !ability.can(
            PermissionCodeAccess.CLIENT_ASSOCIATION_PERMISSION,
            'any'
          )
        }
      />
    )
  }
  return (
    <Box>
      <Skeleton variant="rectangular" width={400} height={300} />
    </Box>
  )
}

export default ClientAssociations
