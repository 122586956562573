interface States {
  codeCategory: string
  codeKey: string
  description: string
}

interface CommonSelectTootip {
  value: any
  label: string
  description: string
  icon?: string
  color?: string
}

interface CommonValue {
  value: any
  label: string
}

interface Country {
  codeCountry: string
  description: string
}

interface Category {
  codeCategory: string
  description: string
}

const CountriesData: Country[] = [
  { codeCountry: 'US', description: 'United States' },
  { codeCountry: 'CA', description: 'Canada' },
]

const Departments: CommonValue[] = [
  { value: 'Accounting', label: 'Accounting' },
  { value: 'Business-Development ', label: 'Business Development ' },
  { value: 'Compliance', label: 'Compliance' },
  { value: 'Debt-Strategies', label: 'Debt Strategies' },
  { value: 'Development', label: 'Development' },
  { value: 'Human-Resources', label: 'Human Resources' },
  { value: 'Product', label: 'Product' },
  { value: 'Recovery-Success', label: 'Recovery Success' },
]

const AccountTypeData: CommonValue[] = [
  { value: 'Checking', label: 'Checking' },
  { value: 'Saving', label: 'Saving' },
]

const DefaultStatus: CommonValue[] = [
  { value: 'Active', label: 'Active' },
  { value: 'Inactive', label: 'Inactive' },
]

const AlternativeStatus: CommonValue[] = [
  { value: 'enabled', label: 'Enabled' },
  { value: 'disabled', label: 'Disabled' },
]

const CategoriesData: Category[] = [
  { codeCategory: 'Agency', description: 'Agency' },
  { codeCategory: 'Buyer', description: 'Buyer' },
  { codeCategory: 'Seller', description: 'Seller' },
  { codeCategory: 'Servicer', description: 'Servicer' },
  { codeCategory: 'Law Firm', description: 'Law Firm' },
]

const CanadaStatesData = [
  { codeCategory: 'CA-PROVINCE', codeKey: 'AB', description: ' Alberta' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'BC',
    description: ' British Columbia',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'MB', description: ' Manitoba' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'NB', description: ' New Brunswick' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'NL',
    description: ' Newfoundland and Labrador',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'NS', description: ' Nova Scotia' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'NT',
    description: ' Northwest Territories',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'NU', description: ' Nunavut' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'ON', description: ' Ontario' },
  {
    codeCategory: 'CA-PROVINCE',
    codeKey: 'PE',
    description: ' Prince Edward Island',
  },
  { codeCategory: 'CA-PROVINCE', codeKey: 'QC', description: ' Quebec' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'SK', description: ' Saskatchewan' },
  { codeCategory: 'CA-PROVINCE', codeKey: 'YT', description: ' Yukon' },
]

const EUAStateData = [
  { codeCategory: 'US-STATE', codeKey: 'AK', description: 'Alaska' },
  { codeCategory: 'US-STATE', codeKey: 'AL', description: 'Alabama' },
  { codeCategory: 'US-STATE', codeKey: 'AR', description: 'Arkansas' },
  { codeCategory: 'US-STATE', codeKey: 'AZ', description: 'Arizona' },
  { codeCategory: 'US-STATE', codeKey: 'CA', description: 'California' },
  { codeCategory: 'US-STATE', codeKey: 'CO', description: 'Colorado' },
  { codeCategory: 'US-STATE', codeKey: 'CT', description: 'Connecticut' },
  {
    codeCategory: 'US-STATE',
    codeKey: 'DC',
    description: 'District of Columbia',
  },
  { codeCategory: 'US-STATE', codeKey: 'DE', description: 'Delaware' },
  { codeCategory: 'US-STATE', codeKey: 'FL', description: 'Florida' },
  { codeCategory: 'US-STATE', codeKey: 'GA', description: 'Georgia' },
  { codeCategory: 'US-STATE', codeKey: 'HI', description: 'Hawaii' },
  { codeCategory: 'US-STATE', codeKey: 'IA', description: 'Iowa' },
  { codeCategory: 'US-STATE', codeKey: 'ID', description: 'Idaho' },
  { codeCategory: 'US-STATE', codeKey: 'IL', description: 'Illinois' },
  { codeCategory: 'US-STATE', codeKey: 'IN', description: 'Indiana' },
  { codeCategory: 'US-STATE', codeKey: 'KS', description: 'Kansas' },
  { codeCategory: 'US-STATE', codeKey: 'KY', description: 'Kentucky' },
  { codeCategory: 'US-STATE', codeKey: 'LA', description: 'Louisiana' },
  { codeCategory: 'US-STATE', codeKey: 'MA', description: 'Massachusetts' },
  { codeCategory: 'US-STATE', codeKey: 'MD', description: 'Maryland' },
  { codeCategory: 'US-STATE', codeKey: 'ME', description: 'Maine' },
  { codeCategory: 'US-STATE', codeKey: 'MI', description: 'Michigan' },
  { codeCategory: 'US-STATE', codeKey: 'MN', description: 'Minnesota' },
  { codeCategory: 'US-STATE', codeKey: 'MO', description: 'Missouri' },
  { codeCategory: 'US-STATE', codeKey: 'MS', description: 'Mississippi' },
  { codeCategory: 'US-STATE', codeKey: 'MT', description: 'Montana' },
  { codeCategory: 'US-STATE', codeKey: 'NC', description: 'North Carolina' },
  { codeCategory: 'US-STATE', codeKey: 'ND', description: 'North Dakota' },
  { codeCategory: 'US-STATE', codeKey: 'NE', description: 'Nebraska' },
  { codeCategory: 'US-STATE', codeKey: 'NH', description: 'New Hampshire' },
  { codeCategory: 'US-STATE', codeKey: 'NJ', description: 'New Jersey' },
  { codeCategory: 'US-STATE', codeKey: 'NM', description: 'New Mexico' },
  { codeCategory: 'US-STATE', codeKey: 'NV', description: 'Nevada' },
  { codeCategory: 'US-STATE', codeKey: 'NY', description: 'New York' },
  { codeCategory: 'US-STATE', codeKey: 'OH', description: 'Ohio' },
  { codeCategory: 'US-STATE', codeKey: 'OK', description: 'Oklahoma' },
  { codeCategory: 'US-STATE', codeKey: 'OR', description: 'Oregon' },
  { codeCategory: 'US-STATE', codeKey: 'PA', description: 'Pennsylvania' },
  { codeCategory: 'US-STATE', codeKey: 'RI', description: 'Rhode Island' },
  { codeCategory: 'US-STATE', codeKey: 'SC', description: 'South Carolina' },
  { codeCategory: 'US-STATE', codeKey: 'SD', description: 'South Dakota' },
  { codeCategory: 'US-STATE', codeKey: 'TN', description: 'Tennessee' },
  { codeCategory: 'US-STATE', codeKey: 'TX', description: 'Texas' },
  { codeCategory: 'US-STATE', codeKey: 'UT', description: 'Utah' },
  { codeCategory: 'US-STATE', codeKey: 'VA', description: 'Virginia' },
  { codeCategory: 'US-STATE', codeKey: 'VT', description: 'Vermont' },
  { codeCategory: 'US-STATE', codeKey: 'WA', description: 'Washington' },
  { codeCategory: 'US-STATE', codeKey: 'WI', description: 'Wisconsin' },
  { codeCategory: 'US-STATE', codeKey: 'WV', description: 'West Virginia' },
  { codeCategory: 'US-STATE', codeKey: 'WY', description: 'Wyoming' },
]

const UKStateData = [
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Bath and North East Somerset',
    description: 'Bath and North East Somerset',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Bedford', description: 'Bedford' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Blackburn with Darwen',
    description: 'Blackburn with Darwen',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Blackpool', description: 'Blackpool' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Bournemouth',
    description: 'Bournemouth',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Bracknell Forest',
    description: 'Bracknell Forest',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Brighton & Hove',
    description: 'Brighton & Hove',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Bristol', description: 'Bristol' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Buckinghamshire',
    description: 'Buckinghamshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Cambridgeshire',
    description: 'Cambridgeshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Central Bedfordshire',
    description: 'Central Bedfordshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Cheshire East',
    description: 'Cheshire East',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Cheshire West and Chester',
    description: 'Cheshire West and Chester',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Cornwall', description: 'Cornwall' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'County Durham',
    description: 'County Durham',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Cumbria', description: 'Cumbria' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Darlington',
    description: 'Darlington',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Derby', description: 'Derby' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Derbyshire',
    description: 'Derbyshire',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Devon', description: 'Devon' },
  { codeCategory: 'UK-STATE', codeKey: 'Dorset', description: 'Dorset' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'East Riding of Yorkshire',
    description: 'East Riding of Yorkshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'East Sussex',
    description: 'East Sussex',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Gloucestershire',
    description: 'Gloucestershire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Greater London',
    description: 'Greater London',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Greater Manchester',
    description: 'Greater Manchester',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Halton', description: 'Halton' },
  { codeCategory: 'UK-STATE', codeKey: 'Hampshire', description: 'Hampshire' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Hartlepool',
    description: 'Hartlepool',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Herefordshire',
    description: 'Herefordshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Hertfordshire',
    description: 'Hertfordshire',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Hull', description: 'Hull' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Isle of Wight',
    description: 'Isle of Wight',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Isles of Scilly',
    description: 'Isles of Scilly',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Kent', description: 'Kent' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Lancashire',
    description: 'Lancashire',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Leicester', description: 'Leicester' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Leicestershire',
    description: 'Leicestershire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Lincolnshire',
    description: 'Lincolnshire',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Luton', description: 'Luton' },
  { codeCategory: 'UK-STATE', codeKey: 'Medway', description: 'Medway' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Merseyside',
    description: 'Merseyside',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Middlesbrough',
    description: 'Middlesbrough',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Milton Keynes',
    description: 'Milton Keynes',
  },
  { codeCategory: 'UK-STATE', codeKey: 'Norfolk', description: 'Norfolk' },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'North East Lincolnshire',
    description: 'North East Lincolnshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'North Lincolnshire',
    description: 'North Lincolnshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'North Somerset',
    description: 'North Somerset',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'North Yorkshire',
    description: 'North Yorkshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'North Somerset',
    description: 'North Somerset',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Northamptonshire',
    description: 'Northamptonshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Northumberland',
    description: 'Northumberland',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Nottingham',
    description: 'Nottingham',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Nottinghamshire',
    description: 'Nottinghamshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Oxfordshire',
    description: 'Oxfordshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Peterborough',
    description: 'Peterborough',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Plymouth',
    description: 'Plymouth',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Poole',
    description: 'Poole',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Portsmouth',
    description: 'Portsmouth',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Reading',
    description: 'Reading',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Redcar and Cleveland',
    description: 'Redcar and Cleveland',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Rutland',
    description: 'Rutland',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Shropshire',
    description: 'Shropshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Slough',
    description: 'Slough',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Somerset',
    description: 'Somerset',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'South Gloucestershire',
    description: 'South Gloucestershire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'South Yorkshire',
    description: 'South Yorkshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Southampton',
    description: 'Southampton',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Southend-on-Sea',
    description: 'Southend-on-Sea',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Staffordshire',
    description: 'Staffordshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Stockton-on-Tees',
    description: 'Stockton-on-Tees',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Stoke-on-Trent',
    description: 'Stoke-on-Trent',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Suffolk',
    description: 'Suffolk',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Surrey',
    description: 'Surrey',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Swindon',
    description: 'Swindon',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Telford and Wrekin',
    description: 'Telford and Wrekin',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Thurrock',
    description: 'Thurrock',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Torbay',
    description: 'Torbay',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Tyne and Wear',
    description: 'Tyne and Wear',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Warrington',
    description: 'Warrington',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Warwickshire',
    description: 'Warwickshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'West Berkshire',
    description: 'West Berkshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'West Midlands',
    description: 'West Midlands',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'West Sussex',
    description: 'West Sussex',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'West Yorkshire',
    description: 'West Yorkshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Wiltshire',
    description: 'Wiltshire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Windsor and Maidenhead',
    description: 'Windsor and Maidenhead',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Wokingham',
    description: 'Wokingham',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'Worcestershire',
    description: 'Worcestershire',
  },
  {
    codeCategory: 'UK-STATE',
    codeKey: 'York',
    description: 'York',
  },
]

const StatusData = [
  {
    value: 'Approved',
    label: 'Approved',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
  {
    value: 'Terminated',
    label: 'Terminated',
  },
]

const BusinessStatusData = [
  {
    value: 'Approved',
    label: 'Accepted',
  },
  {
    value: 'Application In Process',
    label: 'Application In Process',
  },
  {
    value: 'pre-approved',
    label: 'RMAi Certified Members',
  },
  {
    value: 'Suspended',
    label: 'Suspended',
  },
  {
    value: 'Terminated',
    label: 'Terminated',
  },
  {
    value: 'Terminated - Out of Business',
    label: 'Terminated - Out of Business',
  },
]

const MilitaryCertificates = [
  { label: 'No Certificates', value: 0 },
  { label: 'All Certificates', value: 1 },
  { label: 'Active Duty Only', value: 2 },
  { label: 'Non-Active Duty Only', value: 3 },
]

interface IStatesStategy {
  [field: string]: any
  US: States[]
  CA: States[]
  UK: States[]
}

const StatesStategy: IStatesStategy = {
  US: EUAStateData,
  CA: CanadaStatesData,
  UK: UKStateData,
}

const BuyerType: CommonSelectTootip[] = [
  {
    label: 'Active',
    value: 'Active',
    description: 'This buyer is active on the platform.',
  },
  {
    label: 'Passive',
    value: 'Passive',
    description: 'This buyer is on the platform, but does not actively engage.',
  },
]

const AgenciesCertified: CommonSelectTootip[] = [
  {
    label: 'Agencies EC Certified | Provided to EverChain',
    value: 2,
    description:
      'Collection Agency identity has been provided to EverChain and Agencies have been EverChain certified.',
    icon: 'check',
    color: '#00c4b3',
  },
  {
    label: 'Not EC Certified | Will Provide to Seller',
    value: 1,
    description:
      'Collection Agency identity has not been provided to EverChain nor have agencies been EverChain certified. Buyer is willing to provide the identity of the Collection Agencies to the Seller.',
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'Not EC Certified | Not Provided to EverChain/Seller',
    value: 0,
    description:
      'Collection Agency identity has not been provided to EverChain nor have Agencies been EverChain certified. Buyer is unwilling to provide the identity of the Collection Agencies to the Seller.',
    icon: 'close',
    color: 'red',
  },
]

const BackgroundChecks: CommonSelectTootip[] = [
  {
    label: 'EC Can Perform Background Checks',
    value: 2,
    description:
      "Permission to perform background checks on the organization's principals has been granted to EverChain.",
    icon: 'check',
    color: '#00c4b3',
  },
  {
    label: 'May Allow Seller to Perform Background Checks',
    value: 1,
    description:
      "Permission to perform background checks on the organization's principals has not been granted to EverChain but may be to Seller.",
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'No Background Checks',
    value: 0,
    description:
      "Permission to perform background checks on the organization's principals will not be granted.",
    icon: 'close',
    color: 'red',
  },
]

const LicenseAttestation: CommonSelectTootip[] = [
  {
    label: 'Available | Provided to EverChain',
    value: 2,
    description:
      'Item has been provided to EverChain and is contained within the Due Diligence package.',
    icon: 'check',
    color: '#00c4b3',
  },
  {
    label: 'Available from Buyer | Not Provided to EverChain',
    value: 1,
    description:
      'Item has not been provided to EverChain but Buyer is willing to provide directly to the Seller.',
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'Not Available | Not Provided to EverChain',
    value: 0,
    description:
      'Item has not been provided to EverChain nor is the Buyer willing to provide directly to the Seller.',
    icon: 'close',
    color: 'red',
  },
]

const FinancialStatements: CommonSelectTootip[] = [
  {
    label: 'Available | Provided to EverChain',
    value: 2,
    description:
      'Financials are available in EverChain and can be downloaded from the platform.',
    icon: 'check',
    color: '#00c4b3',
  },
  {
    label: 'Available from Buyer | Not Provided to EverChain',
    value: 1,
    description:
      'Financials are not available in EverChain, but the buyer will provide them directly to the Seller upon request.',
    icon: 'arrows-swap',
    color: 'gold',
  },
  {
    label: 'Not Available | Not Provided to EverChain',
    value: 0,
    description:
      'Financials are not available in EverChain nor will the buyer provide them to the Seller.',
    icon: 'close',
    color: 'red',
  },
]
const AgreementTemplatesType: CommonSelectTootip[] = [
  {
    label: 'Purchase Sale Agreement',
    value: 'psa',
    description: 'Purchase Sale Agreement',
  },
  {
    label: 'Bill of Sale',
    value: 'bos',
    description: 'Bill of Sale',
  },
  {
    label: 'Non-Disclosure Agreement',
    value: 'nda',
    description: 'Non-Disclosure Agreement',
  },
]

class HardCoreData {
  private states = StatesStategy
  private status = StatusData
  private countries = CountriesData
  private category = CategoriesData
  private accountType = AccountTypeData
  private defaultStatus = DefaultStatus
  private militaryCertificates = MilitaryCertificates
  private businessStatus = BusinessStatusData
  private buyerType = BuyerType
  private agenciesCertified = AgenciesCertified
  private backgroundChecks = BackgroundChecks
  private licenceAttetation = LicenseAttestation
  private financialStatements = FinancialStatements
  private alternativeStatus = AlternativeStatus
  private agreementTemplatesType = AgreementTemplatesType
  private departments = Departments

  getDepartments(): CommonValue[] {
    return this.departments
  }

  getAlternativeStatus(): CommonValue[] {
    return this.alternativeStatus
  }

  getFinancialStatements(): CommonSelectTootip[] {
    return this.financialStatements
  }
  getLicenseAttestation(): CommonSelectTootip[] {
    return this.licenceAttetation
  }
  getBackgroundChecks(): CommonSelectTootip[] {
    return this.backgroundChecks
  }
  getAgenciesCertified(): CommonSelectTootip[] {
    return this.agenciesCertified
  }

  getBuyerType(): CommonSelectTootip[] {
    return this.buyerType
  }
  getAgreementTemplates(): CommonSelectTootip[] {
    return this.agreementTemplatesType
  }

  getCountries(): Country[] {
    return this.countries
  }

  getCategories(): Category[] {
    return this.category
  }

  getStates(type?: string | 'US' | 'CA' | 'UK' | undefined): States[] {
    return this.states[type || 'US'] || []
  }

  getDefaultStatus(): CommonValue[] {
    return this.defaultStatus
  }

  getStatus(): CommonValue[] {
    return this.status
  }

  getAccountType(): CommonValue[] {
    return this.accountType
  }

  getMilitaryCertificates(): CommonValue[] {
    return this.militaryCertificates
  }

  getBusinessStatus(buyerType?: string): CommonValue[] {
    let items = this.businessStatus
    if (this.shouldHidePreAprovedStatus(buyerType)) {
      items = items.filter((x) => x.value !== 'pre-approved')
    }
    return items
  }

  shouldHidePreAprovedStatus(businessType?: string): boolean {
    return (
      process.env.REACT_APP_COUNTRY === 'UK' ||
      businessType?.toLowerCase() !== 'buyer'
    )
  }
}

export default new HardCoreData()
