import { useSnackbar } from 'notistack'
import React, { useContext, useEffect } from 'react'
import { authUrl } from 'src/configs/authConst'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AuthContext } from 'src/context/AuthenticationContext'
import { USER_ID } from 'src/utils/constants'

interface PasswordExpiringProps {
  children: React.ReactNode
}

const PasswordExpiringNotification: React.FC<PasswordExpiringProps> = ({
  children,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useContext(AuthContext)
  const notify = notistackOptions('warning')

  useEffect(() => {
    if (user) {
      const url = `${authUrl}/account/IsPasswordExpiring?userId=${user.profile[USER_ID]}`
      fetch(url, {
        method: 'GET',
      })
        .then((res) => {
          if (res.ok) return res.json()

          const err = new Error(res.statusText)
          throw err
        })
        .then((body) => {
          if (body.isPasswordExpiring)
            enqueueSnackbar(
              `Your password will expire in ${body.expiringIn} days.`,
              notify
            )
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log('Unable to get password information'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, notify])

  return <>{children}</>
}

export default PasswordExpiringNotification
