import React, { useEffect, useState } from 'react'
import { Box, MenuItem, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { BusinessSummary } from 'src/graphql/models/clientProfiles'
import { useAddBuyerBidAccess } from 'src/graphql/operations/mutations/businesses'
import { BuyerBidAccess } from 'src/graphql/models/sellerBusiness'
import { ModalDialog } from 'everchain-uilibrary'

interface BuyerBidAccessForm {
  onClose: () => void
  open: boolean
  buyersOutOfList?: BusinessSummary[] | undefined
  loading?: boolean | undefined
  sellerId: string
  buyerData?: BuyerBidAccess | undefined
}

const buyerBidAccessFormSchema = Yup.object().shape({
  buyerBusinessId: Yup.string().required('Required'),
})
// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
const BuyerBidAccessForm = ({
  onClose,
  open,
  buyersOutOfList,
  loading,
  sellerId,
  buyerData,
}: BuyerBidAccessForm) => {
  const [initialValues, setInitialValues] = useState({
    buyerBusinessId: '',
    comment: '',
  })
  const [openConfirm, setOpenConfirm] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const handleAddBuyerBidAccessCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Buyer access record was added successfully', {
        variant: 'success',
      })
    }
    buyerBidAccessForm.resetForm()
    handleCloseConfirm()
  }

  const handleAddBuyerBidAccessError = (data: any) => {
    if (data) {
      enqueueSnackbar(
        'There was an error while trying to add the buyer access record.',
        {
          variant: 'error',
        }
      )
    }
    buyerBidAccessForm.resetForm()
    handleCloseConfirm()
  }

  const { addBuyerBidAccess, loading: buyerBidAccessLoading } =
    useAddBuyerBidAccess(
      handleAddBuyerBidAccessCompleted,
      handleAddBuyerBidAccessError
    )

  const buyerBidAccessForm = useFormik({
    initialValues,
    validationSchema: buyerBidAccessFormSchema,
    enableReinitialize: true,
    onSubmit: ({ buyerBusinessId, comment }, { setSubmitting }) => {
      if (buyerData?.id) {
        addBuyerBidAccess({
          variables: {
            buyerBidAccess: {
              id: buyerData.id,
              sellerId,
              buyerBusinessId,
              comment,
            },
          },
          refetchQueries: ['GetBuyerBidAccess'],
        })
      } else {
        addBuyerBidAccess({
          variables: {
            buyerBidAccess: {
              sellerId,
              buyerBusinessId,
              comment,
            },
          },
          refetchQueries: ['GetBuyerBidAccess'],
        })
      }
      setSubmitting(false)
    },
  })
  const buyerOutOfListRender = () => {
    if (loading && !buyersOutOfList?.length) {
      return <MenuItem>Loading</MenuItem>
    }
    return buyersOutOfList?.length ? (
      buyersOutOfList.map((buyerOutOfList) => (
        <MenuItem value={buyerOutOfList.id} key={buyerOutOfList.id}>
          {buyerOutOfList.name}
        </MenuItem>
      ))
    ) : buyerData ? (
      <MenuItem
        value={buyerData.buyerBusinessId}
        key={buyerData.buyerBusinessId}
      >
        {buyerData.name}
      </MenuItem>
    ) : (
      <MenuItem>Empty</MenuItem>
    )
  }

  const handleAddBuyer = () => {
    onClose()
    setOpenConfirm(true)
  }

  const handleConfirmAddBuyer = () => {
    buyerBidAccessForm.handleSubmit()
  }

  const handleCloseConfirm = () => {
    setOpenConfirm(false)
  }

  const handleCloseAddBuyer = () => {
    buyerBidAccessForm.resetForm()
    onClose()
  }

  useEffect(() => {
    if (buyerData) {
      setInitialValues((prevState) => ({
        ...prevState,
        ...buyerData,
      }))
    }
  }, [buyerData])

  return (
    <>
      <ModalDialog
        isOpen={open}
        header={buyerData?.id ? 'Edit Buyer' : 'Add Buyer'}
        onClose={handleCloseAddBuyer}
        disableOkButton={loading || buyerBidAccessLoading}
        isFetching={loading || buyerBidAccessLoading}
        buttonOkText="Save"
        onContinue={handleAddBuyer}
      >
        <Box mb={4}>
          <TextField
            label="Buyer"
            name="buyerBusinessId"
            fullWidth
            select
            value={buyerBidAccessForm.values.buyerBusinessId}
            onChange={buyerBidAccessForm.handleChange}
            error={!!buyerBidAccessForm.errors.buyerBusinessId}
            helperText={buyerBidAccessForm.errors.buyerBusinessId}
          >
            {buyerOutOfListRender()}
          </TextField>
        </Box>
        <Box mb={4}>
          <TextField
            fullWidth
            multiline
            placeholder="Comment"
            name="comment"
            maxRows={4}
            value={buyerBidAccessForm.values.comment}
            onChange={buyerBidAccessForm.handleChange}
            error={!!buyerBidAccessForm.errors.comment}
            helperText={buyerBidAccessForm.errors.comment}
          />
        </Box>
      </ModalDialog>
      <ModalDialog
        isOpen={openConfirm}
        header="Add buyer"
        onClose={handleCloseConfirm}
        disableOkButton={loading || buyerBidAccessLoading}
        isFetching={loading || buyerBidAccessLoading}
        onContinue={handleConfirmAddBuyer}
        buttonOkText="Confirm"
      >
        <Box mb={4}>
          <Typography align="center">
            This buyer will be added to future auctions only. This buyer will
            NOT be added to currently open auctions.
          </Typography>
        </Box>
      </ModalDialog>
    </>
  )
}

BuyerBidAccessForm.defaultProps = {
  buyersOutOfList: [],
  loading: false,
  buyerData: null,
}

export default BuyerBidAccessForm
