import React from 'react'
import { Container, Typography, Box } from '@mui/material'

const NotFound: React.FC = () => {
  return (
    <Container>
      <Box mt={6}>
        <Typography variant="h2">Ops, Page not found</Typography>
      </Box>
    </Container>
  )
}

export default NotFound
