import React, { useState } from 'react'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  Form,
  FormDropdown,
  FormInput,
  FormModalSubmitButton,
  Grid,
  ModalDialog,
  FormSwitch,
  Colors,
  Tooltip,
  Icon,
} from 'everchain-uilibrary'
import { notistackOptions } from 'src/configs/notistackOptions'
import { enqueueSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import {
  addBusinessFee,
  updateBusinessFee,
} from 'src/infra/api/services/business'
import {
  AddBusinessFeeRequest,
  BusinessFee,
  UpdateBusinessFeeRequest,
} from 'src/infra/api/models/business'
import { getPortfolioTypes } from 'src/infra/api/services/portfolio'
import { PortfolioType } from 'src/infra/api/models/portfolio'

interface BusinessFeeAddOrEditModalProps {
  businessId?: string
  businessFee?: BusinessFee
  allBusinessFee?: BusinessFee[]
  modalOpen?: boolean
  onCloseModal?: () => void
}

const BusinessFeeAddOrEditModal: React.FC<BusinessFeeAddOrEditModalProps> = ({
  businessId,
  businessFee,
  allBusinessFee,
  modalOpen,
  onCloseModal,
}) => {
  const [addBusinessFeeRequest, setAddBusinessFeeRequest] =
    useState<AddBusinessFeeRequest>()
  const [updateBusinessFeeRequest, setUpdateBusinessFeeRequest] =
    useState<UpdateBusinessFeeRequest>()
  const isEditing = businessFee?.id
  const portfolioTypesAlreadyExists = allBusinessFee?.map(
    (x) => x.portfolioTypeId
  )

  const initialValues = {
    id: businessFee?.id,
    businessId: businessId,
    portfolioTypeId: businessFee?.portfolioTypeId,
    feePercent: businessFee?.feePercent
      ? (businessFee.feePercent * 100).toFixed(4)
      : businessFee?.feePercent,
    forwardFlowFeePercent: businessFee?.forwardFlowFeePercent
      ? (businessFee.forwardFlowFeePercent * 100).toFixed(4)
      : businessFee?.forwardFlowFeePercent,
    minimumFee: businessFee?.minimumFee,
    enforceMinimumFee: businessFee?.enforceMinimumFee,
  }

  const reactQueryClient = useQueryClient()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { data: portfolioTypeData, isFetching: portfolioTypeDataLoading } =
    useCustomQuery<PortfolioType[]>(
      ['getPortfolioTypes'],
      async () => {
        return getPortfolioTypes()
      },
      { cacheTime: 0 }
    )

  const { isFetching: addBusinessFeeLoading } = useCustomQuery(
    ['addBusinessFee', addBusinessFeeRequest],
    async () => {
      if (addBusinessFeeRequest) {
        return addBusinessFee(addBusinessFeeRequest)
          .then(() => {
            enqueueSnackbar('Service fee created.', notifySuccess)
            onCloseModal?.()
            reactQueryClient.refetchQueries({
              queryKey: ['getBusinessFee'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error when creating the service fee.', notifyError)
          })
          .finally(() => {
            setAddBusinessFeeRequest(undefined)
          })
      }
    },
    {
      enabled: !!addBusinessFeeRequest,
      cacheTime: 0,
    }
  )

  const { isFetching: updateBusinessFeeLoading } = useCustomQuery(
    ['updateBusinessFee', updateBusinessFeeRequest],
    async () => {
      if (updateBusinessFeeRequest) {
        return updateBusinessFee(updateBusinessFeeRequest)
          .then(() => {
            enqueueSnackbar('Service fee updated.', notifySuccess)
            onCloseModal?.()
            reactQueryClient.refetchQueries({
              queryKey: ['getBusinessFee'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error when updating the service fee.', notifyError)
          })
          .finally(() => {
            setUpdateBusinessFeeRequest(undefined)
          })
      }
    },
    {
      enabled: !!updateBusinessFeeRequest,
      cacheTime: 0,
    }
  )

  const getModalHeader = () => {
    const addOrEdit = businessFee?.id ? 'Update' : 'Create'
    return `${addOrEdit} Service Fee`
  }

  const onSubmit = (data: any) => {
    const commonFeeRequest = {
      feePercent: data?.feePercent ? data.feePercent / 100 : data?.feePercent,
      forwardFlowFeePercent: data?.forwardFlowFeePercent
        ? data.forwardFlowFeePercent / 100
        : data?.forwardFlowFeePercent,
      minimumFee: data?.minimumFee
        ? Number(data?.minimumFee)
        : data?.minimumFee,
      enforceMinimumFee: data?.enforceMinimumFee,
    }

    if (isEditing) {
      setUpdateBusinessFeeRequest({
        id: data.id,
        ...commonFeeRequest,
      })
    } else {
      setAddBusinessFeeRequest({
        businessId: data?.businessId,
        portfolioTypeId: data?.portfolioTypeId,
        ...commonFeeRequest,
      })
    }
  }

  const getPortfolioTypeOptions = (): any[] => {
    if (!portfolioTypeData) {
      return []
    }

    if (isEditing) {
      return portfolioTypeData
    } else {
      return portfolioTypeData?.filter(
        (x) => !portfolioTypesAlreadyExists?.includes(x.id)
      )
    }
  }

  return (
    <ModalDialog
      isOpen={modalOpen}
      header={getModalHeader()}
      buttonOkText="Save"
      disableOkButton={false}
      onClose={() => {
        onCloseModal?.()
      }}
      width="60%"
      maxwidth="500px"
      isForm
    >
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={11}>
            <FormDropdown
              name="portfolioTypeId"
              placeholder="Portfolio Type"
              options={getPortfolioTypeOptions()}
              valueOptionName="id"
              labelOptionName="typeName"
              isLoading={portfolioTypeDataLoading}
              validation={{
                required: 'Portfolio Type is required',
              }}
              width="100%"
              disabled={!!isEditing ?? false}
            />
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '15px',
            }}
          >
            <Tooltip
              title="The dropdown only shows portfolio types that have not yet been created."
              disabled={!!isEditing}
            >
              <Icon
                name="InfoOutlined"
                color={isEditing ? Colors.mediumGray : Colors.primary}
                fontSize="small"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="feePercent"
              placeholder="Fee Percent"
              type="number"
              afterValue="%"
              validation={{
                required: 'Fee Percent is required',
              }}
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="forwardFlowFeePercent"
              placeholder="Forward Flow Fee Percent"
              type="number"
              afterValue="%"
              width="100%"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              name="minimumFee"
              placeholder="Minimum Fee"
              type="number"
              beforeValue="$"
              width="100%"
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: Colors.black,
            }}
          >
            <FormSwitch label="Enforce Minimum Fee" name="enforceMinimumFee" />
          </Grid>
        </Grid>
        <FormModalSubmitButton
          isFetching={addBusinessFeeLoading || updateBusinessFeeLoading}
          text={isEditing ? 'Save' : 'Create'}
        />
      </Form>
    </ModalDialog>
  )
}

export default BusinessFeeAddOrEditModal
