import { useQuery } from '@apollo/client'
import { Box, Button, Typography } from '@mui/material'
import { Add, Delete, Edit } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import DynamicTable, { cellDate } from 'src/components/DynamicTable'
import { AbilityContext } from 'src/context/Can'
import { GetBusinessLicenses } from 'src/graphql/models/agency'
import { useDeleteBusinessLicense } from 'src/graphql/operations/mutations/licenses'
import { GET_BUSINESS_LICENSES } from 'src/graphql/operations/queries/business'
import { getStateDropDownName } from 'src/utils/common'
import { PermissionCodeAccess } from 'src/utils/constants'
import License from '.'
import { ModalDialog } from 'everchain-uilibrary'

interface BusinessLicensesListProps {
  businessId: string
  clientCountry?: string | null
  businessCountry?: string | null
}

const BusinessLicensesList = ({
  businessId,
  clientCountry,
  businessCountry,
}: BusinessLicensesListProps) => {
  const [showAddForm, setShowAddForm] = useState(false)
  const [licenseToEdit, setLicenseToEdit] = useState()
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [licenseId, setLicenseId] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const pageSize = 25
  const ability = useContext(AbilityContext)
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )

  const {
    data: getLicensesData,
    loading: licensesLoading,
    fetchMore,
  } = useQuery<GetBusinessLicenses>(GET_BUSINESS_LICENSES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      businessId,
      pagination: {
        pageSize,
        pageNumber: 0,
      },
    },
  })

  const onRemove = (id: string) => {
    setLicenseId(id)
  }

  const handleDeleteLicenseCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('License removed successfully', {
        variant: 'success',
      })
    }
  }

  const { DeleteLicense, loading: licenseLoading } = useDeleteBusinessLicense(
    handleDeleteLicenseCompleted
  )

  const handleRemoveLicense = (license: string): void => {
    DeleteLicense({
      variables: {
        request: license,
      },
      refetchQueries: ['GetBusinessLicenses'],
    })
    setLicenseId('')
    setOpenRemoveDialog(false)
  }

  const handleClose = () => {
    setOpenRemoveDialog(false)
    setLicenseId('')
  }

  const handleRemove = () => {
    handleRemoveLicense(licenseId)
  }
  const country = process.env.REACT_APP_COUNTRY

  const dataColumns = [
    {
      Header: getStateDropDownName(country),
      accessor: 'stateCode',
    },
    {
      Header: 'Country',
      accessor: 'countryCode',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'Issued',
      accessor: 'issuedDate',
      Cell: cellDate,
    },
    {
      Header: 'Expires',
      accessor: 'expirationDate',
      Cell: cellDate,
    },
    {
      Header: 'Comments',
      accessor: 'comment',
    },
    {
      Header: '',
      accessor: 'id',
      Cell: (props: any) => {
        return (
          <Box flexDirection="row">
            <Button
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              disabled={licenseLoading || !hasBasicInfoPermission}
              startIcon={
                <Box>
                  <Delete fontSize="small" />
                </Box>
              }
              onClick={() => {
                onRemove(props.row.original.id)
              }}
            />
            <Button
              disableRipple={true}
              style={{ backgroundColor: 'transparent' }}
              disabled={licenseLoading || !hasBasicInfoPermission}
              startIcon={
                <Box>
                  <Edit fontSize="small" />
                </Box>
              }
              onClick={() => {
                setLicenseToEdit(props.row.original)
                setShowAddForm(true)
              }}
            />
          </Box>
        )
      },
    },
  ]

  const handleLicenseFormToggle = () => {
    setShowAddForm(!showAddForm)
    setLicenseToEdit(undefined)
  }

  useEffect(() => {
    if (!openRemoveDialog && licenseId) {
      setOpenRemoveDialog(true)
    }
  }, [licenseId, openRemoveDialog])

  return (
    <>
      <Box>
        <Box mb={4} textAlign="right">
          <Button
            variant="contained"
            color="primary"
            disabled={!hasBasicInfoPermission}
            startIcon={<Add />}
            onClick={() => {
              setShowAddForm(true)
            }}
          >
            Add License
          </Button>
        </Box>
        <Box>
          <DynamicTable
            id="tbLicenses"
            loading={licensesLoading}
            data={getLicensesData?.getBusinessLicenses.licenseResponse || []}
            pageSize={pageSize}
            totalItems={getLicensesData?.getBusinessLicenses.total}
            columns={dataColumns}
            onChangePagination={(_, pageNumber: number) => {
              const newPageNumber = pageNumber - 1
              if (fetchMore) {
                fetchMore({
                  variables: {
                    businessId,
                    pagination: {
                      pageSize,
                      pageNumber: newPageNumber,
                    },
                  },
                  updateQuery: (
                    prev,
                    { fetchMoreResult }
                  ): GetBusinessLicenses => {
                    if (!fetchMoreResult) return prev
                    return fetchMoreResult
                  },
                })
              }
            }}
          />
        </Box>
      </Box>
      <License
        clientCountry={clientCountry}
        businessCountry={businessCountry}
        businessId={businessId}
        open={showAddForm}
        onClose={handleLicenseFormToggle}
        initialValue={licenseToEdit}
      />
      <ModalDialog
        isOpen={openRemoveDialog}
        header="Remove License"
        buttonCancelText="Cancel"
        buttonOkText="Continue"
        onClose={handleClose}
        onContinue={handleRemove}
        isFetching={licenseLoading}
      >
        <Box mb={3}>
          <Typography variant="body2" color="textSecondary">
            Are you sure you want to remove this license?
          </Typography>
        </Box>
      </ModalDialog>
    </>
  )
}
BusinessLicensesList.defaultProps = {
  clientCountry: null,
  businessCountry: null,
}

export default BusinessLicensesList
