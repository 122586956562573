/* eslint-disable react/require-default-props */
import React from 'react'
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  MenuItem,
  TextField,
  Divider,
  Button,
  CircularProgress,
  Tooltip,
  Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ExpandMore, Info } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { Module, Option } from 'src/graphql/models/managePermissions'
import { MANAGE_PERMISSIONS_ROLE } from 'src/routes'

import { BuilderForm } from './styles'
import { Colors } from 'everchain-uilibrary'

const denyInherited = {
  label: 'Deny (inherited)',
  value: 'DENY_INHERITED',
  hidden: true,
  disabled: true,
}
const allowInherited = {
  label: 'Allow (inherited)',
  value: 'ALLOW_INHERITED',
  hidden: true,
  disabled: true,
}
const deny = {
  label: 'Deny',
  value: 'DENY',
  hidden: false,
  disabled: false,
}

const options = (permission: string) => {
  const optionList = [
    {
      label: 'Not Set',
      value: 'NOT_SET',
      hidden: false,
      disabled: false,
    },
    {
      label: 'Allow',
      value: 'ALLOW',
      hidden: false,
      disabled: false,
    },
    deny,
  ]

  if (permission === allowInherited.value) {
    optionList.push(allowInherited)
  }
  if (permission === denyInherited.value) {
    optionList.push(denyInherited)
  }

  return optionList
}

const useStylesTooltip = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.common.black,
  },
}))

const HtmlTooltip = ({
  children,
  title,
  onClick,
  onFocus,
}: {
  children: React.ReactElement<any, any>
  title: React.ReactNode
  onClick: (event: any) => void
  onFocus: (event: any) => void
}) => {
  const classes = useStylesTooltip()
  return (
    <Tooltip
      title={title}
      className={classes.tooltip}
      onClick={onClick}
      onFocus={onFocus}
      style={{ backgroundColor: '#e0e0e0' }}
    >
      {children}
    </Tooltip>
  )
}

interface PermissionBuilderFormProps {
  modules: Module[]
  loading?: Boolean
  emptyText?: string
  permissionType: 'USER' | 'ROLE'
  onChange: (event: React.ChangeEvent<HTMLInputElement>, option: Option) => void
}

const PermissionBuilderForm = ({
  modules,
  loading,
  emptyText,
  permissionType,
  onChange,
}: PermissionBuilderFormProps) => {
  const actions = {
    onClick: (event: any) => {
      event?.stopPropagation()
    },
    onFocus: (event: any) => {
      event?.stopPropagation()
    },
  }

  if (!modules.length && loading) {
    return (
      <Box
        p={3}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress color="primary" size={16} />
        <Typography>Building permission form ...</Typography>
      </Box>
    )
  }

  return (
    <BuilderForm>
      {!!modules.length && !loading ? (
        modules.map((module) => (
          <Accordion key={module.code}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${1}-permission`}
              id={`panel${1}-permission`}
              style={{
                borderBottom: '1px solid #C7C7C7',
                backgroundColor: '#f4f4f4',
              }}
            >
              <Grid container style={{ paddingLeft: 15, paddingRight: 15 }}>
                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={4}
                  style={{ alignSelf: 'center' }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography>
                        <strong>{module.description}</strong>
                      </Typography>
                    </Box>
                    <Box display="flex">
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        {...actions}
                      >
                        <TextField
                          id={module.name}
                          select
                          value={module.permission}
                          style={{ width: '160px' }}
                          disabled={module.permission === denyInherited.value}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            onChange(event, {
                              type: 'module',
                              name: module.name,
                              permissionValue: event.target.value,
                              permissionCode: module.code,
                              permissionType,
                            })
                          }}
                        >
                          {options(module.permission).map((option) => (
                            <MenuItem
                              key={option.value}
                              disabled={option.disabled}
                              value={option.value}
                              style={{
                                display: option.disabled ? 'none' : 'block',
                              }}
                            >
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Box>
                      {module.inheritedFromRole.length ? (
                        <Box
                          ml={2}
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <HtmlTooltip
                            {...actions}
                            title={
                              <>
                                <Typography style={{ color: Colors.white }}>
                                  Inherited from:
                                </Typography>
                                {module.inheritedFromRole.map(
                                  (inheritedRole) => (
                                    <Button
                                      key={inheritedRole.roleId}
                                      component={Link}
                                      to={`${MANAGE_PERMISSIONS_ROLE}/${inheritedRole.roleId}`}
                                      variant="text"
                                      style={{
                                        textDecoration: 'underline',
                                        color: Colors.white,
                                      }}
                                    >
                                      {inheritedRole.roleDescription}
                                    </Button>
                                  )
                                )}
                              </>
                            }
                          >
                            <Info fontSize="small" />
                          </HtmlTooltip>
                        </Box>
                      ) : (
                        <Box ml={7} />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {!!module.features.length &&
                module.features.map((feature) => (
                  <Box
                    component="div"
                    width="100%"
                    key={feature.name}
                    className="accordion-box-detail"
                    style={{
                      backgroundColor: '#e0e0e0',
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} md={4} xl={4}>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-between"
                          style={{ paddingLeft: 6, paddingRight: 6 }}
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            ml={2.5}
                          >
                            <Typography>
                              <strong>{feature.description}</strong>
                            </Typography>
                          </Box>

                          <Box display="flex">
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              <TextField
                                id={feature.name}
                                select
                                value={feature.permission}
                                style={{ width: '160px' }}
                                fullWidth
                                disabled={
                                  module.permission === denyInherited.value ||
                                  module.permission === deny.value
                                }
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  onChange(event, {
                                    type: 'feature',
                                    name: feature.name,
                                    permissionValue: event.target.value,
                                    permissionCode: feature.code,
                                    permissionType,
                                  })
                                }}
                              >
                                {options(feature.permission).map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    style={{
                                      display: option.disabled
                                        ? 'none'
                                        : 'block',
                                    }}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                            <Box
                              ml={2}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                            >
                              {feature.inheritedFromRole.length ? (
                                <HtmlTooltip
                                  {...actions}
                                  title={
                                    <>
                                      <Typography
                                        style={{ color: Colors.white }}
                                      >
                                        Inherited from:
                                      </Typography>
                                      {feature.inheritedFromRole.map(
                                        (inheritedRole) => (
                                          <Button
                                            key={inheritedRole.roleId}
                                            component={Link}
                                            to={`${MANAGE_PERMISSIONS_ROLE}/${inheritedRole.roleId}`}
                                            variant="text"
                                            style={{
                                              textDecoration: 'underline',
                                              color: Colors.white,
                                            }}
                                          >
                                            {inheritedRole.roleDescription}
                                          </Button>
                                        )
                                      )}
                                    </>
                                  }
                                >
                                  <Info fontSize="small" />
                                </HtmlTooltip>
                              ) : (
                                <Box ml={5} />
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Box p={4}>
                      {!!feature.actions.length && (
                        <Grid container spacing={4}>
                          {feature.actions.map((action) => (
                            <React.Fragment key={action.code}>
                              <Grid item xs={4} md={4}>
                                <Box display="flex" alignItems="center">
                                  <TextField
                                    id={action.name}
                                    select
                                    label={action.description}
                                    value={action.permission}
                                    fullWidth
                                    disabled={
                                      feature.permission ===
                                        denyInherited.value ||
                                      feature.permission === deny.value ||
                                      module.permission ===
                                        denyInherited.value ||
                                      module.permission === deny.value
                                    }
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      onChange(event, {
                                        type: 'action',
                                        name: action.name,
                                        permissionValue: event.target.value,
                                        permissionCode: action.code,
                                        permissionType,
                                      })
                                    }}
                                  >
                                    {options(action.permission).map(
                                      (option) => (
                                        <MenuItem
                                          key={option.value}
                                          value={option.value}
                                          style={{
                                            display: option.disabled
                                              ? 'none'
                                              : 'block',
                                          }}
                                        >
                                          {option.label}
                                        </MenuItem>
                                      )
                                    )}
                                  </TextField>
                                  <Box
                                    ml={2}
                                    display="flex"
                                    flexDirection="column"
                                  >
                                    {action.inheritedFromRole.length ? (
                                      <HtmlTooltip
                                        {...actions}
                                        title={
                                          <>
                                            <Typography
                                              style={{ color: Colors.white }}
                                            >
                                              Inherited from:
                                            </Typography>
                                            {action.inheritedFromRole.map(
                                              (inheritedRole) => (
                                                <Button
                                                  key={inheritedRole.roleId}
                                                  component={Link}
                                                  to={`${MANAGE_PERMISSIONS_ROLE}/${inheritedRole.roleId}`}
                                                  variant="text"
                                                  style={{
                                                    textDecoration: 'underline',
                                                    color: Colors.white,
                                                  }}
                                                >
                                                  {
                                                    // eslint-disable-next-line max-len
                                                    inheritedRole.roleDescription
                                                  }
                                                </Button>
                                              )
                                            )}
                                          </>
                                        }
                                      >
                                        <Info fontSize="small" />
                                      </HtmlTooltip>
                                    ) : (
                                      <Box ml={5} />
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      )}
                    </Box>
                    <Divider />
                  </Box>
                ))}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <Box p={3} display="flex" justifyContent="center" alignItems="center">
          <Typography>{emptyText}</Typography>
        </Box>
      )}
    </BuilderForm>
  )
}

export default PermissionBuilderForm
