export const HOME = '/'
export const ROOT = '/root'
export const PORTFOLIO = '/portfolio'
export const MANAGE_PERMISSIONS = '/manage-permissions'
export const MANAGE_PERMISSIONS_ROLE = `${MANAGE_PERMISSIONS}/role`
export const LOAD_PORTFOLIO = '/load-portfolio'
export const PORTFOLIO_DETAIL = '/portfolio/detail'
export const LOGIN = '/login'
export const ACCESSDENIED = '/accessdenied'
export const USER_ROLES = `${MANAGE_PERMISSIONS}/user`
export const CLIENT_PROFILES = '/client-profiles'
export const CLIENT_PROFILES_MANAGE = `${CLIENT_PROFILES}/manage`
export const INTERNAL_USERS = '/internal-users'
export const INTERNAL_NOTIFICATION = '/internal-notification'
export const LOGS = '/logs'
export const SELLER_BUSINESS_MANAGE = '/seller-business/manage'
export const BUYER_BUSINESS_MANAGE = '/buyer-business/manage'
export const AGENCY_BUSINESS_MANAGE = '/agency-business/manage'
export const LAWFIRM_BUSINESS_MANAGE = '/lawfirm-business/manage'
export const SERVICER_BUSINESS_MANAGE = '/servicer-business/manage'
export const USER_MANAGE = '/user'
export const PROFILE_REPORT = '/profile-report'
export const COMMISSIONS = '/commissions'
export const INTERNAL_EDIT_NOTIFICATION_TEXT =
  '/internal-edit-notification-text'
export const AGGREMENT_TEMPLATE_FORM = '/aggrement-template-form'
export const MEDIA_NAMING_CONVENTION_FORM = '/media-naming-convention-form'
export const KNOWLEDGE_BASE = 'https://kb.everchain.com/knowledge'
export const HELP_REQUEST = 'https://kb.everchain.com/knowledge/kb-tickets/new'
export const AGGREMENT_TEMPLATE_DETAIL = '/aggrement-template-detail'
