import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

import Header from 'src/components/Header'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { CLIENT_PROFILES_MANAGE } from 'src/routes'
import LawFirmBusinessTab from './components/LawFirmBusinessTab'
import { getLawFirmDetails } from 'src/infra/api/services/lawfirm'
import { BusinessLawFirmResponseType } from 'src/infra/api/models/lawfirm'

const LawFirmBusinessManage = () => {
  const { lawFirmBusinessId, clientId } = useParams<any>()

  const { data: getLawFirmData, isFetching: lawFirmLoading } =
    useCustomQuery<BusinessLawFirmResponseType>(
      ['getLawFirmDetails', lawFirmBusinessId],
      async () => {
        return getLawFirmDetails(lawFirmBusinessId)
      },
      {
        enabled: !!lawFirmBusinessId,
      }
    )

  return (
    <>
      <Box p={5}>
        <Header
          title={getLawFirmData ? getLawFirmData.name : 'Create a New Law Firm'}
          customBackUrl={`${CLIENT_PROFILES_MANAGE}/${clientId}`}
        />
      </Box>
      <Box p={5}>
        <LawFirmBusinessTab
          clientId={clientId}
          lawFirmBusinessId={lawFirmBusinessId}
          lawFirmData={getLawFirmData}
          lawFirmLoading={lawFirmLoading}
        />
      </Box>
    </>
  )
}

export default LawFirmBusinessManage
