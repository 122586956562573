import React, { useState, useCallback, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { useUpdatePermission } from 'src/graphql/operations/mutations/managePermissions'
import { GET_PERMISSION_LIST } from 'src/graphql/operations/queries/managePermissions'
import { Option, GetPermissionList } from 'src/graphql/models/managePermissions'
import { makeModulesUpdate } from 'src/utils/common'
import PermissionBuilderForm from './PermissionBuilderForm'

interface PermissionArguments {
  isSeller: boolean
  isBuyer: boolean
  isInternal: boolean
  isCreditor: boolean
  isVendor: boolean
  isAgency: boolean
  isServicer: boolean
}

interface PermissionBuilderProps {
  entityId: string
  permissionArguments: PermissionArguments
  loading?: boolean
  permissionType: 'USER' | 'ROLE'
}

const PermissionBuilder = ({
  entityId,
  permissionArguments,
  loading,
  permissionType,
}: PermissionBuilderProps) => {
  const [userSelected, setUserSelected] = useState<PermissionArguments | any>(
    permissionArguments
  )

  const { enqueueSnackbar } = useSnackbar()
  const permissionListRequest = {
    isBuyer: permissionArguments.isBuyer,
    isSeller: permissionArguments.isSeller,
    isInternal: permissionArguments.isInternal,
    isCreditor:
      permissionArguments.isCreditor &&
      process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production',
    isAgency: permissionArguments.isAgency,
    isVendor:
      permissionArguments.isVendor &&
      process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production',
    isServicer:
      permissionArguments.isServicer &&
      process.env.REACT_APP_CUSTOM_NODE_ENV !== 'production',
    entityId,
    permissionType,
  }
  const { data: permissionUserData, loading: permissionLoading } =
    useQuery<GetPermissionList>(GET_PERMISSION_LIST, {
      fetchPolicy: 'cache-and-network',
      variables: {
        permissionListRequest,
      },
      skip:
        !permissionArguments?.isBuyer &&
        !permissionArguments?.isSeller &&
        !permissionArguments?.isInternal &&
        !permissionArguments?.isCreditor &&
        !permissionArguments?.isVendor &&
        !permissionArguments?.isAgency &&
        !permissionArguments?.isServicer,
    })

  const handleUpdatePermissionCompleted = (data: any) => {
    if (data) {
      const name = data?.updatePermission?.permissionCodeDescription || ''
      enqueueSnackbar(`"${name}" permission updated successfully`, {
        variant: 'success',
      })
    }
  }

  const { updatePermission, error: updatePermissionError } =
    useUpdatePermission(handleUpdatePermissionCompleted)

  const handlePermissionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, option: Option) => {
      updatePermission({
        variables: {
          updatePermissionRequest: {
            entityId,
            permissionCode: option.permissionCode,
            permissionValue: option.permissionValue,
            permissionType: option.permissionType,
          },
        },
        optimisticResponse: {
          updatePermission: {
            __typename: 'UpdatePermissionResponseType',
            entityId,
            permissionCode: option.permissionCode,
            permissionValue: option.permissionValue,
            permissionType: option.permissionType,
          },
        },
        update: (cache, { data }) => {
          const modulesCache = cache.readQuery<GetPermissionList>({
            query: GET_PERMISSION_LIST,
            variables: {
              permissionListRequest: {
                isBuyer: userSelected?.isBuyer,
                isSeller: userSelected?.isSeller,
                isInternal: userSelected?.isInternal,
                isCreditor: userSelected?.isCreditor,
                isVendor: userSelected?.isVendor,
                isAgency: userSelected?.isAgency,
                isServicer: userSelected?.isServicer,
                entityId,
                permissionType,
              },
            },
          })

          if (
            modulesCache?.getPermissionList &&
            modulesCache.getPermissionList.modules.length
          ) {
            const modulesUpdates = makeModulesUpdate(
              modulesCache?.getPermissionList.modules,
              option
            )
            cache.writeQuery({
              query: GET_PERMISSION_LIST,
              variables: {
                permissionListRequest: {
                  isBuyer: userSelected?.isBuyer,
                  isSeller: userSelected?.isSeller,
                  isInternal: userSelected?.isInternal,
                  isCreditor: userSelected?.isCreditor,
                  isVendor: userSelected?.isVendor,
                  isAgency: userSelected?.isAgency,
                  isServicer: userSelected?.isServicer,
                  entityId,
                  permissionType,
                },
              },
              data: {
                getPermissionList: {
                  ...modulesCache?.getPermissionList,
                  modules: modulesUpdates,
                },
              },
            })
          }
        },
      })
    },
    [updatePermission, entityId, userSelected, permissionType]
  )

  useEffect(() => {
    if (updatePermissionError) {
      enqueueSnackbar('There was a error, try again later', {
        variant: 'error',
      })
    }
  }, [updatePermissionError, enqueueSnackbar])

  useEffect(() => {
    if (
      permissionArguments.isBuyer ||
      permissionArguments.isSeller ||
      permissionArguments.isInternal ||
      permissionArguments.isCreditor ||
      permissionArguments.isVendor ||
      permissionArguments.isServicer ||
      permissionArguments.isAgency
    ) {
      setUserSelected((prevState: any) => ({
        ...prevState,
        ...permissionArguments,
      }))
    }
  }, [permissionArguments])

  return (
    <PermissionBuilderForm
      modules={permissionUserData?.getPermissionList.modules || []}
      loading={loading || permissionLoading}
      onChange={handlePermissionChange}
      permissionType={
        permissionType || permissionUserData?.getPermissionList.permissionType
      }
    />
  )
}

PermissionBuilder.defaultProps = {
  loading: false,
}

export default PermissionBuilder
