import React, { useContext } from 'react'
import { Switch } from 'react-router-dom'
import PrivatedRoute from 'src/components/PrivatedRoute'
import { buildAbilityFor } from 'src/configs/ability'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext } from 'src/context/Can'
import { MainLayout } from 'src/layouts'
import {
  CLIENT_PROFILES,
  CLIENT_PROFILES_MANAGE,
  MANAGE_PERMISSIONS,
  MANAGE_PERMISSIONS_ROLE,
  USER_ROLES,
  LOGS,
  SELLER_BUSINESS_MANAGE,
  BUYER_BUSINESS_MANAGE,
  AGENCY_BUSINESS_MANAGE,
  USER_MANAGE,
  COMMISSIONS,
  INTERNAL_USERS,
  INTERNAL_NOTIFICATION,
  INTERNAL_EDIT_NOTIFICATION_TEXT,
  PROFILE_REPORT,
  AGGREMENT_TEMPLATE_FORM,
  AGGREMENT_TEMPLATE_DETAIL,
  SERVICER_BUSINESS_MANAGE,
  MEDIA_NAMING_CONVENTION_FORM,
  LAWFIRM_BUSINESS_MANAGE,
} from 'src/routes'

import { Permissions } from 'src/graphql/models/permissions'

import { PermissionCodeAccess } from 'src/utils/constants'
import NotFound from '../NotFound'
import ManagePermissions from '../ManagePermissions'
import ManagePermissionsRole from '../ManagePermissionsRole'
import UserPermissionRole from '../UserPermissionRole'
import ClientProfiles from '../ClientProfiles'
import ClientProfilesManager from '../ClientProfilesManage'
import LogPages from '../LogPages'
import SellerBusinessManage from '../SellerBusinessManage'
import BuyerBusinessManage from '../BuyerBusinessManage'
import AgencyBusinessManage from '../AgencyBusinessManage'
import User from '../UserManage'
import Commission from '../Commissions'
import InternalUsers from '../InternalUsers'
import InternalNotifications from '../SellerBusinessManage/components/InternalNotifications'
import EditNotificationText from '../InternalUsers/EditNotificationsText'
import ProfileReport from '../Report/ProfileReport'
import AgreementTemplatesForm from 'src/components/AgreementTemplates/AgreementTemplatesForm'
import AgreementTemplatesDetail from 'src/components/AgreementTemplates/AgreementTemplatesDetail'
import ServicerBusinessManage from '../ServicerBusinessManage'
import { MediaNamingConventionForm } from 'src/components/MediaNamingConvention'
import LawFirmBusinessManage from '../LawFirmBusinessManage'

interface AbilityProviderProps {
  children: React.ReactNode
  permissionValues: Permissions[]
}

interface AppProps {
  managePermission: Permissions[]
}

const AbilityProvider = ({
  children,
  permissionValues,
}: AbilityProviderProps) => {
  const { userPermissions } = useContext(AuthContext)
  const ability = buildAbilityFor(userPermissions.type, permissionValues)
  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  )
}

interface PermissionReduce {
  [field: string]: boolean
}
const App = ({ managePermission }: AppProps) => {
  const permissionReduce = (
    permissionValues: Permissions[] = []
  ): PermissionReduce =>
    permissionValues.reduce(
      (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
      {}
    )
  const managePermissionReduce = permissionReduce(managePermission)

  const hasPermission = (codePermission: string | number) => {
    if (
      managePermissionReduce &&
      codePermission &&
      !managePermissionReduce[codePermission]
    ) {
      return false
    }
    return true
  }
  return (
    <AbilityProvider permissionValues={managePermission || []}>
      <MainLayout>
        <Switch>
          <PrivatedRoute
            hasPermission={hasPermission(
              PermissionCodeAccess.MANAGE_EXTERNAL_PERMISSION
            )}
            exact
            path={MANAGE_PERMISSIONS}
          >
            <ManagePermissions />
          </PrivatedRoute>
          <PrivatedRoute
            hasPermission={hasPermission(
              PermissionCodeAccess.MANAGE_INTERNAL_PERMISSION ||
                PermissionCodeAccess.MANAGE_EXTERNAL_PERMISSION
            )}
            path={`${MANAGE_PERMISSIONS_ROLE}/:roleId/:tabType?/:selectedTenantId?`}
          >
            <ManagePermissionsRole />
          </PrivatedRoute>
          <PrivatedRoute
            hasPermission={hasPermission(
              PermissionCodeAccess.MANAGE_INTERNAL_PERMISSION
            )}
            path={`${USER_ROLES}/:userId/:tabType?/internal/:selectedTenantId?/:userCreated?`}
          >
            <UserPermissionRole />
          </PrivatedRoute>
          <PrivatedRoute
            hasPermission={hasPermission(
              PermissionCodeAccess.MANAGE_EXTERNAL_PERMISSION
            )}
            path={`${USER_ROLES}/:userId/:tabType?/:selectedTenantId?/:userCreated?`}
          >
            <UserPermissionRole />
          </PrivatedRoute>
          <PrivatedRoute exact path={CLIENT_PROFILES}>
            <ClientProfiles />
          </PrivatedRoute>
          <PrivatedRoute
            hasPermission={hasPermission(
              PermissionCodeAccess.CLIENT_PERMISSION
            )}
            path={`${CLIENT_PROFILES_MANAGE}/:clientProfileId?/:tabIndex?`}
          >
            <ClientProfilesManager />
          </PrivatedRoute>
          <PrivatedRoute
            hasPermission={hasPermission(PermissionCodeAccess.LOG_PERMISSION)}
            path={`${LOGS}`}
          >
            <LogPages />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${SELLER_BUSINESS_MANAGE}/:clientId/:sellerBusinessId?`}
          >
            <SellerBusinessManage />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${BUYER_BUSINESS_MANAGE}/:clientId/:buyerBusinessId?`}
          >
            <BuyerBusinessManage />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${AGENCY_BUSINESS_MANAGE}/:clientId/:agencyBusinessId?`}
          >
            <AgencyBusinessManage />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${LAWFIRM_BUSINESS_MANAGE}/:clientId/:lawFirmBusinessId?`}
          >
            <LawFirmBusinessManage />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${SERVICER_BUSINESS_MANAGE}/:clientId/:servicerBusinessId?`}
          >
            <ServicerBusinessManage />
          </PrivatedRoute>
          <PrivatedRoute path={`${USER_MANAGE}/:tenantId/:clientId/:userId?`}>
            <User />
          </PrivatedRoute>

          <PrivatedRoute path={`${COMMISSIONS}`}>
            <Commission />
          </PrivatedRoute>

          <PrivatedRoute path={`${INTERNAL_USERS}`}>
            <InternalUsers />
          </PrivatedRoute>
          <PrivatedRoute path={`${INTERNAL_NOTIFICATION}`}>
            <InternalNotifications />
          </PrivatedRoute>
          <PrivatedRoute path={`${INTERNAL_EDIT_NOTIFICATION_TEXT}`}>
            <EditNotificationText />
          </PrivatedRoute>
          <PrivatedRoute exact path={PROFILE_REPORT}>
            <ProfileReport />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${AGGREMENT_TEMPLATE_FORM}/:sellerId/:clientId`}
          >
            <AgreementTemplatesForm />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${AGGREMENT_TEMPLATE_DETAIL}/:id/:sellerId/:clientId/:tabSelected?`}
          >
            <AgreementTemplatesDetail />
          </PrivatedRoute>
          <PrivatedRoute
            path={`${MEDIA_NAMING_CONVENTION_FORM}/:sellerId/:clientId/:tabSelected?`}
          >
            <MediaNamingConventionForm />
          </PrivatedRoute>
          <PrivatedRoute path="*">
            <NotFound />
          </PrivatedRoute>
        </Switch>
      </MainLayout>
    </AbilityProvider>
  )
}

export default App
