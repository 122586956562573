import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  Grid as KendoGrid,
  GridCellProps,
  GridColumn as KendoGridColumn,
  GridColumnMenuFilter,
  GridRowClickEvent,
} from '@progress/kendo-react-grid'
import {
  Box,
  Button,
  TableBody,
  TableRow,
  TableCell,
  Table,
  Skeleton,
} from '@mui/material'
import { Add } from '@mui/icons-material'
import { GetClient } from 'src/graphql/models/clientProfiles'

import {
  AGENCY_BUSINESS_MANAGE,
  BUYER_BUSINESS_MANAGE,
  SELLER_BUSINESS_MANAGE,
  SERVICER_BUSINESS_MANAGE,
  LAWFIRM_BUSINESS_MANAGE,
} from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PermissionCodeAccess, TENANT_TYPE } from 'src/utils/constants'
import { GetBusinessStatus } from 'src/utils/common'
import { AbilityContext } from 'src/context/Can'
import { State } from '@progress/kendo-data-query'
import { useQuery } from '@apollo/client'
import { GET_CLIENT } from 'src/graphql/operations/queries/clientProfiles'

interface ClientBusinessProps {
  clientProfileId?: number
}

const ClientBusiness = ({ clientProfileId }: ClientBusinessProps) => {
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const {
    data: getClientData,
    loading,
    fetchMore,
  } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      clientId: Number(clientProfileId),
      kendoPagination: JSON.stringify(gridState),
    },
  })

  const ability = useContext(AbilityContext)
  const history = useHistory()
  useEffect(() => {
    setGridState({ skip: 0, take: 25, filter: undefined, sort: undefined })
  }, [setGridState])

  const ColumnMenu = (props: any): React.ReactElement => {
    return (
      <Box>
        <GridColumnMenuFilter {...props} expanded={true} />
      </Box>
    )
  }

  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'

  if (!getClientData?.getClient || loading) {
    return (
      <Box mt={5}>
        <Skeleton width="100%" height={50} variant="rectangular" />
      </Box>
    )
  }

  return (
    <Box>
      <Box p="4" mr="4">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell align="right">
                {(getClientData?.getClient?.category.toLowerCase() ===
                  'buyer' ||
                  getClientData?.getClient?.category.toLowerCase() ===
                    'law firm') &&
                  isInternal && (
                    <Button
                      style={{
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 4,
                        marginBottom: 4,
                      }}
                      variant="contained"
                      color="primary"
                      disabled={!hasBasicInfoPermission}
                      startIcon={<Add />}
                      component={Link}
                      to={`${BUYER_BUSINESS_MANAGE}/${clientProfileId}`}
                    >
                      Add a New Buyer
                    </Button>
                  )}
                <></>
                {(getClientData?.getClient?.category.toLowerCase() ===
                  'seller' ||
                  getClientData?.getClient?.category.toLowerCase() ===
                    'buyer') &&
                  isInternal && (
                    <Button
                      style={{
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 4,
                        marginBottom: 4,
                      }}
                      variant="contained"
                      color="primary"
                      disabled={!hasBasicInfoPermission}
                      startIcon={<Add />}
                      component={Link}
                      to={`${SELLER_BUSINESS_MANAGE}/${clientProfileId}`}
                    >
                      Add a New Seller
                    </Button>
                  )}
                {isInternal &&
                  (getClientData?.getClient?.category.toLowerCase() ===
                    'buyer' ||
                    getClientData?.getClient?.category.toLowerCase() ===
                      'agency' ||
                    getClientData?.getClient?.category.toLowerCase() ===
                      'law firm') && (
                    <Button
                      style={{
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 4,
                        marginBottom: 4,
                      }}
                      variant="contained"
                      color="primary"
                      disabled={!hasBasicInfoPermission}
                      startIcon={<Add />}
                      component={Link}
                      to={`${AGENCY_BUSINESS_MANAGE}/${clientProfileId}`}
                    >
                      Add a New Agency
                    </Button>
                  )}
                {isInternal &&
                  (getClientData?.getClient?.category.toLowerCase() ===
                    'buyer' ||
                    getClientData?.getClient?.category.toLowerCase() ===
                      'agency' ||
                    getClientData?.getClient?.category.toLowerCase() ===
                      'law firm') && (
                    // eslint-disable-next-line react/jsx-indent
                    <Button
                      style={{
                        marginLeft: 4,
                        marginRight: 4,
                        marginTop: 4,
                        marginBottom: 4,
                      }}
                      variant="contained"
                      color="primary"
                      disabled={!hasBasicInfoPermission}
                      startIcon={<Add />}
                      component={Link}
                      to={`${LAWFIRM_BUSINESS_MANAGE}/${clientProfileId}`}
                    >
                      Add a New Law Firm
                    </Button>
                  )}
                {getClientData?.getClient?.category.toLowerCase() ===
                  'servicer' &&
                  isInternal && (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={!hasBasicInfoPermission}
                      startIcon={<Add />}
                      component={Link}
                      to={`${SERVICER_BUSINESS_MANAGE}/${clientProfileId}`}
                    >
                      Add a New Servicer
                    </Button>
                  )}
                <></>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <KendoGrid
        data-cy="businesses"
        sortable
        style={{
          height: '100%',
          maxHeight: '80vh',
          cursor: 'pointer',
        }}
        pageable={{ pageSizes: [25, 50, 100] }}
        skip={gridState.skip}
        take={gridState.take}
        pageSize={gridState.take}
        filter={gridState.filter}
        sort={gridState.sort}
        data={
          getClientData?.getClient?.businessSummaryResponse.businesses || []
        }
        total={
          getClientData?.getClient
            ? getClientData?.getClient?.businessSummaryResponse.totalCount
            : 0
        }
        onRowClick={(row: GridRowClickEvent) => {
          switch (row.dataItem['businessType'].toLowerCase()) {
            case 'seller':
              history.push(
                `${SELLER_BUSINESS_MANAGE}/${clientProfileId}/${row.dataItem.id}`
              )
              break
            case 'buyer':
              history.push(
                `${BUYER_BUSINESS_MANAGE}/${clientProfileId}/${row.dataItem.id}`
              )
              break
            case 'agency':
              history.push(
                `${AGENCY_BUSINESS_MANAGE}/${clientProfileId}/${row.dataItem.id}`
              )
              break
            case 'servicer':
              history.push(
                `${SERVICER_BUSINESS_MANAGE}/${clientProfileId}/${row.dataItem.id}`
              )
              break
            case 'law firm':
              history.push(
                `${LAWFIRM_BUSINESS_MANAGE}/${clientProfileId}/${row.dataItem.id}`
              )
          }
        }}
        onDataStateChange={(e) => {
          setGridState(e.dataState)
          fetchMore({
            variables: {
              kendoPagination: JSON.stringify(e.dataState),
            },
          })
        }}
      >
        <KendoGridColumn field="name" title="Name" columnMenu={ColumnMenu} />
        <KendoGridColumn
          field="businessType"
          title="Type"
          columnMenu={ColumnMenu}
        />
        <KendoGridColumn
          field="status"
          title="Status"
          cell={(props: GridCellProps) => {
            return <td>{GetBusinessStatus(props.dataItem['status'])}</td>
          }}
        />
      </KendoGrid>
    </Box>
  )
}

ClientBusiness.defaultProps = {
  clientProfileId: null,
}

export default ClientBusiness
