import React from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index?: any
  value?: any
}

function TabPanel({ children, value, index, ...rest }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...rest}
    >
      {!!children && children}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
  index: null,
  value: null,
}
export default TabPanel
