import React, { useContext, useRef, useState } from 'react'
import { Box, TextField, Button, CircularProgress } from '@mui/material'
import { GET_API_LOGS } from 'src/graphql/operations/queries/logs'
import { useLazyQuery } from '@apollo/client'
import { logClient } from 'src/configs/apolloConfig'
import { format, subDays } from 'date-fns'
import { formatDateAndTime } from 'src/utils/date'
import { useFormik } from 'formik'
import { State } from '@progress/kendo-data-query'
import {
  Grid as KendoGrid,
  GridColumn as Column,
} from '@progress/kendo-react-grid'
import LogDetail from './LogDetails'
import { DatePicker } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'

interface IInitialValues {
  fromDate: string
  toDate: string
  filterByUserName: string
  filterByMethod: string
}

const defaultFromDate = format(subDays(new Date(), 30), 'yyyy-MM-dd')
const defaultToDate = format(new Date(), 'yyyy-MM-dd')

const initialValues: IInitialValues = {
  fromDate: defaultFromDate,
  toDate: defaultToDate,
  filterByUserName: '',
  filterByMethod: '',
}

export const renderDate = (props: any) => {
  return (
    <td style={{ cursor: 'default' }}>
      {props.dataItem[props.field]
        ? formatDateAndTime(new Date(props.dataItem[props.field]))
        : 'N/A'}
    </td>
  )
}

const ApiLogs = () => {
  const [openDetailForm, setOpenDetailForm] = useState<boolean>(false)
  const [selectedRowData, setSelectedRowData] = useState<any>([])

  const { profileClient } = useContext(AuthContext)

  const grid = useRef<any>(null)
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const getLogData = (values: any, kendoState: any) => {
    const options = {
      variables: {
        from: values.fromDate,
        to: values.toDate,
        filterByMethod: values.filterByMethod,
        filterByUserName: values.filterByUserName,
        kendoPagination: JSON.stringify(kendoState),
      },
    }

    getApiLogs(options)
  }

  const searchForm = useFormik({
    initialValues,
    onSubmit: (values: any) => {
      getLogData(values, gridState)
    },
  })

  const [getApiLogs, { data, loading }] = useLazyQuery<any>(GET_API_LOGS, {
    fetchPolicy: 'cache-and-network',
    client: logClient,
  })

  const disableUI = loading

  const handleRowClick = (row: any): void => {
    const dataAPI: any[] = []
    dataAPI.push({ label: 'API log ID', value: row?.id })
    dataAPI.push({ label: 'Message', value: row?.message })
    dataAPI.push({ label: 'Level', value: row?.level })
    dataAPI.push({ label: 'Date', value: row?.timeStamp })
    if (row?.exception)
      dataAPI.push({ label: 'Exception', value: row?.exception })
    dataAPI.push({ label: 'Application Name', value: row?.applicationName })
    dataAPI.push({ label: 'Elapsed', value: row?.elapsed })
    dataAPI.push({ label: 'Username', value: row?.username })
    dataAPI.push({ label: 'User ID', value: row?.userId })
    dataAPI.push({ label: 'Business Info', value: row?.businessInfo })
    dataAPI.push({ label: 'Endpoint Name', value: row?.endpointName })
    if (row?.queryString)
      dataAPI.push({ label: 'Query String', value: row?.queryString })
    if (row?.body) dataAPI.push({ label: 'Body', value: row?.body })
    dataAPI.push({ label: 'Host', value: row?.host })
    if (row?.filesQty)
      dataAPI.push({ label: 'Files Quantity', value: row?.filesQty })

    setSelectedRowData(() => dataAPI)
    setOpenDetailForm(true)
  }

  const handleCloseDetailForm = (): void => {
    setOpenDetailForm(false)
  }

  const gridColumns: any[] = [
    {
      title: 'ID',
      field: 'id',
      show: true,
    },
    {
      title: 'Message',
      field: 'message',
      show: true,
      width: '75px',
      filter: 'numeric',
    },
    {
      title: 'Application Name',
      field: 'applicationName',
      show: true,
      width: '120px',
    },
    {
      title: 'Username',
      field: 'username',
      show: true,
    },
    {
      title: 'Timestamp',
      field: 'timeStamp',
      show: true,
      width: '80px',
      render: renderDate,
    },
  ]

  return (
    <form onSubmit={searchForm.handleSubmit}>
      <Box>
        <Box display="flex" flexDirection="row" alignItems="end" pb={1}>
          <Box ml={4} mr={4}>
            <DatePicker
              id="filter-from"
              label="From"
              name="fromDate"
              value={searchForm.values.fromDate}
              onChange={(date) => {
                searchForm.setFieldValue('fromDate', date)
              }}
              disabled={disableUI}
              country={profileClient?.Country}
            />
          </Box>
          <Box mr={4}>
            <DatePicker
              id="filter-to"
              label="To"
              name="toDate"
              value={searchForm.values.toDate}
              onChange={(date) => {
                searchForm.setFieldValue('toDate', date)
              }}
              disabled={disableUI}
              country={profileClient?.Country}
            />
          </Box>
          <Box mr={4} mt={4} pb="3px">
            <TextField
              id="method-filter"
              label="Operation"
              name="filterByMethod"
              value={searchForm.values.filterByMethod}
              onChange={searchForm.handleChange}
              disabled={disableUI}
            />
          </Box>
          <Box mr={4} mt={4} pb="3px">
            <TextField
              id="user-name-filter"
              label="Username"
              name="filterByUserName"
              value={searchForm.values.filterByUserName}
              onChange={searchForm.handleChange}
              disabled={disableUI}
            />
          </Box>
          <Box mr={4} mt={4}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={disableUI}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Search
            </Button>
          </Box>
        </Box>
        <Box pb={2} />
        <div style={{ cursor: 'pointer' }}>
          <KendoGrid
            style={{
              height: '90%',
              maxHeight: '65vh',
            }}
            ref={grid}
            skip={gridState.skip}
            take={gridState.take}
            pageSize={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            pageable={{ pageSizes: [5, 10, 25, 50, 100, 1000] }}
            data={data?.getApiLogs.apiLogs}
            total={data?.getApiLogs.totalNumber}
            sortable
            onRowClick={(e) => {
              handleRowClick(e.dataItem)
            }}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
              getLogData(searchForm.values, e.dataState)
            }}
          >
            {gridColumns.reduce((previousValue, currentValue, idx) => {
              const columns = [...previousValue]

              if (currentValue.show) {
                const mapper: any = {}
                if (currentValue.render) {
                  mapper.cell = (props: any) => (
                    <currentValue.render {...props} />
                  )
                }

                if (currentValue.filter) {
                  mapper.filter = currentValue.filter
                }

                columns.push(
                  <Column
                    {...mapper}
                    key={currentValue.field + currentValue.title}
                    field={currentValue.field}
                    title={currentValue.title}
                    filter={currentValue.filter}
                  />
                )
              }

              return columns
            }, [])}
          </KendoGrid>
        </div>
        <LogDetail
          isOpen={openDetailForm}
          title="API Log Detail"
          onClose={handleCloseDetailForm}
          data={selectedRowData}
        />
      </Box>
    </form>
  )
}

export default ApiLogs
