/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Button,
  Skeleton,
} from '@mui/material'
import { useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useChangeWebhookRegister } from 'src/graphql/operations/mutations/sellerBusiness'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TENANT_TYPE } from 'src/utils/constants'
import { useQuery } from '@apollo/client'
import { GET_WEBHOOK_REGISTER } from 'src/graphql/operations/queries/clientProfiles'
import {
  GetWebhookRegisterResponse,
  WebhookRegisterResponse,
} from 'src/graphql/models/clientProfiles'
import { SkeletonTitle } from 'src/components/BusinessSkeleton/styles'

interface WebHooksNotificationProps {
  sellerBusinessId?: string
}

const initialValueData = {
  urlWebhookPlacement: '',
  enableWebhookPlacement: false,
  enableWebhookMonitoringDownloadFile: false,
  urlWebhookMonitoringDownloadFile: '',
}

const WebHooksNotification = ({
  sellerBusinessId,
}: WebHooksNotificationProps) => {
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const { enqueueSnackbar } = useSnackbar()
  const [initialFormValues, setInitialFormValues] =
    useState<any>(initialValueData)

  const handleAddOrUpdateBusinessCompleted = (data: any) => {
    if (data) {
      const text = sellerBusinessId ? 'Update' : 'Create'
      enqueueSnackbar(`${text} webhook successful`, { variant: 'success' })
    }
  }

  const { data: registerResponse, loading: registerLoading } =
    useQuery<GetWebhookRegisterResponse>(GET_WEBHOOK_REGISTER, {
      variables: {
        eventType: 'Placement',
        businessId: sellerBusinessId,
      },
    })

  const {
    data: registerMonitoringResponse,
    loading: registerMonitoringLoading,
  } = useQuery<GetWebhookRegisterResponse>(GET_WEBHOOK_REGISTER, {
    variables: {
      eventType: 'MonitoringFileAvailableToDownload',
      businessId: sellerBusinessId,
    },
  })

  useEffect(() => {
    if (registerResponse) {
      setInitialFormValues((prevState: WebhookRegisterResponse) => ({
        ...prevState,
        urlWebhookPlacement: registerResponse?.getWebhookRegister?.url,
        enableWebhookPlacement: registerResponse?.getWebhookRegister?.url,
      }))
    }
  }, [registerResponse])

  useEffect(() => {
    if (registerMonitoringResponse) {
      setInitialFormValues((prevState: WebhookRegisterResponse) => ({
        ...prevState,
        urlWebhookMonitoringFileAvailableToDownload:
          registerMonitoringResponse?.getWebhookRegister?.url,
        enableWebhookMonitoringFileAvailableToDownload:
          registerMonitoringResponse?.getWebhookRegister?.url,
      }))
    }
  }, [registerMonitoringResponse])

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.urlWebhookPlacement && values.enableWebhookPlacement) {
      errors.urlWebhookPlacement = 'Required'
    }
    if (
      !values.urlWebhookMonitoringDownloadFile &&
      values.enableWebhookMonitoringDownloadFile
    ) {
      errors.urlWebhookMonitoringDownloadFile = 'Required'
    }
    return errors
  }

  const { changeWebhookRegister, loading: isLoading } =
    useChangeWebhookRegister(handleAddOrUpdateBusinessCompleted)

  const useCreateForm = (eventType: string, initialValues: any) => {
    return useFormik({
      initialValues,
      enableReinitialize: true,
      validate,
      onSubmit: (values, { setSubmitting }) => {
        changeWebhookRegister({
          variables: {
            request: {
              businessId: sellerBusinessId,
              eventType,
              url: values[`enableWebhook${eventType}`]
                ? values[`urlWebhook${eventType}`]
                : '',
            },
          },
          refetchQueries: ['GetWebhookRegister'],
        })
      },
    })
  }

  const placementForm = useCreateForm('Placement', initialFormValues)
  const monitoringDownloadFilesForm = useCreateForm(
    'MonitoringFileAvailableToDownload',
    initialFormValues
  )

  if (registerLoading) {
    return (
      <Box display="flex" flexDirection="column" style={{ gap: 50 }}>
        <SkeletonTitle />
        <Box p={2} display="flex" style={{ gap: 20 }}>
          <Skeleton
            width="100%"
            height={50}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            width="100%"
            height={50}
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            width="100%"
            height={50}
            animation="wave"
            variant="rectangular"
          />
        </Box>
      </Box>
    )
  }

  const renderForm = (form: any, eventType: string, label: string) => (
    <form onSubmit={form.handleSubmit}>
      <Box mb={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <FormControlLabel
              style={{ marginTop: 20 }}
              control={
                <Switch
                  color="primary"
                  disabled={!isInternal}
                  onChange={() => {
                    form.setFieldValue(
                      `enableWebhook${eventType}`,
                      !form.values[`enableWebhook${eventType}`],
                      true
                    )
                    form.setFieldValue(`urlWebhook${eventType}`, '', true)
                  }}
                  name={`enableWebhook${eventType}`}
                  value={form.values[`enableWebhook${eventType}`]}
                  checked={form.values[`enableWebhook${eventType}`]}
                  title={`Enable ${label}`}
                />
              }
              label={`Enable ${label}`}
            />
          </Grid>
          {form.values[`enableWebhook${eventType}`] && (
            <Grid item xs={12} lg={6}>
              <TextField
                fullWidth
                disabled={!form.values[`enableWebhook${eventType}`]}
                label={`Url ${label}`}
                name={`urlWebhook${eventType}`}
                onChange={form.handleChange}
                error={!!form.errors[`urlWebhook${eventType}`]}
                value={form.values[`urlWebhook${eventType}`]}
                helperText={form.errors[`urlWebhook${eventType}`]}
              />
            </Grid>
          )}
          <Grid item xs={12} lg={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                isLoading ||
                !form.dirty ||
                registerLoading ||
                registerMonitoringLoading
              }
              style={{ marginTop: 10 }}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  )

  return (
    <>
      <Typography variant="h6">Webhooks</Typography>
      {renderForm(placementForm, 'Placement', 'placement notification')}
      {renderForm(
        monitoringDownloadFilesForm,
        'MonitoringFileAvailableToDownload',
        'Monitoring file available to download notification'
      )}
    </>
  )
}

WebHooksNotification.defaultProps = {
  sellerBusinessId: '',
}

export default WebHooksNotification
