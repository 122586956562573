import {
  Box,
  Button,
  MenuItem,
  Grid,
  TextField,
  Paper,
  Typography,
} from '@mui/material'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useAddUserNotificationTemplate } from 'src/graphql/operations/mutations/clientProfiles'
import { useSnackbar } from 'notistack'
import { NotificationTemplateResponse } from 'src/graphql/models/clientProfiles'
import { GET_NOTIFICATION_TEMPLATE } from 'src/graphql/operations/queries/clientProfiles'
import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'

const NotificationTemplateSchema = Yup.object().shape({
  businessType: Yup.string().nullable(),
  subject: Yup.string().nullable(),
  textContent: Yup.string().nullable(),
  htmlContent: Yup.string().nullable(),
})

const EditNotificationText = () => {
  const initialValueData: any = {
    businessType: '',
    subject: '',
    textContent: '',
    htmlContent: '',
  }

  const [initialValues, setInitialValues] = useState<any>(initialValueData)
  const { enqueueSnackbar } = useSnackbar()
  const ability = useContext(AbilityContext)
  const hasBusinessNotificationPermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_NOTIFICATION_PERMISSION,
    'any'
  )
  const handleUserNotificationTemplateCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('User notification template created successful', {
        variant: 'success',
      })
    }
  }

  const { addUserNotificationTemplate } = useAddUserNotificationTemplate(
    () => handleUserNotificationTemplateCompleted
  )

  const NotificationTemplateForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: NotificationTemplateSchema,

    onSubmit: (values, { setSubmitting }) => {
      const notificationTemplateRequest = {
        businessType: values.businessType || null,
        subject: values.subject || null,
        textContent: values.textContent || null,
        htmlContent: values.htmlContent || null,
      }

      addUserNotificationTemplate({
        variables: {
          request: notificationTemplateRequest,
        },
      })
    },
  })

  const { data: getNotificationTemplate } =
    useQuery<NotificationTemplateResponse>(GET_NOTIFICATION_TEMPLATE, {})

  useEffect(() => {
    setInitialValues((prevState: NotificationTemplateResponse) => ({
      ...prevState,
      ...getNotificationTemplate?.notificationTemplate?.find(
        (x) => x.id === NotificationTemplateForm.values.businessType
      ),
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [NotificationTemplateForm.values.businessType])

  return (
    <form onSubmit={NotificationTemplateForm.handleSubmit}>
      <Box p={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h1" color="secondary">
              Edit Notifications Text
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={5}>
        <Paper>
          <Box>
            <Grid container style={{ padding: '20px' }}>
              <Grid xs={12} sm={12} md={12} style={{ display: 'flex' }}>
                <Grid xs={6} sm={6} md={6}>
                  <Box
                    gap={2.5}
                    style={{ display: 'flex', flexDirection: 'row' }}
                  >
                    <TextField
                      fullWidth
                      label="Business type"
                      name="businessType"
                      select
                      disabled={!hasBusinessNotificationPermission}
                      onChange={NotificationTemplateForm.handleChange}
                      error={!!NotificationTemplateForm.errors.businessType}
                    >
                      <MenuItem key={1} value={1}>
                        Internal
                      </MenuItem>
                      <MenuItem key={2} value={2}>
                        External Buyer
                      </MenuItem>
                      <MenuItem key={3} value={3}>
                        External Seller
                      </MenuItem>
                    </TextField>
                    <TextField
                      fullWidth
                      label="Subject Line"
                      name="subject"
                      select
                      disabled={!hasBusinessNotificationPermission}
                      onChange={NotificationTemplateForm.handleChange}
                      error={!!NotificationTemplateForm.errors.subject}
                      value={NotificationTemplateForm.values.subject}
                    >
                      <MenuItem
                        key={NotificationTemplateForm.values.subject}
                        value={NotificationTemplateForm.values.subject}
                      >
                        {NotificationTemplateForm.values.subject}
                      </MenuItem>
                    </TextField>
                  </Box>
                </Grid>
                <Box
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <Button
                    disabled={!hasBusinessNotificationPermission}
                    data-cy="save-internal-notification-text"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box
              gap={5}
              style={{
                marginTop: '20px',
                padding: '20px',
                display: 'flex',
              }}
            >
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="h6">Text Content</Typography>
                <TextField
                  disabled={!hasBusinessNotificationPermission}
                  id="-outlined-multiline-edit-text"
                  label="Edit notification text"
                  name="textContent"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={8}
                  onChange={NotificationTemplateForm.handleChange}
                  error={!!NotificationTemplateForm.errors.textContent}
                  value={NotificationTemplateForm.values.textContent}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Typography variant="h6">HTML Content</Typography>
                <TextField
                  disabled={!hasBusinessNotificationPermission}
                  id="outlined-multiline-edit-html-text"
                  label="Edit HTML notification text"
                  name="htmlContent"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={8}
                  onChange={NotificationTemplateForm.handleChange}
                  error={!!NotificationTemplateForm.errors.htmlContent}
                  value={NotificationTemplateForm.values.htmlContent}
                />
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Box>
    </form>
  )
}
export default EditNotificationText
