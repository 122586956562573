/* eslint-disable react/require-default-props */
import {
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableCell,
} from '@mui/material'
import React from 'react'
import { ModalDialog } from 'everchain-uilibrary'

interface LogDetailProps {
  data?: any
  title: string
  isOpen: boolean
  onClose: () => void
}

const LogDetail: React.FC<LogDetailProps> = ({
  data = [],
  title,
  isOpen,
  onClose,
}: LogDetailProps) => {
  return (
    <ModalDialog isOpen={isOpen} header={title} onClose={onClose}>
      <TableContainer>
        <Table size="small">
          <TableBody>
            {data &&
              data.map((row: any, i: number) => (
                <TableRow key={i}>
                  <TableCell>{row.label}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ModalDialog>
  )
}

export default LogDetail
