import { ApolloClient } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import { authUrl, clientId } from 'src/configs/authConst'

import { cache } from '../apollo-cache'

const user = window.localStorage.getItem(`dt.user:${authUrl}:${clientId}` || '')

const userToken = user ? `bearer ${JSON.parse(user).access_token}` : ''

const uri = process.env.REACT_APP_API
const logUri = process.env.REACT_APP_LOG_API
const uriMarketPlace = process.env.REACT_APP_MARKETPLACE

export const client = new ApolloClient({
  cache,
  // @ts-ignore
  link: createUploadLink({
    uri,
    headers: {
      Authorization: userToken,
    },
  }),
  connectToDevTools: true,
})

export const logClient = new ApolloClient({
  cache,
  uri: logUri,
  connectToDevTools: true,
  headers: {
    Authorization: userToken,
  },
})

export const clientMarketPlace = new ApolloClient({
  cache,
  // @ts-ignore
  uri: uriMarketPlace,
  connectToDevTools: true,
  headers: {
    Authorization: userToken,
  },
})
