/* eslint-disable react/require-default-props */
import React, { useState, useMemo } from 'react'
import { Tabs, Tab, Paper, Box, Divider } from '@mui/material'

import TabPanel from 'src/components/TabPanel'

import PermissionBuilder from 'src/components/PermissionBuilder/PermissionBuilder'
import { Role } from 'src/graphql/models/managePermissions'

import RoleMembersTable from './RoleMembersTable'

enum TabControls {
  PERMISSIONS = 'permission',
  MEMBERS = 'members',
}

interface RoleControlTabsProps {
  onAddNewUsers: () => void
  roleId: string
  roleLoading?: boolean
  role?: Role
  tabType?: string
  selectedTenantId?: string
}
const RoleControlTabs: React.FC<RoleControlTabsProps> = ({
  onAddNewUsers,
  roleId,
  roleLoading,
  role,
  tabType,
  selectedTenantId,
}: RoleControlTabsProps) => {
  const [value, setValue] = useState(tabType || String(TabControls.PERMISSIONS))
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Paper>
      <Tabs
        value={value}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleChange}
        aria-label="Roles and Users"
      >
        <Tab label="Permissions" value={TabControls.PERMISSIONS} />
        <Tab label="Members" value={TabControls.MEMBERS} />
      </Tabs>
      <Divider />
      <TabPanel value={value} index={TabControls.PERMISSIONS}>
        <Box p={2}>
          <PermissionBuilder
            permissionType="ROLE"
            entityId={roleId}
            permissionArguments={{
              isSeller: role?.isSeller || false,
              isBuyer: role?.isBuyer || false,
              isInternal: role?.isInternal || false,
              isCreditor: role?.isCreditor || false,
              isVendor: role?.isVendor || false,
              isAgency: role?.isAgency || false,
              isServicer: role?.isServicer || false,
            }}
            loading={roleLoading}
          />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={TabControls.MEMBERS}>
        {useMemo(
          () => (
            <RoleMembersTable
              roleId={roleId}
              onAddNewUsers={onAddNewUsers}
              selectedTenantId={selectedTenantId}
            />
          ),
          [roleId, onAddNewUsers, selectedTenantId]
        )}
      </TabPanel>
    </Paper>
  )
}

RoleControlTabs.defaultProps = {
  roleLoading: false,
  role: {
    clientName: '',
    description: '',
    id: '',
    name: '',
    numberOfMembers: 0,
    isBuyer: false,
    isInternal: false,
    isSeller: false,
    isCreditor: false,
    isVendor: false,
    isAgency: false,
    isServicer: false,
  },
}

RoleControlTabs.defaultProps = {
  tabType: 'permission',
}

export default React.memo(RoleControlTabs)
