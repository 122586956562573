/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { userCommission } from 'src/graphql/models/commissions'
import { useSnackbar } from 'notistack'
import { useAddOrUpdateUserCommission } from 'src/graphql/operations/mutations/commissions'
import { useFormik } from 'formik'
import { Box, Grid, InputAdornment, MenuItem, TextField } from '@mui/material'
import { formatDate } from 'src/utils/date'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DatePicker, Flex, ModalDialog, Button } from 'everchain-uilibrary'

interface DefaultValuesProps {
  id: number | string | undefined
  startDate: Date | null
  userId: string
  commissionPercent: number | string | null
  type: string | null
}

const initialValuesDefault = {
  id: '',
  startDate: null,
  userId: '',
  commissionPercent: null,
  type: 'individual',
}

interface UserCommissionParam {
  userId: string
  userCommission: userCommission | undefined
  open?: boolean
  onClose: () => void
}

const formSchema = Yup.object().shape({
  startDate: Yup.date().nullable().required('Required'),
  commissionPercent: Yup.number()
    .min(0, 'Commission Percent must be more than or equal to 0')
    .nullable()
    .required('Required'),
})

const UserCommission: React.FC<UserCommissionParam> = ({
  userId,
  userCommission,
  open = false,
  onClose,
}: UserCommissionParam) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()

  const { AddOrUpdateUserCommission, loading: formLoading } =
    useAddOrUpdateUserCommission({
      onCompleted: () => {
        enqueueSnackbar('User commission saved successfully', notifySuccess)
        onClose()
      },
      onError: () => {
        enqueueSnackbar('You can not select a date in the past.', notifyError)
        onClose()
      },
    })
  const actualDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )
  const userCommissionForm = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id: values.id || null,
        userId,
        startDate: values.startDate
          ? new Date(formatDate(values.startDate))
          : null,

        commissionPercent: values.commissionPercent
          ? Number(values.commissionPercent)
          : null,
        type: values.type,
      }

      if (userId || userCommission) {
        AddOrUpdateUserCommission({
          variables: {
            userCommissionRequest: {
              ...requestValues,
            },
          },
          refetchQueries: ['GetUserCommissions'],
        })
      }

      setSubmitting(false)
    },
  })
  useEffect(() => {
    if (userCommission && userCommission.id) {
      setInitialValues((prevState) => ({
        ...prevState,
        id: userCommission.id || '',
        startDate: userCommission.startDate || '',
        userId: userId || '',
        commissionPercent: userCommission.commissionPercent || '',
        type: userCommission.type || '',
      }))
    }
  }, [userCommission, userId])

  return (
    <ModalDialog
      isOpen={open}
      onClose={onClose}
      header={`${userCommission?.id ? 'Edit' : 'Add'} User Commission`}
      isForm={true}
    >
      <form onSubmit={userCommissionForm.handleSubmit}>
        <Box>
          <Grid container spacing={5}>
            <Grid item>
              <DatePicker
                name="startDate"
                id="startDate"
                label="Period"
                views={['year', 'month']}
                value={userCommissionForm.values.startDate}
                errorMessage={userCommissionForm.errors.startDate}
                onChange={(date) => {
                  if (date && date < actualDate) {
                    enqueueSnackbar(
                      'You can not select a date in the past.',
                      notifyError
                    )
                  } else {
                    userCommissionForm.setFieldValue('startDate', date, false)
                  }
                }}
                inputFormat="YYYY/MM"
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Commission Percent"
                name="commissionPercent"
                type="number"
                inputProps={{ step: '.01' }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
                onChange={userCommissionForm.handleChange}
                value={userCommissionForm.values.commissionPercent}
                error={!!userCommissionForm.errors.commissionPercent}
                helperText={userCommissionForm.errors.commissionPercent}
              />
            </Grid>

            <Grid item>
              <TextField
                fullWidth
                label="Type"
                name="type"
                select
                onChange={userCommissionForm.handleChange}
                error={!!userCommissionForm.errors.type}
                value={userCommissionForm.values.type}
                helperText={userCommissionForm.errors.type}
              >
                <MenuItem key="team" value="team">
                  Team
                </MenuItem>
                <MenuItem key="individual" value="individual">
                  Individual
                </MenuItem>
              </TextField>
            </Grid>
          </Grid>
        </Box>
        <Flex
          data-test-id={'modal-dialog-footer'}
          justifyContent="flex-end"
          pb="12px"
          pt="20px"
        >
          <Button
            data-test-id={'modal-dialog-ok-buton'}
            margin={'0 0 0 10px'}
            onClick={onClose}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            data-test-id={'modal-dialog-ok-buton'}
            margin={'0 0 0 10px'}
            type="submit"
            isFetching={formLoading}
            disabled={formLoading}
          >
            Save
          </Button>
        </Flex>
      </form>
    </ModalDialog>
  )
}

export default UserCommission
