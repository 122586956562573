import React, { useContext } from 'react'
import { GET_DELIVERY_STRUCTURES } from 'src/graphql/operations/queries/business'
import TransferList from 'src/components/TransferList'
import {
  DeliveryStructure,
  DeliveryStructureResponse,
} from 'src/graphql/models/businessRecovery'
import {
  useAddDeliveryStructure,
  useRemoveDeliveryStructure,
} from 'src/graphql/operations/mutations/businesses'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useQuery } from '@apollo/client'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

const BusinessDeliveryStructure = (businessId: string | any) => {
  const ability = useContext(AbilityContext)
  const { enqueueSnackbar } = useSnackbar()
  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')

  const { data: getDeliveryStructure } = useQuery<DeliveryStructureResponse>(
    GET_DELIVERY_STRUCTURES,
    {
      variables: {
        businessId: businessId.businessId,
      },
      skip: !businessId,
    }
  )

  const mapItems = (array: DeliveryStructure[]) =>
    array.map((e) => ({ id: e.id, name: e.name }))

  const { addDeliveryStructure } = useAddDeliveryStructure((data: any) => {
    if (data) {
      enqueueSnackbar(
        'Delivery structures updated successfully',
        notistackSucces
      )
    } else {
      enqueueSnackbar(
        'Error while trying to update the delivery structures',
        notistackWarning
      )
    }
  })

  const { removeDeliveryStructure } = useRemoveDeliveryStructure(
    (data: any) => {
      if (data) {
        enqueueSnackbar(
          'Delivery structures updated successfully',
          notistackSucces
        )
      } else {
        enqueueSnackbar(
          'Error while trying to update the delivery structures',
          notistackWarning
        )
      }
    }
  )

  const handleSendLeftColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    removeDeliveryStructure({
      variables: {
        request: {
          businessId: businessId.businessId,
          deliveryStructureId: ids,
        },
      },
      refetchQueries: ['GetDeliveryStructures'],
    })
  }

  const handleSendRightColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    addDeliveryStructure({
      variables: {
        request: {
          businessId: businessId.businessId,
          deliveryStructureId: ids,
        },
      },
      refetchQueries: ['GetDeliveryStructures'],
    })
  }

  return (
    <TransferList
      leftData={mapItems(
        getDeliveryStructure?.deliveryStructures?.availableDeliveryStructures ||
          []
      )}
      rightData={mapItems(
        getDeliveryStructure?.deliveryStructures?.deliveryStructures || []
      )}
      onSendLeftColumn={handleSendLeftColumn}
      onSendRightColumn={handleSendRightColumn}
      readonly={
        !ability.can(PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION, 'any')
      }
    />
  )
}

export default BusinessDeliveryStructure
