import React from 'react'
import { useParams } from 'react-router-dom'
import { Box } from '@mui/material'

import Header from 'src/components/Header'
import { GET_SELLER } from 'src/graphql/operations/queries/sellerBusiness'
import { GetSeller } from 'src/graphql/models/sellerBusiness'
import { useQuery } from '@apollo/client'
import SellerBusinessTab from './components/SellerBusinessTab'
import { CLIENT_PROFILES_MANAGE } from 'src/routes'

const SellerBusinessManage = () => {
  const { sellerBusinessId, clientId } = useParams<any>()

  const { data: getSellerData } = useQuery<GetSeller>(GET_SELLER, {
    variables: {
      sellerId: sellerBusinessId,
    },
    skip: !sellerBusinessId,
    fetchPolicy: 'no-cache',
  })
  return (
    <>
      <Box p={5}>
        <Header
          title={getSellerData?.getSeller.name || 'Create a New Seller'}
          customBackUrl={`${CLIENT_PROFILES_MANAGE}/${clientId}`}
        />
      </Box>
      <Box p={5}>
        <SellerBusinessTab
          clientId={clientId}
          sellerBusinessId={sellerBusinessId}
          sellerData={getSellerData?.getSeller}
        />
      </Box>
    </>
  )
}

export default SellerBusinessManage
