import React from 'react'
import { format } from 'date-fns'

import { numberCurrency, numberToPercentage } from 'src/utils/numbers'

export const cellDate = (props: any): React.ReactElement => {
  return props.cell.value ? (
    <span>
      {props.cell.value
        ? format(new Date(props.cell.value), 'yyyy-MM-dd')
        : props.cell.value}
    </span>
  ) : (
    <span>N/A</span>
  )
}

export const cellPeriod = (props: any): React.ReactElement => {
  return props.cell.value ? (
    <span>
      {props.cell.value
        ? format(new Date(props.cell.value), 'yyyy-MM')
        : props.cell.value}
    </span>
  ) : (
    <span>N/A</span>
  )
}

export const cellDateTime = (props: any): React.ReactElement => {
  let dt = props?.cell?.value
  if (dt && dt.indexOf('+00:00') < 0) dt += '+00:00'
  return dt ? (
    <span>{format(new Date(dt), 'yyyy-MM-dd hh:mm:ss a')}</span>
  ) : (
    <span>N/A</span>
  )
}

export const cellCurrency = (props: any): React.ReactElement => {
  return <span>{numberCurrency(props.cell.value)}</span>
}

export const cellPercentage = (props: any): React.ReactElement => {
  return <span>{numberToPercentage(props.cell.value)}</span>
}
export const CellClick = (props: any) => {
  return <span>{props.onClick(props.cell.value)}</span>
}
