import { gql } from '@apollo/client'

export const GET_SALES_AGENTS = gql`
  query GetSalesAgents($isSuccessManager: Boolean) {
    getSalesAgents(isSuccessManager: $isSuccessManager) {
      displayName
      status
      title
      userId
    }
  }
`

export const GET_BUSINESS_NAME_HISTORY = gql`
  query GetBusinessNameHistory($businessId: String!) {
    getBusinessNameHistory(businessId: $businessId) {
      endDateUtc
      startDateUtc
      name
      id
      businessId
    }
  }
`
export const GET_SELLER = gql`
  query GetSeller($sellerId: String!) {
    getSeller(sellerId: $sellerId) {
      accountNumber
      accountTypeCode
      allowAdditionalUnmaskedData
      bankAccountStatus
      bankName
      # bidReviewQuantity
      businessType
      buyerBidAccessOption
      city
      clientId
      corpHQPhoneNumber
      countryCode
      customerServiceEmail
      customerServicePhoneNumber
      defaultBankAccountId
      dTCanAcceptBid
      dTCanClosePostSaleRequest
      dTCanConfirmFundsReceived
      dTCanCreatePostSaleRequest
      dTCanList
      dTCanMaintainComplaints
      dTCanRemoveAccountsFromPortfolio
      dTCanRespondToPostSaleRequest
      dTCanUploadDownloadPSA
      enableBCOReport
      enforceMinimumFee
      feePercent
      forwardFlowFeePercent
      holdScrub
      id
      importFromId
      includeTLODismissals
      insurance_Comment
      insurance_CoverageAmount
      insurance_ExpirationDate
      insurance_Name
      insurance_PolicyType
      isOriginator
      lastUpdatedByUserId
      lastUpdatedByUserName
      lastUpdatedUTCDate
      legalName
      militaryCertificates
      militaryScrub
      minimumFee
      name
      permissionsLastUpdatedByUserId
      permissionsLastUpdatedUTC
      postalCode
      primaryContact_EMail
      primaryContact_FirstName
      primaryContact_LastName
      primaryContact_MobilePhone
      primaryContact_OfficePhone
      requireBCOFinancials
      routingNumber
      nameOnAccount
      salesAgentDisplayName
      salesAgentId
      clientSuccessUserId
      scrubReviewThreshold
      sellerTerms
      showWinningBid
      signer_FullName
      signer_Title
      softwareUsed
      stateCode
      status
      street1
      street2
      originationState
      disclosure
      startHoursOfOperation
      endHoursOfOperation
      daysOfOperation
      projectedMonthlyPlacementVolumes
      fF_PSA_SignatureRequired
      allowPortfolioSplit
      unmaskDataNDABlobUri
      isUnmaskDataNDARequired
      billingStreet1
      billingStreet2
      billingCity
      billingStateCode
      billingPostalCode
      bosSignerName
      bosSignerTitle
      bosSignerEmail
      psaSignerName
      psaSignerTitle
      psaSignerEmail
      apiKey
      consumerPaymentInstruction
      enablePartnershipScoring
      mediaFileNamingConvention
      mediaProvidedInBulk
      numberOfDaysToProvideMedia
      companyWebsiteAddress
      taxId
      businessTypeLLC
      stateOfIncorporation
      dateOfEstablishment
      useAccountsProcessPortfolioFunc
    }
  }
`

export const GET_BUYER_BID_ACCESS = gql`
  query GetBuyerBidAccess($sellerId: String!) {
    getBuyerBidAccess(sellerId: $sellerId) {
      sellerId
      bidAccessOption
      buyersInList {
        buyerId
        buyerName
        comment
        id
        lastUpdated
        lastUpdatedUserName
      }
      buyersOutOfList {
        businessType
        feePercent
        id
        name
        status
      }
    }
  }
`
