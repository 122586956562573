import React, { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material'
import {
  GET_AGENCIES,
  GET_APPROVED_AGENCIES,
  GET_PLACEMENT_SETTING,
} from 'src/graphql/operations/queries/clientProfiles'
import {
  useAddApprovedAgencies,
  useAddOrUpdatePlacementSettings,
  useRemoveApprovedAgencies,
} from 'src/graphql/operations/mutations/clientProfiles'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import TransferList from 'src/components/TransferList'
import { cache } from 'src/apollo-cache'
import { Save } from '@mui/icons-material'
import { removeDuplicateArrayById } from './RemoveDuplicatedItems'

const initialValueData = {
  enabled: false,
  dormantAccountNotificationEnabled: false,
  dormantAccountPercentage: 20,
  dormantAccountLenght: 365,
}

interface AccountPlacementProps {
  clientProfileId: number
}

const placementAccountSchema = Yup.object().shape({
  dormantAccountPercentage: Yup.number()
    .nullable()
    .when('dormantAccountNotificationEnabled', {
      is: true,
      then: Yup.number().required('Required'),
    })
    .min(0, 'Percentage must be between 0 and 100.')
    .max(100, 'Percentage must be between 0 and 100.'),
  dormantAccountLenght: Yup.number()
    .nullable()
    .when('dormantAccountNotificationEnabled', {
      is: true,
      then: Yup.number().required('Required'),
    })
    .min(0, 'Lenght must be more than or equal to 0'),
})

const AccountPlacement = ({ clientProfileId }: AccountPlacementProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const ability = useContext(AbilityContext)
  const [initialValues, setInitialValues] = useState<any>(initialValueData)

  const { data, loading } = useQuery<any>(GET_PLACEMENT_SETTING, {
    variables: {
      clientId: Number(clientProfileId),
    },
  })
  const { loading: loadingAgencies } = useQuery<any>(GET_AGENCIES)

  const { data: getApprovedAgencies, loading: approvedAgenciesLoading } =
    useQuery<any>(GET_APPROVED_AGENCIES, {
      fetchPolicy: 'no-cache',

      variables: {
        clientId: Number(clientProfileId),
      },
    })

  const handleAddApprovedAgenciesCompleted = (addedData: any) => {
    if (addedData) {
      enqueueSnackbar('Agency selection updated successfully', {
        variant: 'success',
      })
    }
  }

  const handleRemoveApprovedAgenciesCompleted = (removedData: any) => {
    if (removedData) {
      enqueueSnackbar('Agency selection updated successfully', {
        variant: 'success',
      })
    }
  }

  const { addApprovedAgencies, loading: loadingApprove } =
    useAddApprovedAgencies(handleAddApprovedAgenciesCompleted)

  const { removeApprovedAgencies, loading: loadingRemove } =
    useRemoveApprovedAgencies(handleRemoveApprovedAgenciesCompleted)

  const handleAddorUpdatePlacementSettingsCompleted = (savedData: any) => {
    if (savedData) {
      enqueueSnackbar('Option updated.', {
        variant: 'success',
      })
    }
  }

  const handleSendLeftColumn = (leftData: any[]) => {
    if (leftData.length) {
      const associatedClientId = leftData.map((item) => item.id)
      removeApprovedAgencies({
        variables: {
          approvedAgencies: {
            clientId: Number(clientProfileId),
            agenciesId: associatedClientId,
          },
        },
        refetchQueries: ['GetApprovedAgencies'],
      })
    }
  }

  const handleonSendRightColumn = (rightData: any[]) => {
    if (rightData.length) {
      const associatedClientId = rightData.map((item) => item.id)
      addApprovedAgencies({
        variables: {
          approvedAgencies: {
            clientId: Number(clientProfileId),
            agenciesId: associatedClientId,
          },
        },
        refetchQueries: ['GetApprovedAgencies'],
      })
    }
  }

  let clientList: any[] | null = null
  const {
    addOrUpdatePlacementSettings,
    loading: loadingAddOrUpdatePlacementSettings,
  } = useAddOrUpdatePlacementSettings(
    handleAddorUpdatePlacementSettingsCompleted
  )

  const handleSavePlacementSettings = (values: any) => {
    addOrUpdatePlacementSettings({
      variables: {
        request: {
          id: data?.getPlacementSettings.id || null,
          clientId: Number(clientProfileId),
          enabled: values.enabled,
          dormantAccountPercentage: values.dormantAccountPercentage,
          dormantAccountNotificationEnabled:
            values.dormantAccountNotificationEnabled,
          dormantAccountLenght: values.dormantAccountLenght,
        },
      },
      refetchQueries: ['GetPlacementSettings'],
    })
  }

  const PlacementForm = useFormik({
    initialValues,
    enableReinitialize: true,

    validationSchema: placementAccountSchema,
    onSubmit: (values) => {
      handleSavePlacementSettings(values)
    },
  })

  if (!approvedAgenciesLoading && !loadingAgencies) {
    const agencies = cache.readQuery<any>({
      query: GET_AGENCIES,
    }).getAgencies.businesses

    const clientListFiltered = agencies.filter(
      (agency: any) => agency.status === 'Approved'
    )

    const clientListNormalize = removeDuplicateArrayById(
      clientListFiltered,
      getApprovedAgencies?.getApprovedAgencies
    )
    clientList = clientListNormalize
  }

  useEffect(() => {
    if (!loading && data) {
      setInitialValues((prevState: any) => ({
        ...prevState,
        enabled: data.getPlacementSettings?.enabled,
        dormantAccountPercentage:
          isNaN(data.getPlacementSettings?.dormantAccountPercentage) ||
          data.getPlacementSettings?.dormantAccountPercentage === null
            ? null
            : Number(
                parseFloat(
                  data.getPlacementSettings?.dormantAccountPercentage.toString()
                ).toFixed(2)
              ),

        dormantAccountNotificationEnabled:
          data.getPlacementSettings?.dormantAccountNotificationEnabled,
        dormantAccountLenght: data.getPlacementSettings?.dormantAccountLenght,
      }))
    }
  }, [data, loading])

  if (!loading && !loadingAgencies && !approvedAgenciesLoading) {
    return (
      <form onSubmit={PlacementForm.handleSubmit}>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            style={{ marginBottom: 20 }}
            control={
              <Switch
                color="primary"
                onChange={(event: any) => {
                  PlacementForm.handleChange(event)
                }}
                name="enabled"
                disabled={
                  loadingAddOrUpdatePlacementSettings ||
                  loading ||
                  !ability.can(
                    PermissionCodeAccess.CLIENT_STATUS_PERMISSION,
                    'any'
                  )
                }
                checked={PlacementForm.values.enabled}
                value={PlacementForm.values.enabled}
              />
            }
            label="Enable account placement"
          />

          <TransferList
            loading={
              loadingAgencies ||
              approvedAgenciesLoading ||
              loadingApprove ||
              loadingRemove
            }
            leftData={clientList || []}
            rightData={getApprovedAgencies?.getApprovedAgencies || []}
            showAllButtons={false}
            onSendLeftColumn={handleSendLeftColumn}
            onSendRightColumn={handleonSendRightColumn}
            readonly={
              !ability.can(PermissionCodeAccess.CLIENT_STATUS_PERMISSION, 'any')
            }
          />
          <Box mt={12} mb={12}>
            <Typography variant="h6">Dormant Accounts</Typography>
            <FormControlLabel
              style={{ marginBottom: 5, marginTop: 5 }}
              control={
                <Switch
                  color="primary"
                  onChange={(event: any) => {
                    PlacementForm.handleChange(event)
                  }}
                  name="dormantAccountNotificationEnabled"
                  disabled={
                    loadingAddOrUpdatePlacementSettings ||
                    loading ||
                    !ability.can(
                      PermissionCodeAccess.CLIENT_STATUS_PERMISSION,
                      'any'
                    )
                  }
                  checked={
                    PlacementForm.values.dormantAccountNotificationEnabled
                  }
                  value={PlacementForm.values.dormantAccountNotificationEnabled}
                />
              }
              label="Dormant account notification enabled"
            />

            <Grid container spacing={3} style={{ width: '100%' }}>
              <Grid item md={3} lg={3} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  defaultValue={20}
                  label="Dormant account percentage"
                  name="dormantAccountPercentage"
                  onChange={PlacementForm.handleChange}
                  error={!!PlacementForm.errors.dormantAccountPercentage}
                  value={PlacementForm.values.dormantAccountPercentage}
                  helperText={
                    <>{PlacementForm.errors.dormantAccountPercentage}</>
                  }
                  inputProps={{
                    step: '.01',
                    min: '0',
                    max: '100',
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={3} lg={3} xs={12}>
                <TextField
                  fullWidth
                  type="number"
                  defaultValue={365}
                  label="Dormant account length (days after purchase)"
                  name="dormantAccountLenght"
                  onChange={PlacementForm.handleChange}
                  error={!!PlacementForm.errors.dormantAccountLenght}
                  value={PlacementForm.values.dormantAccountLenght}
                  helperText={<>{PlacementForm.errors.dormantAccountLenght}</>}
                  inputProps={{
                    min: '0',
                  }}
                />
              </Grid>
            </Grid>
          </Box>
          <Box display="flex" justifyContent="flex-end" my={2}>
            <Button
              startIcon={
                !loadingAddOrUpdatePlacementSettings ? (
                  <Save />
                ) : (
                  <CircularProgress size={16} />
                )
              }
              variant="contained"
              color="primary"
              type="submit"
              disabled={loadingAddOrUpdatePlacementSettings}
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    )
  }
  return (
    <Box>
      <Skeleton variant="rectangular" width={400} height={100} />
    </Box>
  )
}

export default AccountPlacement
