import { httpClient } from '../../axios-wrapper/httpClient'
import { AgreementTemplateResponseType } from '../../models/agreementTemplates'
import {
  DownloadAgreementFile,
  GetAgreementTemplateDetail,
  GetAgreementTemplates,
  UpdateAgreementTemplateFeatureBusiness,
} from '../../urls/agreementTemplates'

export const getAgreementTemplates = async (
  type: string | undefined,
  sellerId: string | undefined,
  kendoPagination: string | undefined
): Promise<AgreementTemplateResponseType> => {
  const response = await httpClient.get(
    `${GetAgreementTemplates}?type=${type}&sellerId=${sellerId}&kendoPagination=${kendoPagination}`
  )

  return response.data
}

export const getAgreementTemplateDetail = async (
  id: number | undefined
): Promise<any> => {
  const response = await httpClient.get(
    `${GetAgreementTemplateDetail}?id=${id}`
  )
  return response.data
}

export const updateAgreementTemplateFeatureBusiness = async (
  businessId: string | undefined,
  enableBOSAgreementTemplate: boolean,
  enablePSAAgreementTemplate: boolean,
  enableNDAAgreementTemplate: boolean,
  allowUploadPSADuringBid: boolean
) => {
  const response = await httpClient.post(
    UpdateAgreementTemplateFeatureBusiness,
    {
      businessId,
      enableBOSAgreementTemplate,
      enablePSAAgreementTemplate,
      enableNDAAgreementTemplate,
      allowUploadPSADuringBid,
    }
  )
  return response?.data
}
export const downloadAgreementFile = async (
  id: number | undefined,
  file: any,
  sellerId: string
): Promise<any> => {
  const form = new FormData()
  form.append('id', id != null ? id.toString() : '')
  form.append('sellerId', sellerId)
  form.append('file', file)
  const response = await httpClient.post(DownloadAgreementFile, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response?.data
}
