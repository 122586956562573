import React from 'react'
import { useSnackbar } from 'notistack'
import { useQuery } from '@apollo/client'
import { useChangeClientName } from 'src/graphql/operations/mutations/clientProfiles'
import { GET_CLIENT_NAME_HISTORY } from 'src/graphql/operations/queries/clientProfiles'
import { GetClientNameHistories } from 'src/graphql/models/clientProfiles'
import NameHistory from './NameHistory'

interface ClientNameHistoryProps {
  clientId: number
  refetchQueries?: string[]
}

const ClientNameHistory = ({
  clientId,
  refetchQueries = [],
}: ClientNameHistoryProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const onSubmit = (newName: string) =>
    changeClientName({
      variables: {
        clientId: Number(clientId),
        newName,
      },
      refetchQueries: [...refetchQueries, 'GetClientNameHistory'],
    })

  const { data: clientNameHistory, loading: nameHistoryLoading } =
    useQuery<GetClientNameHistories>(GET_CLIENT_NAME_HISTORY, {
      variables: {
        clientId: Number(clientId),
      },
    })

  const handleChangeNameCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('New name added successful', {
        variant: 'success',
      })
    }
  }

  const { changeClientName, loading: changeNameLoading } = useChangeClientName(
    handleChangeNameCompleted
  )

  return (
    <NameHistory
      nameHistoryData={clientNameHistory?.getClientNameHistory}
      onSubmit={onSubmit}
      nameHistoryLoading={nameHistoryLoading}
      changeNameLoading={changeNameLoading}
    />
  )
}

ClientNameHistory.defaultProps = {
  refetchQueries: [],
}

export default ClientNameHistory
