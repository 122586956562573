/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useMemo } from 'react'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Skeleton,
  IconButton,
} from '@mui/material'
import { Refresh } from '@mui/icons-material'

import styled, { css } from 'styled-components'
import { useTable, usePagination, useColumnOrder } from 'react-table'

interface StyledTableCellProps {
  typetable: 'primary' | 'secondary'
}

const variantCell = {
  primary: css`
    background-color: ${({ theme }): string => theme.palette.common.white};
    color: ${({ theme }): string => theme.debtColors.debtTea} !important;
  `,
  secondary: css`
    background-color: ${({ theme }): string => theme.debtColors.debtTea};
    color: ${({ theme }): string => theme.palette.common.white} !important;
  `,
}

const StyledTableCell = styled(TableCell)<StyledTableCellProps>`
  ${({ typetable }) => variantCell[typetable]}
  body {
    font-size: 0.85rem;
  }
  min-width: 130px;
`

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: ${({ theme }): string => theme.debtColors.debtLightBlue};
  }
`

interface DynamicTableProps {
  id: string
  columns: any[]
  data?: any[]
  totalItems?: number
  pageSize?: number
  loading?: boolean
  showPageOf?: boolean
  showRefresh?: boolean
  orderColumns?: any[]
  hiddenColumns?: any[]
  typetable?: 'primary' | 'secondary'
  footerJustifyContent?: string
  emptyText?: string
  tableSize?: 'medium' | 'small'
  onRefresh?: () => void
  onClickRow?: (row: any) => void | undefined
  onClickCell?: (cell: any) => void | undefined
  onChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  id,
  columns,
  data = [],
  totalItems,
  loading,
  orderColumns,
  hiddenColumns,
  typetable = 'primary',
  footerJustifyContent = 'center',
  pageSize,
  showPageOf,
  showRefresh,
  emptyText,
  tableSize,
  onChangePagination,
  onRefresh,
  onClickRow,
  onClickCell,
}: DynamicTableProps) => {
  const memoriData = useMemo(() => data, [data])
  const memoriColumns = useMemo(() => columns, [columns])
  const {
    getTableProps,
    prepareRow,
    getTableBodyProps,
    headerGroups,
    page,
    allColumns,
    pageOptions,
    setColumnOrder,
    setHiddenColumns,
    state: { pageIndex },
  } = useTable(
    {
      columns: memoriColumns,
      data: memoriData,
      initialState: {
        pageSize,
      },
    },
    usePagination,
    useColumnOrder
  )

  useEffect(() => {
    if (hiddenColumns?.length) {
      setHiddenColumns(hiddenColumns)
    }
  }, [hiddenColumns, setHiddenColumns])

  useEffect(() => {
    if (orderColumns?.length) {
      setColumnOrder(orderColumns)
    }
  }, [orderColumns, setColumnOrder])

  const totalPages =
    totalItems && pageSize ? Math.ceil(totalItems / pageSize) : 0

  return (
    <Box width="100%">
      <TableContainer>
        <Table
          id={id}
          aria-label="Portfolio data table"
          size={tableSize}
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => {
              const { key: headerKey, ...restHeaderGroupProps } =
                headerGroup.getHeaderGroupProps()
              return (
                <TableRow key={headerKey} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column) => {
                    const { key: columnKey, ...restHeaderProps } =
                      column.getHeaderProps()
                    return (
                      <StyledTableCell
                        key={columnKey}
                        typetable={typetable}
                        {...restHeaderProps}
                      >
                        {column.render('Header')}
                      </StyledTableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableHead>
          {loading ? (
            <TableBody {...getTableBodyProps()}>
              {[0, 1, 2, 3].map((item) => (
                <StyledTableRow key={item}>
                  <TableCell colSpan={allColumns.length} align="center">
                    <Skeleton />
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody {...getTableBodyProps()}>
              {page.length ? (
                page.map((row, i) => {
                  prepareRow(row)

                  const { key: rowKey, ...restRowProps } = row.getRowProps()
                  // console.log(restRowProps)
                  return (
                    <StyledTableRow
                      key={rowKey}
                      {...restRowProps}
                      style={{
                        cursor: onClickRow ? 'pointer' : 'default',
                      }}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        event.stopPropagation()
                        if (onClickRow) {
                          onClickRow(row)
                        }
                      }}
                    >
                      {row.cells.map((cell, j) => {
                        const { key: cellKey, ...restCellProps } =
                          cell.getCellProps()
                        const notClickable = columns.find(
                          (e) =>
                            e.Header === cell.column.id && e.clickable === false
                        )
                        return (
                          <TableCell
                            data-test-cy={`${i}-${j}`}
                            key={cellKey}
                            {...restCellProps}
                            onClick={() => {
                              if (!notClickable)
                                if (onClickCell) {
                                  onClickCell(cell)
                                }
                            }}
                          >
                            {cell.render('Cell')}
                          </TableCell>
                        )
                      })}
                    </StyledTableRow>
                  )
                })
              ) : (
                <StyledTableRow>
                  <TableCell colSpan={allColumns.length} align="center">
                    {emptyText}
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {!!totalPages && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent={footerJustifyContent}
          my={2}
        >
          <Pagination
            siblingCount={1}
            count={totalPages}
            color="primary"
            onChange={onChangePagination}
          />
          <Box display="flex" alignItems="center">
            {showPageOf && (
              <Typography variant="body1" color="textSecondary">
                {`${pageIndex + 1} of ${pageOptions.length}`}
              </Typography>
            )}
            {showRefresh && (
              <IconButton
                aria-label="refresh"
                aria-controls="action-refresh"
                aria-haspopup="true"
                onClick={onRefresh}
                color="primary"
                size="small"
              >
                <Refresh />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

DynamicTable.defaultProps = {
  data: [],
  orderColumns: [],
  hiddenColumns: [],
  totalItems: 0,
  pageSize: 25,
  typetable: 'secondary',
  footerJustifyContent: 'center',
  loading: false,
  showPageOf: false,
  showRefresh: false,
  onChangePagination: (): void => {},
  onRefresh: (): void => {},
  onClickRow: undefined,
  onClickCell: undefined,
  emptyText: 'None',
  tableSize: 'medium',
}

export default DynamicTable
