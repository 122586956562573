import React, { useState } from 'react'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ModalDialog, Typography } from 'everchain-uilibrary'
import { notistackOptions } from 'src/configs/notistackOptions'
import { enqueueSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import { deleteBusinessFee } from 'src/infra/api/services/business'
import { BusinessFee } from 'src/infra/api/models/business'

interface BusinessFeeDeleteModalProps {
  businessFee?: BusinessFee
  modalOpen?: boolean
  onCloseModal?: () => void
}

const BusinessFeeDeleteModal: React.FC<BusinessFeeDeleteModalProps> = ({
  businessFee,
  modalOpen,
  onCloseModal,
}) => {
  const [deleteBusinessFeeRequest, setDeleteBusinessFeeRequest] =
    useState<number>()

  const reactQueryClient = useQueryClient()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { isFetching: deleteBusinessFeeLoading } = useCustomQuery(
    ['deleteBusinessFee', deleteBusinessFeeRequest],
    async () => {
      if (deleteBusinessFeeRequest) {
        return deleteBusinessFee(deleteBusinessFeeRequest)
          .then(() => {
            enqueueSnackbar('Service fee deleted.', notifySuccess)
            onCloseModal?.()
            reactQueryClient.refetchQueries({
              queryKey: ['getBusinessFee'],
            })
          })
          .catch(() => {
            enqueueSnackbar('Error when deleting the service fee.', notifyError)
          })
          .finally(() => {
            setDeleteBusinessFeeRequest(undefined)
          })
      }
    },
    {
      enabled: !!deleteBusinessFeeRequest,
      cacheTime: 0,
    }
  )

  return (
    <ModalDialog
      isOpen={modalOpen}
      header="Delete Service Fee"
      buttonOkText="Delete"
      disableOkButton={false}
      onClose={() => {
        onCloseModal?.()
      }}
      width="60%"
      maxwidth="500px"
      onContinue={() => setDeleteBusinessFeeRequest(businessFee?.id)}
      isFetching={deleteBusinessFeeLoading}
    >
      <Typography>
        Are you sure you want to <b>delete</b> the business fee for the
        portfolio type <b>{businessFee?.portfolioTypeName}</b>?{' '}
        <b>This action is permanent and cannot be undone.</b>
      </Typography>
    </ModalDialog>
  )
}

export default BusinessFeeDeleteModal
