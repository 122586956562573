import { httpClient } from '../../axios-wrapper/httpClient'
import * as urls from '../../urls/portfolio'
import * as types from '../../models/portfolio'

export const getPortfolioTemplateFileMap = async (
  portfolioTemplateId: number | undefined
): Promise<types.PortfolioTemplateFileMapResponse> => {
  const response = await httpClient.get(urls.PortfolioGetTemplateFileMap, {
    params: { portfolioTemplateId },
  })

  return response.data
}

export const getPortfolioTypes = async (): Promise<types.PortfolioType[]> => {
  const response = await httpClient.get(urls.PortfolioGetTypes)
  return response.data
}
