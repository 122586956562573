import React from 'react'
import { createRoot } from 'react-dom/client'

import * as serviceWorker from './serviceWorker'

import Root from './root'
import ErrorBoundary from './Error'
import '@progress/kendo-theme-material/dist/all.css'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Root />
    </ErrorBoundary>
  </React.StrictMode>
)

serviceWorker.unregister()
