import React, {
  useState,
  useMemo,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { Typography, Box, Grid, MenuItem, TextField } from '@mui/material'
import { GET_CLIENTS } from 'src/graphql/operations/queries/managePermissions'
import { Clients } from 'src/graphql/models/managePermissions'
import { useQuery } from '@apollo/client'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TENANT_TYPE } from 'src/utils/constants'
import ControlTabs from './components/ControlTabs'
import FormGroupPermissions from './components/FormGroupPermissions'

const ManagePermissions: React.FC = () => {
  const { profileClient, user } = useContext(AuthContext)
  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [selectedTenantId, setSelectedTenantId] = useState('')
  const [roleAction, setRoleAction] = useState<{
    roleId?: string | null
    roleName?: string | null
    roleDescription?: string | null
    roleTenantId?: string | null
    actionType?: string | null
  }>({
    roleId: null,
    roleName: null,
    roleDescription: null,
    roleTenantId: null,
    actionType: null,
  })
  const [visibleClientFilter, setVisibleClientFilter] = useState<boolean>(false)

  const handleOpenDrawer = useCallback(
    (
      roleId?: string | null,
      roleName?: string | null,
      roleDescription?: string | null,
      roleTenantId?: string | null,
      actionType?: string | null
    ) => {
      setRoleAction(() => ({
        roleId,
        roleName,
        roleDescription,
        roleTenantId,
        actionType,
      }))

      setOpenDrawer(true)
    },
    [setOpenDrawer]
  )
  const handleCloseDrawer = useCallback(() => {
    setOpenDrawer(false)
  }, [setOpenDrawer])

  const clientIds: Number[] = profileClient?.map((c: any) => Number(c.Id)) || []
  const { data: clients, loading: loadingClients } = useQuery<Clients>(
    GET_CLIENTS,
    {
      variables: {
        clientListRequest: { cliendIdFilter: clientIds },
        showInternal: true,
      },
    }
  )

  const handleClientSelection = (e: any) => {
    setSelectedTenantId(e.tenantId)
    window.localStorage.setItem(
      'dt.managepermissions.selectedTenantId',
      e.tenantId
    )
  }

  useEffect(() => {
    if (!loadingClients && clients) {
      const listClients = clients?.getClients?.clientList
      const isInternal = user && user.profile[TENANT_TYPE] === 'internal'

      if (listClients?.length > 0) {
        if (isInternal) {
          const tenantId =
            window.localStorage.getItem(
              'dt.managepermissions.selectedTenantId'
            ) || listClients[0].tenantId

          setSelectedTenantId(tenantId)
        } else {
          handleClientSelection(listClients[0])
        }
      }
      setVisibleClientFilter(isInternal || listClients?.length > 1)
    }
  }, [loadingClients, clients, user])

  useEffect(() => {
    return () => {
      setSelectedTenantId('')
    }
  }, [])

  return (
    <div>
      <Box p={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h1" color="secondary">
              Manage Permissions
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box
              flexDirection="row"
              display={visibleClientFilter ? 'flex' : 'none'}
            >
              <TextField
                select
                key={selectedTenantId}
                fullWidth
                label="Client Filter"
                value={selectedTenantId}
              >
                {loadingClients && <MenuItem value="">Loading</MenuItem>}
                {!loadingClients &&
                  clients?.getClients?.clientList &&
                  clients?.getClients?.clientList.map((item: any) => (
                    <MenuItem
                      onClick={() => handleClientSelection(item)}
                      key={item.tenantId}
                      value={item.tenantId}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box p={5}>
        {useMemo(
          () => (
            <ControlTabs
              onAddOrUpdateRole={handleOpenDrawer}
              selectedTenantId={selectedTenantId}
            />
          ),
          [handleOpenDrawer, selectedTenantId]
        )}
      </Box>
      {useMemo(
        () =>
          openDrawer && (
            <FormGroupPermissions
              open={openDrawer}
              onClose={handleCloseDrawer}
              selectedTenantId={selectedTenantId}
              roleId={roleAction.roleId}
              roleName={roleAction.roleName}
              roleDescription={roleAction.roleDescription}
              roleTenantId={roleAction.roleTenantId}
              actionType={roleAction.actionType}
            />
          ),
        [handleCloseDrawer, selectedTenantId, openDrawer, roleAction]
      )}
    </div>
  )
}

export default ManagePermissions
