import { httpClient } from '../../axios-wrapper/httpClient'
import { MediaNamingConventionResponseType } from '../../models/mediaNamingConvention'
import {
  DeleteMediaNamingConventionUrl,
  GetMediaNamingConventionsUrl,
  SaveMediaNamingConventionURL,
} from '../../urls/mediaNamingConvention'

export const saveMediaNamingConvention = async (
  sellerId: string,
  name: string,
  namingConvention: string,
  assetTypes: string[]
) => {
  const form = [
    {
      name,
      namingConvention,
      sellerId,
      assetTypes: JSON.stringify(assetTypes),
    },
  ]
  const response = await httpClient.post(SaveMediaNamingConventionURL, form)
  return response?.data
}

export const getMediaNamingConventions = async (
  sellerId: string | undefined,
  kendoPagination: string | undefined
): Promise<MediaNamingConventionResponseType> => {
  const response = await httpClient.get(
    `${GetMediaNamingConventionsUrl}?sellerId=${sellerId}&kendoPagination=${kendoPagination}`
  )

  return response.data
}

export const deleteMediaNamingConvention = async (id: number) => {
  const response = await httpClient.post(
    `${DeleteMediaNamingConventionUrl}?id=${id}`
  )
  return response?.data
}
