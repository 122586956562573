import { Module, Option } from 'src/graphql/models/managePermissions'
import { capitalize, convertToTrueOrFalse } from './text'
import { formatDate } from './date'
import { numberCurrencyDollar, numberToPercentage } from './numbers'

interface Options {
  min: number
  max: number
  opacity: number
}

export interface CommomProps {
  /** An optional prop that allows you to assign a unique identifier to the component. This can be useful for styling or targeting specific elements. */
  id?: string
  /** An optional prop that allows you to add one or more CSS classes to the component. This enables you to apply custom styling or select the component using CSS selectors. */
  className?: string
  /** An optional prop that allows you to pass a ref to the component. Refs provide a way to access the underlying DOM element or React component instance. */
  ref?: any
  /** An optional prop that allows you to apply inline CSS styles to the component. This can be used to customize the appearance or layout of the component. */
  style?: React.CSSProperties
  /** A boolean flag that indicates the loading state of the component. When set to true, it indicates that the component is in a loading state and is currently fetching or processing data. This is commonly used to display a loading indicator or placeholder content while waiting for the actual data to be loaded. */
  isLoading?: boolean
  /** Specifies the width of the skeleton element used for placeholder content. It can be defined as a string (e.g., "100px") or a number representing the width value. */
  skeletonWidth?: string | number
  /** Specifies the height of the skeleton element used for placeholder content. It can be defined as a string (e.g., "50%") or a number representing the height value. */
  skeletonHeight?: string | number
  variant?: string
  color?: string
}

export const getCountryCurrency = (country: string | undefined): string => {
  let currency = ''

  switch (country) {
    case 'CA':
      currency = 'CAD'
      break
    case 'UK':
      currency = '£'
      break
    default:
      currency = '$'
  }
  return currency
}

export const formatDataToExport = (
  entryData: any,
  columnArray: any[],
  profileClient: any
) => {
  const dataToExport: any[] = []

  entryData?.forEach((item: any[]) => {
    const row: any = {}
    columnArray.forEach((g: any) => {
      if (g.show) {
        row[g.title] = g.render
          ? g.render.name === 'renderDate'
            ? formatDate(item[g.field])
            : g.render.name === 'renderTrueOrFalse'
            ? convertToTrueOrFalse(item[g.field])
            : g.render.name === 'renderCurrency'
            ? numberCurrencyDollar(
                item[g.field],
                isUkCountry(
                  profileClient?.Country || process.env.REACT_APP_COUNTRY
                )
                  ? 'UK'
                  : 'US'
              )
            : g.render.name === 'renderPercent'
            ? numberToPercentage(item[g.field])
            : item[g.field]
          : item[g.field]
      }
    })

    dataToExport.push(row)
  })

  return dataToExport
}

export const dynamicColors = (options: Options): string => {
  const newOptions = {
    ...options,
  }
  const { min, max, opacity } = newOptions
  const r = Math.floor(Math.random() * (max - min + 1)) + min
  const g = Math.floor(Math.random() * (max - min + 1)) + min
  const b = Math.floor(Math.random() * (max - min + 1)) + min
  return `rgba(${r},${g},${b}, ${opacity})`
}

export const isString = (value: any): boolean =>
  Object.prototype.toString.call(value) === '[object String]'

export const splitName = (name = '') => {
  if (isString(name) && name.length) {
    const nameSplit = name.split(' ')
    const firstWordName = nameSplit[0][0]
    if (nameSplit.length > 1 && nameSplit[1] !== '') {
      const lastWordName = nameSplit[1][0]
      return `${firstWordName}${lastWordName}`
    }
    if (
      nameSplit.length === 1 ||
      (nameSplit.length > 1 && nameSplit[1] === '')
    ) {
      return firstWordName
    }
  }
  return name
}

export const makeModulesUpdate = (modules: Module[], option: Option) => {
  return modules.reduce((acc, module, idx, arr) => {
    let updates: any = [...acc]

    if (option.type === 'module') {
      updates = arr.map((moduleItem) => {
        if (option.permissionCode === moduleItem.code) {
          return { ...moduleItem, permission: option.permissionValue }
        }
        return moduleItem
      })
    }

    if (option.type === 'feature') {
      updates = arr.map((moduleItem) => ({
        ...moduleItem,
        features: moduleItem.features.map((featureItem) => {
          if (option.permissionCode === featureItem.code) {
            return {
              ...featureItem,
              permission: option.permissionValue,
              updated: true,
            }
          }
          return featureItem
        }),
      }))
    }

    if (option.type === 'action') {
      updates = arr.map((moduleItem) => ({
        ...moduleItem,
        features: moduleItem.features.map((featureItem) => ({
          ...featureItem,
          actions: featureItem.actions.map((actionItem) => {
            if (option.permissionCode === actionItem.code) {
              return {
                ...actionItem,
                permission: option.permissionValue,
              }
            }
            return actionItem
          }),
        })),
      }))
    }

    return updates
  }, [])
}

export interface IGridColumn {
  title: string
  field: string
  show: boolean
  render?: (props: any) => JSX.Element
  width?: string
}

export function mapPropNullToString(obj: any) {
  const data: any = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      data[key] = obj[key] === null ? '' : obj[key]
    }
  }
  return data
}

export const getStateDropDownName = (country: string | undefined): string => {
  if (!country) return 'State'

  switch (country.toLowerCase()) {
    case 'uk':
      return 'County'
    case 'ca':
      return 'Province'
    default:
      return 'State'
  }
}

export const GetBusinessStatus = (status: string): string => {
  if (status.toUpperCase() === 'APPROVED') return 'Accepted'
  return capitalize(status)
}

export const isUkCountry = (country: string | undefined) => {
  return country !== undefined ? country.toUpperCase() === 'UK' : false
}
