/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { AuthContext } from 'src/context/AuthenticationContext'
import { CHECK_PERMISSION_VALUES } from 'src/graphql/operations/queries/permission'
import {
  CheckPermissionValues,
  Permissions,
} from 'src/graphql/models/permissions'
import {
  PermissionCodeAccess,
  PERMISSION_INFO_001,
  PermissionCodeAccessForApps,
  TENANT_TYPE,
} from 'src/utils/constants'

import {
  ACCESSDENIED,
  CLIENT_PROFILES,
  ROOT,
  MANAGE_PERMISSIONS,
} from './routes'
import Main from './pages/Main'

import LoaderPage from './components/LoaderPage'

interface PermissionReduce {
  [field: string]: boolean
}

const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce =>
  permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )

const SwitchRouteProtect = () => {
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [loadingPermission, setLoadingPermission] = useState(true)
  const { user, setLoadedPermission } = useContext<any>(AuthContext)

  const location = useLocation()
  const { data: permissionData, loading } = useQuery<CheckPermissionValues>(
    CHECK_PERMISSION_VALUES,
    {
      variables: {
        permissionCodes,
        encodedPermissions,
      },
      skip: !permissionCodes.length && !encodedPermissions,
    }
  )

  const managePermission = permissionData?.checkPermissionValues.permissions
  const managePermissionReduce = permissionReduce(managePermission)

  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(PermissionCodeAccess).map(
        ([pName, pCode]) => pCode
      )
      setPermissionCodes(permissionCode)
      setEncodedPermissions(profile[PERMISSION_INFO_001])
      setLoadingPermission(false)
      setLoadedPermission(true)
    }
  }, [user])
  if (
    (user && user.isAuthenticated && !managePermission && loadingPermission) ||
    loading
  ) {
    return <LoaderPage />
  }

  if (
    user &&
    user.isAuthenticated &&
    !managePermissionReduce[PermissionCodeAccessForApps.CONTROL_PANEL]
  ) {
    return <Redirect to={ACCESSDENIED} />
  }

  if (
    !loadingPermission &&
    user &&
    user.profile[TENANT_TYPE] !== 'internal' &&
    !managePermissionReduce[PermissionCodeAccess.MANAGE_INTERNAL_PERMISSION] &&
    !managePermissionReduce[PermissionCodeAccess.MANAGE_EXTERNAL_PERMISSION] &&
    !managePermissionReduce[PermissionCodeAccess.CLIENT_PERMISSION]
  ) {
    return <Redirect to={ACCESSDENIED} />
  }
  if (location.pathname === ROOT) {
    if (managePermissionReduce[PermissionCodeAccess.CLIENT_PERMISSION])
      return <Redirect to={CLIENT_PROFILES} />
    if (
      managePermissionReduce[PermissionCodeAccess.MANAGE_INTERNAL_PERMISSION] ||
      managePermissionReduce[PermissionCodeAccess.MANAGE_EXTERNAL_PERMISSION]
    )
      return <Redirect to={MANAGE_PERMISSIONS} />

    return <Redirect to={CLIENT_PROFILES} />
  }

  return (
    <Switch>
      <Route>
        <Main managePermission={managePermission || []} />
      </Route>
    </Switch>
  )
}

export default SwitchRouteProtect
