/* eslint-disable max-len */
import { useQuery } from '@apollo/client'
import {
  Box,
  Table,
  TableBody,
  TableRow,
  Typography,
  Skeleton,
} from '@mui/material'
import React from 'react'
import { RevenuesSummaryResponse } from 'src/graphql/models/commissions'
import { GET_REVENUE_SUMMARY } from 'src/graphql/operations/queries/commissions'
import { toMonthName } from 'src/utils/date'
import { numberCurrencyDollar } from 'src/utils/numbers'
import { RevenueSummaryCell } from '../styles'

interface RevenueSummaryProps {
  revenueYear: number
}

const YearlyRevenueSummary: React.FC<RevenueSummaryProps> = ({
  revenueYear,
}: RevenueSummaryProps) => {
  const { data: getRevenueSummary, loading: revenueSummaryLoading } =
    useQuery<RevenuesSummaryResponse>(GET_REVENUE_SUMMARY, {
      variables: {
        revenueYear,
        revenuePeriod: 1,
      },
    })

  return (
    <Box style={{ margin: 10 }}>
      {revenueSummaryLoading ? (
        <Skeleton variant="rectangular" height={150} width="100%" />
      ) : (
        <Box>
          <Table>
            <TableBody>
              {revenueSummaryLoading ? (
                <Skeleton variant="rectangular" height={150} width="100%" />
              ) : (
                <Box style={{ display: 'flex', flexDirection: 'row', gap: 50 }}>
                  {getRevenueSummary &&
                  getRevenueSummary?.getRevenueSummary
                    ?.revenueSummaryDataResponse.length > 0 ? (
                    getRevenueSummary?.getRevenueSummary?.revenueSummaryDataResponse?.map(
                      (item, index) => (
                        <Box key={index}>
                          <TableRow>
                            <Box style={{ margin: 5 }}>
                              <Typography variant="h6">
                                {toMonthName(item.month)}
                              </Typography>
                              <RevenueSummaryCell>
                                <Typography
                                  variant="body1"
                                  style={{ display: 'flex', gap: 10 }}
                                >
                                  <b>Projected:</b>{' '}
                                  {item.revenueSummaryResponse.projected !== 0
                                    ? numberCurrencyDollar(
                                        item.revenueSummaryResponse.projected
                                      )
                                    : 'N/A'}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{ display: 'flex', gap: 10 }}
                                >
                                  <b>Actual:</b>{' '}
                                  {item.revenueSummaryResponse.actual !== 0
                                    ? numberCurrencyDollar(
                                        item.revenueSummaryResponse.actual
                                      )
                                    : 'N/A'}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  style={{ display: 'flex', gap: 10 }}
                                >
                                  <b>Remaining:</b>{' '}
                                  {item.revenueSummaryResponse.remaining !== 0
                                    ? numberCurrencyDollar(
                                        item.revenueSummaryResponse.remaining
                                      )
                                    : 'N/A'}
                                </Typography>
                              </RevenueSummaryCell>
                            </Box>
                          </TableRow>
                        </Box>
                      )
                    )
                  ) : revenueYear ? (
                    <Box style={{ margin: 5 }}>
                      <Typography variant="body1">
                        No records for the selected period
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              )}
              <Box>
                <Typography variant="h6">
                  {getRevenueSummary?.getRevenueSummary?.year} Total:
                </Typography>
                <Box gap={2.5} style={{ margin: 5 }}>
                  <Typography
                    variant="body1"
                    style={{ display: 'flex', gap: 10 }}
                  >
                    <b>Projected:</b>{' '}
                    {getRevenueSummary?.getRevenueSummary
                      ?.projectedAmountPeriod !== 0
                      ? numberCurrencyDollar(
                          getRevenueSummary?.getRevenueSummary
                            ?.projectedAmountPeriod
                        )
                      : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: 'flex', gap: 10 }}
                  >
                    <b>Actual:</b>{' '}
                    {getRevenueSummary?.getRevenueSummary
                      ?.actualAmountPeriod !== 0
                      ? numberCurrencyDollar(
                          getRevenueSummary?.getRevenueSummary
                            ?.actualAmountPeriod
                        )
                      : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: 'flex', gap: 10 }}
                  >
                    <b>Remaining:</b>{' '}
                    {getRevenueSummary?.getRevenueSummary
                      ?.remainingAmountPeriod !== 0
                      ? numberCurrencyDollar(
                          getRevenueSummary?.getRevenueSummary
                            ?.remainingAmountPeriod
                        )
                      : 'N/A'}
                  </Typography>
                </Box>
              </Box>
            </TableBody>
          </Table>
        </Box>
      )}
    </Box>
  )
}
export default YearlyRevenueSummary
