import React, { useContext } from 'react'
import TransferList from 'src/components/TransferList'
import {
  BusinessBuyerRequestType,
  PortfolioType,
} from 'src/graphql/models/buyer'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { useMutation } from '@tanstack/react-query'
import { BuyerPortfolioTypeRequest } from 'src/infra/api/models/portfolioType'
import {
  addBuyerPortfolioTypes,
  removeBuyerPortfolioTypes,
} from 'src/infra/api/services/portfolioType'
import { useApolloClient } from '@apollo/client'
import { GET_BUYER } from 'src/graphql/operations/queries/buyer'

interface BuyerPortoflioTypesProps {
  buyerData: BusinessBuyerRequestType | undefined
}

const BuyerPortoflioTypes = ({ buyerData }: BuyerPortoflioTypesProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const ability = useContext(AbilityContext)
  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')
  const client = useApolloClient()

  const mapItems = (array: PortfolioType[]) =>
    array.map((e) => ({ id: e.id, name: e.typeName }))

  const addBuyerPortfolioType = useMutation({
    mutationFn: (request: BuyerPortfolioTypeRequest) =>
      addBuyerPortfolioTypes(request),
    onSuccess: (data: any) => {
      enqueueSnackbar('Portfolio types updated successfully', notistackSucces)
      client.refetchQueries({
        include: [GET_BUYER],
      })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        'Error while trying to update the portfolio types',
        notistackWarning
      )
    },
  })

  const removeBuyerPortfolioType = useMutation({
    mutationFn: (request: BuyerPortfolioTypeRequest) =>
      removeBuyerPortfolioTypes(request),
    onSuccess: (data: any) => {
      enqueueSnackbar('Portfolio types updated successfully', notistackSucces)
      client.refetchQueries({
        include: [GET_BUYER],
      })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        'Error while trying to update the portfolio types',
        notistackWarning
      )
    },
  })

  const handleSendLeftColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    removeBuyerPortfolioType.mutate({
      buyerId: buyerData?.id || '',
      portfolioTypeId: ids,
    })
  }

  const handleSendRightColumn = (data: any[]) => {
    const ids = data.map((e) => e.id)
    addBuyerPortfolioType.mutate({
      buyerId: buyerData?.id || '',
      portfolioTypeId: ids,
    })
  }

  return (
    <TransferList
      loading={false}
      leftData={mapItems(
        buyerData?.buyerPortfolioType?.availablePortfolioTypes || []
      )}
      rightData={mapItems(
        buyerData?.buyerPortfolioType?.buyerPortfolioTypes || []
      )}
      onSendLeftColumn={handleSendLeftColumn}
      onSendRightColumn={handleSendRightColumn}
      readonly={
        !ability.can(PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION, 'any')
      }
    />
  )
}

export default BuyerPortoflioTypes
