import React, { useContext } from 'react'
import { Box, Colors, Flex, Icon, Tooltip } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TENANT_TYPE } from 'src/utils/constants'
import hardCodeData from 'src/utils/hardcodeData'

export interface BusinessStatusInfoProps {
  businessType?: string
}

const BusinessStatusInfo: React.FC<BusinessStatusInfoProps> = ({
  businessType,
}) => {
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'

  const statusesTooltipText = () => {
    return (
      <Flex flexDirection="column" style={{ gap: 8 }}>
        <StatusText
          status="Accepted"
          text="Fully accepted into the EverChain Platform with an active Contract"
        />
        <StatusText
          status="Application in Process"
          text="Client’s contract has been signed and they’re being onboarded to Network - Access to UDD has been provided"
        />
        {!hardCodeData.shouldHidePreAprovedStatus(businessType) && (
          <StatusText
            status="RMAi Certified"
            text="Pre-Approved: No contract has been executed, but Client has access to view how Platform works"
          />
        )}
        <StatusText
          status="Suspended"
          text="Client no longer is actively working with EverChain Network, or has been removed from active work due to Compliance issues. Contract is still active, access only to Accounts and Complaints"
        />
        <StatusText
          status="Terminated"
          text="No existing contract, No access to EverChain Platform"
        />
        <StatusText
          status="Terminated - Out of Business"
          text="Client’s company has been dissolved"
        />
      </Flex>
    )
  }

  return (
    <>
      {isInternal && (
        <Flex alignItems="center" pt={4} pl={1}>
          <Tooltip title={statusesTooltipText()}>
            <span>
              <Icon color={Colors.primary} fontSize="medium" name="Info" />
            </span>
          </Tooltip>
        </Flex>
      )}
    </>
  )
}

export default BusinessStatusInfo

const StatusText = ({ status, text }: any) => {
  return (
    <Box>
      <b>{status}:</b> {text}
    </Box>
  )
}
