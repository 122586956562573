import React from 'react'

import { IconButton, Avatar, Typography, Box } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import { withRouter, RouteComponentProps } from 'react-router-dom'

import { splitName, dynamicColors } from 'src/utils/common'
import { Header as HeaderStyle, HeaderActions, HeaderInfos } from './styles'

interface HeaderProps extends RouteComponentProps {
  title?: string
  subtitle?: React.ReactNode
  actions?: React.ReactNode
  customBackUrl?: string
  ignoreFirstName?: boolean
  hideAvatar?: boolean
}

const avatarDynamicColors = dynamicColors({
  min: 0,
  max: 190,
  opacity: 1,
})

const Header: React.FC<HeaderProps> = ({
  title,
  subtitle,
  actions,
  history,
  customBackUrl,
  ignoreFirstName,
  hideAvatar,
}: HeaderProps) => {
  return (
    <HeaderStyle>
      <HeaderInfos>
        <IconButton
          aria-label="go-back"
          color="secondary"
          className="btn-goback --btn-mr"
          onClick={(): void => {
            if (customBackUrl) history.push(customBackUrl)
            else history.goBack()
          }}
        >
          <ArrowBack />
        </IconButton>
        <Box display="flex">
          {!!title && (
            <Box mr={2}>
              <Avatar
                style={{
                  backgroundColor: avatarDynamicColors,
                  display: hideAvatar ? 'none' : '',
                }}
              >
                {ignoreFirstName
                  ? splitName(
                      title.substring(title.indexOf(' ') + 1, title.length)
                    )
                  : splitName(title)}
              </Avatar>
            </Box>
          )}
          <Box display="flex" flexDirection="column">
            {!!title && <Typography variant="h1">{title}</Typography>}
            {!!subtitle && (
              <Typography variant="body1" color="textSecondary">
                {subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </HeaderInfos>
      {!!actions && <HeaderActions>{actions}</HeaderActions>}
    </HeaderStyle>
  )
}

Header.defaultProps = {
  title: '',
  subtitle: '',
  actions: null,
  customBackUrl: '',
  ignoreFirstName: false,
}

export default withRouter(Header)
