/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useCallback, useState, useContext } from 'react'
import {
  Box,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  debounce,
} from '@mui/material'
import DynamicTable from 'src/components/DynamicTable'
import { RetrieveEmailLogResponse } from 'src/graphql/models/logs'
import { GET_EMAIL_LOGS } from 'src/graphql/operations/queries/logs'
import { useLazyQuery } from '@apollo/client'
import { logClient } from 'src/configs/apolloConfig'
import { cellDateTime } from 'src/components/DynamicTable/DynamicTableUltis'
import { format } from 'date-fns'
import LogDetail from './LogDetails'
import { DatePicker } from 'everchain-uilibrary'
import { AuthContext } from 'src/context/AuthenticationContext'

const EmailLogs = () => {
  const columns = [
    {
      Header: 'Date',
      accessor: 'dateCreated',
      Cell: cellDateTime,
    },
    {
      Header: 'Sender',
      accessor: 'fromAddress',
    },
    {
      Header: 'Recipient',
      accessor: 'toAddress',
    },
    {
      Header: 'Subject',
      accessor: 'subject',
    },
  ]

  const getFromDate = () => {
    const dt = new Date()
    dt.setDate(dt.getDate() - 7)
    return dt
  }

  const [pageSize, setPageSize] = useState<number>(100)
  const [fromDateValue, setFromDateValue] = useState<Date>(getFromDate())
  const [toDateValue, setToDateValue] = useState<Date>(new Date())
  const [filterByReceiver, setFilterByReceiver] = useState<string>()
  const [filterBySender, setFilterBySender] = useState<string>()
  const [filterBySubject, setFilterBySubject] = useState<string>()
  const [openDetailForm, setOpenDetailForm] = useState<boolean>(false)
  const [selectedRowData, setSelectedRowData] = useState<any>([])

  const { profileClient } = useContext(AuthContext)

  const [getEmailLogs, { data, loading, fetchMore }] = useLazyQuery<any>(
    GET_EMAIL_LOGS,
    {
      fetchPolicy: 'cache-and-network',
      client: logClient,
      variables: {
        pageNumber: 0,
        pageSize,
        from: fromDateValue,
        to: toDateValue,
        filterByReceiver,
        filterBySender,
        filterBySubject,
      },
    }
  )

  const refetchData = useCallback(() => {
    debounce(getEmailLogs, 1000)
  }, [getEmailLogs])

  const handlePageSize = (e: any) => {
    setPageSize(e?.target?.value)
  }

  const handleFromDate = (e: any) => {
    setFromDateValue(e)
    if (e > toDateValue) {
      const dt = new Date(e)
      dt.setDate(dt.getDate() + 1)
      setToDateValue(dt)
    }
  }

  const handleToDate = (e: any) => {
    setToDateValue(e)
    if (fromDateValue > e) {
      const dt = new Date(e)
      dt.setDate(dt.getDate() - 1)
      setFromDateValue(dt)
    }
  }

  const handleFilterByReceiverChange = (e: any) => {
    setFilterByReceiver(e?.target?.value)
  }

  const handleFilterBySenderChange = (e: any) => {
    setFilterBySender(e?.target?.value)
  }

  const handleFilterBySubjectChange = (e: any) => {
    setFilterBySubject(e?.target?.value)
  }

  const handleRowClick = (row: any): void => {
    const data = []
    data.push({ label: 'Email log ID', value: row?.original?.id })
    const date =
      row?.original?.dateCreated?.indexOf('+00:00') < 0
        ? format(
            new Date(`${row?.original?.dateCreated}+00:00`),
            'yyyy-MM-dd hh:mm:ss a'
          )
        : format(new Date(row?.original?.dateCreated), 'yyyy-MM-dd hh:mm:ss a')
    data.push({ label: 'Date', value: date })
    data.push({ label: 'To', value: row?.original?.toAddress })
    data.push({ label: 'From', value: row?.original?.fromAddress })
    data.push({ label: 'Subject', value: row?.original?.subject })
    data.push({ label: 'HTML content', value: row?.original?.htmlContent })
    data.push({ label: 'Direction', value: row?.original?.direction })
    setSelectedRowData(data)
    setOpenDetailForm(true)
  }

  const handleCloseDetailForm = (): void => {
    setOpenDetailForm(false)
  }

  const emailLogData: RetrieveEmailLogResponse = data?.getEmailLogs

  useEffect(() => {
    refetchData()
  }, [refetchData])

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="end" pb={3}>
        <Box mr={4} mt={4} display="flex" flexDirection="column" pb="3px">
          <InputLabel shrink id="page-size-label">
            Page Size
          </InputLabel>
          <Select
            labelId="page-size-label"
            id="page-size"
            value={pageSize}
            onChange={handlePageSize}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Box>
        <Box mr={4}>
          <DatePicker
            id="filter-from"
            label="From"
            value={fromDateValue}
            onChange={handleFromDate}
            country={profileClient?.Country}
          />
        </Box>
        <Box mr={4}>
          <DatePicker
            id="filter-to"
            label="To"
            value={toDateValue}
            onChange={handleToDate}
            country={profileClient?.Country}
          />
        </Box>
        <Box mr={4} mt={4} pb="3px">
          <TextField
            id="sender-filter"
            label="Sender Filter"
            onChange={handleFilterBySenderChange}
          />
        </Box>
        <Box mr={4} mt={4} pb="3px">
          <TextField
            id="recipient-filter"
            label="Recipient Filter"
            onChange={handleFilterByReceiverChange}
          />
        </Box>
        <Box mr={4} mt={4} pb="3px">
          <TextField
            id="subject-filter"
            label="Subject Filter"
            onChange={handleFilterBySubjectChange}
          />
        </Box>
      </Box>
      <Box pb={2}>
        <DynamicTable
          id="tbEmailLogs"
          loading={loading}
          data={emailLogData?.emailLogs || []}
          totalItems={emailLogData?.totalNumber}
          pageSize={pageSize}
          onClickRow={handleRowClick}
          onChangePagination={(_, pageNumber: number) => {
            const newPageNumber = pageNumber - 1
            if (fetchMore) {
              fetchMore({
                variables: {
                  pagination: {
                    pageSize,
                    pageNumber: newPageNumber,
                  },
                },
                updateQuery: (
                  prev,
                  { fetchMoreResult }
                ): RetrieveEmailLogResponse => {
                  if (!fetchMoreResult) return prev
                  return fetchMoreResult
                },
              })
            }
          }}
          columns={columns}
        />
      </Box>
      <LogDetail
        isOpen={openDetailForm}
        title="Email Log Detail"
        onClose={handleCloseDetailForm}
        data={selectedRowData}
      />
    </Box>
  )
}

export default EmailLogs
