import React, { useContext, useState } from 'react'
import {
  Box,
  Grid,
  MenuItem,
  TextField,
  Button as MuiButton,
  CircularProgress,
  Tooltip,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material'
import hardCodeData from 'src/utils/hardcodeData'

import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  Button,
  DataTable,
  Paper,
  renderCurrency,
  renderDate,
} from 'everchain-uilibrary'

import { useParams } from 'react-router-dom'
import MultipleSelectionDropDown from '../MultipleSelectionDropDown'
import { AssetType, BuyerRelated } from 'src/graphql/models/businessRecovery'
import {
  getAssetTypes,
  getBuyerRelated,
  updateAgreementTemplate,
} from 'src/infra/api/services/client'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { enqueueSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import Header from '../Header'
import { renderCapitalize } from 'src/utils/formatKendoColumns'
import { AuthContext } from 'src/context/AuthenticationContext'
import {
  downloadAgreementFile,
  getAgreementTemplateDetail,
} from 'src/infra/api/services/agreementTemplates'
import { CloudDownload } from '@mui/icons-material'

const AgreementTemplatesDetail = () => {
  const { id, sellerId } = useParams<any>()

  const { data: assetType } = useCustomQuery<AssetType[]>(
    ['getAssetType'],
    async () => {
      return getAssetTypes().then((result: AssetType[]) => {
        result.unshift({ name: 'All', id: -1, assetTypeFee: 0 })
        return result
      })
    },
    {
      enabled: true,
      cacheTime: 0,
    }
  )

  const { data: agreementTemplateDetail, isFetching } = useCustomQuery<any>(
    ['getAgreementTemplateDetail'],
    async () => {
      return getAgreementTemplateDetail(id).then((result: any) => {
        setName(result.name)
        setReason(result.archiveReason)
        setBuyersSelected(result.buyersSeleted)
        setAssetTypeSelected(result.assetTypes)
        setType(result.type)
        return result
      })
    },
    {
      enabled: true,
      cacheTime: 0,
    }
  )
  const reactQueryClient = useQueryClient()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const updateAgreementTemplateEvent = useMutation({
    mutationFn: (request: any) => updateAgreementTemplate(id, request.name),
    onSuccess: () => {
      setIsLoading(false)
      enqueueSnackbar('Agreement template updated successfully.', notifySuccess)
      reactQueryClient.refetchQueries({
        queryKey: ['getAgreementTemplateDetail'],
      })
    },
    onError: () => {
      setIsLoading(false)
      enqueueSnackbar('Error when update Agreement template.', notifyError)
    },
  })
  const { profileClient } = useContext(AuthContext)
  const userCountry = () => {
    return profileClient?.Country || process.env.REACT_APP_COUNTRY
  }
  const portfolioColumns: any[] = [
    {
      title: 'Portfolio Number',
      field: 'portfolioNumber',
      show: true,
    },
    {
      title: 'Status',
      field: 'status',
      render: (props: any) => renderCapitalize(props),
      show: true,
    },
    {
      title: 'Purchase Price',
      field: 'purchasePriceAmount',
      render: (props: any) => renderCurrency(props, undefined, userCountry()),
      show: true,
    },
    {
      title: 'Sale Date',
      field: 'soldUtc',
      render: (props: any) =>
        renderDate(props, undefined, undefined, false, true),
      show: true,
    },
  ]
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [assetTypeSelected, setAssetTypeSelected] = useState<string[]>([])
  const [name, setName] = useState<string>('')
  const [reason, setReason] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [buyersSelected, setBuyersSelected] = useState<string[]>([])

  const { data: buyers } = useCustomQuery<BuyerRelated[]>(
    ['getBuyersRelated'],
    async () => {
      return getBuyerRelated(sellerId).then((result: BuyerRelated[]) => {
        return result
      })
    },
    {
      enabled: true,
      cacheTime: 0,
    }
  )
  const renderAssetTypeOption = () => {
    const assetTypes =
      assetType != null ? assetType?.map((x: AssetType) => x.name) : []

    return (
      <MultipleSelectionDropDown
        label="Asset Type"
        selectionState={assetTypeSelected}
        handleSelectionChange={() => {}}
        disable
        data={assetTypes}
        maxWidth="350px"
        minWidth="350px"
      />
    )
  }
  const downloadTemplateEvent = useMutation({
    mutationFn: () => downloadAgreementFile(id, undefined, sellerId),
    onSuccess: (data: any) => {
      if (data) {
        const { fileContentBase64, fileContentType, fileDownloadName } = data
        const linkSource = `data:${fileContentType};base64,${fileContentBase64}`
        const downloadLink = document.createElement('a')

        downloadLink.href = linkSource
        downloadLink.download = fileDownloadName
        downloadLink.click()
      }
      setIsLoading(false)
    },
    onError: () => {
      setIsLoading(false)
      enqueueSnackbar('Error when download Agreement template.', notifyError)
    },
  })
  const renderBuyersOption = () => {
    const buyersRelated =
      buyers != null ? buyers?.map((x: BuyerRelated) => x.name) : []
    buyersRelated.unshift('All')

    return (
      <MultipleSelectionDropDown
        label="Buyer"
        selectionState={buyersSelected}
        handleSelectionChange={() => {}}
        disable
        data={buyersRelated}
        maxWidth="350px"
        minWidth="350px"
      />
    )
  }
  const DownloadFile = (fileUri: string) => {
    if (fileUri !== '') {
      window.location.href = fileUri
    }
  }
  return (
    <>
      <Box p={5}>
        <Header hideAvatar title="Detail Agreement Template" />
      </Box>

      <Box p={5}>
        <Paper style={{ padding: 15 }} id="agreement-details">
          <Grid mt={2} container spacing={4}>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                label="Type"
                select
                disabled
                name="type"
                value={type}
              >
                {hardCodeData.getAgreementTemplates().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              ></TextField>
            </Grid>
            {type !== 'nda' && (
              <>
                <Grid item xs={12} lg={3}>
                  {renderAssetTypeOption()}
                </Grid>
                <Grid item xs={12} lg={3}>
                  {renderBuyersOption()}
                </Grid>
                <Grid item lg={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        name="allowedForForwardFlow"
                        checked={agreementTemplateDetail?.allowedForForwardFlow}
                        value={agreementTemplateDetail?.allowedForForwardFlow}
                        disabled={true}
                      />
                    }
                    label="Allowed for Forward Flow"
                  />
                </Grid>
              </>
            )}
            {type === 'psa' && (
              <Grid item lg={3}>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      name="useAsCustomPSA"
                      checked={agreementTemplateDetail?.useAsCustomPSA}
                      value={agreementTemplateDetail?.useAsCustomPSA}
                      disabled={true}
                    />
                  }
                  label="Use As Custom PSA"
                />
              </Grid>
            )}
            {reason && (
              <Grid item xs={12} lg={3}>
                <TextField
                  multiline
                  fullWidth
                  label="Archive Reason"
                  name="archiveReason"
                  disabled
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                ></TextField>
              </Grid>
            )}
            <Grid item xs={12} lg={12}>
              {agreementTemplateDetail?.fileUri && (
                <Grid item xs={12} lg={12}>
                  <MuiButton
                    disabled={agreementTemplateDetail?.fileUri === ''}
                    disableRipple={agreementTemplateDetail?.fileUri === ''}
                    onClick={() => {
                      DownloadFile(agreementTemplateDetail?.fileUri)
                    }}
                    endIcon={
                      isLoading ? (
                        <CircularProgress size={15} color="primary" />
                      ) : (
                        <CloudDownload fontSize="small" />
                      )
                    }
                    size="small"
                  >
                    {agreementTemplateDetail?.originalFileName?.length > 30 ? (
                      <Tooltip
                        title={agreementTemplateDetail?.originalFileName}
                        aria-label="originalFileName"
                      >
                        <Typography style={{ fontSize: '0.8em' }}>
                          {agreementTemplateDetail?.originalFileName?.substring(
                            0,
                            30
                          )}
                          ...
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography>
                        {agreementTemplateDetail?.originalFileName}
                      </Typography>
                    )}
                  </MuiButton>
                </Grid>
              )}
            </Grid>
            <Grid item lg={12}>
              <Button
                disabled={
                  !agreementTemplateDetail?.originalFileName || isLoading
                }
                onClick={() => {
                  setIsLoading(true)
                  downloadTemplateEvent.mutate()
                }}
              >
                Test {type?.toUpperCase()} Template Document
              </Button>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Button
                onClick={(e: any) => {
                  updateAgreementTemplateEvent.mutate({ name: name })
                  setIsLoading(true)
                }}
                disabled={
                  (agreementTemplateDetail &&
                    agreementTemplateDetail?.name === name) ||
                  isLoading
                }
              >
                Save
              </Button>
            </Grid>
            {type === 'psa' && (
              <Paper style={{ padding: 10, marginTop: 10, marginRight: 10 }}>
                <Grid item xs={12} lg={12}>
                  <Box p={3}>
                    <Typography>Portfolios</Typography>
                  </Box>

                  <DataTable
                    isLoading={isLoading || isFetching}
                    isFetching={isLoading || isFetching}
                    gridColumns={portfolioColumns}
                    data={agreementTemplateDetail?.portfolios}
                    sortable
                  />
                </Grid>
              </Paper>
            )}
          </Grid>
        </Paper>
      </Box>
    </>
  )
}

export default AgreementTemplatesDetail
