/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useLazyQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { Box, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React, { useEffect, useState, useContext } from 'react'
import DynamicTable, { cellCurrency } from 'src/components/DynamicTable'
import { cellPeriod } from 'src/components/DynamicTable/DynamicTableUltis'
import { getUserGoals, userGoal } from 'src/graphql/models/commissions'
import { GET_USER_GOALS } from 'src/graphql/operations/queries/commissions'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { useRemoveUserGoal } from 'src/graphql/operations/mutations/commissions'
import UserGoal from './UserGoal'

interface UserGoalsParam {
  userId: string
}

const UserGoals: React.FC<UserGoalsParam> = ({ userId }: UserGoalsParam) => {
  const [userGoalData, setUserGoalData] = useState<userGoal | undefined>(
    undefined
  )
  const [openUserGoal, setOpenUserGoal] = useState(false)
  const pageSize = 25
  const handleUserGoalClick = (item: userGoal) => {
    setUserGoalData(item)
  }
  const handleOpenUserGoal = () => {
    setUserGoalData(undefined)
    setOpenUserGoal(!openUserGoal)
  }

  const [
    // eslint-disable-next-line no-shadow
    getUserGoals,
    { data: userGoalsData, loading: loadingGoals, fetchMore },
  ] = useLazyQuery<getUserGoals>(GET_USER_GOALS, {
    variables: {
      userId,
    },
  })

  const ability = useContext(AbilityContext)

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()

  const { RemoveUserGoal } = useRemoveUserGoal({
    onCompleted: () => {
      enqueueSnackbar('User Goal removed successfully', notifySuccess)
    },
    onError: () => {
      enqueueSnackbar('You can not select a date in the past.', notifyError)
    },
  })

  const handleRemoveClick = (item: userGoal) => {
    RemoveUserGoal({
      variables: {
        id: Number(item.id),
      },
      refetchQueries: ['GetUserGoals'],
    })
  }

  const actualDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )
  const canEdit = ability.can(PermissionCodeAccess.MANAGE_COMMISSION, 'any')

  const goalsColumns = [
    { Header: 'Amount', accessor: 'amount', Cell: cellCurrency },
    { Header: 'Period', accessor: 'startDate', Cell: cellPeriod },
    {
      Header: '',
      accessor: 'id',
      Cell: (props: any) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="middle"
            style={{ alignItems: 'center' }}
          >
            <IconButton
              aria-label="more"
              aria-controls="action-menus"
              color="secondary"
              disabled={
                !canEdit || new Date(props.row.original.startDate) < actualDate
              }
              onClick={() => {
                handleUserGoalClick(props.row.original)
              }}
              size="small"
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="more"
              aria-controls="action-menus"
              color="secondary"
              disabled={
                !canEdit || new Date(props.row.original.startDate) < actualDate
              }
              onClick={() => {
                handleRemoveClick(props.row.original)
              }}
              size="small"
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )
      },
    },
  ]

  useEffect(() => {
    if (userGoalData) {
      setOpenUserGoal(true)
    }
  }, [userGoalData])

  useEffect(() => {
    if (userId) {
      getUserGoals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <>
      <DynamicTable
        id="tbGoals"
        data={userGoalsData?.getUserGoals.userGoalResponse || []}
        loading={loadingGoals}
        pageSize={pageSize}
        totalItems={userGoalsData?.getUserGoals.total}
        columns={goalsColumns}
        onChangePagination={(_, pageNumber: number) => {
          const newPageNumber = pageNumber - 1
          if (fetchMore) {
            fetchMore({
              variables: {
                pagination: {
                  pageSize,
                  pageNumber: newPageNumber,
                },
              },
              updateQuery: (prev, { fetchMoreResult }): getUserGoals => {
                if (!fetchMoreResult) return prev
                return fetchMoreResult
              },
            })
          }
        }}
      />
      {openUserGoal && (
        <UserGoal
          open={openUserGoal}
          onClose={handleOpenUserGoal}
          userId={userId || ''}
          userGoal={userGoalData}
        />
      )}
    </>
  )
}
export default UserGoals
