/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Box } from '@mui/material'
import {
  GridColumnMenuFilter,
  GridColumnMenuWrapper,
} from '@progress/kendo-react-grid'
import React from 'react'

export const ColumnMenu = (props: any): React.ReactElement => {
  return (
    <Box>
      <GridColumnMenuFilter {...props} expanded={true} />
    </Box>
  )
}

export const CustomHeaderCell = (props: any, tooltipTitle?: string) => {
  return (
    <span className="k-cell-inner">
      <span className="k-link">
        <span
          onClick={props.onClick}
          className="k-column-title"
          title={tooltipTitle || props.title}
        >
          {props.title}
        </span>
        {props.children}
      </span>
    </span>
  )
}

export const CustomHeaderCellFilter = (props: any, tooltipTitle?: string) => {
  return (
    <span className="k-cell-inner">
      <span className="k-link">
        <span
          onClick={props.onClick}
          className="k-column-title"
          title={tooltipTitle || props.title}
        >
          {props.title}
        </span>
        {props.children}
      </span>
      <GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
    </span>
  )
}
