import { userTypeVar, menuControlVar } from 'src/apollo-cache'

import createUpdateUserType from './managePermissions/updateUserType'
import createMenuControl from './menuControl'

export const commonMutation = {
  upadateMenuControl: createMenuControl(menuControlVar),
}
export const permissionMutation = {
  updateUserType: createUpdateUserType(userTypeVar),
}
