import React from 'react'
import { Typography, Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

import { DrawerRightWrapper } from './styles'

interface DrawerRightProps {
  title: string
  open: boolean
  onClose: () => void
  children?: React.ReactNode
  width?: number | string | undefined
}
const DrawerRight = ({
  title,
  open,
  onClose,
  children,
  width,
}: DrawerRightProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box flex="0 0 auto" flexShrink="0">
        <DrawerRightWrapper open={open} width={width}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            bgcolor="#f0f0f0"
            p={3}
          >
            <Typography>{title}</Typography>
            <IconButton title="close drawer" onClick={onClose} size="small">
              <Close />
            </IconButton>
          </Box>
          {open && <Box flex="1">{children}</Box>}
        </DrawerRightWrapper>
      </Box>
    </Box>
  )
}

DrawerRight.defaultProps = {
  width: 'auto',
  children: null,
}
export default DrawerRight
