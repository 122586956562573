import { gql, useMutation, MutationUpdaterFn } from '@apollo/client'

export const ADD_OR_UPDATE_AGENCY = gql`
  mutation AddOrUpdateAgency($agency: BusinessAgencyRequest!) {
    addOrUpdateAgency(agency: $agency) {
      id
      name
      clientId
    }
  }
`

export function useAddOrUpdateAgency(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addOrUpdateAgency, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_AGENCY,
    {
      update,
      onCompleted,
    }
  )
  return { addOrUpdateAgency, data, error, loading }
}
