import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ACCESSDENIED, HOME } from './routes'
import { SignIn } from './pages/Authentication/SignIn'
import { SignInCallback } from './pages/Authentication/SignInCallback'
import { SignInSilent } from './pages/Authentication/SignInSilent'
import { SignOutCallback } from './pages/Authentication/SignOutCallback'
import AccessDenied from './pages/AccessDenied'
import '@progress/kendo-theme-material/dist/all.css'

import SwitchRouteProtect from './SwitchRouteProtect'
import { PasswordExpiringNotification } from './components/PasswordExpiringNotification'

const App = () => {
  return (
    <Switch>
      <Route exact path={HOME}>
        <SignIn />
      </Route>
      <Route exact path="/signin-callback">
        <SignInCallback />
      </Route>
      <Route exact path="/signin-silent">
        <SignInSilent />
      </Route>
      <Route exact path="/signout-callback">
        <SignOutCallback />
      </Route>
      <Route exact path={ACCESSDENIED}>
        <AccessDenied />
      </Route>
      <Route>
        <PasswordExpiringNotification>
          <SwitchRouteProtect />
        </PasswordExpiringNotification>
      </Route>
    </Switch>
  )
}

export default App
