import { gql } from '@apollo/client'

export const GET_PROFILE_REPORT_DATA = gql`
  query GetProfileReportData(
    $profileReportRequest: ProfileReportRequest!
    $kendoPagination: String
  ) {
    profileReportData: getProfileReportData(
      profileReportRequest: $profileReportRequest
      kendoPagination: $kendoPagination
    ) {
      total
      profileReportResponse {
        clientId
        clientName
        clientStatus
        clientCategory
        businessId
        businessName
        businessStatus
        businessCategory
        activeBankAccount
        enabledUsers
        disabledUsers
      }
    }
  }
`
