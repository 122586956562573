import { gql } from '@apollo/client'

export const GET_BUSINESS_FILE_LIST = gql`
  query GetBusinessFileList($businessId: String!, $fileType: String!) {
    getBusinessFileList(businessId: $businessId, fileType: $fileType) {
      containerName
      contentType
      fileType
      value
      blobUri
      businessId
      uploadedByUserId
      uploadedByUserName
      uploadedUtc
    }
  }
`

export const GET_BUSINESS_FILE_URI = gql`
  query GetBusinessFileTempUri(
    $businessId: String!
    $fileType: String!
    $outputFileName: String
  ) {
    getBusinessFileTempUri(
      businessId: $businessId
      fileType: $fileType
      outputFileName: $outputFileName
    )
  }
`

export const GET_BUYER_BCO_DATA = gql`
  query GetBuyerBcoData($buyerId: String!) {
    getBuyerBcoData(buyerId: $buyerId) {
      bcoEnabled
      bcoFinancials
      bcoAgenciesCertified
      bcoAttestation
      bcoBuyerType
      bcoComplianceNotes
      bcoOfficerBackgroundChecks
      buyerAddress
      buyerBcr
      buyerId
      buyerCityStateZip
      buyerName
      insurance_CoverageAmount
      hasNoAgencyInformation
      buyerMonthlyBcr {
        key
        value
        description
      }
      ccoAttestation {
        containerName
        contentType
        fileType
        value
        blobUri
        businessId
        uploadedByUserId
        uploadedByUserName
        uploadedUtc
      }
      financials {
        containerName
        contentType
        fileType
        value
        blobUri
        businessId
        uploadedByUserId
        uploadedByUserName
        uploadedUtc
      }
    }
  }
`

export const GET_BUYER = gql`
  query GetBuyer($buyerId: String!) {
    getBuyer(buyerId: $buyerId) {
      accountNumber
      accountTypeCode
      bankAccountStatus
      bankName
      bCOAgenciesCertified
      bCOAttestation
      bCOAgenciesCertified
      bCOAttestation
      bCOBuyerType
      bCOComplianceNotes
      bCOEnabled
      bCOFinancials
      bCOOfficerBackgroundChecks
      businessType
      city
      clientId
      corpHQPhoneNumber
      countryCode
      customerServiceEmail
      customerServicePhoneNumber
      defaultBankAccountId
      dTCanClosePostSaleRequest
      dTCanConfirmFundsSent
      dTCanCreatePostSaleRequest
      dTCanMaintainComplaints
      dTCanPlaceBid
      dTCanRespondToPostSaleRequest
      dTCanUploadDownloadPSA
      enforceMinimumFee
      feePercent
      id
      importFromId
      insurance_Comment
      insurance_CoverageAmount
      insurance_ExpirationDate
      insurance_Name
      insurance_PolicyType
      lastUpdatedByUserId
      lastUpdatedByUserName
      lastUpdatedUTCDate
      legalName
      minimumFee
      name
      permissionsLastUpdatedByUserId
      permissionsLastUpdatedUTC
      postalCode
      primaryContact_EMail
      primaryContact_FirstName
      primaryContact_LastName
      primaryContact_MobilePhone
      primaryContact_OfficePhone
      routingNumber
      nameOnAccount
      signer_FullName
      signer_Title
      stateCode
      status
      street1
      street2
      tU_ScoringEnabled
      tU_UploadDirectory
      tU_ProductType
      membershipEstablished
      onSiteAudit
      recertificationFrequency
      startHoursOfOperation
      endHoursOfOperation
      daysOfOperation
      buyerPortfolioType {
        buyerPortfolioTypes {
          id
          typeName
        }
        availablePortfolioTypes {
          id
          typeName
        }
      }
      billingStreet1
      billingStreet2
      billingCity
      billingStateCode
      billingPostalCode
      psaSignerName
      psaSignerTitle
      psaSignerEmail
      hasNoAgencyInformation
      consumerPaymentInstruction
      enablePartnershipScoring
      companyWebsiteAddress
      taxId
      businessTypeLLC
      stateOfIncorporation
      dateOfEstablishment
    }
  }
`
