import React from 'react'
import {
  ChartData,
  ChartOptions,
  Chart as ChartJS,
  registerables,
} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Line } from 'react-chartjs-2'

interface LineChartProps {
  data: ChartData<'line'>
  options?: ChartOptions<'line'>
}

const LineChart = ({ data, options }: LineChartProps) => {
  const key = JSON.stringify(options)
  ChartJS.register(...registerables)

  return (
    <Line key={key} data={data} options={options} plugins={[ChartDataLabels]} />
  )
}

LineChart.defaultProps = {
  options: {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        tension: 0, // disables bezier curves
      },
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'Value',
        },
      },
    },
  },
}

export default LineChart
