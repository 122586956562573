import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Switch,
  Skeleton,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import { notistackOptions } from 'src/configs/notistackOptions'
import {
  GetClient,
  IpAddressesWhitelistedResponse,
} from 'src/graphql/models/clientProfiles'
import {
  useAddIpAddressToWhitelist,
  useDeleteIpAddressOnWhitelist,
  useEnableWhitelist,
} from 'src/graphql/operations/mutations/clientProfiles'
import {
  GET_CLIENT,
  GET_IP_ADDRESSES_WHITELISTED,
} from 'src/graphql/operations/queries/clientProfiles'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

interface ClientWhitelistProps {
  clientProfileId: number
}

const notifySuccess = notistackOptions('success')
const notifyError = notistackOptions('error')

const ClientWhitelist: React.FC<ClientWhitelistProps> = ({
  clientProfileId,
}: ClientWhitelistProps) => {
  const ability = useContext(AbilityContext)
  const hasBasicInfoPermission = ability.can(
    PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION,
    'any'
  )

  const { enqueueSnackbar } = useSnackbar()
  const [enable, setEnable] = useState(false)
  const [initialValues] = useState<any>({
    name: '',
    startIpAddress: '',
    endIpAddress: '',
  })

  const { data: getClientData, loading: loadingWhitelistStatus } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, {
    variables: { clientId: Number(clientProfileId) },
    fetchPolicy: 'no-cache',
  })

  const { data: ipAddressesQueryData } =
    useQuery<IpAddressesWhitelistedResponse>(GET_IP_ADDRESSES_WHITELISTED, {
      variables: { clientId: Number(clientProfileId) },
    })

  const ipAddresses = ipAddressesQueryData?.addresses

  useEffect(() => {
    if (getClientData?.getClient.whitelistEnabled)
      setEnable(getClientData?.getClient.whitelistEnabled)
  }, [getClientData])

  const { enableWhitelist, loading: updatingStatus } = useEnableWhitelist({
    onCompleted: () => {
      const operation = enable ? 'enabled' : 'disabled'
      enqueueSnackbar(`Whitelist ${operation}`, notifySuccess)
    },
    onError: () => {
      enqueueSnackbar('Operation failed', notifyError)
    },
  })

  const onSwitchChange = () => {
    setEnable(!enable)

    enableWhitelist({
      variables: {
        clientId: Number(clientProfileId),
        enable: !enable,
      },
      refetchQueries: ['getClient'],
    })
  }

  const { addIpAddressToWhitelist, loading: addingIpAddress } =
    useAddIpAddressToWhitelist({
      onCompleted: (data) => {
        if (data.addIpAddressToWhitelist === 'Ip Address whitelisted')
          enqueueSnackbar('Ip Address Whitelisted', notifySuccess)
        else if (data.addIpAddressToWhitelist === 'Ip address already exist')
          enqueueSnackbar('Ip address already exist', notifyError)
      },
      onError: (error) => {
        enqueueSnackbar('Operation failed', notifyError)
      },
    })

  const { deleteIpAddressOnWhitelist, loading: deletingIpAddress } =
    useDeleteIpAddressOnWhitelist({
      onCompleted: () => {
        enqueueSnackbar('Ip Address removed', notifySuccess)
      },
      onError: () => {
        enqueueSnackbar('Operation failed', notifySuccess)
      },
    })

  const ipToNumber = (ip: any) => {
    if (!ip) return 0
    return ip.split('.').reduce((int: any, v: any) => int * 256 + +v)
  }

  const whitelistSchema = Yup.object()
    .shape({
      name: Yup.string().nullable().required('Required'),
      startIpAddress: Yup.string()
        .nullable()
        .required('Required')
        .matches(
          // eslint-disable-next-line max-len
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
          'Invalid Ip Address'
        ),
      endIpAddress: Yup.string()
        .nullable()
        .required('Required')
        .matches(
          // eslint-disable-next-line max-len
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
          'Invalid Ip Address'
        ),
    })
    .test({
      name: 'IpRange',
      test(values) {
        const start = values['startIpAddress']
        const end = values['endIpAddress']

        const a = ipToNumber(start)
        const b = ipToNumber(end)

        if (b >= a) return true
        // eslint-disable-next-line react/no-this-in-sfc

        const errors = [
          new Yup.ValidationError(
            'Start ip must be smaller than end',
            start,
            'startIpAddress'
          ),
          new Yup.ValidationError(
            'End ip must be bigger than start',
            end,
            'endIpAddress'
          ),
        ]

        // eslint-disable-next-line react/no-this-in-sfc
        return this.createError({
          message: () => errors,
        })

        // return this.createError({
        //   path: 'endIpAddress|startIpAddress',
        //   message: 'Ip range invalid',
        // })
      },
    })

  const whitelistForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: whitelistSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const requestValues = {
        name: values.name || null,
        startIpAddress: values.startIpAddress || null,
        endIpAddress: values.endIpAddress || null,
      }
      addIpAddressToWhitelist({
        variables: {
          addRequest: {
            clientId: Number(clientProfileId),
            ...requestValues,
          },
        },
        refetchQueries: ['GetWhitelistedIpAddresses'],
      })
      setSubmitting(false)
      resetForm()
    },
  })

  const onDeleteClick = (id: number) => {
    deleteIpAddressOnWhitelist({
      variables: {
        id,
      },
      refetchQueries: ['GetWhitelistedIpAddresses'],
    })
  }

  return (
    <Box>
      <form onSubmit={whitelistForm.handleSubmit}>
        <Grid
          container
          direction="row"
          style={{ borderBottom: '1px solid lightgrey', paddingBottom: '16px' }}
        >
          {loadingWhitelistStatus ? (
            <Grid item>
              <Skeleton variant="rectangular" width="170px" />
            </Grid>
          ) : (
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    name="whitelist"
                    checked={enable}
                    onChange={onSwitchChange}
                    disabled={
                      updatingStatus ||
                      addingIpAddress ||
                      deletingIpAddress ||
                      !hasBasicInfoPermission
                    }
                  />
                }
                label="Enable Whitelist"
              />
            </Grid>
          )}
          <Grid item>
            <Divider orientation="vertical" />
          </Grid>
          <Grid item style={{ marginLeft: '16px' }}>
            <TextField
              label="Name"
              name="name"
              onChange={whitelistForm.handleChange}
              error={!!whitelistForm.errors.name}
              value={whitelistForm.values.name}
              helperText={<>{whitelistForm.errors.name}</>}
              disabled={!hasBasicInfoPermission}
            />
          </Grid>
          <Grid item style={{ marginLeft: '16px' }}>
            <TextField
              label="Start IP Address"
              name="startIpAddress"
              onChange={whitelistForm.handleChange}
              error={!!whitelistForm.errors.startIpAddress}
              value={whitelistForm.values.startIpAddress}
              helperText={<>{whitelistForm.errors.startIpAddress}</>}
              disabled={!hasBasicInfoPermission}
            />
          </Grid>
          <Grid item style={{ marginLeft: '16px' }}>
            <TextField
              label="End IP Address"
              name="endIpAddress"
              onChange={whitelistForm.handleChange}
              error={!!whitelistForm.errors.endIpAddress}
              value={whitelistForm.values.endIpAddress}
              helperText={<>{whitelistForm.errors.endIpAddress}</>}
              disabled={!hasBasicInfoPermission}
            />
          </Grid>
          <Grid item style={{ marginLeft: '16px' }}>
            <Button
              color="primary"
              variant="contained"
              disabled={
                loadingWhitelistStatus ||
                updatingStatus ||
                addingIpAddress ||
                deletingIpAddress ||
                !hasBasicInfoPermission
              }
              type="submit"
            >
              Add
            </Button>
          </Grid>
        </Grid>
      </form>
      <List dense={true} style={{ maxWidth: '250px', marginTop: '18px' }}>
        {ipAddresses &&
          ipAddresses.map((x) => {
            return (
              <ListItem key={x.name}>
                <ListItemText
                  primary={x.name}
                  secondary={`${x.startIpAddress} - ${x.endIpAddress}`}
                  style={{ width: '500px' }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    disabled={
                      loadingWhitelistStatus ||
                      updatingStatus ||
                      deletingIpAddress ||
                      !hasBasicInfoPermission
                    }
                    onClick={() => onDeleteClick(x.id)}
                    style={{ marginRight: '-150px' }}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
      </List>
    </Box>
  )
}

export default ClientWhitelist
