/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@apollo/client'
import { Box, IconButton, Tooltip } from '@mui/material'
import { Delete, Edit, Error } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import React, { useContext, useEffect, useState } from 'react'
import DynamicTable from 'src/components/DynamicTable'
import { cellPeriod } from 'src/components/DynamicTable/DynamicTableUltis'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AbilityContext } from 'src/context/Can'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { getCompanyGoals, companyGoal } from 'src/graphql/models/commissions'
import { useRemoveCompanyGoal } from 'src/graphql/operations/mutations/commissions'
import { GET_COMPANY_GOALS } from 'src/graphql/operations/queries/commissions'
import { PermissionCodeAccess } from 'src/utils/constants'
import { numberCurrency } from 'src/utils/numbers'
import CompanyGoal from './CompanyGoal'

const CompanyGoals: React.FC = () => {
  const [companyGoalData, setCompanyGoalData] = useState<
    companyGoal | undefined
  >(undefined)

  const pageSize = 25
  const {
    // eslint-disable-next-line no-shadow
    data: getCompanyGoals,
    loading: companyGoalsLoading,
    fetchMore,
  } = useQuery<getCompanyGoals>(GET_COMPANY_GOALS, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: {
      pagination: {
        pageNumber: 0,
        pageSize,
      },
    },
  })
  const [openCompanyGoal, setOpenCompanyGoal] = useState(false)

  const handleCompanyGoalClick = (item: companyGoal) => {
    setCompanyGoalData(item)
  }

  const ability = useContext(AbilityContext)

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()

  const { RemoveCompanyGoal } = useRemoveCompanyGoal({
    onCompleted: () => {
      enqueueSnackbar('Company Goal removed successfully', notifySuccess)
    },
    onError: () => {
      enqueueSnackbar('You can not select a date in the past.', notifyError)
    },
  })

  const handleRemoveClick = (item: companyGoal) => {
    RemoveCompanyGoal({
      variables: {
        id: Number(item.id),
      },
      refetchQueries: ['GetCompanyGoals'],
    })
  }

  const actualDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )
  const canEdit = ability.can(PermissionCodeAccess.MANAGE_COMMISSION, 'any')

  const handleOpenCompanyGoal = () => {
    setCompanyGoalData(undefined)
    setOpenCompanyGoal(!openCompanyGoal)
  }

  const goalsColumns = [
    // { Header: 'Amount', accessor: 'amount', Cell: cellCurrency },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: (props: any) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="middle"
            style={{ alignItems: 'center' }}
          >
            {props.row.original.disclaimer ? (
              <>
                <span>{numberCurrency(props.row.original.amount)}</span>
                <Tooltip
                  placement="right-start"
                  title={
                    <span>
                      <p>
                        Team Goal = ${props.row.original.amount} <br />
                        Sum of User Goals = ${
                          props.row.original.sumUserGoal
                        }{' '}
                        <br />
                        Team Goal can not exceed the sum of user goals.
                      </p>
                    </span>
                  }
                  aria-label="list filter"
                >
                  <Error style={{ paddingLeft: 5 }} />
                </Tooltip>
              </>
            ) : (
              <span>{numberCurrency(props.row.original.amount)}</span>
            )}
          </Box>
        )
      },
    },

    { Header: 'Start Date', accessor: 'startDate', Cell: cellPeriod },
    {
      Header: '',
      accessor: 'id',
      Cell: (props: any) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="middle"
            style={{ alignItems: 'center' }}
          >
            <IconButton
              aria-label="more"
              aria-controls="action-menus"
              color="secondary"
              disabled={
                !canEdit || new Date(props.row.original.startDate) < actualDate
              }
              onClick={() => {
                handleCompanyGoalClick(props.row.original)
              }}
              size="small"
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="more"
              aria-controls="action-menus"
              color="secondary"
              disabled={
                !canEdit || new Date(props.row.original.startDate) < actualDate
              }
              onClick={() => {
                handleRemoveClick(props.row.original)
              }}
              size="small"
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )
      },
    },
  ]

  useEffect(() => {
    if (companyGoalData) {
      setOpenCompanyGoal(true)
    }
  }, [companyGoalData])

  return (
    <>
      <DynamicTable
        id="tbGoals"
        loading={companyGoalsLoading}
        data={getCompanyGoals?.getCompanyGoals.companyGoalResponse || []}
        pageSize={pageSize}
        totalItems={getCompanyGoals?.getCompanyGoals.total}
        columns={goalsColumns}
        onChangePagination={(_, pageNumber: number) => {
          const newPageNumber = pageNumber - 1
          if (fetchMore) {
            fetchMore({
              variables: {
                pagination: {
                  pageSize,
                  pageNumber: newPageNumber,
                },
              },
              updateQuery: (prev, { fetchMoreResult }): getCompanyGoals => {
                if (!fetchMoreResult) return prev
                return fetchMoreResult
              },
            })
          }
        }}
      />
      {openCompanyGoal && (
        <CompanyGoal
          open={openCompanyGoal}
          onClose={handleOpenCompanyGoal}
          companyGoal={companyGoalData}
        />
      )}
    </>
  )
}
export default CompanyGoals
