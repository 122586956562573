import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_CLIENT_PROFILES } from 'src/graphql/operations/queries/clientProfiles'
import { GetClients } from 'src/graphql/models/clientProfiles'
import {
  Grid as KendoGrid,
  GridColumn as KendoGridColumn,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid'
import { State } from '@progress/kendo-data-query'
import { Box, Button, Paper, Skeleton } from '@mui/material'
import { CLIENT_PROFILES_MANAGE } from 'src/routes'
import { AuthContext } from 'src/context/AuthenticationContext'
import { GetBusinessStatus, getStateDropDownName } from 'src/utils/common'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { maskPhoneNumber } from 'src/utils/masker'

const ClientProfilesTable = () => {
  const ability = useContext(AbilityContext)

  const country = process.env.REACT_APP_COUNTRY
  const gridColumns: any[] = [
    {
      field: 'name',
      title: 'Name',
      minWidth: 225,
      render: (props: any) => {
        return ability.can(PermissionCodeAccess.CLIENT_PERMISSION, 'any') ? (
          <td>
            <Button
              component={Link}
              to={`${CLIENT_PROFILES_MANAGE}/${props.dataItem.id}`}
            >
              {' '}
              {props.dataItem.name}
            </Button>
          </td>
        ) : (
          <td>{props.dataItem.name}</td>
        )
      },
    },
    {
      field: 'status',
      title: 'Status',
      minWidth: 150,
      render: (props: any) => {
        return <td>{GetBusinessStatus(props.dataItem.status)}</td>
      },
    },
    {
      field: 'category',
      title: 'Category',
      minWidth: 150,
    },
    {
      field: 'customerServicePhoneNumber',
      title: 'Customer Service',
      minWidth: 180,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.customerServicePhoneNumber
              ? maskPhoneNumber(
                  props.dataItem.customerServicePhoneNumber,
                  country
                )
              : ''}
          </td>
        )
      },
    },
    {
      field: 'customerServiceEmail',
      title: 'E-mail',
      minWidth: 200,
    },
    {
      field: 'city',
      title: 'City',
      minWidth: 150,
    },
    {
      field: 'stateCode',
      title: getStateDropDownName(country),
      minWidth: 100,
    },
    {
      field: 'countryCode',
      title: 'Country',
      minWidth: 100,
    },
    {
      field: 'corpHQPhoneNumber',
      title: 'HQ Phone',
      minWidth: 180,
      render: (props: any) => {
        return (
          <td>
            {props.dataItem.corpHQPhoneNumber
              ? maskPhoneNumber(props.dataItem.corpHQPhoneNumber, country)
              : ''}
          </td>
        )
      },
    },
    {
      field: 'primaryContact_FirstName',
      title: 'Primary Contact First',
      minWidth: 200,
    },
    {
      field: 'primaryContact_LastName',
      title: 'Primary Contact Last',
      minWidth: 200,
    },
    {
      field: 'id',
      title: 'Id',
      minWidth: 100,
    },
  ]
  const pageSize = 25
  const { profileClient } = useContext(AuthContext)
  const clientIds: Number[] = profileClient?.map((c: any) => Number(c.Id)) || []

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const [totalDataSize, setTotalDataSize] = useState<number>(25)

  const {
    data: getClientsData,
    loading,
    fetchMore,
  } = useQuery<GetClients>(GET_CLIENT_PROFILES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      kendoPagination: JSON.stringify(gridState),
      clientListRequest: {
        cliendIdFilter: clientIds,
        pagination: {
          pageNumber: 0,
          pageSize,
        },
      },
    },
  })
  const clientsData = getClientsData?.getClients
  const clients = clientsData?.clientList || []

  useEffect(() => {
    setTotalDataSize(getClientsData?.getClients.totalOfAccounts ?? 25)
  }, [getClientsData])

  useEffect(() => {
    setGridState({ skip: 0, take: 25, filter: undefined, sort: undefined })
  }, [setGridState])

  const ColumnMenu = (props: any): React.ReactElement => {
    return (
      <Box>
        <GridColumnMenuFilter {...props} expanded={true} />
      </Box>
    )
  }
  return (
    <Paper>
      {loading ? (
        <Box mt={5}>
          <Skeleton width="100%" height={50} variant="rectangular" />
        </Box>
      ) : (
        <KendoGrid
          sortable
          style={{
            height: '100%',
            maxHeight: '80vh',
          }}
          pageable={{ pageSizes: [25, 50, 100] }}
          total={totalDataSize}
          skip={gridState.skip}
          take={gridState.take}
          pageSize={gridState.take}
          filter={gridState.filter}
          sort={gridState.sort}
          data={clients}
          onDataStateChange={(e) => {
            setGridState(e.dataState)
            fetchMore({
              variables: {
                kendoPagination: JSON.stringify(e.dataState),
              },
            })
          }}
        >
          {gridColumns.reduce((previousValue, currentValue, index) => {
            const columns = [...previousValue]

            const mapper: any = {}
            if (currentValue.render) {
              mapper.cell = (props: any) => <currentValue.render {...props} />
            }

            if (currentValue.format) {
              mapper.format = currentValue.format
            }

            if (currentValue.filter) {
              mapper.filter = currentValue.filter
            }

            mapper.width = currentValue.minWidth ?? 200

            columns.push(
              <KendoGridColumn
                {...mapper}
                key={index}
                field={currentValue.field}
                title={currentValue.title}
                columnMenu={ColumnMenu}
                headerClassName={
                  GridColumnMenuFilter.active(
                    currentValue.field,
                    gridState.filter
                  )
                    ? 'active'
                    : ''
                }
              />
            )

            return columns
          }, [])}
        </KendoGrid>
      )}
    </Paper>
  )
}

export default ClientProfilesTable
