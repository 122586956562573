import { CreateUsers } from '../../urls/user'
import { httpClient } from '../../axios-wrapper/httpClient'

export const updateUserType = (userType: string) => {
  return (type: string): void => {
    localStorage.setItem('userType', type)
  }
}

export const createUsers = async (
  businessId: string,
  users: string
): Promise<any> => {
  const response = await httpClient.post(CreateUsers, {
    users,
    businessId,
  })

  return response?.data
}
