import { gql } from '@apollo/client'

export const GET_SALES_USER = gql`
  query getSalesUser($id: String) {
    getSalesUser(id: $id) {
      id
      isSales
      isCustomerSuccess
      displayName
    }
  }
`

export const GET_COMPANY_GOALS = gql`
  query GetCompanyGoals($pagination: DataPagination) {
    getCompanyGoals(pagination: $pagination) {
      companyGoalResponse {
        id
        amount
        startDate
        disclaimer
        sumUserGoal
      }
      total
    }
  }
`

export const GET_USER_GOALS = gql`
  query GetUserGoals($userId: String!, $pagination: DataPagination) {
    getUserGoals(userId: $userId, pagination: $pagination) {
      userGoalResponse {
        id
        amount
        startDate
      }
      total
    }
  }
`

export const GET_USER_COMMISSIONS = gql`
  query GetUserCommissions(
    $userId: String!
    $userCommissionId: Long
    $pagination: DataPagination
  ) {
    getUserCommissions(
      userId: $userId
      userCommissionId: $userCommissionId
      pagination: $pagination
    ) {
      userCommissionResponse {
        id
        startDate
        commissionPercent
        userId
        type
      }
      total
    }
  }
`

export const GET_REVENUE_SUMMARY = gql`
  query GetRevenueSummary(
    $revenuePeriod: Long!
    $revenueYear: Long!
    $revenuePeriodQuery: Long
  ) {
    getRevenueSummary(
      revenuePeriod: $revenuePeriod
      revenueYear: $revenueYear
      revenuePeriodQuery: $revenuePeriodQuery
    ) {
      year
      projectedAmountPeriod
      actualAmountPeriod
      remainingAmountPeriod
      revenueSummaryDataResponse {
        month
        revenueSummaryResponse {
          projected
          actual
          remaining
        }
      }
    }
  }
`
