import React, { useContext, useEffect, useState } from 'react'
import { Tabs, Tab, Box, Tooltip, Skeleton, Theme } from '@mui/material'
import Comment from 'src/components/Comment'
import DefaultBankAccount from 'src/components/DefaultBankAccount'
import { BusinessSellerResponseType } from 'src/graphql/models/sellerBusiness'
import { useQuery } from '@apollo/client'
import { GetBankAccounts, GetClient } from 'src/graphql/models/clientProfiles'
import {
  GET_BANK_ACCOUNTS,
  GET_CLIENT,
} from 'src/graphql/operations/queries/clientProfiles'
import { Info } from '@mui/icons-material'
import { AuthContext } from 'src/context/AuthenticationContext'
import { PermissionCodeAccess, TENANT_TYPE } from 'src/utils/constants'
import WebHooksNotification from 'src/pages/WebHooks/WebHooksNotification'
import BusinessInformation from './BusinessInformation'
import BuyerAccess from './BuyerBidAccess'
import BusinessNotifications from './BusinessNotifications'
import BusinessDeliveryStructure from '../../BusinessManage/components/BusinessDeliveryStructure'
import BusinessAssetTypes from '../../BusinessManage/components/BusinessAssetTypes'
import shadows from '@mui/material/styles/shadows'
import { makeStyles } from '@mui/styles'
import CustomerAuditInfo from 'src/components/Audit/CustomerAuditInfo'
import { AgreementTemplatesList } from 'src/components/AgreementTemplates'
import { useHistory, useLocation } from 'react-router-dom'
import FileUnmaskList from 'src/components/FileUnmask/FileUnmaskList'
import BusinessFeeList from 'src/components/BusinessFee/BusinessFeeList'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getBusinessFeeEnabled } from 'src/infra/api/services/business'
import { MediaNamingConventionList } from 'src/components/MediaNamingConvention'
import { AbilityContext } from 'src/context/Can'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: shadows[1],
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 250,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
}))

interface SellerBusinessTabProps {
  sellerBusinessId?: string | undefined
  clientId: number
  sellerData?: BusinessSellerResponseType | undefined
}
const SellerBusinessTab: React.FC<SellerBusinessTabProps> = ({
  sellerBusinessId,
  clientId,
  sellerData,
}: SellerBusinessTabProps) => {
  const ability = useContext(AbilityContext)
  const showPortfolioDataManagerTab = ability.can(
    PermissionCodeAccess.PORTFOLIO_DATA_MANAGER,
    'any'
  )
  const showRecoveryTabs = !process.env.REACT_APP_HIDE_RECOVERY_TABS
  const indexSubtract = showRecoveryTabs ? 0 : 1
  const indexSubtractAfterPortfolioDataManager = showPortfolioDataManagerTab
    ? 0 + indexSubtract
    : 1 + indexSubtract

  const tabIndices: { [key: string]: number } = {
    'business-information': 0,
    'default-bank-account': 1,
    notifications: 2,
    'asset-types': 3,
    'media-naming-convention': 4,
    'agreement-templates': 5,
    'delivery-structure': showRecoveryTabs ? 6 - indexSubtract : -1,
    comments: 7 - indexSubtract,
    'buyer-bid-access': 8 - indexSubtract,
    webhooks: 9 - indexSubtract,
    audit: 10 - indexSubtract,
    'portfolio-data-manager': 11 - indexSubtract,
    'service-fee': 12 - indexSubtractAfterPortfolioDataManager,
  }
  const classes = useStyles()
  const history = useHistory()
  const [value, setValue] = useState(0)
  const location = useLocation()
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    const tabText =
      Object.keys(tabIndices).find((key) => tabIndices[key] === newValue) ||
      'business-information'

    const currentPathname = location.pathname
    const newSearch = `/tab=${tabText}`
    const newUrl = currentPathname.includes('tab=')
      ? currentPathname.replace(/\/tab=[^&]+/, newSearch)
      : currentPathname + newSearch
    history.push(newUrl)
  }
  const defaultAccountDisable = () => {
    return !clientId || !sellerBusinessId
  }
  const bankAccountIsEmpty = () => {
    return (
      getBankAccountData?.getBankAccounts == null ||
      getBankAccountData?.getBankAccounts.length === 0
    )
  }
  useEffect(() => {
    const tabText =
      location.pathname.split('/').pop()?.replace('tab=', '')?.trim() ||
      'business-information'
    const tabIndex = tabIndices[tabText]
    if (tabIndex !== undefined) {
      setValue(tabIndex)
    } else setValue(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])
  const defaultAccontIsFilled = () => {
    return (
      defaultAccountDisable() ||
      bankAccountIsEmpty() ||
      (sellerData?.defaultBankAccountId && getBankAccountData?.getBankAccounts)
    )
  }
  const { data: getBankAccountData } = useQuery<GetBankAccounts>(
    GET_BANK_ACCOUNTS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        request: {
          clientIdFilter: Number(clientId),
        },
      },
      skip: !clientId,
    }
  )
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const { data: getClientData, loading } = useQuery<{
    getClient: GetClient
  }>(GET_CLIENT, { variables: { clientId: Number(clientId) } })

  const { data: businessFeeEnabled, isFetching: feeEnabledLoading } =
    useCustomQuery<boolean>(
      ['getBusinessFeeEnabled', sellerBusinessId],
      async () => {
        return getBusinessFeeEnabled(sellerBusinessId)
      },
      { enabled: !!sellerBusinessId, cacheTime: 0 }
    )

  const tabSeleted =
    Object.keys(tabIndices).find((key) => tabIndices[key] === value) ||
    'business-information'

  if (loading)
    return (
      <Box mt={5}>
        <Skeleton width="100%" height={150} variant="rectangular" />
      </Box>
    )
  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Seller Business Profile"
        className={classes.tabs}
      >
        <Tab label="Business information (Seller)" {...a11yProps(0)} />
        <Tab
          style={{
            color: defaultAccontIsFilled() ? undefined : 'red',
          }}
          label="Default Bank Account"
          icon={
            <Tooltip title="Default bank is required">
              <Info
                style={{
                  display: defaultAccontIsFilled() ? 'none' : 'block',
                  position: 'absolute',
                  right: 8,
                  marginBottom: 0,
                }}
              />
            </Tooltip>
          }
          {...a11yProps(1)}
          disabled={defaultAccountDisable()}
        />
        <Tab
          label="Notifications"
          {...a11yProps(2)}
          disabled={!clientId || !sellerBusinessId}
        />
        <Tab
          label="Asset Types"
          {...a11yProps(3)}
          disabled={!clientId || !sellerBusinessId}
        />
        <Tab
          label="Media Naming Convention"
          {...a11yProps(4)}
          disabled={!clientId || !sellerBusinessId}
        />
        {isInternal && (
          <Tab
            label={
              <Tooltip title="PSA & BOS templates">
                <span>Agreement Templates</span>
              </Tooltip>
            }
            disabled={!clientId || !sellerBusinessId}
            {...a11yProps(5)}
          />
        )}
        {showRecoveryTabs && (
          <Tab
            label="Delivery Structure"
            {...a11yProps(6 - indexSubtract)}
            disabled={!clientId || !sellerBusinessId}
          />
        )}

        {isInternal && (
          <Tab
            label="Comments"
            {...a11yProps(7 - indexSubtract)}
            disabled={!clientId || !sellerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Buyer Bid Access"
            disabled={!clientId || !sellerBusinessId}
            {...a11yProps(8 - indexSubtract)}
          />
        )}
        {isInternal && (
          <Tab
            label="Webhooks"
            disabled={!clientId || !sellerBusinessId}
            {...a11yProps(9 - indexSubtract)}
          />
        )}
        {isInternal && (
          <Tab
            label="Audit"
            {...a11yProps(10 - indexSubtract)}
            disabled={!sellerBusinessId}
          />
        )}
        {showPortfolioDataManagerTab && (
          <Tab
            label="Portfolio Data Manager"
            {...a11yProps(11 - indexSubtract)}
            disabled={!sellerBusinessId}
          />
        )}
        {isInternal && (
          <Tab
            label="Service Fee"
            {...a11yProps(12 - indexSubtractAfterPortfolioDataManager)}
            disabled={!sellerBusinessId}
          />
        )}
      </Tabs>
      <TabPanel value={value} index={0}>
        <BusinessInformation
          clientId={clientId}
          sellerBusinessId={sellerBusinessId}
          sellerData={sellerData}
          client={getClientData?.getClient}
          businessFeeEnabled={businessFeeEnabled}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {sellerBusinessId && clientId && (
          <DefaultBankAccount
            businessId={sellerBusinessId}
            clientId={clientId}
            defaultId={sellerData?.defaultBankAccountId}
            isSeller={true}
            client={getClientData?.getClient}
          />
        )}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {sellerBusinessId && (
          <BusinessNotifications businessId={sellerBusinessId} />
        )}
      </TabPanel>

      <TabPanel value={value} index={3}>
        {sellerBusinessId && (
          <BusinessAssetTypes businessId={sellerBusinessId} />
        )}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {!!sellerBusinessId && (
          <>
            <MediaNamingConventionList
              sellerId={sellerBusinessId}
              clientId={clientId}
              tabSelected={tabSeleted}
            />
          </>
        )}
      </TabPanel>
      {isInternal && (
        <TabPanel value={value} index={5}>
          <AgreementTemplatesList
            sellerId={sellerBusinessId}
            clientId={clientId}
            tabSelected={tabSeleted}
          />
        </TabPanel>
      )}
      {showRecoveryTabs && (
        <TabPanel value={value} index={6 - indexSubtract}>
          {sellerBusinessId && (
            <BusinessDeliveryStructure businessId={sellerBusinessId} />
          )}
        </TabPanel>
      )}

      {isInternal && (
        <>
          <TabPanel value={value} index={7 - indexSubtract}>
            {clientId && sellerBusinessId && (
              <Comment clientId={clientId} businessId={sellerBusinessId} />
            )}
          </TabPanel>
          <TabPanel value={value} index={8 - indexSubtract}>
            {sellerBusinessId && sellerData?.id && (
              <BuyerAccess
                sellerId={sellerBusinessId}
                sellerData={sellerData}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={9 - indexSubtract}>
            <WebHooksNotification sellerBusinessId={sellerBusinessId} />
          </TabPanel>
          <TabPanel value={value} index={10 - indexSubtract}>
            {!!sellerBusinessId && (
              <CustomerAuditInfo id={sellerBusinessId} tableName="business" />
            )}
          </TabPanel>
          {showPortfolioDataManagerTab && (
            <TabPanel value={value} index={11 - indexSubtract}>
              {!!sellerBusinessId && (
                <FileUnmaskList sellerId={sellerBusinessId} />
              )}
            </TabPanel>
          )}
          <TabPanel
            value={value}
            index={12 - indexSubtractAfterPortfolioDataManager}
          >
            {!!sellerBusinessId && (
              <BusinessFeeList
                businessId={sellerBusinessId}
                businessFeeEnabled={businessFeeEnabled}
                feeEnabledLoading={feeEnabledLoading}
              />
            )}
          </TabPanel>
        </>
      )}
    </div>
  )
}

SellerBusinessTab.defaultProps = {
  sellerBusinessId: undefined,
  sellerData: undefined,
}

export default SellerBusinessTab
