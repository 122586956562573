/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { formatDateAndTime } from 'src/utils/date'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Typography,
  Pagination,
  Skeleton,
} from '@mui/material'
import { getComment } from 'src/graphql/models/clientProfiles/index'
import { useAddCommentClientProfile } from 'src/graphql/operations/mutations/clientProfiles'
import { Save, Block } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import { DividerBottom } from './styles'

interface DefaultValuesProps {
  comment: string
  clientIdComment?: number | undefined
  businessIdComment?: string | undefined
  id: number
}

interface Props {
  commentsData?: getComment[]
  totalItems?: number | any
  pageSize: number
  isLoading: boolean | any
  clientId?: number | undefined
  businessId?: string
  page: number | any
  // eslint-disable-next-line react/no-unused-prop-types
  defaultValues?: DefaultValuesProps | undefined
  onChangePagination?: (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => void
}

const initialValuesDefault = {
  comment: '',
  id: 0,
  clientIdComment: undefined,
  businessIdComment: '',
}
const CommentList: React.FC<Props> = ({
  commentsData,
  totalItems,
  isLoading,
  page,
  clientId,
  businessId,
  onChangePagination,
  pageSize,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)

  const totalOfComment = totalItems ? Math.ceil(totalItems / pageSize) : 0

  const formCollectionValidation = yup.object().shape({
    comment: yup.string().required('Required'),
  })

  const addCommentOnCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Comment updated successfully', {
        variant: 'success',
      })
      setIndexEditing(null)
      refreshItems()
    }
  }

  const { AddComment, loading: addCommentLoading } = useAddCommentClientProfile(
    addCommentOnCompleted
  )

  const validate = (values: any) => {
    const errors: any = {}
    if (!values.comment) {
      setError('Required')
    } else {
      setError('')
    }
    return errors
  }
  const formCollection = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: formCollectionValidation,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values, { setSubmitting }) => {
      const { comment, id, clientIdComment, businessIdComment } = values
      AddComment({
        variables: {
          comment: {
            id,
            clientId: Number(clientIdComment),
            businessId: businessIdComment,
            comment,
          },
        },
        refetchQueries: ['GetComments'],
      })
      setSubmitting(false)
    },
  })

  const [data, setData] = useState(commentsData)
  const [indexEditing, setIndexEditing] = useState(null)
  const refreshItems = () => {
    const newArr = data?.map((item) => {
      if (formCollection.values.id === item.id) {
        return {
          ...item,
          comment: formCollection.values.comment,
        }
      }
      return item
    })
    setData(newArr)
  }
  const [error, setError] = useState('')

  const setModEdit = (index: number | any, item: getComment) => {
    setIndexEditing(index)
    setInitialValues((prevState) => ({
      ...prevState,
      ...item,
      clientIdComment: clientId,
      businessIdComment: businessId,
    }))
  }

  const cancelEdit = (item: getComment) => {
    setIndexEditing(null)
    formCollection.setFieldValue('comment', item)
  }

  useEffect(() => {
    setData(commentsData)
  }, [commentsData])

  if (isLoading) {
    return <Skeleton variant="rectangular" height={150} width="100%" />
  }

  if (totalOfComment === 0) {
    return <></>
  }
  return (
    <form onSubmit={formCollection.handleSubmit}>
      {data?.map((item, index) => (
        <Box key={index} pl={4} pt={4} pr={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>
                  <strong> {item.lastUpdatedByUserName}</strong>
                </Typography>
                {index !== indexEditing && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      setModEdit(index, item)
                    }}
                  >
                    Edit
                  </Button>
                )}
                {index === indexEditing && (
                  <Box>
                    <Button
                      style={{ marginRight: '5px' }}
                      color="primary"
                      variant="outlined"
                      disabled={addCommentLoading}
                      startIcon={
                        addCommentLoading ? (
                          <CircularProgress size={15} color="primary" />
                        ) : (
                          <Block fontSize="small" />
                        )
                      }
                      onClick={() => {
                        cancelEdit(item)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      disabled={addCommentLoading}
                      startIcon={
                        addCommentLoading ? (
                          <CircularProgress size={15} color="primary" />
                        ) : (
                          <Save fontSize="small" />
                        )
                      }
                    >
                      Save
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              {index !== indexEditing && (
                <TextField
                  fullWidth
                  multiline={true}
                  maxRows={4}
                  value={item.comment}
                />
              )}
              {index === indexEditing && (
                <TextField
                  fullWidth
                  name="comment"
                  label="Comment"
                  multiline={true}
                  maxRows={4}
                  onChange={formCollection.handleChange}
                  value={formCollection.values.comment}
                  error={!!error}
                  helperText={error}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography color="textSecondary">
                {formatDateAndTime(item.createdUTC)}
              </Typography>
            </Grid>
          </Grid>
          <DividerBottom>
            <Divider variant="fullWidth" />
          </DividerBottom>
        </Box>
      ))}

      {!!totalItems && (
        <Box display="flex" alignItems="center" justifyContent="center" my={2}>
          <Pagination
            count={totalOfComment}
            color="primary"
            onChange={onChangePagination}
            page={page}
          />
          <Box display="flex" alignItems="center" />
        </Box>
      )}
    </form>
  )
}
CommentList.defaultProps = {
  defaultValues: initialValuesDefault,
}
export default CommentList
