import { ThemeOptions } from '@mui/material'

export const debtTea = '#002E3A'
export const debtGreyLight = '#e7f7ff'
export const debtGreyLightDark = '#e7f7ff'
export const debtWhite = '#FFFFFF'
export const debtBlack = '#000000'
export const debtGreyClean = '#D6D4D3'
export const debtGrey = '#e7f7ff'
export const error = '#E03F3F'
export const white = '#FFFFFF'
export const debtBlue = '#82C2E1'
export const debtLightBlue = '#f3fafe'

interface ThemeDefaultOptions extends ThemeOptions {
  debtColors: {
    white: string
    teaTransparent: string
    debtTea: string
    debtGrey: string
    debtGreyLight: string
    debtGreyClean: string
    error: string
    debtBlue: string
    debtLightBlue: string
  }
}

const defaultTheme: ThemeDefaultOptions = {
  palette: {
    common: {
      black: debtTea,
    },
    primary: {
      main: debtTea,
      contrastText: debtWhite,
    },
    secondary: {
      main: debtBlack,
      contrastText: debtWhite,
    },
    error: {
      main: error,
      contrastText: debtWhite,
    },
  },
  debtColors: {
    white: '#FFFFFF',
    debtTea,
    debtGreyLight,
    teaTransparent: '#002E3A',
    debtGreyClean: '#DEDEDE',
    debtGrey,
    error,
    debtBlue,
    debtLightBlue,
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightLight: 300,
    h1: {
      fontSize: 24,
      fontWeight: 400,
    },
    h2: {
      color: debtTea,
      fontSize: 18,
      fontWeight: 500,
    },
  },
  spacing: 4,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          body: {
            backgroundColor: debtWhite,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: debtTea,
          color: '#222',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: white,
          color: debtBlack,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          textTransform: 'none',
          fontWeight: 400,
        },
        contained: {
          textTransform: 'none',
          fontWeight: 400,
        },
        outlined: {
          fontWeight: 400,
          textTransform: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          textTransform: 'none',
          color: '#000',
        },
        body1: { fontSize: '0.9rem' },
        body2: { fontSize: '0.9rem' },
        subtitle1: { fontSize: '1.5rem' },
        subtitle2: { fontWeight: 700 },
        h6: { fontSize: '1rem' },
      },
    },
    MuiInputBase: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiFormLabel: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiTableCell: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiTooltip: {
      styleOverrides: { tooltip: { fontSize: '0.75rem' } },
    },
    MuiMenuItem: {
      styleOverrides: { root: { fontSize: '0.9rem' } },
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          paddingTop: '2px',
          minHeight: '0px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
}

export default defaultTheme
