import styled from 'styled-components'

interface DrawerRightWrapperProps {
  open: boolean
  width?: number | string | undefined
}

export const DrawerRightWrapper = styled.div<DrawerRightWrapperProps>`
  position: fixed;
  top: 0;
  right: 0;
  left: auto;
  visibility: ${({ open }): string => (open ? 'visible' : 'hidden')};
  opacity: ${({ open }): number => (open ? 1 : 0)};
  display: flex;
  flex: 1 0 auto;
  width: ${({ width, open }): string =>
    (width && open && `${width}px`) || (!open && '0px') || `${width}`};
  height: 100%;
  flex-direction: column;
  box-shadow: inset 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  overflow-y: auto;
  background-color: ${({ theme }): string => theme.palette.common.white};
  z-index: 1300;
  -webkit-overflow-scrolling: touch;
  color: ${({ theme }): string => theme.debtColors.debtTea};
  outline: none;
  transition: all 0.2s ease-in-out;
`
