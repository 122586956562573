import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import { Add } from '@mui/icons-material'
import { AuthContext } from 'src/context/AuthenticationContext'

import { CLIENT_PROFILES_MANAGE } from 'src/routes'
import { TENANT_TYPE } from 'src/utils/constants'
import ClientProfilesTable from './components/ClientProfilesTable'

const ClientProfiles = () => {
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  return (
    <Box>
      <Box p={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h1" color="secondary">
              Client Profiles
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Box display="flex" justifyContent="flex-end">
              {isInternal && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  component={Link}
                  to={CLIENT_PROFILES_MANAGE}
                >
                  Add a New Client
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box p={5}>
        <ClientProfilesTable />
      </Box>
    </Box>
  )
}

export default ClientProfiles
