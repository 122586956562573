import styled from 'styled-components'
import { Box as MBox, Skeleton as MSkeleton } from '@mui/material'

export const Container = styled(MBox)`
  && {
    display: flex;
    flex-direction: column;
    padding: 24px;
    padding-top: 0px;
    ${(props) => props.theme.breakpoints.up('lg')} {
      margin-right: 20px;
      margin-left: 20px;
    }
    ${(props) => props.theme.breakpoints.down('lg')} {
      margin: 3px;
    }
  }
`

export const Medias = styled(MBox)`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`

export const SkeletonSummary = styled(MSkeleton)`
  && {
  }
`

export const SkeletonTitle = styled(MSkeleton)`
  && {
    height: 60px;
    top: 0px;
  }
`

export const SkeletonMedia = styled(MSkeleton)`
  && {
    height: 231px;
    width: 49%;
  }
`

export const Comment = styled(MBox)`
  && {
    display: flex;
    flex-direction: row;
    padding: 15px;
    height: 80px;
  }
`

export const SkeletonAvatar = styled(MSkeleton)`
  && {
  }
`

export const Box = styled(MBox)`
  && {
  }
`
