import styled from 'styled-components'

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  .item {
    margin-left: ${({ theme }): string => theme.spacing(8)};
    &:first-child {
      margin-left: 0;
    }
  }
`

export const Header = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(1fr, auto));
  align-items: center;
  row-gap: 0.4rem;
  ${({ theme }): any => theme.breakpoints.up('sm')} {
    grid-template-columns: 40% 60%;
    ${HeaderActions} {
      justify-self: end;
      align-self: center;
    }
  }
`
export const HeaderInfos = styled.div`
  display: flex;
  justify-content: center;
  justify-self: flex-start;
  align-items: center;
  > .btn-goback {
    align-self: flex-start;
    &.--btn-mr {
      margin-right: ${({ theme }) => theme.spacing(2)};
    }
  }
`
