import React from 'react'
import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { Add } from '@mui/icons-material'
import { useFormik } from 'formik'
import * as Yup from 'yup'

interface CommentProps {
  onAddComment?: (comment: string) => void
  children?: React.ReactNode
  loading?: boolean
}

const initialValues = {
  comment: '',
}

const commentValidationSchema = Yup.object().shape({
  comment: Yup.string().required('Required'),
})

const Comment = ({
  onAddComment = () => {},
  children,
  loading,
}: CommentProps) => {
  const commentForm = useFormik({
    initialValues,
    validationSchema: commentValidationSchema,
    onSubmit: (values) => {
      const { comment } = values
      onAddComment(comment)
    },
  })

  return (
    <div>
      <Box>
        <form onSubmit={commentForm.handleSubmit}>
          <Box mb={2}>
            <TextField
              fullWidth
              multiline
              placeholder="Enter a new comment"
              name="comment"
              maxRows={4}
              onChange={commentForm.handleChange}
              error={!!commentForm.errors.comment}
              helperText={commentForm.errors.comment}
            />
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              startIcon={!loading ? <Add /> : <CircularProgress size={16} />}
              disabled={loading}
              type="submit"
            >
              Add Comment
            </Button>
          </Box>
        </form>
      </Box>
      <Box my={4}>{children}</Box>
    </div>
  )
}

Comment.defaultProps = {
  onAddComment: () => {},
  children: null,
  loading: false,
}

export default Comment
