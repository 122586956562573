import { gql, useMutation, MutationUpdaterFn } from '@apollo/client'

export const UPLOAD_NDA_FILE = gql`
  mutation uploadUnmaskDataNDAFile($businessId: String!, $file: Upload!) {
    uploadUnmaskDataNDAFile(businessId: $businessId, file: $file)
  }
`
interface Params {
  onCompleted?: (data: any) => void
}

export function useUploadUnmaskDataNDAFile({ onCompleted }: Params) {
  const [uploadUnmaskDataNDAFile, { data, error, loading }] = useMutation(
    UPLOAD_NDA_FILE,
    { onCompleted }
  )
  return { uploadUnmaskDataNDAFile, data, error, loading }
}

export const ADD_OR_UPDATE_SELLER = gql`
  mutation AddOrUpdateSeller($seller: BusinessSellerRequestType!) {
    addOrUpdateSeller(seller: $seller) {
      id
      name
      bankName
      clientId
    }
  }
`

export function useAddOrUpdateSeller(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addOrUpdateSeller, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_SELLER,
    {
      update,
      onCompleted,
    }
  )
  return { addOrUpdateSeller, data, error, loading }
}
export const CHANGE_WEBHOOK_REGISTER = gql`
  mutation ChangeWebhookRegister($request: ChangeWebhookRegisterRequestType!) {
    changeWebhookRegister(request: $request)
  }
`

export function useChangeWebhookRegister(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [changeWebhookRegister, { data, error, loading }] = useMutation(
    CHANGE_WEBHOOK_REGISTER,
    {
      update,
      onCompleted,
    }
  )
  return { changeWebhookRegister, data, error, loading }
}
export const RENEW_API_KEY_BUSINESS = gql`
  mutation RenewApiKeyBusiness($businessId: String!) {
    renewApiKeyBusiness(businessId: $businessId)
  }
`

export function useRenewApiKeyBusiness({ onCompleted }: Params) {
  const [renewApiKeyBusiness, { data, error, loading }] = useMutation(
    RENEW_API_KEY_BUSINESS,
    { onCompleted }
  )
  return { renewApiKeyBusiness, data, error, loading }
}
