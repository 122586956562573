import React, { memo } from 'react'
import { Skeleton } from '@mui/material'
import { Container, SkeletonSummary, SkeletonTitle, Box } from './styles'

const StyledSkeleton = () => {
  return (
    <Skeleton width="100%" height={50} animation="wave" variant="rectangular" />
  )
}

function BusinessInformationSkeleton() {
  return (
    <Container>
      <Box display="flex" flexDirection="column" style={{ gap: 50 }}>
        <SkeletonTitle />
        <Box p={2} display="flex" style={{ gap: 20 }}>
          <StyledSkeleton />
          <StyledSkeleton />
          <StyledSkeleton />
        </Box>
        <Box p={2} display="flex" style={{ gap: 20 }}>
          <StyledSkeleton />
          <StyledSkeleton />
          <StyledSkeleton />
        </Box>
        <Box p={2} display="flex" style={{ gap: 20 }}>
          <StyledSkeleton />
          <StyledSkeleton />
          <StyledSkeleton />
        </Box>
        <Box p={2} display="flex" style={{ gap: 20 }}>
          <StyledSkeleton />
          <StyledSkeleton />
          <StyledSkeleton />
        </Box>
        <Box p={2} display="flex" style={{ gap: 20 }}>
          <StyledSkeleton />
          <StyledSkeleton />
          <StyledSkeleton />
        </Box>
        <Box p={2} display="flex" style={{ gap: 20 }}>
          <SkeletonSummary />
        </Box>
      </Box>
    </Container>
  )
}

export default memo(BusinessInformationSkeleton)
