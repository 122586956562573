import { httpClient } from '../../axios-wrapper/httpClient'
import * as urls from '../../urls/portfolioType'
import * as types from '../../models/portfolioType'

export const addBuyerPortfolioTypes = async (
  request: types.BuyerPortfolioTypeRequest
): Promise<void> => {
  await httpClient.post(urls.AddBuyerPortfolioType, request)
}

export const removeBuyerPortfolioTypes = async (
  request: types.BuyerPortfolioTypeRequest
): Promise<void> => {
  await httpClient.post(urls.DeleteBuyerPortfolioType, request)
}
