import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  TextField,
  MenuItem,
  Box,
  Typography,
  Divider,
  FormControlLabel,
  Switch,
  Button,
  IconButton,
  Grid,
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  GET_INTERNAL_NOTIFICATION_DETAIL,
  GET_INTERNAL_NOTIFICATION_RECIPIENTS,
} from 'src/graphql/operations/queries/business'
import {
  useUpdateNotificationSubscription,
  useDeleteBusinessEmailNotifications,
} from 'src/graphql/operations/mutations/businesses'

import {
  NotificationRecipientsResponse,
  GetInternalNotificationDetail,
  formatBusinessNotificationGroupFields,
} from 'src/graphql/models/business'
import { notistackOptions } from 'src/configs/notistackOptions'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { emailValidate } from 'src/utils/text'
import ManageNotifications from './ManageNotifications'
import { ModalDialog } from 'everchain-uilibrary'

interface DropDownBusinessItem {
  id: any | undefined
  name: string | undefined
  email: string | undefined
  userId: any | undefined
  status: string | undefined
}
const InternalNotifications = () => {
  const [formNotification, setFormNotification] = useState<{
    userIdNotification: string | undefined
    emailAddress: string | undefined
    notificationType: string | undefined
    selectedRecipient: string | undefined
  }>({
    userIdNotification: undefined,
    emailAddress: undefined,
    notificationType: 'Internal',
    selectedRecipient: '',
  })

  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [emailToDelete, setEmailToDelete] = useState<string | undefined>(
    undefined
  )
  const [canDeleteEmail, setCanDeleteEmail] = useState(false)
  const [groupedNotification, setGroupedNotification] = useState<any[]>([])
  const [addUserToggle, setAddUserToggle] = useState(false)
  const savingEmail = useRef(false)
  const ability = useContext(AbilityContext)
  const hasBusinessNotificationPermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_NOTIFICATION_PERMISSION,
    'any'
  )
  const [dropDownBusinessItems, setDropDownBusinessItems] = useState<
    DropDownBusinessItem[]
  >([])
  const [emailToAdd, setEmailToAdd] = useState<string>()

  const [showDisabled, showDisabledHandleChange] = useState<boolean>(false)

  const { enqueueSnackbar } = useSnackbar()

  const {
    data: internalNotificationRecipientsData,
    loading: internalNotificationRecipientsLoading,
  } = useQuery<NotificationRecipientsResponse>(
    GET_INTERNAL_NOTIFICATION_RECIPIENTS,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        const emails =
          // eslint-disable-next-line max-len
          internalNotificationRecipientsData?.getInternalNotificationRecipients.listEmails.map(
            function getEmail(email) {
              const container: DropDownBusinessItem = {
                name: '',
                email: '',
                id: '',
                userId: '',
                status: '',
              }
              container.id = email
              container.email = email
              container.name = email
              return container
            }
          )

        const users =
          // eslint-disable-next-line max-len
          internalNotificationRecipientsData?.getInternalNotificationRecipients.listUsers?.map(
            function getUser(user) {
              const container: DropDownBusinessItem = {
                name: '',
                email: '',
                id: '',
                userId: '',
                status: '',
              }

              container.name = user.displayName
              container.id = user.id
              container.userId = user.id
              container.status = user.status
              return container
            }
          )

        setDropDownBusinessItems(users?.concat(emails ?? []) ?? [])
      },
    }
  )

  const [
    getInternalNotificationDetail,
    {
      data: getInternalNotificationDetailData,
      loading: internalNotificationDetailLoading,
    },
  ] = useLazyQuery<GetInternalNotificationDetail>(
    GET_INTERNAL_NOTIFICATION_DETAIL,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-and-network',
      onCompleted: () => {
        if (
          getInternalNotificationDetailData?.getInternalNotificationDetail
            .length &&
          !internalNotificationDetailLoading
        ) {
          setGroupedNotification(
            formatBusinessNotificationGroupFields(
              getInternalNotificationDetailData.getInternalNotificationDetail
            )
          )
        }
      },
    }
  )

  const handleGetNotificationsDetails = () => {
    getInternalNotificationDetail({
      variables: {
        request: {
          emailAddressFilter: formNotification.emailAddress,
          userIdFilter: formNotification.userIdNotification,
          notificationTypeFilter: formNotification.notificationType,
        },
      },
    })
  }

  const handleNotificationTypeChangeForm = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event
    setFormNotification((prevState: any) => ({
      ...prevState,
      notificationType: value,
    }))
  }

  const handleRecipientChangeForm = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const {
      target: { value },
    } = event
    setCanDeleteEmail(false)
    if (dropDownBusinessItems?.find((x) => x.email === value)) {
      setFormNotification((prevState: any) => ({
        ...prevState,
        userIdNotification: undefined,
        emailAddress: value,
        selectedRecipient: value,
      }))
      setCanDeleteEmail(true)
    } else {
      setFormNotification((prevState: any) => ({
        ...prevState,
        userIdNotification: value,
        emailAddress: undefined,
        selectedRecipient: value,
      }))
    }
    handleGetNotificationsDetails()
  }

  const handleNotificationSubiscriptionCompleted = (data: any) => {
    if (data && data.updateNotificationSubscription) {
      enqueueSnackbar('Updated notification subscription successful', {
        variant: 'success',
      })
    }
  }

  const { updateNotificationSubscription } = useUpdateNotificationSubscription(
    handleNotificationSubiscriptionCompleted
  )

  const handleDeleteNotificationSubiscriptionCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Notification deleted successfully', notistackSucces)
    } else {
      enqueueSnackbar(
        'Error while trying to delete notification',
        notistackWarning
      )
    }
  }
  const { deleteBusinessEmailNotifications } =
    useDeleteBusinessEmailNotifications(
      handleDeleteNotificationSubiscriptionCompleted
    )
  const removeItemOnce = (arr: any[], value: any) => {
    const index = arr.indexOf(value)
    if (index > -1) {
      arr.splice(index, 1)
    }
    return arr
  }
  const handleDeleteEmail = () => {
    const email = emailToDelete
    deleteBusinessEmailNotifications({
      variables: {
        email,
      },
      refetchQueries: ['GetNotificationSummary'],
    })
    const element = dropDownBusinessItems?.find((x) => x.email === email)
    if (element && dropDownBusinessItems) {
      setDropDownBusinessItems(removeItemOnce(dropDownBusinessItems, element))
      formNotification.emailAddress = undefined
      handleGetNotificationsDetails()
    }
  }
  const handleClose = () => {
    setOpenRemoveDialog(false)
    setEmailToDelete('')
  }

  const handleRemove = () => {
    setOpenRemoveDialog(false)
    handleDeleteEmail()
    setEmailToDelete('')
  }

  useEffect(() => {
    if (!openRemoveDialog && emailToDelete) {
      setOpenRemoveDialog(true)
    }
  }, [emailToDelete, openRemoveDialog])

  const handleAddUserToggle = () => {
    setAddUserToggle(!addUserToggle)

    setEmailToAdd('')
  }

  const handleChangeItems = (
    newGroupedNotification: any[][],
    eventType: string,
    subscribed: boolean
  ) => {
    if (
      dropDownBusinessItems?.find(
        (x) => x.userId === formNotification.userIdNotification
      )
    ) {
      updateNotificationSubscription({
        variables: {
          request: {
            userId: formNotification.userIdNotification,
            subscribed,
            eventType,
            notificationType: formNotification.notificationType,
          },
        },
      })
    } else if (
      dropDownBusinessItems?.find(
        (x) => x.email === formNotification.emailAddress
      )
    ) {
      updateNotificationSubscription({
        variables: {
          request: {
            emailAddress: formNotification.emailAddress,
            subscribed,
            eventType,
            notificationType: formNotification.notificationType,
          },
        },
      })
    }

    setGroupedNotification(newGroupedNotification)
  }

  const dropDownBusinessItemsRender = (loading = false) => {
    if (loading) {
      return <MenuItem value="">Loading</MenuItem>
    }

    return dropDownBusinessItems?.length && !loading ? (
      dropDownBusinessItems
        .filter((recipient) =>
          showDisabled ? true : recipient.status !== 'disabled'
        )
        .map((recipient) => (
          <MenuItem
            value={recipient.userId ? recipient.userId : recipient.email}
            key={recipient.id}
            style={{
              color: recipient.status === 'disabled' ? 'gray' : 'black',
            }}
          >
            {recipient.name} {recipient.status === 'disabled' && '- Disabled'}
          </MenuItem>
        ))
    ) : (
      <MenuItem value="">Empty</MenuItem>
    )
  }

  useEffect(() => {
    if (savingEmail.current && dropDownBusinessItems) {
      const { email } = dropDownBusinessItems[dropDownBusinessItems?.length - 1]

      setFormNotification((prevState: any) => ({
        ...prevState,
        userIdNotification: undefined,
        emailAddress: email,
        selectedRecipient: email,
      }))

      handleGetNotificationsDetails()
      savingEmail.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownBusinessItems, formNotification])

  const addEmailToList = (email: string | undefined) => {
    if (!dropDownBusinessItems?.find((x) => x.email === email)) {
      savingEmail.current = true
      setDropDownBusinessItems((prev) => [
        ...prev,
        { id: email, name: email, email, userId: '', status: '' },
      ])
      setGroupedNotification([])
      setAddUserToggle(false)
    } else {
      enqueueSnackbar(
        'Email already added to the list of recipients',
        notistackWarning
      )
    }
  }

  const handleCloseAddEmail = () => {
    setAddUserToggle(false)
  }

  useEffect(() => {
    if (formNotification.emailAddress || formNotification.userIdNotification)
      handleGetNotificationsDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formNotification])
  const renderDeleteButton = () => {
    if (canDeleteEmail) {
      return (
        <Grid item xs={2} sm={2} md={2} style={{ marginBottom: 5 }}>
          <IconButton
            style={{
              top: '10px',
              maxWidth: '80%',
            }}
            onClick={(event) => {
              event.stopPropagation()
              setEmailToDelete(formNotification.emailAddress)
            }}
          >
            <Delete style={{ maxWidth: '80%' }} />
          </IconButton>
        </Grid>
      )
    }
    return null
  }

  return (
    <div>
      <Box p={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h1" color="secondary">
              Internal Notification
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box data-test-cy="boxUserNotification" p={5}>
        <Box p={5}>
          <Typography variant="h6">Change email notifications</Typography>
          <Typography color="textSecondary">
            Modify a user’s notifications
          </Typography>

          <Grid container>
            <Grid item xs={12} sm={3} md={3}>
              <Box my={4}>
                <TextField
                  label="Notification Type"
                  name="notificationType"
                  select
                  fullWidth
                  disabled={!hasBusinessNotificationPermission}
                  value={formNotification.notificationType}
                  onChange={handleNotificationTypeChangeForm}
                >
                  <MenuItem value="Buyer">Buyer</MenuItem>
                  <MenuItem value="Seller">Seller</MenuItem>
                  <MenuItem value="Internal">Internal</MenuItem>
                </TextField>
              </Box>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={3} md={3}>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      onChange={() => showDisabledHandleChange(!showDisabled)}
                      checked={showDisabled}
                      value={showDisabled}
                    />
                  }
                  label={<Typography>Show disabled recipient</Typography>}
                />
              </Box>
              <Box display={'flex'} gap={2}>
                <TextField
                  label="Choose a recipient"
                  name="selectedRecipient"
                  select
                  fullWidth
                  disabled={!hasBusinessNotificationPermission}
                  defaultValue={formNotification.selectedRecipient}
                  value={formNotification.selectedRecipient}
                  onChange={handleRecipientChangeForm}
                >
                  {dropDownBusinessItemsRender(
                    internalNotificationRecipientsLoading
                  )}
                </TextField>
                {renderDeleteButton()}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {groupedNotification &&
          (formNotification.emailAddress ||
            formNotification.userIdNotification) && (
            <ManageNotifications
              groupedNotification={groupedNotification}
              isLoading={internalNotificationDetailLoading}
              shouldReplaceBkName={true}
              disabled={!hasBusinessNotificationPermission}
              onChangeItem={handleChangeItems}
            />
          )}
      </Box>
      <Box p={5}>
        <Typography variant="h6">E-mail Notifications</Typography>
        <Typography color="textSecondary">
          Send notification to an email address you specify. This email does not
          have to be associated with a user on the DebtTrader Platform.
        </Typography>
        <Box maxWidth={460} my={4}>
          <Button
            variant="contained"
            color="primary"
            disabled={!hasBusinessNotificationPermission}
            startIcon={<Add />}
            onClick={handleAddUserToggle}
          >
            Add Distribution List Email
          </Button>
          <ModalDialog
            isOpen={addUserToggle}
            header="Change e-mail recipient notifications"
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
            onClose={handleCloseAddEmail}
            buttonOkText="Save"
            onContinue={() => addEmailToList(emailToAdd)}
            disableOkButton={!emailValidate(emailToAdd)}
          >
            <Box>
              <TextField
                fullWidth
                label="Notification Recipient"
                name="emailToAdd"
                onChange={(event) => setEmailToAdd(event?.target.value)}
                error={!emailValidate(emailToAdd)}
                value={emailToAdd}
                helperText={!emailValidate(emailToAdd) && 'Email is not valid'}
              />
            </Box>
          </ModalDialog>
        </Box>
        <ModalDialog
          isOpen={openRemoveDialog}
          header="Remove E-mail"
          buttonCancelText="Cancel"
          buttonOkText="Continue"
          onClose={handleClose}
          onContinue={handleRemove}
        >
          <Box>
            <Typography variant="body2" color="textSecondary">
              Are you sure you want to remove this E-mail?
            </Typography>
          </Box>
        </ModalDialog>
        <Divider />
      </Box>
    </div>
  )
}

export default InternalNotifications
