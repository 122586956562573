/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useLazyQuery } from '@apollo/client'
import { Box, IconButton } from '@mui/material'
import { Delete, Edit } from '@mui/icons-material'
import React, { useEffect, useState, useContext } from 'react'
import DynamicTable from 'src/components/DynamicTable'
import { cellPeriod } from 'src/components/DynamicTable/DynamicTableUltis'
import {
  getUserCommissions,
  userCommission,
} from 'src/graphql/models/commissions'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { GET_USER_COMMISSIONS } from 'src/graphql/operations/queries/commissions'
import { useRemoveUserCommission } from 'src/graphql/operations/mutations/commissions'
import { useSnackbar } from 'notistack'
import UserCommission from './UserCommission'

interface UserCommissionParam {
  userId: string
}

const UserCommissions: React.FC<UserCommissionParam> = ({
  userId,
}: UserCommissionParam) => {
  const [userCommissionData, setUserCommissionData] = useState<
    userCommission | undefined
  >(undefined)
  const [openUserCommission, setOpenUserCommission] = useState(false)
  const pageSize = 25
  const handleUserCommissionClick = (item: userCommission) => {
    setUserCommissionData(item)
  }
  const handleOpenUserCommission = () => {
    setOpenUserCommission(!openUserCommission)
  }

  const [
    // eslint-disable-next-line no-shadow
    getUserCommissions,
    { data: userCommissionsData, fetchMore },
  ] = useLazyQuery<getUserCommissions>(GET_USER_COMMISSIONS, {
    variables: {
      userId,
    },
  })

  const ability = useContext(AbilityContext)

  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')
  const { enqueueSnackbar } = useSnackbar()

  const { RemoveUserCommission } = useRemoveUserCommission({
    onCompleted: () => {
      enqueueSnackbar('User Commission removed successfully', notifySuccess)
    },
    onError: () => {
      enqueueSnackbar('You can not select a date in the past.', notifyError)
    },
  })

  const handleRemoveClick = (item: userCommission) => {
    RemoveUserCommission({
      variables: {
        id: Number(item.id),
      },
      refetchQueries: ['GetUserCommissions'],
    })
  }

  const actualDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )
  const canEdit = ability.can(PermissionCodeAccess.MANAGE_COMMISSION, 'any')

  const Columns = [
    { Header: 'Period', accessor: 'startDate', Cell: cellPeriod },
    {
      Header: '% Commission',
      accessor: 'commissionPercent',
      Cell: (props: any) => {
        return (
          <span>
            {props.row.original.commissionPercent}
            {'%'}{' '}
          </span>
        )
      },
    },

    { Header: 'Type', accessor: 'type' },
    {
      Header: '',
      accessor: 'id',
      Cell: (props: any) => {
        return (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="middle"
            style={{ alignItems: 'center' }}
          >
            <IconButton
              aria-label="more"
              aria-controls="action-menus"
              color="secondary"
              disabled={
                !canEdit || new Date(props.row.original.startDate) < actualDate
              }
              onClick={() => {
                handleUserCommissionClick(props.row.original)
              }}
              size="small"
            >
              <Edit fontSize="small" />
            </IconButton>
            <IconButton
              aria-label="more"
              aria-controls="action-menus"
              color="secondary"
              disabled={
                !canEdit || new Date(props.row.original.startDate) < actualDate
              }
              onClick={() => {
                handleRemoveClick(props.row.original)
              }}
              size="small"
            >
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        )
      },
    },
  ]

  useEffect(() => {
    if (userCommissionData) {
      setOpenUserCommission(true)
    }
  }, [userCommissionData])

  useEffect(() => {
    if (userId) {
      getUserCommissions()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <>
      <DynamicTable
        id="tbCommission"
        data={
          userCommissionsData?.getUserCommissions.userCommissionResponse || []
        }
        pageSize={pageSize}
        columns={Columns}
        totalItems={userCommissionsData?.getUserCommissions.total}
        onChangePagination={(_, pageNumber: number) => {
          const newPageNumber = pageNumber - 1
          if (fetchMore) {
            fetchMore({
              variables: {
                pagination: {
                  pageSize,
                  pageNumber: newPageNumber,
                },
              },
              updateQuery: (prev, { fetchMoreResult }): getUserCommissions => {
                if (!fetchMoreResult) return prev
                return fetchMoreResult
              },
            })
          }
        }}
      />
      {openUserCommission && (
        <UserCommission
          open={openUserCommission}
          onClose={handleOpenUserCommission}
          userId={userId || ''}
          userCommission={userCommissionData}
        />
      )}
    </>
  )
}
export default UserCommissions
