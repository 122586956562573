import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import LogTabs from './Components/LogTabs'

const LogPages: React.FC = () => {
  return (
    <Box>
      <Box p={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h1" color="secondary">
              Logs
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={5}>
        <LogTabs />
      </Box>
    </Box>
  )
}

export default LogPages
