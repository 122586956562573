import React, { useContext } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { ContactMail } from '@mui/icons-material'
import { BACKEND_URL } from 'src/infra/api/axios-wrapper/httpClient'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { ClientContactInfo } from 'src/infra/api/models/client'
import axios from 'axios'
import { authUrl, clientId } from 'src/configs/authConst'
import { AuthContext } from 'src/context/AuthenticationContext'
import { ModalDialog } from 'everchain-uilibrary'

interface ContactDialogProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const ContactDialog: React.FC<ContactDialogProps> = ({
  open,
  setOpen,
}: ContactDialogProps) => {
  const { userPermissions } = useContext(AuthContext)
  const user = window.localStorage.getItem(`dt.user:${authUrl}:${clientId}`)
  const userToken = user ? `bearer ${JSON.parse(user).access_token}` : ''

  const isCreditor = false // to-do: implement creditor logic
  const isVendor = false // to-do: implement vendor logic
  const isSeller = userPermissions.type.toLowerCase() === 'seller'
  const isBuyer = userPermissions.type.toLowerCase() === 'buyer'

  const { data: contactInfoData, isFetching: loadingContactInfo } =
    useCustomQuery<ClientContactInfo>(
      ['GetContactInfo'],
      async () => {
        return (
          (isBuyer || isSeller || isCreditor || isVendor) &&
          axios({
            method: 'get',
            url: `${BACKEND_URL}/client.GetContactInfo`,
            headers: {
              Authorization: userToken,
            },
          }).then((result: any) => {
            return result.data
          })
        )
      },
      { enabled: !!clientId, cacheTime: 0 }
    )

  return (
    <ModalDialog
      isOpen={open}
      header="Contact"
      onClose={() => setOpen(false)}
      aria-labelledby="form-dialog-title"
      hideCancelButton={true}
      hideOkButton={true}
    >
      {!loadingContactInfo && (
        <Box>
          {isSeller && (
            <>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <Box>
                  <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <ContactMail />
                    <Typography style={{ marginLeft: '5px' }} variant="h2">
                      {contactInfoData?.salesDirectorTitle}
                    </Typography>
                  </Box>
                  <Box>Name: {contactInfoData?.salesDirectorName}</Box>
                  <Box>Email: {contactInfoData?.salesDirectorEmail}</Box>
                </Box>
              </Box>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </>
          )}
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Box>
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ContactMail />
                <Typography style={{ marginLeft: '5px' }} variant="h2">
                  {contactInfoData?.recoverSuccessManagerTitle}
                </Typography>
              </Box>
              <Box>Name: {contactInfoData?.recoverSuccessManagerName}</Box>
              <Box>Email: {contactInfoData?.recoverSuccessManagerEmail}</Box>
            </Box>
          </Box>
        </Box>
      )}
    </ModalDialog>
  )
}

export default ContactDialog
