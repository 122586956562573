/* eslint-disable max-len */
import { useQuery } from '@apollo/client'
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableRow,
  TextField,
  Typography,
  MenuItem,
  Skeleton,
} from '@mui/material'
import React, { useState } from 'react'
import { RevenuesSummaryResponse } from 'src/graphql/models/commissions'
import { GET_REVENUE_SUMMARY } from 'src/graphql/operations/queries/commissions'
import { toMonthName } from 'src/utils/date'
import { numberCurrencyDollar } from 'src/utils/numbers'
import { RevenueSummaryCell } from '../styles'

interface RevenueSummaryProps {
  revenueYear: number
}

const QuarterlyRevenueSummary: React.FC<RevenueSummaryProps> = ({
  revenueYear,
}: RevenueSummaryProps) => {
  const [revenuePeriodQuery, setRevenuePeriodQuery] = useState<any>(0)

  const {
    data: getRevenueSummary,
    loading: revenueSummaryLoading,
    refetch,
  } = useQuery<RevenuesSummaryResponse>(GET_REVENUE_SUMMARY, {
    variables: {
      revenueYear,
      revenuePeriod: 2,
      revenuePeriodQuery,
    },
  })

  const handleRevenuePeriodQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRevenuePeriodQuery(Number(event.target.value))
    refetch()
  }

  const getQuarterShortName = () => {
    switch (revenuePeriodQuery) {
      case 1:
        return '1st'
      case 2:
        return '2nd'
      case 3:
        return '3rd'
      default:
        return '4th'
    }
  }

  return (
    <Box>
      <Grid xs={1} sm={1} md={1} style={{ margin: 10, paddingBottom: 10 }}>
        <TextField
          fullWidth
          label="Quarter"
          name="revenuePeriodQuarter"
          select
          onChange={handleRevenuePeriodQueryChange}
        >
          <MenuItem key={1} value="1">
            First Quarter
          </MenuItem>
          <MenuItem key={2} value="2">
            Second Quarter
          </MenuItem>
          <MenuItem key={3} value="3">
            Third Quarter
          </MenuItem>
          <MenuItem key={4} value="4">
            Fourth Quarter
          </MenuItem>
        </TextField>
      </Grid>
      <Box>
        <Table>
          <TableBody>
            {revenueSummaryLoading ? (
              <Skeleton variant="rectangular" height={150} width="100%" />
            ) : (
              <Box style={{ display: 'flex', flexDirection: 'row', gap: 50 }}>
                {getRevenueSummary &&
                getRevenueSummary?.getRevenueSummary?.revenueSummaryDataResponse
                  .length > 0 ? (
                  getRevenueSummary?.getRevenueSummary?.revenueSummaryDataResponse?.map(
                    (item, index) => (
                      <Box key={index}>
                        <TableRow>
                          <Box gap={2.5} style={{ margin: 5 }}>
                            <Typography variant="h6">
                              {toMonthName(item.month)}
                            </Typography>
                            <RevenueSummaryCell>
                              <Typography
                                variant="body1"
                                style={{ display: 'flex', gap: 10 }}
                              >
                                <b>Projected:</b>{' '}
                                {item.revenueSummaryResponse.projected !== 0
                                  ? numberCurrencyDollar(
                                      item.revenueSummaryResponse.projected
                                    )
                                  : 'N/A'}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ display: 'flex', gap: 10 }}
                              >
                                <b>Actual:</b>{' '}
                                {item.revenueSummaryResponse.actual !== 0
                                  ? numberCurrencyDollar(
                                      item.revenueSummaryResponse.actual
                                    )
                                  : 'N/A'}
                              </Typography>
                              <Typography
                                variant="body1"
                                style={{ display: 'flex', gap: 10 }}
                              >
                                <b>Remaining:</b>{' '}
                                {item.revenueSummaryResponse.remaining !== 0
                                  ? numberCurrencyDollar(
                                      item.revenueSummaryResponse.remaining
                                    )
                                  : 'N/A'}
                              </Typography>
                            </RevenueSummaryCell>
                          </Box>
                        </TableRow>
                      </Box>
                    )
                  )
                ) : revenuePeriodQuery ? (
                  <Box style={{ margin: 5 }}>
                    <Typography variant="body1">
                      No records for the selected period
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            )}
            {revenuePeriodQuery !== 0 && (
              <Box>
                <Typography variant="h6">
                  {getQuarterShortName()} Quarter Totals:
                </Typography>
                <Box gap={2.5} style={{ margin: 5 }}>
                  <Typography
                    variant="body1"
                    style={{ display: 'flex', gap: 10 }}
                  >
                    <b>Projected:</b>{' '}
                    {getRevenueSummary?.getRevenueSummary
                      ?.projectedAmountPeriod !== 0
                      ? numberCurrencyDollar(
                          getRevenueSummary?.getRevenueSummary
                            ?.projectedAmountPeriod
                        )
                      : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: 'flex', gap: 10 }}
                  >
                    <b>Actual:</b>{' '}
                    {getRevenueSummary?.getRevenueSummary
                      ?.actualAmountPeriod !== 0
                      ? numberCurrencyDollar(
                          getRevenueSummary?.getRevenueSummary
                            ?.actualAmountPeriod
                        )
                      : 'N/A'}
                  </Typography>
                  <Typography
                    variant="body1"
                    style={{ display: 'flex', gap: 10 }}
                  >
                    <b>Remaining:</b>{' '}
                    {getRevenueSummary?.getRevenueSummary
                      ?.remainingAmountPeriod !== 0
                      ? numberCurrencyDollar(
                          getRevenueSummary?.getRevenueSummary
                            ?.remainingAmountPeriod
                        )
                      : 'N/A'}
                  </Typography>
                </Box>
              </Box>
            )}
          </TableBody>
        </Table>
      </Box>
    </Box>
  )
}
export default QuarterlyRevenueSummary
