import React, { useContext, useMemo, useState } from 'react'
import { Box, Button, Typography, Grid, IconButton } from '@mui/material'
import { Add, Check, Delete, Edit } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import DynamicTable, { cellDate } from 'src/components/DynamicTable'
import {
  useSetBuyerBidAccess,
  useRemoveBuyerBidAccess,
} from 'src/graphql/operations/mutations/businesses'
import {
  BusinessSellerResponseType,
  BuyerBidAccess,
  BuyerBidAccessResponse,
  BuyerBidAccessSummary,
} from 'src/graphql/models/sellerBusiness'
import { useQuery } from '@apollo/client'
import { GET_BUYER_BID_ACCESS } from 'src/graphql/operations/queries/sellerBusiness'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { BoxBreakSpace, BuyerAccessActions } from '../styles'
import BuyerBidAccessForm from './BuyerBidAccessForm'
import { ModalDialog } from 'everchain-uilibrary'

interface BuyerAccessProps {
  sellerId: string
  sellerData: BusinessSellerResponseType
}

const excludeBuyer = 0
const includeBuyer = 1

const BuyerAccess = ({ sellerId, sellerData }: BuyerAccessProps) => {
  const [open, setOpen] = useState(false)
  const ability = useContext(AbilityContext)
  const hasBuyerAccessPermission = ability.can(
    PermissionCodeAccess.CLIENT_BUYER_ACCESS_PERMISSION,
    'any'
  )
  const [openConfirmFlip, setOpenConfirmFlip] = useState(false)
  const [openConfirmRemove, setOpenConfirmRemove] = useState(false)

  const [buyerSelected, setBuyerSelected] = useState<BuyerBidAccess>()

  const { enqueueSnackbar } = useSnackbar()

  const handleBuyerFormToggle = () => {
    setOpen(!open)
  }

  const handleConfirmDialog = () => {
    setBuyerSelected(undefined)
    // setOpenConfirm(!openConfirm)
    setOpen(true)
  }

  const { data: getBuyerBidAccessData, loading: buyerBidAccessLoading } =
    useQuery<BuyerBidAccessResponse>(GET_BUYER_BID_ACCESS, {
      variables: {
        sellerId,
      },
      skip: !sellerId,
    })

  const handleBuyerBidAccessCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Buyers bid access type was changed successfully', {
        variant: 'success',
      })
    }
    setOpenConfirmFlip(false)
  }

  const handleBuyerBidAccessError = (data: any) => {
    if (data) {
      enqueueSnackbar(
        'There was an error while changing buyers bid access type',
        {
          variant: 'error',
        }
      )
    }
    setOpenConfirmFlip(false)
  }

  const handleRemoveBuyerBidAccessCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Buyer access was removed successfully', {
        variant: 'success',
      })
    }
    setOpenConfirmRemove(false)
  }

  const handleRemoveBuyerBidAccessError = (data: any) => {
    if (data) {
      enqueueSnackbar('There was an error while removing buyer bid access', {
        variant: 'error',
      })
    }
    setOpenConfirmRemove(false)
  }

  const { setBuyerBidAccess, loading: loadingBuyerAccess } =
    useSetBuyerBidAccess(
      handleBuyerBidAccessCompleted,
      handleBuyerBidAccessError
    )

  const handleSetBuyerBidAccess = () => {
    setBuyerBidAccess({
      variables: {
        sellerId,
        bidOption,
      },
      refetchQueries: ['GetSeller', 'GetBuyerBidAccess'],
    })
  }

  const { removeBuyerBidAccess, loading: loadingRemoveBuyerBidAccess } =
    useRemoveBuyerBidAccess(
      handleRemoveBuyerBidAccessCompleted,
      handleRemoveBuyerBidAccessError
    )
  const [bidOption, setBidOption] = useState<number>(0)

  const handlBuyerBidEdit = (buyer: BuyerBidAccessSummary) => {
    const buyerRequest = {
      id: buyer.id,
      sellerId,
      buyerBusinessId: buyer.buyerId,
      comment: buyer.comment,
      name: buyer.buyerName,
    }
    setBuyerSelected(buyerRequest)
    setOpen(true)
  }

  const handleCloseFlip = () => {
    setOpenConfirmFlip(false)
  }

  const handleConfirmFlip = () => {
    handleSetBuyerBidAccess()
  }

  const handleCloseRemove = () => {
    setOpenConfirmRemove(false)
  }

  const handleConfirmRemove = () => {
    removeBuyerBidAccess({
      variables: {
        buyerBidAccess: {
          id: buyerSelected?.id,
          sellerId,
          buyerBusinessId: buyerSelected?.buyerBusinessId,
          comment: buyerSelected?.comment,
        },
      },
      refetchQueries: ['GetBuyerBidAccess'],
    })
  }

  // eslint-disable-next-line no-redeclare, react-hooks/exhaustive-deps
  const buyerAccessColumns = [
    { Header: 'Buyer', accessor: 'buyerName' },
    { Header: 'Comment', accessor: 'comment' },
    { Header: 'Start Date', accessor: 'lastUpdated', Cell: cellDate },
    {
      Header: 'Action',
      accessor: '',
      Cell: (props: any) => (
        <Box display="flex" alignItems="center">
          <IconButton
            disabled={!hasBuyerAccessPermission}
            onClick={(): void => handlBuyerBidEdit(props.row.original)}
          >
            <Edit />
          </IconButton>

          <IconButton
            disabled={!hasBuyerAccessPermission}
            onClick={() => {
              const buyerRequest = {
                id: props.row.original.id,
                sellerId,
                buyerBusinessId: props.row.original.buyerId,
                comment: props.row.original.comment,
                name: props.row.original.buyerName,
              }
              setBuyerSelected(buyerRequest)
              setOpenConfirmRemove(true)
            }}
          >
            <Delete />
          </IconButton>
        </Box>
      ),
    },
  ]

  const buyerAccessColumnsMemo = useMemo(
    () => buyerAccessColumns,
    [buyerAccessColumns]
  )

  if (buyerBidAccessLoading) {
    return <></>
  }

  return (
    <div>
      <Box display="flex" justifyContent="flex-end" mb={8}>
        <Button
          disabled={!hasBuyerAccessPermission}
          color="primary"
          variant="contained"
          startIcon={<Add />}
          onClick={handleConfirmDialog}
        >
          Add a Buyer
        </Button>
      </Box>
      <BuyerAccessActions as={Box} my={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={6}>
            <BoxBreakSpace p={4}>
              <Box py={4} display="flex" justifyContent="center">
                <Button
                  color="primary"
                  variant={
                    sellerData.buyerBidAccessOption === excludeBuyer
                      ? 'contained'
                      : 'outlined'
                  }
                  size="medium"
                  onClick={(): void => {
                    setBidOption(0)
                    setOpenConfirmFlip(true)
                  }}
                  disabled={!hasBuyerAccessPermission || loadingBuyerAccess}
                  startIcon={
                    sellerData.buyerBidAccessOption === excludeBuyer && (
                      <Check fontSize="small" />
                    )
                  }
                >
                  Exclude Buyers
                </Button>
              </Box>
              <Typography align="center">
                If selected, the buyers within this list will be restricted from
                the seller’s portfolios. All other buyers will have access.
              </Typography>
            </BoxBreakSpace>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <BoxBreakSpace p={4}>
              <Box py={4} display="flex" justifyContent="center">
                <Button
                  color="primary"
                  variant={
                    sellerData.buyerBidAccessOption === includeBuyer
                      ? 'contained'
                      : 'outlined'
                  }
                  size="medium"
                  onClick={(): void => {
                    setBidOption(1)
                    setOpenConfirmFlip(true)
                  }}
                  disabled={!hasBuyerAccessPermission || loadingBuyerAccess}
                  startIcon={
                    sellerData.buyerBidAccessOption === includeBuyer && (
                      <Check fontSize="small" />
                    )
                  }
                >
                  Include Buyers
                </Button>
              </Box>
              <Typography align="center">
                If selected, the buyers within this list will have access to the
                seller’s portfolios. All other buyers will be restricted.
              </Typography>
            </BoxBreakSpace>
          </Grid>
        </Grid>
      </BuyerAccessActions>
      <DynamicTable
        id="tbBuyerBidAccess"
        data={getBuyerBidAccessData?.getBuyerBidAccess.buyersInList || []}
        columns={buyerAccessColumnsMemo}
        loading={buyerBidAccessLoading}
        pageSize={25}
      />
      <BuyerBidAccessForm
        open={open}
        sellerId={sellerId}
        onClose={handleBuyerFormToggle}
        buyersOutOfList={
          buyerSelected
            ? []
            : getBuyerBidAccessData?.getBuyerBidAccess.buyersOutOfList
        }
        loading={buyerBidAccessLoading}
        buyerData={buyerSelected}
      />
      <ModalDialog
        isOpen={openConfirmFlip}
        header={bidOption === 0 ? 'Exclude Buyer' : 'Include Buyer'}
        onClose={handleCloseFlip}
        disableOkButton={loadingBuyerAccess}
        isFetching={loadingBuyerAccess}
        buttonOkText="Confirm"
        onContinue={handleConfirmFlip}
      >
        <Box mb={4}>
          <Typography align="center">
            {bidOption === 0
              ? `
            Changing From Include to Exclude will affect only future auctions.
            Currently open auctions will not be affected.
            The existing list of included buyers, if any, will be removed.`
              : `Changing From Exclude to Include will affect only future auctions.
            Currently open auctions will not be affected.
            The existing list of excluded buyers, if any, will be removed.`}
          </Typography>
        </Box>
      </ModalDialog>
      <ModalDialog
        isOpen={openConfirmRemove}
        header="Remove Buyer"
        onClose={handleCloseRemove}
        disableOkButton={loadingRemoveBuyerBidAccess}
        isFetching={loadingRemoveBuyerBidAccess}
        onContinue={handleConfirmRemove}
        buttonOkText="Confirm"
      >
        <Box mb={4}>
          <Typography align="center">
            {buyerSelected?.name} will be removed from future auctions only. It
            will NOT be removed from currently open auctions
          </Typography>
        </Box>
      </ModalDialog>
    </div>
  )
}

export default BuyerAccess
