import React, { useReducer, useEffect, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
} from '@mui/material'
import { useQuery } from '@apollo/client'

import DrawerRight from 'src/components/DrawerRight'
import { GetRolesOutUser } from 'src/graphql/models/managePermissions'
import { GET_ROLES_OUT_USER } from 'src/graphql/operations/queries/managePermissions'
import { useAddUserRole } from 'src/graphql/operations/mutations/managePermissions'
import { useSnackbar } from 'notistack'

interface RoleToUserFormProps {
  open: boolean
  onClose: () => void
  userId: string
}

const initialRoles = {
  roles: [],
}

const roleFormReduce = (
  state: { [x: string]: any },
  action: { [x: string]: any }
) => ({
  ...state,
  ...action,
})

const RoleToUserForm = ({ open, onClose, userId }: RoleToUserFormProps) => {
  const [roleForm, setRoleForm] = useReducer(roleFormReduce, initialRoles)
  const [openAutoComple, setOpenAutoComple] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { data: rolesOutUserData, loading: rolesOutUserLoading } =
    useQuery<GetRolesOutUser>(GET_ROLES_OUT_USER, {
      fetchPolicy: 'cache-and-network',
      variables: {
        roleListRequest: {
          userIdFilter: userId,
        },
      },
    })

  const addUserRoleCompleted = (): void => {
    enqueueSnackbar('Roles added with success', {
      variant: 'success',
    })
    onClose()
  }

  const {
    addUserRole,
    data: roleMemberData,
    loading: addUserRoleLoading,
    error: addUserRoleError,
  } = useAddUserRole(addUserRoleCompleted)

  const rolesOutUser = rolesOutUserData?.getRolesOutUser

  useEffect(() => {
    if (!open) {
      setRoleForm({
        roles: [],
      })
    }
  }, [open])

  useEffect(() => {
    if (addUserRoleError && !roleMemberData) {
      enqueueSnackbar('There was an error, try again later', {
        variant: 'error',
      })
    }
  }, [addUserRoleError, roleMemberData, enqueueSnackbar])

  return (
    <DrawerRight
      title="Add role to user"
      open={open}
      onClose={onClose}
      width={330}
    >
      <Box px={4} py={6} component="div" display="flex" flexDirection="column">
        <Box component="div">
          <Autocomplete
            open={openAutoComple}
            multiple
            onOpen={(): void => setOpenAutoComple(true)}
            onClose={(): void => setOpenAutoComple(false)}
            options={rolesOutUser?.roles || []}
            getOptionLabel={(option: any) => option.name}
            loading={rolesOutUserLoading}
            onChange={(ev, values) => {
              setRoleForm({
                roles: values,
              })
            }}
            renderInput={(params: any) => (
              <TextField
                {...params}
                variant="standard"
                label="Roles"
                placeholder="Select roles"
                margin="normal"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {rolesOutUserLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </Box>
        <Box mt={4} mb={3} display="flex" flex={1} justifyContent="flex-end">
          <Button onClick={onClose}>Cancel</Button>
          <Box mr={2} />
          <Button
            variant="contained"
            color="primary"
            disabled={addUserRoleLoading}
            onClick={() => {
              if (roleForm.roles && roleForm.roles.length) {
                const roleIds = roleForm.roles.map((item: any) => item.id)
                addUserRole({
                  variables: {
                    userId,
                    roleIds,
                  },
                  refetchQueries: ['GetRolesInUser'],
                })
              }
            }}
            startIcon={
              addUserRoleLoading && (
                <CircularProgress color="primary" size={16} />
              )
            }
          >
            Add
          </Button>
        </Box>
      </Box>
    </DrawerRight>
  )
}

export default RoleToUserForm
