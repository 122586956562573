/* eslint-disable react/require-default-props */
import React, { useContext } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'

import { AuthContext } from 'src/context/AuthenticationContext'
import LoaderPage from '../LoaderPage'

interface PrivitedRouteProps extends RouteProps {
  children: React.ReactElement
  hasPermission?: boolean
}

const PrivitedRoute: React.FC<PrivitedRouteProps> = ({
  children,
  path,
  exact,
  hasPermission,
  ...rest
}: PrivitedRouteProps) => {
  const { isAuth, loadedPermission } = useContext<any>(AuthContext)

  if (isAuth() && !loadedPermission) return <LoaderPage />
  return (
    <Route
      {...rest}
      path={path}
      exact={exact}
      render={(): React.ReactNode => {
        if (isAuth()) {
          if (hasPermission != null && hasPermission === false) {
            return <Redirect to="/accessdenied" />
          }
          return children
        }
        return <Redirect to="/" />
      }}
    />
  )
}

export default PrivitedRoute
