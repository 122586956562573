import React from 'react'
import { dynamicColors, splitName } from 'src/utils/common'
import { Avatar, Box, Typography } from '@mui/material'

interface AvatarName {
  title?: string
}

// eslint-disable-next-line @typescript-eslint/no-redeclare, no-redeclare
const AvatarName = ({ title }: AvatarName) => {
  return (
    <Box display="flex" alignItems="center">
      {!!title && (
        <Avatar
          style={{
            backgroundColor: dynamicColors({
              min: 0,
              max: 190,
              opacity: 1,
            }),
          }}
        >
          {splitName(title)}
        </Avatar>
      )}
      <Box ml={2} display="flex" alignItems="center">
        <Typography>{title}</Typography>
      </Box>
    </Box>
  )
}

AvatarName.defaultProps = {
  title: '',
}

export default AvatarName
