import { gql, useMutation, MutationUpdaterFn } from '@apollo/client'

export const ADD_BUSINESS_LICENSE = gql`
  mutation addBusinessLicense($request: LicenseRequest!) {
    addBusinessLicense(request: $request) {
      id
    }
  }
`

export function useAddBusinessLicense(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [AddLicense, { data, error, loading }] = useMutation(
    ADD_BUSINESS_LICENSE,
    {
      update,
      onCompleted,
    }
  )
  return { AddLicense, data, error, loading }
}

export const DELETE_BUSINESS_LICENSE = gql`
  mutation deleteBusinessLicense($request: Long!) {
    deleteBusinessLicense(request: $request)
  }
`

export function useDeleteBusinessLicense(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [DeleteLicense, { data, error, loading }] = useMutation(
    DELETE_BUSINESS_LICENSE,
    {
      update,
      onCompleted,
    }
  )
  return { DeleteLicense, data, error, loading }
}

export const EDIT_BUSINESS_LICENSE = gql`
  mutation editBusinessLicense($request: LicenseRequest!) {
    editBusinessLicense(request: $request) {
      id
    }
  }
`

export function useEditBusinessLicense(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [EditLicense, { data, error, loading }] = useMutation(
    EDIT_BUSINESS_LICENSE,
    {
      update,
      onCompleted,
    }
  )
  return { EditLicense, data, error, loading }
}
