import styled from 'styled-components'

export const BuilderForm = styled.div`
  .MuiAccordion-root.MuiAccordion-root {
    box-shadow: none;
  }
  .MuiAccordionDetails-root.MuiAccordionDetails-root {
    flex-direction: column;
    align-items: center;
    background-color: rgb(240, 240, 240);
  }
  .MuiAccordionDetails-root.MuiAccordionDetails-root {
    > .accordion-box-detail {
      background-color: #fff;
      margin: 8px;
      border-radius: 3px;
    }
  }
`
