import React from 'react'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import { Button } from '@mui/material'

interface ExportExcelButtonProps {
  fileName: string
  data: any
  disabled?: boolean
}

const ExportExcelButton: React.FC<ExportExcelButtonProps> = ({
  fileName,
  data,
  disabled,
}: ExportExcelButtonProps) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data)

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const excelData = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(excelData, fileName + fileExtension)
  }

  return (
    <Button
      disabled={disabled}
      onClick={exportToCSV}
      variant="contained"
      color="primary"
    >
      Export to Excel
    </Button>
  )
}
ExportExcelButton.defaultProps = {
  disabled: false,
}

export default ExportExcelButton
