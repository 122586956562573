import React, { useState } from 'react'
import { Box, Grid, TextField } from '@mui/material'

import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { Button, Paper } from 'everchain-uilibrary'
import { useHistory, useParams } from 'react-router-dom'
import MultipleSelectionDropDown from '../MultipleSelectionDropDown'
import { AssetType } from 'src/graphql/models/businessRecovery'
import { getAssetTypes } from 'src/infra/api/services/client'
import { useFormik } from 'formik'
import { useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import * as Yup from 'yup'
import Header from '../Header'
import { saveMediaNamingConvention } from 'src/infra/api/services/mediaNamingConvention'
import { SELLER_BUSINESS_MANAGE } from 'src/routes'

const MediaNamingConventionForm = () => {
  const initialValues = {
    name: '',
    assetTypes: '',
    namingConvetion: '',
  }
  const commentValidationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    namingConvention: Yup.string().required('Required'),
    assetTypes: Yup.array().of(Yup.string()).required('Required'),
  })
  const { sellerId, clientId, tabSelected } = useParams<any>()

  const { data: assetType, isFetching: assetTypeLoading } = useCustomQuery<
    AssetType[]
  >(
    ['getAssetType'],
    async () => {
      return getAssetTypes().then((result: AssetType[]) => {
        return result
      })
    },
    {
      enabled: true,
      cacheTime: 0,
    }
  )

  const history = useHistory()
  const mediaNamingConventionForm = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    isInitialValid: false,
    validationSchema: commentValidationSchema,
    onSubmit: (values: any) => {
      const dbListAssetType = assetType ?? []
      const selectedAssetTypeIds =
        assetTypeSelected.length > 0
          ? dbListAssetType
              .filter((asset) => assetTypeSelected.includes(asset.name))
              .map((asset) => asset.id.toString())
          : dbListAssetType.map((asset) => asset.id.toString())

      saveMediaNamingConventionMutation.mutate({
        name: values.name,
        namingConvention: values.namingConvention,
        assetTypes: selectedAssetTypeIds,
      })
    },
  })
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const saveMediaNamingConventionMutation = useMutation({
    mutationFn: (request: any) =>
      saveMediaNamingConvention(
        sellerId,
        request.name,
        request.namingConvention,
        request.assetTypes
      ),
    onSuccess: (data: any) => {
      history.push(
        `${SELLER_BUSINESS_MANAGE}/${clientId}/${sellerId}/tab=${tabSelected}`
      )
      setIsLoading(false)
      enqueueSnackbar('Naming Convention created successfully.', notifySuccess)
    },
    onError: (error: any) => {
      if (error?.data?.type === 'validationError')
        enqueueSnackbar(error?.data?.detail?.trim(), notifyError)
      else
        enqueueSnackbar('Error when creating Naming Convention .', notifyError)
      setIsLoading(false)
    },
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [assetTypeSelected, setAssetTypeSelected] = useState<string[]>([])

  const renderAssetTypeOption = () => {
    const assetTypes =
      assetType != null ? assetType?.map((x: AssetType) => x.name) : []

    const handleChange = (value: any, event: any) => {
      if (
        event === 'AllSelect' &&
        value.filter((item: any) => item !== 'All').length ===
          assetTypeSelected.length
      ) {
        setAssetTypeSelected([])
        mediaNamingConventionForm.setFieldValue('assetTypes', [])
      } else if (
        event === 'AllSelect' &&
        value.length > assetTypeSelected.length
      ) {
        const items = value.filter((item: any) => item !== 'All')
        setAssetTypeSelected(items)
        mediaNamingConventionForm.setFieldValue('assetTypes', items)
      } else if (event !== 'AllSelect') {
        if (assetTypeSelected.includes('All') && value.length > 1) {
          const items = value.filter((item: any) => item !== 'All')
          setAssetTypeSelected(items)
          mediaNamingConventionForm.setFieldValue(' assetTypes', items)
        } else if (value.includes('All')) {
          setAssetTypeSelected(['All'])
          mediaNamingConventionForm.setFieldValue('assetTypes', ['All'])
        } else {
          setAssetTypeSelected(value)
          mediaNamingConventionForm.setFieldValue('assetTypes', value)
        }
      }
    }
    return (
      <MultipleSelectionDropDown
        label="Asset Type"
        selectionState={assetTypeSelected}
        handleSelectionChange={handleChange}
        disable={assetTypeLoading}
        data={assetTypes}
        maxWidth="300px"
        minWidth="300px"
      />
    )
  }

  return (
    <form onSubmit={mediaNamingConventionForm.handleSubmit}>
      <Box p={5}>
        <Header hideAvatar title={'Create a new Media Naming Convention'} />
      </Box>
      <Box p={5}>
        <Paper style={{ padding: 15 }} id="media-naming-convention">
          <Grid mt={2} container spacing={4}>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                onChange={mediaNamingConventionForm.handleChange}
                value={mediaNamingConventionForm.values.name}
              ></TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                label="Naming Convention"
                name="namingConvention"
                onChange={mediaNamingConventionForm.handleChange}
                value={mediaNamingConventionForm.values.namingConvention}
              ></TextField>
            </Grid>
            <Grid item xs={12} lg={3}>
              {renderAssetTypeOption()}
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                onClick={(e: any) => {
                  e.preventDefault()
                  setIsLoading(true)
                  mediaNamingConventionForm.handleSubmit()
                }}
                disabled={!mediaNamingConventionForm.isValid || isLoading}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </form>
  )
}

MediaNamingConventionForm.defaultProps = {
  sellerId: null,
}
export default MediaNamingConventionForm
