import { httpClient } from '../../axios-wrapper/httpClient'
import { AuditResponse } from '../../models/audit'
import * as urls from '../../urls/audit'

export const getCustomerChanges = async (
  id: string,
  tableName: 'client' | 'business' | undefined,
  kendoPagination: string
): Promise<AuditResponse> => {
  const response = await httpClient.get(urls.AuditGetCustomerChanges, {
    params: { id, tableName, kendoPagination },
  })
  return response?.data
}
