import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import {
  Button,
  Colors,
  DataTable,
  Flex,
  Icon,
  IconButton,
  Switch,
  Typography,
  renderCurrency,
  renderDate,
  renderYesOrNoBoolean,
} from 'everchain-uilibrary'
import {
  getBusinessFee,
  updateBusinessFeeEnabled,
} from 'src/infra/api/services/business'
import {
  BusinessFee,
  UpdateBusinessFeeEnabledRequest,
} from 'src/infra/api/models/business'
import { renderPercent } from 'src/utils/formatKendoColumns'
import BusinessFeeAddOrEditModal from './BusinessFeeAddOrEditModal'
import { enqueueSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import { notistackOptions } from 'src/configs/notistackOptions'
import BusinessFeeDeleteModal from './BusinessFeeDeleteModal'

interface BusinessFeeListProps {
  businessId?: string
  businessFeeEnabled?: boolean
  feeEnabledLoading?: boolean
}

const BusinessFeeList: React.FC<BusinessFeeListProps> = ({
  businessId,
  businessFeeEnabled,
  feeEnabledLoading,
}) => {
  const [openCreateOrEditModal, setOpenCreateOrEditModal] =
    useState<boolean>(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [actionBusinessFee, setActionBusinessFee] = useState<BusinessFee>()
  const [updateBusinessFeeEnabledRequest, setUpdateBusinessFeeEnabledRequest] =
    useState<UpdateBusinessFeeEnabledRequest>()

  const reactQueryClient = useQueryClient()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const gridColumns: any[] = [
    {
      field: 'portfolioTypeName',
      title: 'Portfolio Type Name',
      width: 140,
      show: true,
    },
    {
      field: 'feePercent',
      title: 'Fee %',
      width: 70,
      show: true,
      render: renderPercent,
    },
    {
      field: 'forwardFlowFeePercent',
      title: 'FF Fee %',
      width: 75,
      show: true,
      render: renderPercent,
    },
    {
      field: 'minimumFee',
      title: 'Minimum Fee',
      width: 90,
      show: true,
      render: renderCurrency,
    },
    {
      field: 'enforceMinimumFee',
      title: 'Enforce Minimum Fee',
      width: 90,
      show: true,
      render: renderYesOrNoBoolean,
    },
    {
      field: 'createdByUserName',
      title: 'Created By',
      width: 90,
      show: true,
    },
    {
      field: 'createdDateUtc',
      title: 'Created at',
      width: 90,
      show: true,
      render: renderDate,
    },
    {
      field: 'lastUpdatedByUserName',
      title: 'Updated By',
      width: 90,
      show: true,
    },
    {
      field: 'lastUpdatedDateUtc',
      title: 'Updated at',
      width: 90,
      show: true,
      render: renderDate,
    },
    {
      title: 'Delete',
      width: 50,
      show: true,
      render: (props: any) => (
        <td>
          <Flex>
            <IconButton
              onClick={() => {
                setActionBusinessFee(
                  businessFeeData?.find((x) => x.id === props.dataItem.id)
                )
                setOpenDeleteModal(true)
              }}
            >
              <Icon name="Delete" color={Colors.primary} />
            </IconButton>
          </Flex>
        </td>
      ),
    },
  ]

  const { data: businessFeeData, isFetching: businessFeeDataLoading } =
    useCustomQuery<BusinessFee[]>(
      ['getBusinessFee', businessId],
      async () => {
        return getBusinessFee(businessId)
      },
      { enabled: !!businessId, cacheTime: 0 }
    )

  const { isFetching: updateFeeEnabledLoading } = useCustomQuery<void>(
    ['updateBusinessFeeEnabled', updateBusinessFeeEnabledRequest],
    async () => {
      return updateBusinessFeeEnabled(updateBusinessFeeEnabledRequest!)
        .then(() => {
          enqueueSnackbar('Service fee updated.', notifySuccess)
          reactQueryClient.refetchQueries({
            queryKey: ['getBusinessFeeEnabled'],
          })
          return
        })
        .catch(() => {
          enqueueSnackbar('Error when updating the service fee.', notifyError)
        })
        .finally(() => {
          setUpdateBusinessFeeEnabledRequest(undefined)
        })
    },
    { enabled: !!updateBusinessFeeEnabledRequest, cacheTime: 0 }
  )

  const handleUpdateBusinessFeeEnabled = () => {
    setUpdateBusinessFeeEnabledRequest({
      businessId: businessId!,
      businessFeeEnabled: !businessFeeEnabled,
    })
  }

  return (
    <Box mb={12}>
      <Typography variant="h6">Service Fee</Typography>
      <Flex
        width="100%"
        justifyContent="end"
        alignItems="center"
        style={{ gap: 15 }}
      >
        <Switch
          label="Enable Service Fee"
          checked={businessFeeEnabled}
          onClick={handleUpdateBusinessFeeEnabled}
          isLoading={feeEnabledLoading || updateFeeEnabledLoading}
          color={Colors.black}
          skeletonHeight={20}
          skeletonWidth={150}
        />
        <Button onClick={() => setOpenCreateOrEditModal(true)}>
          Add Service Fee
        </Button>
      </Flex>
      <Grid pt={2} container spacing={4}>
        <Grid item xs={12} lg={12}>
          <DataTable
            style={{
              cursor: 'pointer',
            }}
            isLoading={businessFeeDataLoading}
            isFetching={businessFeeDataLoading}
            gridColumns={gridColumns}
            data={businessFeeData}
            onRowClick={(row) => {
              setActionBusinessFee(
                businessFeeData?.find((x) => x.id === row.dataItem.id)
              )
              setOpenCreateOrEditModal(true)
            }}
          />
        </Grid>
      </Grid>
      <BusinessFeeAddOrEditModal
        businessId={businessId}
        businessFee={actionBusinessFee}
        allBusinessFee={businessFeeData}
        modalOpen={openCreateOrEditModal}
        onCloseModal={() => {
          setOpenCreateOrEditModal(false)
          setActionBusinessFee(undefined)
        }}
      />
      <BusinessFeeDeleteModal
        businessFee={actionBusinessFee}
        modalOpen={openDeleteModal}
        onCloseModal={() => {
          setOpenDeleteModal(false)
          setActionBusinessFee(undefined)
        }}
      />
    </Box>
  )
}

export default BusinessFeeList
