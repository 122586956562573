import { gql, useMutation, MutationUpdaterFn } from '@apollo/client'

interface Params {
  onCompleted?: (data: any) => void
  onError?: (data: any) => void
}

export const SEND_USER_INVITE = gql`
  mutation SendUserInvite($id: String!) {
    sendUserInvite(id: $id)
  }
`

export function useSendUserInvite(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [SendUserInvite, { data, error, loading }] = useMutation(
    SEND_USER_INVITE,
    {
      update,
      onCompleted,
    }
  )
  return { SendUserInvite, data, error, loading }
}

export const UNLOCK_USER = gql`
  mutation UnlockUser($userId: String!) {
    unlockUser(userId: $userId)
  }
`
export function useUnlockUser(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [UnlockUser, { data, error, loading }] = useMutation(UNLOCK_USER, {
    update,
    onCompleted,
  })
  return { UnlockUser, data, error, loading }
}

export const ADD_COMMENT_CLIENT_PROFILE = gql`
  mutation AddUpdateComment($comment: CommentRequest!) {
    addUpdateComment(comment: $comment) {
      id
      comment
    }
  }
`

export function useAddCommentClientProfile(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [AddComment, { data, error, loading }] = useMutation(
    ADD_COMMENT_CLIENT_PROFILE,
    {
      update,
      onCompleted,
    }
  )
  return { AddComment, data, error, loading }
}

export const ADD_OR_UPDATE_CLIENT = gql`
  mutation addOrUpdateClient($client: ClientRequestType!) {
    addOrUpdateClient(client: $client) {
      id
      name
    }
  }
`
export function useAddOrUpdateClient(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addOrUpdateClient, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_CLIENT,
    {
      update,
      onCompleted,
    }
  )
  return { addOrUpdateClient, data, error, loading }
}

export const ADD_OR_UPDATE_BANK_ACCOUNT = gql`
  mutation addOrUpdateBankAccount($request: ClientBankAccountRequest!) {
    addOrUpdateBankAccount(request: $request) {
      id
      bankName
    }
  }
`

export function useAddOrUpdateBankAccount(
  { onCompleted, onError }: Params,
  update?: MutationUpdaterFn
) {
  const [addOrUpdateBankAccount, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_BANK_ACCOUNT,
    {
      update,
      onCompleted,
      onError,
    }
  )

  return { addOrUpdateBankAccount, data, error, loading }
}

export const ADD_APPROVED_AGENCIES = gql`
  mutation AddApprovedAgencies($approvedAgencies: ApprovedAgencyRequest!) {
    addApprovedAgencies(approvedAgencies: $approvedAgencies) {
      id
      name
      approvedAgencyBusinessId
      clientId
    }
  }
`
export function useAddApprovedAgencies(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addApprovedAgencies, { data, error, loading }] = useMutation(
    ADD_APPROVED_AGENCIES,
    {
      update,
      onCompleted,
    }
  )

  return { addApprovedAgencies, data, error, loading }
}

export const ADD_CLIENT_ASSOCIATON = gql`
  mutation addClientAssociation($clientAssociation: ClientAssociationRequest!) {
    addClientAssociation(clientAssociation: $clientAssociation) {
      id
      name
    }
  }
`

export function useAddClientAssociation(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addClientAssociation, { data, error, loading }] = useMutation(
    ADD_CLIENT_ASSOCIATON,
    {
      update,
      onCompleted,
    }
  )

  return { addClientAssociation, data, error, loading }
}

export const ADD_UPDATE_PLACEMENT_SETTING = gql`
  mutation AddOrUpdatePlacementSetting($request: PlacementSettingsRequest!) {
    addOrUpdatePlacementSetting(request: $request) {
      id
      clientId
      enabled
      dormantAccountNotificationEnabled
      dormantAccountPercentage
      dormantAccountLenght
    }
  }
`

export function useAddOrUpdatePlacementSettings(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addOrUpdatePlacementSettings, { data, error, loading }] = useMutation(
    ADD_UPDATE_PLACEMENT_SETTING,
    {
      update,
      onCompleted,
    }
  )

  return { addOrUpdatePlacementSettings, data, error, loading }
}

export const REMOVE_APPROVED_AGENCIES = gql`
  mutation removeApprovedAgencies($approvedAgencies: ApprovedAgencyRequest!) {
    removeApprovedAgencies(approvedAgencies: $approvedAgencies) {
      id
      name
      approvedAgencyBusinessId
      clientId
    }
  }
`
export function useRemoveApprovedAgencies(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeApprovedAgencies, { data, error, loading }] = useMutation(
    REMOVE_APPROVED_AGENCIES,
    {
      update,
      onCompleted,
    }
  )

  return { removeApprovedAgencies, data, error, loading }
}

export const REMOVE_CLIENT_ASSOCIATON = gql`
  mutation removeClientAssociation(
    $clientAssociation: ClientAssociationRequest!
  ) {
    removeClientAssociation(clientAssociation: $clientAssociation) {
      id
      name
    }
  }
`

export function useRemoveClientAssociation(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [removeClientAssociation, { data, error, loading }] = useMutation(
    REMOVE_CLIENT_ASSOCIATON,
    {
      update,
      onCompleted,
    }
  )

  return { removeClientAssociation, data, error, loading }
}

export const ENABLE_WHITELIST = gql`
  mutation enableWhitelist($clientId: Long!, $enable: Boolean!) {
    enableWhitelist(clientId: $clientId, enable: $enable)
  }
`

export function useEnableWhitelist({ onCompleted, onError }: Params) {
  const [enableWhitelist, { data, error, loading }] = useMutation(
    ENABLE_WHITELIST,
    {
      onCompleted,
      onError,
    }
  )

  return { enableWhitelist, data, error, loading }
}

export const ADD_IP_ADDRESS_WHITELIST = gql`
  mutation AddIpAddressToWhitelist($addRequest: AddIpAddressRequest!) {
    addIpAddressToWhitelist(addRequest: $addRequest)
  }
`

export function useAddIpAddressToWhitelist({ onCompleted, onError }: Params) {
  const [addIpAddressToWhitelist, { data, error, loading }] = useMutation(
    ADD_IP_ADDRESS_WHITELIST,
    {
      onCompleted,
      onError,
    }
  )

  return { addIpAddressToWhitelist, data, error, loading }
}

export const DELETE_IP_ADDRESS_WHITELIST = gql`
  mutation DeleteIpAddressOnWhitelist($id: Int!) {
    deleteIpAddressOnWhitelist(id: $id)
  }
`

export function useDeleteIpAddressOnWhitelist({
  onCompleted,
  onError,
}: Params) {
  const [deleteIpAddressOnWhitelist, { data, error, loading }] = useMutation(
    DELETE_IP_ADDRESS_WHITELIST,
    {
      onCompleted,
      onError,
    }
  )

  return { deleteIpAddressOnWhitelist, data, error, loading }
}

export const ADD_USER_NOTIFICATION_TEMPLATE = gql`
  mutation AddUserNotificationTemplate(
    $addRequest: AddNotificationTemplateRequest!
  ) {
    addNotificationTemplate(addeRequest: $addRequest)
  }
`
export function useAddUserNotificationTemplate(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [addUserNotificationTemplate, { data, error, loading }] = useMutation(
    ADD_USER_NOTIFICATION_TEMPLATE,
    {
      update,
      onCompleted,
    }
  )

  return { addUserNotificationTemplate, data, error, loading }
}

export const CHANGE_CLIENT_NAME = gql`
  mutation ChangeClientName($clientId: Long!, $newName: String!) {
    changeClientName(clientId: $clientId, newName: $newName)
  }
`

export function useChangeClientName(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [changeClientName, { data, error, loading }] = useMutation(
    CHANGE_CLIENT_NAME,
    {
      update,
      onCompleted,
    }
  )
  return { changeClientName, data, error, loading }
}
