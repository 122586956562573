import { gql, useMutation, MutationUpdaterFn } from '@apollo/client'

export const ADD_OR_UPDATE_ROLES = gql`
  mutation AddOrUpdateRole($role: RoleRequest!) {
    addOrUpdateRole(role: $role) {
      id
      name
      description
      clientName
      numberOfMembers
      tenantId
    }
  }
`

export function useAddOrUpdateRole(onCompleted?: (data?: any) => void) {
  const [addOrUpdateRole, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_ROLES,
    {
      onCompleted,
    }
  )

  return { addOrUpdateRole, data, error, loading }
}

export const DELETE_ROLE_MEMBER = gql`
  mutation DeleteRoleMember($roleId: String!, $userIds: [String]!) {
    deleteRoleMember(roleId: $roleId, userIds: $userIds)
  }
`

export function useDeleteRoleMember(onCompleted?: (data: any) => void) {
  const [deleteRoleMember, { data, error, loading }] = useMutation(
    DELETE_ROLE_MEMBER,
    {
      onCompleted,
    }
  )

  return { deleteRoleMember, data, error, loading }
}

export const ADD_ROLE_MEMBER = gql`
  mutation AddRoleMember($roleId: String!, $userIds: [String]!) {
    addRoleMember(roleId: $roleId, userIds: $userIds)
  }
`

export function useAddRoleMember(onCompleted?: (data: any) => void) {
  const [addRoleMember, { data, error, loading }] = useMutation(
    ADD_ROLE_MEMBER,
    {
      onCompleted,
    }
  )

  return { addRoleMember, data, error, loading }
}

export const ADD_USER_ROLE = gql`
  mutation addUserRole($userId: String!, $roleIds: [String]!) {
    addUserRole(userId: $userId, roleIds: $roleIds)
  }
`

export function useAddUserRole(onCompleted?: (data: any) => void) {
  const [addUserRole, { data, error, loading }] = useMutation(ADD_USER_ROLE, {
    onCompleted,
  })

  return { addUserRole, data, error, loading }
}

export const DELETE_USER_ROLE = gql`
  mutation deleteUserRole($userId: String!, $roleIds: [String]!) {
    deleteUserRole(userId: $userId, roleIds: $roleIds)
  }
`

export function useDeleteUserRole(onCompleted?: (data: any) => void) {
  const [deleteUserRole, { data, error, loading }] = useMutation(
    DELETE_USER_ROLE,
    {
      onCompleted,
    }
  )

  return { deleteUserRole, data, error, loading }
}

export const REMOVE_ROLE = gql`
  mutation RemoveRole($roleId: String!) {
    removeRole(roleId: $roleId)
  }
`

export function useRemoveRole(onCompleted?: (data: any) => void) {
  const [removeRole, { data, error, loading }] = useMutation(REMOVE_ROLE, {
    onCompleted,
  })
  return { removeRole, data, error, loading }
}

export const UPDATE_PERMISSION = gql`
  mutation UpdatePermission(
    $updatePermissionRequest: UpdatePermissionRequestType!
  ) {
    updatePermission(updatePermissionRequest: $updatePermissionRequest) {
      permissionCode
      entityId
      permissionValue
      permissionType
      permissionCodeDescription
    }
  }
`

export function useUpdatePermission(
  onCompleted?: (data: any) => void,
  update?: MutationUpdaterFn
) {
  const [updatePermission, { data, error, loading }] = useMutation(
    UPDATE_PERMISSION,
    {
      update,
      onCompleted,
    }
  )
  return { updatePermission, data, error, loading }
}
