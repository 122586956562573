import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  TextField,
  Box,
  Typography,
  Divider,
  Button,
  IconButton,
  Grid,
} from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { useLazyQuery, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import {
  GET_NOTIFICATION_DETAIL,
  GET_NOTIFICATION_SUMMARY,
} from 'src/graphql/operations/queries/business'
import {
  useUpdateNotificationSubscription,
  useDeleteBusinessEmailNotifications,
} from 'src/graphql/operations/mutations/businesses'

import {
  NotificationSummaryResponse,
  GetNotificationDetail,
  formatBusinessNotificationGroupFields,
} from 'src/graphql/models/business'
import { notistackOptions } from 'src/configs/notistackOptions'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { emailValidate } from 'src/utils/text'
import ManageNotifications from 'src/pages/SellerBusinessManage/components/ManageNotifications'
import NotificationRecipientDropdown from 'src/components/Dropdown/NotificationRecipientDropdown'
import { ModalDialog } from 'everchain-uilibrary'

interface BuyerNotificationInterface {
  businessId: string
}
interface DropDownBusinessItem {
  id: any
  name: string
  email: string
}
const BuyerNotification = ({ businessId }: BuyerNotificationInterface) => {
  const [formNotification, setFormNotification] = useState<{
    userIdNotification: string
    userRecipient: string
    emailAddress: string
    notificationType: string
  }>({
    userIdNotification: '',
    userRecipient: '',
    emailAddress: '',
    notificationType: 'External',
  })
  const notistackSucces = notistackOptions('success')
  const notistackWarning = notistackOptions('warning')
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false)
  const [emailToDelete, setEmailToDelete] = useState('')
  const [canDeleteEmail, setCanDeleteEmail] = useState(false)
  const [typeNotificationDetail, setTypeNotificationDetail] = useState<any>()
  const [groupedNotification, setGroupedNotification] = useState<any[]>([])
  const [addUserToggle, setAddUserToggle] = useState(false)
  const savingEmail = useRef(false)
  const [dropDownBusinessItems, setDropDownBusinessItems] = useState<
    DropDownBusinessItem[]
  >([])
  const { enqueueSnackbar } = useSnackbar()
  const ability = useContext(AbilityContext)
  const hasBusinessNotificationPermission = ability.can(
    PermissionCodeAccess.CLIENT_BUSINESS_NOTIFICATION_PERMISSION,
    'any'
  )

  const {
    data: getNotificationSummaryData,
    loading: notificationSummaryLoading,
  } = useQuery<NotificationSummaryResponse>(GET_NOTIFICATION_SUMMARY, {
    variables: {
      businessId,
    },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      const emails =
        getNotificationSummaryData?.getNotificationSummary.listEmails.map(
          function getEmail(email) {
            const container: DropDownBusinessItem = {
              name: '',
              email: '',
              id: '',
            }

            container.email = email
            container.name = email
            return container
          }
        )

      const users =
        getNotificationSummaryData?.getNotificationSummary.listUsers.map(
          function getUser(user) {
            const container: DropDownBusinessItem = {
              name: '',
              email: '',
              id: '',
            }

            container.email = user.emailAddress
            container.name = user.firstName
            container.id = user.id
            return container
          }
        )
      if (emails && users) setDropDownBusinessItems(users.concat(emails))
      else if (users) setDropDownBusinessItems(users)
      else setDropDownBusinessItems(emails ?? [])
    },
  })

  const [
    getNotificationDetail,
    { data: getNotificationDetailData, loading: notificationDetailLoading },
  ] = useLazyQuery<GetNotificationDetail>(GET_NOTIFICATION_DETAIL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (
        typeNotificationDetail === 'userIdType' &&
        getNotificationDetailData?.getNotificationDetail.length &&
        !notificationDetailLoading
      ) {
        setGroupedNotification(
          formatBusinessNotificationGroupFields(
            getNotificationDetailData.getNotificationDetail
          )
        )
      }
    },
  })

  const setValuesNotification = (name: any, value: any) => {
    setFormNotification((prevState: any) => ({
      ...prevState,
      [name]: value,
    }))
    if (dropDownBusinessItems?.find((x) => x.id === value)) {
      setCanDeleteEmail(false)
      getNotificationDetail({
        variables: {
          request: {
            businessIdFilter: businessId,
            userIdFilter: value,
          },
        },
      })
      setTypeNotificationDetail('userIdType')
    } else if (dropDownBusinessItems?.find((x) => x.email === value)) {
      setCanDeleteEmail(true)
      getNotificationDetail({
        variables: {
          request: {
            businessIdFilter: businessId,
            emailAddressFilter: value,
          },
        },
      })
    }
  }

  const handleChangeForm =
    (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const {
        target: { name, value },
      } = event
      setValuesNotification(name, value)
      setTypeNotificationDetail('userIdType')
    }
  const handleNotificationSubiscriptionCompleted = (data: any) => {
    if (data && data.updateNotificationSubscription) {
      enqueueSnackbar('Updated notification subscription successful', {
        variant: 'success',
      })
    }
  }

  const { updateNotificationSubscription } = useUpdateNotificationSubscription(
    handleNotificationSubiscriptionCompleted
  )

  const handleDeleteNotificationSubiscriptionCompleted = (data: any) => {
    if (data) {
      enqueueSnackbar('Notification deleted successfully', notistackSucces)
    } else {
      enqueueSnackbar(
        'Error while trying to delete notification',
        notistackWarning
      )
    }
  }
  const { deleteBusinessEmailNotifications } =
    useDeleteBusinessEmailNotifications(
      handleDeleteNotificationSubiscriptionCompleted
    )
  const removeItemOnce = (arr: any[], value: any) => {
    const index = arr.indexOf(value)
    if (index > -1) {
      arr.splice(index, 1)
    }
    return arr
  }
  const handleDeleteEmail = () => {
    const email = emailToDelete
    deleteBusinessEmailNotifications({
      variables: {
        businessId,
        email,
      },
      refetchQueries: ['GetNotificationSummary'],
    })
    const element = dropDownBusinessItems?.find((x) => x.email === email)
    if (element && dropDownBusinessItems) {
      setDropDownBusinessItems(removeItemOnce(dropDownBusinessItems, element))
      setValuesNotification('userIdNotification', '')
      setTypeNotificationDetail('userIdType')
    }
  }
  const handleClose = () => {
    setOpenRemoveDialog(false)
    setEmailToDelete('')
  }

  const handleRemove = () => {
    setOpenRemoveDialog(false)
    handleDeleteEmail()
    setEmailToDelete('')
  }
  useEffect(() => {
    if (!openRemoveDialog && emailToDelete) {
      setOpenRemoveDialog(true)
    }
  }, [emailToDelete, openRemoveDialog])

  const handleAddUserToggle = () => {
    setAddUserToggle(!addUserToggle)
    setFormNotification((prevState: any) => ({
      ...prevState,
      userRecipient: '',
    }))

    setFormNotification((prevState: any) => ({
      ...prevState,
      emailAddress: '',
    }))
  }

  const handleChangeItems = (
    newGroupedNotification: any[][],
    eventType: string,
    subscribed: boolean
  ) => {
    if (
      dropDownBusinessItems?.find(
        (x) => x.id === formNotification.userIdNotification
      )
    ) {
      updateNotificationSubscription({
        variables: {
          request: {
            businessId,
            userId: formNotification.userIdNotification,
            subscribed,
            eventType,
            notificationType: formNotification.notificationType,
          },
        },
      })
    } else if (
      dropDownBusinessItems?.find(
        (x) => x.email === formNotification.userIdNotification
      )
    ) {
      updateNotificationSubscription({
        variables: {
          request: {
            businessId,
            emailAddress: formNotification.userIdNotification,
            subscribed,
            eventType,
            notificationType: formNotification.notificationType,
          },
        },
      })
    }

    setGroupedNotification(newGroupedNotification)
  }

  useEffect(() => {
    if (savingEmail.current && dropDownBusinessItems) {
      const { email } = dropDownBusinessItems[dropDownBusinessItems?.length - 1]
      formNotification.userIdNotification = email
      setValuesNotification('userIdNotification', email)
      setTypeNotificationDetail('userIdType')
      savingEmail.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDownBusinessItems, formNotification])

  const addEmailToList = (email: string) => {
    if (!dropDownBusinessItems?.find((x) => x.email === email)) {
      savingEmail.current = true
      setDropDownBusinessItems((prev) => [
        ...prev,
        { id: '', name: email, email },
      ])
    } else {
      setValuesNotification('userIdNotification', email)
      setTypeNotificationDetail('userIdType')
    }
    setAddUserToggle(false)
  }
  const handleCloseAddEmail = () => {
    setAddUserToggle(false)
  }
  const renderDeleteButton = (email: string) => {
    if (canDeleteEmail && formNotification.userIdNotification) {
      return (
        <Grid item xs={2} sm={2} md={2}>
          <IconButton
            style={{
              maxWidth: '80%',
              top: '25px',
            }}
            onClick={(event) => {
              event.stopPropagation()
              setEmailToDelete(email)
            }}
          >
            <Delete style={{ maxWidth: '80%' }} />
          </IconButton>
        </Grid>
      )
    }
    return null
  }
  return (
    <div>
      <Box data-test-cy="boxUserNotification" my={4}>
        <Box maxWidth={460} my={4}>
          <Typography variant="h6">Change email notifications</Typography>
          <Typography color="textSecondary">
            Modify a user’s notifications
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={10} md={10}>
              <Box my={4}>
                <NotificationRecipientDropdown
                  isLoading={notificationSummaryLoading}
                  items={dropDownBusinessItems}
                  name="userIdNotification"
                  disabled={!hasBusinessNotificationPermission}
                  value={formNotification.userIdNotification}
                  onChange={handleChangeForm('userIdNotification')}
                />
              </Box>
            </Grid>
            {renderDeleteButton(formNotification.userIdNotification)}
          </Grid>
        </Box>
        {!!formNotification.userIdNotification && (
          <ManageNotifications
            groupedNotification={groupedNotification}
            isLoading={notificationDetailLoading}
            shouldReplaceBkName={false}
            disabled={!hasBusinessNotificationPermission}
            onChangeItem={handleChangeItems}
          />
        )}
      </Box>
      <Box my={4}>
        <Typography variant="h6">E-mail Notifications</Typography>
        <Typography color="textSecondary">
          Send notification to an email address you specify. This email does not
          have to be associated with a user on the DebtTrader Platform.
        </Typography>
        <Box maxWidth={460} my={4}>
          <Button
            disabled={!hasBusinessNotificationPermission}
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={handleAddUserToggle}
          >
            Add Distribution List Email
          </Button>
          <ModalDialog
            isOpen={addUserToggle}
            header="Change e-mail recipient notifications"
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
            onClose={handleCloseAddEmail}
            buttonOkText="Save"
            disableOkButton={!emailValidate(formNotification.emailAddress)}
            onContinue={() => addEmailToList(formNotification.emailAddress)}
          >
            <Box>
              <TextField
                fullWidth
                label="Notification Recipient"
                name="emailAddress"
                onChange={handleChangeForm('emailAddress')}
                error={!emailValidate(formNotification.emailAddress)}
                value={formNotification.emailAddress}
                helperText={
                  !emailValidate(formNotification.emailAddress) &&
                  'Email is not valid'
                }
              />
            </Box>
          </ModalDialog>
        </Box>
        <ModalDialog
          isOpen={openRemoveDialog}
          header="Remove E-mail"
          buttonCancelText="Cancel"
          buttonOkText="Continue"
          onClose={handleClose}
          onContinue={handleRemove}
        >
          <Box mb={3}>
            <Typography variant="body2" color="textSecondary">
              Are you sure you want to remove this E-mail?
            </Typography>
          </Box>
        </ModalDialog>
        <Divider />
      </Box>
    </div>
  )
}

export default BuyerNotification
