import React, { useState } from 'react'
import { Tabs, Tab, Box, Theme } from '@mui/material'
import ApiLogs from './ApiLogs'
import EmailLogs from './EmailLogs'
import SystemLogs from './SystemLogs'
import shadows from '@mui/material/styles/shadows'
import { makeStyles } from '@mui/styles'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      style={{ width: '100%', padding: 8 }}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.defaultProps = {
  children: null,
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    boxShadow: shadows[1],
    display: 'flex',
    minHeight: 224,
  },
  tabs: {
    minWidth: 160,
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    textAlign: 'center',
  },
}))
const LogTabs = () => {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        <Tab label="System Logs" {...a11yProps(0)} />
        <Tab label="API Call Logs" {...a11yProps(1)} />
        <Tab label="Email Logs" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <SystemLogs />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ApiLogs />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EmailLogs />
      </TabPanel>
    </div>
  )
}

export default LogTabs
