/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
} from '@mui/material'
import { Add, ExpandMore } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'

import { getSalesUser } from 'src/graphql/models/commissions'
import styled from 'styled-components'
import { GET_SALES_USER } from 'src/graphql/operations/queries/commissions'
import CompanyGoal from './CompanyGoal'
import CompanyGoals from './CompanyGoals'
import UserCommission from './UserCommission'
import UserCommissions from './UserCommissions'
import UserGoal from './UserGoal'
import UserGoals from './UserGoals'
import YearlyRevenueSummary from './components/YearlyRevenueSummary'
import QuarterlyRevenueSummary from './components/QuarterlyRevenueSummary'
import MonthlyRevenueSummary from './components/MonthlyRevenueSummary'
import { DatePicker } from 'everchain-uilibrary'

const AccordionTitle = styled(Typography)`
  font-weight: 700 !important;
  font-size: 1rem !important;
`

const Commissions: React.FC = () => {
  const [salesUser, setSalesUser] = useState('')

  const [openUserGoal, setOpenUserGoal] = useState(false)
  const handleOpenUserGoal = () => {
    setOpenUserGoal(!openUserGoal)
  }

  const [openUserCommission, setOpenUserCommission] = useState(false)
  const handleOpenUserCommission = () => {
    setOpenUserCommission(!openUserCommission)
  }

  const [openCompanyGoal, setOpenCompanyGoal] = useState(false)
  const handleOpenCompanyGoal = () => {
    setOpenCompanyGoal(!openCompanyGoal)
  }

  const [revenuePeriod, setRevenuePeriod] = useState<number>()

  const [revenueYear, setRevenueYear] = useState<any>(new Date())

  const [yearlyRevenueSummaryVisible, setYearlyRevenueSummaryVisible] =
    useState(false)

  const [quarterlyRevenueSummaryVisible, setQuarterlyRevenueSummaryVisible] =
    useState(false)

  const [monthlyRevenueSummaryVisible, setMonthlyRevenueSummaryVisible] =
    useState(false)

  // eslint-disable-next-line no-shadow
  const { data: getSalesUser, loading: salesUserLoading } =
    useQuery<getSalesUser>(GET_SALES_USER, {
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    })

  const handleSalesUserChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = event.target
    setSalesUser(value)
  }

  const handleRevenuePeriodChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setRevenuePeriod(Number(event.target.value))
  }

  useEffect(() => {
    revenuePeriod === 1
      ? setYearlyRevenueSummaryVisible(true)
      : setYearlyRevenueSummaryVisible(false)
    revenuePeriod === 2
      ? setQuarterlyRevenueSummaryVisible(true)
      : setQuarterlyRevenueSummaryVisible(false)
    revenuePeriod === 3
      ? setMonthlyRevenueSummaryVisible(true)
      : setMonthlyRevenueSummaryVisible(false)
  }, [revenuePeriod])

  const [expandedState, setExpandedState] = useState(true)

  if (salesUserLoading) {
    return <Skeleton variant="rectangular" height={150} width="100%" />
  }

  return (
    <>
      <Box p={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={8} md={9}>
            <Typography variant="h1" color="secondary">
              Commissions
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box p={5}>
        <Box>
          <Accordion key="revenueSummary">
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Box>
                <AccordionTitle>Revenue Summary</AccordionTitle>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box style={{ width: '100%' }}>
                <Grid container spacing={2} style={{ margin: '10px' }}>
                  <Grid xs={8} sm={8} md={8}>
                    <Box
                      gap={2.5}
                      style={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <Grid xs={2} sm={2} md={2}>
                        <DatePicker
                          label="Year"
                          width="100%"
                          views={['year']}
                          value={revenueYear}
                          onChange={(date: any) => {
                            setRevenueYear(date)
                          }}
                          inputFormat="YYYY"
                        />
                      </Grid>
                      <Grid xs={2} sm={2} md={2}>
                        <TextField
                          fullWidth
                          label="Period"
                          name="revenuePeriod"
                          select
                          onChange={handleRevenuePeriodChange}
                        >
                          <MenuItem key={1} value={1}>
                            Yearly
                          </MenuItem>
                          <MenuItem key={2} value={2}>
                            Quarterly
                          </MenuItem>
                          <MenuItem key={3} value={3}>
                            Monthly
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12}>
                    {yearlyRevenueSummaryVisible && (
                      <YearlyRevenueSummary
                        revenueYear={revenueYear?.getFullYear()}
                      />
                    )}
                    {quarterlyRevenueSummaryVisible && (
                      <QuarterlyRevenueSummary
                        revenueYear={revenueYear?.getFullYear()}
                      />
                    )}
                    {monthlyRevenueSummaryVisible && (
                      <MonthlyRevenueSummary
                        revenueYear={revenueYear?.getFullYear()}
                      />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt={3}>
          <Accordion key="accCompany">
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Box display="flex" flexDirection="row">
                <AccordionTitle>Company Goals</AccordionTitle>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Paper style={{ width: '100%' }}>
                <Box>
                  <Box display="flex" justifyContent="space-between">
                    <Box
                      m={2}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="h2" color="secondary" />
                    </Box>
                    <Box
                      m={2}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={() => {
                          handleOpenCompanyGoal()
                        }}
                        size="small"
                      >
                        Add Company Goal
                      </Button>
                    </Box>
                  </Box>
                  <Box>
                    <CompanyGoals />
                  </Box>
                </Box>
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box mt={3}>
          <Accordion
            key="accUser"
            expanded={expandedState}
            onChange={() => {
              setExpandedState(!expandedState)
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Box display="flex" flexDirection="row">
                <AccordionTitle>User Goals/Commissions</AccordionTitle>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box style={{ width: '100%' }}>
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12} sm={3} md={3}>
                    <Box mb={4}>
                      <TextField
                        fullWidth
                        label="User"
                        name="userSalesCode"
                        select
                        onChange={handleSalesUserChange}
                      >
                        {getSalesUser?.getSalesUser.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.displayName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={12} sm={6} md={6}>
                    <Paper style={{ height: '100%' }}>
                      <Box justifyContent="center">
                        <Box display="flex" justifyContent="space-between">
                          <Box
                            m={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Typography variant="h2" color="secondary">
                              Goals
                            </Typography>
                          </Box>
                          <Box
                            m={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<Add />}
                              onClick={() => {
                                handleOpenUserGoal()
                              }}
                              size="small"
                              disabled={!salesUser}
                            >
                              Add User Goal
                            </Button>
                          </Box>
                        </Box>
                        <Box m={2}>
                          <UserGoals userId={salesUser} />
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Paper style={{ height: '100%' }}>
                      <Box>
                        <Box display="flex" justifyContent="space-between">
                          <Box
                            m={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Typography variant="h2" color="secondary">
                              Commissions
                            </Typography>
                          </Box>
                          <Box
                            m={2}
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              startIcon={<Add />}
                              onClick={() => {
                                handleOpenUserCommission()
                              }}
                              size="small"
                              disabled={!salesUser}
                            >
                              Add User Commission
                            </Button>
                          </Box>
                        </Box>
                        <Box m={2}>
                          <UserCommissions userId={salesUser} />
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                </Grid>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>

      {openUserGoal && (
        <UserGoal
          open={openUserGoal}
          onClose={handleOpenUserGoal}
          userId={salesUser}
          userGoal={undefined}
        />
      )}

      {openUserCommission && (
        <UserCommission
          open={openUserCommission}
          onClose={handleOpenUserCommission}
          userId={salesUser}
          userCommission={undefined}
        />
      )}
      {openCompanyGoal && (
        <CompanyGoal
          open={openCompanyGoal}
          onClose={handleOpenCompanyGoal}
          companyGoal={undefined}
        />
      )}
    </>
  )
}

export default Commissions
