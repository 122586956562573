/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-underscore-dangle */
import {
  InMemoryCache,
  makeVar,
  defaultDataIdFromObject,
  ReactiveVar,
} from '@apollo/client'
import { MenuControls } from './graphql/models/Common'

const initialMenuControl = {
  menuControl: {
    managePermissionOpen: false,
    clientProfileOpen: false,
    internalusersOpen: false,
    contactOpen: false,
    faqOpen: false,
    commissionsOpen: false,
    otherAppsOpen: false,
    logOpen: false,
  },
}
export const cache: InMemoryCache = new InMemoryCache({
  dataIdFromObject: (responseObject) => {
    switch (responseObject.__typename) {
      case 'FilterOptionValue':
        return `FilterOptionValue:${responseObject.value}`
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
  typePolicies: {
    Query: {
      fields: {
        menuControl: {
          read(): MenuControls {
            return menuControlVar()
          },
        },
        switchCardType: {
          read(): string {
            return switchCardTypeVar()
          },
        },
        userType: {
          read(): string {
            return userTypeVar()
          },
        },
      },
    },
  },
})

const userType = localStorage.getItem('userType') || 'seller'

const initialSwitchCardType: string = 'face_value'
const initialUserType: string = userType

export const switchCardTypeVar: ReactiveVar<string> = makeVar<string>(
  initialSwitchCardType
)

export const userTypeVar: ReactiveVar<string> = makeVar<string>(initialUserType)

export const menuControlVar: ReactiveVar<MenuControls> =
  makeVar<MenuControls>(initialMenuControl)
