import numeral from 'numeral'
import { useContext } from 'react'
import { AuthContext } from 'src/context/AuthenticationContext'

export const numberMoneyAround = (number?: string | number): string => {
  if (!number) {
    return '--'
  }
  return numeral(number).format('0.0a')
}
const ProfileClient = () => {
  const { profileClient } = useContext(AuthContext)
  return profileClient
}

export const numberCurrencyDollar = (
  number?: string | number,
  country?: string | undefined,
  acceptZero?: boolean
): string => {
  if (!acceptZero && number !== 0) {
    if (!number) {
      return ''
    }
  }
  const defaultCountry =
    country || ProfileClient()?.Country || process.env.REACT_APP_COUNTRY

  if (defaultCountry === 'CA')
    return numeral(number).format('$0,0.00a').replace('$', 'C$')
  if (defaultCountry === 'UK')
    return numeral(number).format('$0,0.00a').replace('$', '£')

  return numeral(number).format('$0,0.00a')
}
export const numberCurrency = (
  number?: string | number,
  country: string = ProfileClient()?.Country || process.env.REACT_APP_COUNTRY
): string => {
  if (number !== 0) {
    if (!number) {
      return ''
    }
  }
  const defaultCountry =
    country || ProfileClient()?.Country || process.env.REACT_APP_COUNTRY

  if (defaultCountry === 'CA')
    return numeral(number).format('$0,0.00').replace('$', 'C$')
  if (defaultCountry === 'UK')
    return numeral(number).format('$0,0.00').replace('$', '£')

  return numeral(number).format('$0,0.00')
}

export const numberToPercentage = (
  number?: string | number,
  emptyText?: string,
  decimalDigits?: number
): string => {
  if (!number) {
    return emptyText ?? ''
  }

  if (decimalDigits === 0) return numeral(Number(number)).format('0%')

  let digits = ''
  for (let i = 0; i < (decimalDigits ?? 2); i++) digits += '0'
  return numeral(Number(number)).format(`0.${digits}%`)
}

export const calcNumberToPercent = (
  value?: number | string,
  returnType?: 'string'
): number | string | null => {
  const result = Number(value) / 100
  if (value) {
    if (returnType === 'string') {
      return result.toString()
    }
    return result
  }
  return null
}

export const calcPercentToNumber = (
  value?: number | string
): number | string | null => {
  if (value) {
    return Number(value) * 100
  }
  return null
}
