import { gql } from '@apollo/client'

export const CHECK_PERMISSION_VALUES = gql`
  query checkPermissionValues(
    $permissionCodes: [String!]
    $encodedPermissions: String!
  ) {
    checkPermissionValues(
      permissionCodes: $permissionCodes
      encodedPermissions: $encodedPermissions
    ) {
      permissions {
        code
        value
      }
    }
  }
`
