import React, { useMemo, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Skeleton } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { GET_CLIENT } from 'src/graphql/operations/queries/clientProfiles'
import { GetClient } from 'src/graphql/models/clientProfiles'
import Header from 'src/components/Header'
import { CLIENT_PROFILES } from 'src/routes'
import ClientProfileTab from './components/ClientProfileTab'

const ClientProfilesManager = () => {
  const { clientProfileId, tabIndex } = useParams<any>()

  const [getClient, { data: getClientData, loading: clientLoading }] =
    useLazyQuery<{
      getClient: GetClient
    }>(GET_CLIENT, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    })

  useEffect(() => {
    if (clientProfileId) {
      getClient({
        variables: {
          clientId: Number(clientProfileId),
        },
      })
    }
  }, [clientProfileId, getClient])

  return (
    <Box>
      <Box p={5}>
        <Header
          customBackUrl={CLIENT_PROFILES}
          title={getClientData?.getClient?.name || 'Create a New Client'}
        />
      </Box>
      <Box p={5}>
        {useMemo(
          () => (
            <>
              {!clientLoading && (
                <ClientProfileTab
                  clientProfileId={clientProfileId}
                  clientData={getClientData?.getClient}
                  tabIndex={tabIndex}
                />
              )}
              {clientLoading && (
                <Skeleton
                  width="100%"
                  height={50}
                  animation="wave"
                  variant="rectangular"
                />
              )}
            </>
          ),
          [clientLoading, clientProfileId, getClientData, tabIndex]
        )}
      </Box>
    </Box>
  )
}

export default ClientProfilesManager
