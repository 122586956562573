/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import { Grid, Skeleton } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import {
  Grid as KendoGrid,
  GridColumn as KendoGridColumn,
  GridDetailRowProps,
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid'
import { formatDateAndTime } from 'src/utils/date'
import { State } from '@progress/kendo-data-query'
import { AuditTableResponse } from 'src/infra/api/models/audit'
import { renderExpanded } from 'everchain-uilibrary'

const DetailComponent = (props: GridDetailRowProps) => {
  const dataColumns = props.dataItem.auditColumnResponses

  return (
    <KendoGrid style={{ height: '100%', width: '100%' }} data={dataColumns}>
      <KendoGridColumn field="column" title="Field" resizable={true} />
      <KendoGridColumn field="oldValue" title="Old Value" />
      <KendoGridColumn field="newValue" title="New value" />
    </KendoGrid>
  )
}

interface ExpandedAuditTable extends AuditTableResponse {
  expanded?: boolean
}

interface AuditInfoProps {
  data?: ExpandedAuditTable[]
  totalDataSize?: number
  isLoading?: boolean
  gridState: State
  onDataStateChange: (event: GridDataStateChangeEvent) => void
}

const AuditInfo: React.FC<AuditInfoProps> = ({
  data,
  totalDataSize,
  isLoading,
  gridState,
  onDataStateChange,
}) => {
  const grid = useRef<any>(null)
  const [standardData, setStandardData] = useState<
    ExpandedAuditTable[] | undefined
  >(data)

  const gridColumns: any[] = [
    {
      title: ' ',
      field: 'expanded',
      show: true,
      render: (props: any) =>
        renderExpanded(props, expandRowChange, 'auditColumnResponses'),
      width: '30px',
    },
    {
      field: 'date',
      title: 'Date',
      minWidth: 200,
      render: (props: any) => {
        return <td>{formatDateAndTime(props.dataItem['date'])}</td>
      },
    },
    {
      field: 'action',
      title: 'Action',
      minWidth: 200,
    },
    {
      field: 'user',
      title: 'User',
      minWidth: 300,
    },
  ]

  const expandRowChange = (dataItem: ExpandedAuditTable) => {
    const newItem: ExpandedAuditTable = {
      ...dataItem,
      expanded: !dataItem.expanded,
    }

    setStandardData((prevData) => {
      const index = prevData?.findIndex(
        (item) =>
          item.user === newItem.user &&
          item.date === newItem.date &&
          item.tableName === newItem.tableName
      )

      if (index !== undefined && index !== -1) {
        const updatedData = [...(prevData ?? [])]
        updatedData[index] = newItem
        return updatedData
      }

      return prevData
    })
  }

  useEffect(() => {
    setStandardData(data)
  }, [data])

  if (isLoading) {
    return <Skeleton variant="rectangular" width="100%" height={250} />
  }

  return (
    <Grid item xs={9} sm={9} md={12}>
      <KendoGrid
        ref={grid}
        style={{ height: '100%' }}
        detail={DetailComponent}
        expandField="expanded"
        data={standardData}
        pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
        total={totalDataSize}
        skip={gridState.skip}
        take={gridState.take}
        pageSize={gridState.take}
        onDataStateChange={onDataStateChange}
      >
        {gridColumns.reduce((previousValue, currentValue, index) => {
          const columns = [...previousValue]

          const mapper: any = {}
          if (currentValue.render) {
            mapper.cell = (props: any) => <currentValue.render {...props} />
          }

          if (currentValue.format) {
            mapper.format = currentValue.format
          }

          mapper.width = currentValue.width

          columns.push(
            <KendoGridColumn
              {...mapper}
              key={currentValue.field}
              field={currentValue.field}
              title={currentValue.title}
            />
          )

          return columns
        }, [])}
      </KendoGrid>
    </Grid>
  )
}

export default AuditInfo
