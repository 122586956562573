/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { userGoal } from 'src/graphql/models/commissions'
import { useSnackbar } from 'notistack'
import { useAddOrUpdateUserGoal } from 'src/graphql/operations/mutations/commissions'
import { useFormik } from 'formik'
import { Box, Grid, InputAdornment, TextField } from '@mui/material'
import { maskMoney, unMaskMoney } from 'src/utils/masker'
import { formatDate } from 'src/utils/date'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DatePicker, Flex, ModalDialog, Button } from 'everchain-uilibrary'

interface DefaultValuesProps {
  id: number | string | undefined
  amount: number | any
  startDate: Date | null
  userId: string
}

const initialValuesDefault = {
  id: '',
  amount: null,
  startDate: null,
  userId: '',
}

interface UserGoalParam {
  userId: string
  userGoal: userGoal | undefined
  open?: boolean
  onClose: () => void
}

const formSchema = Yup.object().shape({
  amount: Yup.string().nullable().required('Required'),
  startDate: Yup.date().nullable().required('Required'),
})

const UserGoal: React.FC<UserGoalParam> = ({
  userId,
  userGoal,
  open = false,
  onClose,
}: UserGoalParam) => {
  const [initialValues, setInitialValues] =
    useState<DefaultValuesProps>(initialValuesDefault)

  const { enqueueSnackbar } = useSnackbar()
  const notifySuccess = notistackOptions('success')
  const notifyError = notistackOptions('error')

  const { AddOrUpdateUserGoal, loading: formLoading } = useAddOrUpdateUserGoal({
    onCompleted: () => {
      enqueueSnackbar('User Goal saved successfully', notifySuccess)
      onClose()
    },
    onError: () => {
      enqueueSnackbar('You can not select a date in the past.', notifyError)
      onClose()
    },
  })
  const actualDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  )

  const userGoalForm = useFormik({
    initialValues,
    validationSchema: formSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const requestValues = {
        id: values.id || null,
        userId,
        amount: unMaskMoney(values.amount),
        startDate: values.startDate
          ? new Date(formatDate(values.startDate))
          : null,
      }

      if (userId || userGoal) {
        AddOrUpdateUserGoal({
          variables: {
            userGoalRequest: {
              ...requestValues,
            },
          },
          refetchQueries: ['GetUserGoals'],
        })
      }

      setSubmitting(false)
    },
  })
  useEffect(() => {
    if (userGoal && userGoal.id) {
      setInitialValues((prevState) => ({
        ...prevState,
        id: userGoal.id || '',
        amount: userGoal.amount || '',
        startDate: userGoal.startDate || '',
        userId: userId || '',
      }))
    }
  }, [userGoal, userId])

  return (
    <ModalDialog
      isOpen={open}
      onClose={onClose}
      header={`${userGoal?.id ? 'Edit' : 'Add'} User Goal`}
      isForm={true}
    >
      <form onSubmit={userGoalForm.handleSubmit}>
        <Box>
          <Grid container spacing={5}>
            <Grid item>
              <DatePicker
                name="startDate"
                id="startDate"
                label="Period"
                views={['year', 'month']}
                value={userGoalForm.values.startDate}
                errorMessage={userGoalForm.errors.startDate}
                onChange={(date) => {
                  if (date && date < actualDate) {
                    enqueueSnackbar(
                      'You can not select a date in the past.',
                      notifyError
                    )
                  } else {
                    userGoalForm.setFieldValue('startDate', date, false)
                  }
                }}
                inputFormat="YYYY/MM"
              />
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                label="Amount"
                name="amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={({ target: { value, name } }) => {
                  const masked = maskMoney(value)
                  userGoalForm.setFieldValue(name, masked, false)
                }}
                value={userGoalForm.values.amount}
                error={!!userGoalForm.errors.amount}
                helperText={<>{userGoalForm.errors.amount}</>}
              />
            </Grid>
          </Grid>
        </Box>
        <Flex
          data-test-id={'modal-dialog-footer'}
          justifyContent="flex-end"
          pb="12px"
          pt="20px"
        >
          <Button
            data-test-id={'modal-dialog-ok-buton'}
            margin={'0 0 0 10px'}
            onClick={onClose}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button
            data-test-id={'modal-dialog-ok-buton'}
            margin={'0 0 0 10px'}
            type="submit"
            isFetching={formLoading}
            disabled={formLoading}
          >
            Save
          </Button>
        </Flex>
      </form>
    </ModalDialog>
  )
}

export default UserGoal
