import { gql, useMutation } from '@apollo/client'

interface Params {
  onCompleted?: (data: any) => void
  onError?: (data: any) => void
}

export const ADD_OR_UPDATE_COMPANY_GOAL = gql`
  mutation addOrUpdateCompanyGoal($companyGoalRequest: companyGoalRequest!) {
    addOrUpdateCompanyGoal(companyGoalRequest: $companyGoalRequest)
  }
`

export function useAddOrUpdateCompanyGoal(option: Params) {
  const { onCompleted, onError } = option
  const [AddOrUpdateCompanyGoal, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_COMPANY_GOAL,
    {
      onCompleted,
      onError,
    }
  )
  return { AddOrUpdateCompanyGoal, data, error, loading }
}

export const ADD_OR_UPDATE_USER_COMMISSION = gql`
  mutation addOrUpdateUserCommission(
    $userCommissionRequest: userCommissionRequest!
  ) {
    addOrUpdateUserCommission(userCommissionRequest: $userCommissionRequest)
  }
`
export function useAddOrUpdateUserCommission(option: Params) {
  const { onCompleted, onError } = option
  const [AddOrUpdateUserCommission, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_USER_COMMISSION,
    {
      onCompleted,
      onError,
    }
  )
  return { AddOrUpdateUserCommission, data, error, loading }
}

export const ADD_OR_UPDATE_USER_GOAL = gql`
  mutation addOrUpdateUserGoal($userGoalRequest: userGoalRequest!) {
    addOrUpdateUserGoal(userGoalRequest: $userGoalRequest)
  }
`

export function useAddOrUpdateUserGoal(option: Params) {
  const { onCompleted, onError } = option
  const [AddOrUpdateUserGoal, { data, error, loading }] = useMutation(
    ADD_OR_UPDATE_USER_GOAL,
    {
      onCompleted,
      onError,
    }
  )
  return { AddOrUpdateUserGoal, data, error, loading }
}

export const REMOVE_USER_GOAL = gql`
  mutation removeUserGoal($id: Long!) {
    removeUserGoal(id: $id)
  }
`
export function useRemoveUserGoal(option: Params) {
  const { onCompleted, onError } = option
  const [RemoveUserGoal, { data, error, loading }] = useMutation(
    REMOVE_USER_GOAL,
    {
      onCompleted,
      onError,
    }
  )
  return { RemoveUserGoal, data, error, loading }
}

export const REMOVE_COMPANY_GOAL = gql`
  mutation removeCompanyGoal($id: Long!) {
    removeCompanyGoal(id: $id)
  }
`
export function useRemoveCompanyGoal(option: Params) {
  const { onCompleted, onError } = option
  const [RemoveCompanyGoal, { data, error, loading }] = useMutation(
    REMOVE_COMPANY_GOAL,
    {
      onCompleted,
      onError,
    }
  )
  return { RemoveCompanyGoal, data, error, loading }
}

export const REMOVE_USER_COMMISSION = gql`
  mutation removeUserCommission($id: Long!) {
    removeUserCommission(id: $id)
  }
`
export function useRemoveUserCommission(option: Params) {
  const { onCompleted, onError } = option
  const [RemoveUserCommission, { data, error, loading }] = useMutation(
    REMOVE_USER_COMMISSION,
    {
      onCompleted,
      onError,
    }
  )
  return { RemoveUserCommission, data, error, loading }
}
