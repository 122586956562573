/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Chip,
  Menu,
  MenuItem,
  Typography,
  IconButton,
} from '@mui/material'
import { PeopleOutline, MoreVert } from '@mui/icons-material'
import { useSnackbar } from 'notistack'

import DynamicTable from 'src/components/DynamicTable'
import AvatarName from 'src/components/AvatarName'

import { GET_ROLE_IN_USERS } from 'src/graphql/operations/queries/managePermissions'
import { GetRolesInUser } from 'src/graphql/models/managePermissions'
import { MANAGE_PERMISSIONS_ROLE } from 'src/routes'
import { useDeleteUserRole } from 'src/graphql/operations/mutations/managePermissions'

interface RoleInUserTableProps {
  userId: string
  selectedTenantId?: string
  onOpenAddNewRoleToUser: () => void
}

const pageSize = 25

const RoleInUserTable = ({
  userId,
  selectedTenantId,
  onOpenAddNewRoleToUser,
}: RoleInUserTableProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    data: getRoleInUserData,
    loading: getRoleInUserLoading,
    fetchMore,
  } = useQuery<GetRolesInUser>(GET_ROLE_IN_USERS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      roleListRequest: {
        userIdFilter: userId,
        tenantIdFilter: selectedTenantId,
        pagination: {
          pageNumber: 0,
          pageSize,
        },
      },
    },
    skip: !userId,
  })

  const getRoleInUser = getRoleInUserData?.getRolesInUser

  const deleteUserRoleCompleted = (): void => {
    enqueueSnackbar('Deleted role succesfull', {
      variant: 'success',
    })
  }

  const {
    deleteUserRole,
    data: deleteUserRoleData,
    error: deleteUserRoleError,
  } = useDeleteUserRole(deleteUserRoleCompleted)

  const roleInUserColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => (
        <Button
          component={Link}
          to={`${MANAGE_PERMISSIONS_ROLE}/${props.row.original.id}/permission/${selectedTenantId}`}
        >
          <AvatarName title={props.cell.value} />
        </Button>
      ),
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Members',
      accessor: 'numberOfMembers',
      Cell: (props: any): React.ReactNode => {
        return (
          props.cell.value && (
            <Button
              component={Link}
              to={`${MANAGE_PERMISSIONS_ROLE}/${props.row.original.id}/members/${selectedTenantId}`}
            >
              <Box display="flex" alignItems="center">
                <Box pr={1}>
                  <Typography variant="body2">{props.cell.value}</Typography>
                </Box>
                <PeopleOutline fontSize="small" />
              </Box>
            </Button>
          )
        )
      },
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: (props: any): React.ReactNode => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
        const open = Boolean(anchorEl)

        const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
          setAnchorEl(event.currentTarget)
        }

        const handleClose = (): void => {
          setAnchorEl(null)
        }
        const menus = useMemo(
          () => (
            <div>
              <IconButton
                aria-label="more"
                aria-controls="action-menus"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ position: 'relative', zIndex: 100 }}
                size="small"
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    deleteUserRole({
                      variables: {
                        userId,
                        roleIds: [props.row.original.id],
                      },
                      refetchQueries: ['GetRolesInUser'],
                    })
                  }}
                >
                  Remove
                </MenuItem>
              </Menu>
            </div>
          ),
          [anchorEl, open, props.row.original]
        )
        return <>{menus}</>
      },
    },
  ]

  useEffect(() => {
    if (deleteUserRoleError && !deleteUserRoleData) {
      enqueueSnackbar('There was an error, try again later', {
        variant: 'error',
      })
    }
  }, [deleteUserRoleError, deleteUserRoleData, enqueueSnackbar])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={4}
      >
        <Box display="flex" alignItems="center">
          <Typography>Total</Typography>
          <Box pl={2}>
            <Chip label={getRoleInUser?.totalCount || 0} size="small" />
          </Box>
        </Box>
        <Button
          type="button"
          onClick={onOpenAddNewRoleToUser}
          variant="contained"
          color="primary"
        >
          Add role to user
        </Button>
      </Box>
      <DynamicTable
        id="tbRoleInUser"
        loading={getRoleInUserLoading}
        data={getRoleInUser?.roles || []}
        columns={roleInUserColumns}
        totalItems={getRoleInUser?.totalCount || 0}
        pageSize={pageSize}
        onChangePagination={(_, pageNumber: number) => {
          const newPageNumber = pageNumber - 1
          if (fetchMore) {
            fetchMore({
              variables: {
                userListRequest: {
                  userIdFilter: userId,
                  pagination: {
                    pageNumber: newPageNumber,
                    pageSize,
                  },
                },
              },
              updateQuery: (prev, { fetchMoreResult }): GetRolesInUser => {
                if (!fetchMoreResult) return prev
                return fetchMoreResult
              },
            })
          }
        }}
      />
    </>
  )
}

export default RoleInUserTable
