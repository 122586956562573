import React, { useCallback, useMemo, useReducer } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Box } from '@mui/material'
import { useQuery } from '@apollo/client'

import Header from 'src/components/Header'
import { GET_USER } from 'src/graphql/operations/queries/managePermissions'
import { GetUser } from 'src/graphql/models/managePermissions'
import {
  ACCESSDENIED,
  CLIENT_PROFILES_MANAGE,
  INTERNAL_USERS,
} from 'src/routes'
import UserTabs from './components/UserTabs'
import RoleToUserForm from './components/RoleToUserForm'

interface RouterParams {
  userId: string
  tabType?: string
  selectedTenantId?: string
  userCreated?: string
  userType: string
}

const UserPermissionRole = () => {
  const history = useHistory()
  const [openDrawer, setOpenDrawer] = useReducer(
    (state: any, action: any) => ({ ...state, ...action }),
    { open: false }
  )

  const { userId, tabType, selectedTenantId, userCreated } =
    useParams<RouterParams>()

  const { data: getUserData, loading: userLoading } = useQuery<GetUser>(
    GET_USER,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        userId,
      },
      skip: !userId,
    }
  )

  const handleOpenDrawer = () => {
    setOpenDrawer({ open: true })
  }

  const handleCloseDrawer = useCallback(() => {
    setOpenDrawer({ open: false })
  }, [])

  if (
    getUserData?.getUser?.clientId &&
    window.location.href.indexOf('internal') !== -1
  ) {
    history.push(ACCESSDENIED)
  }

  let customUrl = ''
  if (userCreated) {
    if (getUserData?.getUser?.clientId) {
      customUrl = `${CLIENT_PROFILES_MANAGE}/${getUserData?.getUser.clientId}`
    } else {
      customUrl = `${INTERNAL_USERS}`
    }
  }

  return (
    <Box>
      <Box p={5}>
        {useMemo(
          () => (
            <Header
              title={getUserData?.getUser.userName}
              subtitle={getUserData?.getUser.clientName}
              customBackUrl={customUrl}
            />
          ),
          [getUserData, customUrl]
        )}
      </Box>
      <Box p={5}>
        {useMemo(
          () => (
            <UserTabs
              tabType={tabType}
              userId={userId}
              onOpenAddNewRoleToUser={handleOpenDrawer}
              user={getUserData?.getUser}
              userLoading={userLoading}
              selectedTenantId={selectedTenantId}
            />
          ),
          [getUserData, userId, userLoading, tabType, selectedTenantId]
        )}
      </Box>
      {openDrawer.open && (
        <RoleToUserForm
          open={openDrawer.open}
          userId={userId}
          onClose={handleCloseDrawer}
        />
      )}
    </Box>
  )
}

export default UserPermissionRole
