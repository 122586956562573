/* eslint-disable react/require-default-props */
import React, { useState } from 'react'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Button,
  Paper,
  Box,
  Skeleton,
  InputAdornment,
  TextField,
} from '@mui/material'
import { notistackOptions } from 'src/configs/notistackOptions'
import { useSnackbar } from 'notistack'

const standardListStyle = {
  paper: {
    width: 350,
    height: 400,
    overflow: 'auto',
  },
}

const rightListStyle = {
  paper: {
    width: 500,
    height: 400,
    overflow: 'auto',
  },
}

function not(a: any[], b: any[]): any[] {
  return a.filter(
    (value) => b.findIndex((itemB) => itemB.id === value.id) === -1
  )
}

function intersection(a: any[], b: any[]): any[] {
  return a.filter(
    (value) => b.findIndex((itemB) => itemB.id === value.id) !== -1
  )
}

interface InterchangebleTransferListProps {
  loading?: boolean
  leftData: any[]
  rightData: any[]
  showAllButtons?: boolean
  onSendLeftColumn: (items: any[]) => void
  onSendRightColumn: (items: any[]) => void
  readonly?: boolean
  rightSideInput?: boolean
  onBlurInputValue?: (id: any, props: any) => void
  selectedOperatingStage?: any
  setPlacementStageAssetType: (data: any) => void
  setPlacementStageAvailableAssetType: (data: any) => void
}

const InterchangebleTransferList: React.FC<InterchangebleTransferListProps> = ({
  loading,
  leftData,
  rightData,
  showAllButtons,
  onSendLeftColumn,
  onSendRightColumn,
  readonly,
  rightSideInput,
  onBlurInputValue,
  selectedOperatingStage,
  setPlacementStageAssetType,
  setPlacementStageAvailableAssetType,
}) => {
  const [checked, setChecked] = useState<any[]>([])

  const handleSendLeftItems = (items: any[]) => {
    onSendLeftColumn(items)
  }

  const handleSendRighItems = (items: any[]) => {
    onSendRightColumn(items)
  }

  const { enqueueSnackbar } = useSnackbar()
  const notistackWarning = notistackOptions('warning')

  const leftChecked = intersection(checked, leftData)
  const rightChecked = intersection(checked, rightData)

  const handleToggle = (value: any) => (): void => {
    if (!readonly) {
      const currentIndex = checked.findIndex(
        (checkItem) => checkItem.id === value.id
      )
      const newChecked = [...checked]

      if (currentIndex === -1) {
        newChecked.push(value)
      } else {
        newChecked.splice(currentIndex, 1)
      }

      setChecked(newChecked)
    }
  }

  const handleCheckedRight = () => {
    handleSendRighItems(leftChecked)
    setPlacementStageAssetType(
      rightData.concat(leftChecked).sort((a, b) => a.name - b.name)
    )
    setPlacementStageAvailableAssetType(not(leftData, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    handleSendLeftItems(rightChecked)
    setPlacementStageAvailableAssetType(
      leftData.concat(rightChecked).sort((a, b) => a.name - b.name)
    )
    setPlacementStageAssetType(not(rightData, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleAllRight = () => {
    handleSendRighItems(leftData)
    setPlacementStageAssetType(
      rightData.concat(leftData).sort((a, b) => a.name - b.name)
    )
    setPlacementStageAvailableAssetType([])
  }

  const handleAllLeft = () => {
    handleSendLeftItems(rightData)
    setPlacementStageAvailableAssetType(
      leftData.concat(rightData).sort((a, b) => a.name - b.name)
    )
    setPlacementStageAssetType([])
  }

  const handleChangeInput = (id: any, props: any) => {
    if (props?.target?.value) {
      if (props?.target?.value > 100 || props?.target?.value < 0) {
        enqueueSnackbar(
          'Asset Type fee can`t be higher than 100 or lower than 0',
          notistackWarning
        )

        return
      }
      const oldValues = [...rightData]
      oldValues.find((x) => x.id === id).assetTypeFee = props?.target?.value
      setPlacementStageAssetType(oldValues)
    }
  }

  const handleBlurInput = (id: any, props: any) => {
    if (props?.target?.value) {
      onBlurInputValue?.(id, props?.target?.value)
    }
  }

  const handleFocusInput = (id: any) => {
    const oldValues = [...rightData]
    oldValues.find((x) => x.id === id).assetTypeFee = ''
    setPlacementStageAssetType(oldValues)
  }

  const customList = (items: any[], embeddedInput?: boolean) => (
    <Paper
      style={embeddedInput ? rightListStyle.paper : standardListStyle.paper}
    >
      {loading ? (
        [0, 1, 2, 3].map((item) => (
          <Box key={item} my={2} p={3}>
            <Skeleton variant="rectangular" height={20} />
          </Box>
        ))
      ) : (
        <List dense component="div" role="list">
          {items?.map((assetType) => {
            const labelId = `transfer-list-item-${assetType?.name}-label`
            return (
              <Box
                key={assetType.id}
                style={{ display: 'flex', margin: '10px 20px 0px 0px' }}
              >
                <ListItem
                  role="listitem"
                  button
                  onClick={handleToggle(assetType)}
                >
                  <ListItemIcon>
                    {!readonly && (
                      <Checkbox
                        checked={
                          checked.findIndex(
                            (checkItem) => checkItem.id === assetType?.id
                          ) !== -1
                        }
                        tabIndex={-1}
                        disableRipple
                        color="primary"
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText id={labelId} secondary={assetType?.name} />
                </ListItem>
                {embeddedInput && (
                  <TextField
                    type="number"
                    variant="standard"
                    value={assetType?.assetTypeFee}
                    onFocus={() => handleFocusInput(assetType?.id)}
                    onChange={
                      (props) => handleChangeInput(assetType?.id, props)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    onBlur={
                      (props) => handleBlurInput(assetType?.id, props)
                      // eslint-disable-next-line react/jsx-curly-newline
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                )}
              </Box>
            )
          })}
          <ListItem />
        </List>
      )}
    </Paper>
  )

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      style={{
        flexWrap: 'nowrap',
      }}
    >
      <Grid item>{customList(leftData)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          {showAllButtons && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={leftData?.length === 0 || readonly}
              aria-label="move all right"
            >
              ≫
            </Button>
          )}
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || readonly}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || readonly}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          {showAllButtons && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleAllLeft}
              disabled={rightData?.length === 0 || readonly}
              aria-label="move all left"
            >
              ≪
            </Button>
          )}
        </Grid>
      </Grid>
      <Grid item>{customList(rightData, rightSideInput)}</Grid>
    </Grid>
  )
}

InterchangebleTransferList.defaultProps = {
  loading: false,
  showAllButtons: true,
}
export default InterchangebleTransferList
