import { httpClient } from '../../axios-wrapper/httpClient'
import * as urls from '../../urls/dataManager'
import * as types from '../../models/dataManager'
import { PortfolioType } from '../../models/portfolio'

export const getAllPortfolioTypes = async (): Promise<PortfolioType[]> => {
  const response = await httpClient.get(
    urls.DataManagerGetAllPortfolioTypes,
    {}
  )

  return response.data
}

export const getFileDefinitionByPortfolioType = async (
  portfolioTypeId: number | undefined
): Promise<types.ExportFileDefinition[]> => {
  const response = await httpClient.get(
    urls.DataManagerGetFileDefinitionByPortfolioType,
    {
      params: {
        portfolioTypeId,
      },
    }
  )

  return response.data
}

export const getFieldsByFileDefinitionId = async (
  fileDefinitionId: number | undefined,
  sellerId: string | undefined,
  kendoPagination: string | undefined
): Promise<types.ExportFieldDefinitionResponse> => {
  const response = await httpClient.get(
    urls.DataManagerGetFieldsByFileDefinitionId,
    {
      params: {
        fileDefinitionId,
        sellerId,
        kendoPagination,
      },
    }
  )

  return response.data
}

export const updateFieldDefinition = async (request: any) => {
  const form = new FormData()
  form.append('id', request.id)
  form.append('sellerId', request.sellerId)
  form.append('exportFieldDefinitionId', request.exportFieldDefinitionId)
  form.append('exportFieldDefinitionName', request.exportFieldDefinitionName)
  form.append('exportFieldDescription', request.exportFieldDescription)
  form.append('exportFieldType', request.exportFieldType)
  if (request.exportFieldFormat) {
    form.append('exportFieldFormat', request.exportFieldFormat)
  }
  form.append('isRequired', request.isRequired)

  const response = await httpClient.post(
    urls.DataManagerUpdateFieldDefinition,
    form,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )

  return response?.data
}
