import React, { useEffect } from 'react'
import { Box, LinearProgress } from '@mui/material'
import AuthService from 'src/configs/AuthService'

const styles = { width: '100%' }

export const SignInCallback: React.FC = () => {
  useEffect(() => {
    const authService = new AuthService()
    authService.loginCallback()
  }, [])

  return (
    <div style={styles}>
      <LinearProgress />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <img src="/img/ec-color-logo.svg" width={300} alt="EverChain" />
      </Box>
    </div>
  )
}
