import React, { useContext, useEffect } from 'react'
import { Typography, Box, Chip, Button } from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { PeopleOutline } from '@mui/icons-material'

import { GET_ALL_USERS_ASSOCIATE } from 'src/graphql/operations/queries/managePermissions'
import { UserAssociateListResponse } from 'src/graphql/models/managePermissions'
import DynamicTable from 'src/components/DynamicTable'
import { USER_ROLES } from 'src/routes'
import AvatarName from 'src/components/AvatarName'
import { AuthContext } from 'src/context/AuthenticationContext'
import { TENANT_TYPE, USER_ID } from 'src/utils/constants'

const pageSize = 25

interface UserTableProps {
  selectedTenantId: string
}
const UsersTable: React.FC<UserTableProps> = ({
  selectedTenantId,
}: UserTableProps) => {
  const { user } = useContext(AuthContext)
  const isInternal = user && user.profile[TENANT_TYPE] === 'internal'
  const getUserueryVariables = (tenantId: string, pageNumber: number): {} => {
    if (tenantId === '') {
      return {
        userAssociateListRequest: {
          idCurrentUser: isInternal ? '' : user.profile[USER_ID],
          pagination: {
            pageNumber,
            pageSize,
          },
        },
      }
    }

    return {
      userAssociateListRequest: {
        tenantIdFilter: tenantId,
        idCurrentUser: isInternal ? '' : user.profile[USER_ID],
        pagination: {
          pageNumber,
          pageSize,
        },
      },
    }
  }

  const [getUsers, { data, loading, fetchMore }] =
    useLazyQuery<UserAssociateListResponse>(GET_ALL_USERS_ASSOCIATE, {
      fetchPolicy: 'cache-and-network',
      variables: getUserueryVariables(selectedTenantId, 0),
    })

  const usersData = data?.userAssociateListResponse
  const usersColumns = [
    {
      Header: 'Name',
      accessor: 'userName',
      Cell: (props: any) => {
        return (
          props.cell.value && (
            <Button
              component={Link}
              to={
                props.row.original.isInternal
                  ? `${USER_ROLES}/${props.row.original.id}/permission/internal/${selectedTenantId}`
                  : `${USER_ROLES}/${props.row.original.id}/permission/${selectedTenantId}`
              }
            >
              <AvatarName title={props.cell.value} />
            </Button>
          )
        )
      },
    },
    {
      Header: 'Client Name',
      accessor: 'clientName',
    },
    {
      Header: 'Client Association',
      accessor: 'associatedClient',
    },
    {
      Header: 'Role Membership',
      accessor: 'roleCount',
      Cell: (props: any): React.ReactNode => {
        return (
          <Button
            to={`${USER_ROLES}/${props.row.original.id}/roles/${selectedTenantId}`}
            component={Link}
          >
            <Box display="flex" alignItems="center">
              <Box pr={1}>
                <Typography variant="body2">{props.cell.value}</Typography>
              </Box>
              <PeopleOutline fontSize="small" />
            </Box>
            <PeopleOutline fontSize="small" />
          </Button>
        )
      },
    },
  ]

  useEffect(() => {
    if (selectedTenantId !== '') {
      getUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenantId])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={4}
      >
        <Box display="flex" alignItems="center">
          <Typography>Total</Typography>
          <Box pl={2}>
            <Chip label={usersData?.totalCount || 0} size="small" />
          </Box>
        </Box>
      </Box>
      <Box p={2}>
        <DynamicTable
          id="tbUsersTable"
          data={usersData?.users || []}
          columns={usersColumns}
          loading={loading}
          totalItems={usersData?.totalCount || 0}
          pageSize={pageSize}
          onChangePagination={(_, pageNumber: number) => {
            const newPageNumber = pageNumber - 1
            if (fetchMore) {
              fetchMore({
                variables: getUserueryVariables(
                  selectedTenantId,
                  newPageNumber
                ),
                updateQuery: (
                  prev,
                  { fetchMoreResult }
                ): UserAssociateListResponse => {
                  if (!fetchMoreResult) return prev
                  return fetchMoreResult
                },
              })
            }
          }}
        />
      </Box>
    </>
  )
}

export default UsersTable
