/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link as LinkMaterial } from '@mui/material'
import { Link } from 'react-router-dom'
import React from 'react'
import { GridCellProps } from '@progress/kendo-react-grid'
import { formatDate, getLocalDatetimer } from './date'

import {
  numberCurrency,
  numberCurrencyDollar,
  numberToPercentage,
} from './numbers'
import { capitalize, convertToTrueOrFalse } from './text'

export const renderEscalated = (props: any) => {
  return <td>{props.dataItem[props.field] ? 'Yes' : 'No'}</td>
}

export const renderLeftNumberLink = (props: any, link: any) => {
  return (
    <td style={{ textAlign: 'left', cursor: 'pointer' }}>
      <LinkMaterial
        color="primary"
        variant="body2"
        component={Link}
        to={`${link}${props.dataItem[props.field]}`}
      >
        {props.dataItem[props.field]}
      </LinkMaterial>
    </td>
  )
}

export const renderDate = (props: any) => {
  return (
    <td style={{ cursor: 'default' }}>
      {props.dataItem[props.field]
        ? formatDate(new Date(props.dataItem[props.field]))
        : 'N/A'}
    </td>
  )
}

export const renderNumber = (props: any) => {
  return (
    <td style={{ textAlign: 'right', cursor: 'default' }}>
      {props.dataItem[props.field]}
    </td>
  )
}

export const renderClickableNumberLink = (
  props: any,
  link: any,
  onClick?: any,
  alignment: any = 'left'
) => {
  return (
    <td onClick={onClick} style={{ textAlign: alignment, cursor: 'pointer' }}>
      <LinkMaterial
        color="primary"
        variant="body2"
        component={Link}
        to={`${link}`}
      >
        {props.dataItem[props.field]}
      </LinkMaterial>
    </td>
  )
}

export const renderPercent = (props: any) => {
  return (
    <td style={{ textAlign: 'right' }}>
      {numberToPercentage(props.dataItem[props.field])}
    </td>
  )
}

export const renderCapitalize = (props: any) => {
  return <td>{capitalize(props.dataItem[props.field])}</td>
}

export const renderRemaining = (props: any) => {
  const { days, hours } = getLocalDatetimer(props.dataItem[props.field])
  return <td>{days >= 0 ? `(${days} Days, ${hours} Hours)` : ''}</td>
}

export const resizeColumnByWidth = (
  columnName: string,
  minWith: number,
  maxWith?: number
) => {
  if (columnName.length > 0) {
    const multiplier = 11
    const columnNameWidth = columnName.length * multiplier

    if (columnNameWidth < minWith) return minWith
    if (maxWith && columnNameWidth > maxWith) return maxWith
    if (columnNameWidth > 300) return 300
    return columnNameWidth
  }
  return minWith
}

export const renderDataAttribute = (props: any, attribute: any) => {
  return <td data-cy={attribute}>{props.dataItem[props.field]}</td>
}
export const renderTypeAttribute = (
  props: any,
  attribute: any,
  isUkCountry: boolean
) => {
  return (
    <td data-cy={attribute}>
      {props.dataItem[props.field].toLowerCase().includes('pif-sif')
        ? props.dataItem[props.field].toUpperCase().replace('-', '/')
        : props.dataItem[props.field].toLowerCase().includes('bankrupt')
        ? isUkCountry
          ? 'Insolvency'
          : props.dataItem[props.field]
        : props.dataItem[props.field].toLowerCase().includes('accountclosed')
        ? 'Account Closed'
        : props.dataItem[props.field]}
    </td>
  )
}

export const renderEscalatedDataAttribute = (props: any, attribute: any) => {
  return (
    <td data-cy={attribute}>{props.dataItem[props.field] ? 'Yes' : 'No'}</td>
  )
}

export const renderDateDataAttribute = (props: any, attribute: any) => {
  return (
    <td data-cy={attribute}>
      {props.dataItem[props.field]
        ? formatDate(new Date(props.dataItem[props.field]))
        : props.dataItem[props.field]}
    </td>
  )
}

export const renderCurrency = (props: any, attribute: any, country: string) => {
  return (
    <td style={{ textAlign: 'right' }}>
      {props.dataItem[props.field]
        ? numberCurrencyDollar(props.dataItem[props.field], country)
        : 'N/A'}
    </td>
  )
}

export const renderCurrencyByPortfolioCountry = (props: any) => {
  return (
    <td style={{ textAlign: 'right' }}>
      {numberCurrency(
        props.dataItem[props.field],
        props.dataItem['portfolioCountry']
      )}
    </td>
  )
}

export const formatDateCell = (props: GridCellProps) => {
  const fieldName = props.field || ''

  const fieldHasError = props.dataItem['errors']
    .toLowerCase()
    .includes(fieldName.toLowerCase())

  if (fieldHasError) {
    if (props.dataItem[fieldName])
      return <td>{props.dataItem[fieldName].toString()}</td>

    return <td>{props.dataItem[fieldName]}</td>
  }

  return <td>{formatDate(props.dataItem[fieldName])}</td>
}

export const cellCreateBy = (props: any) => {
  return props.dataItem[props.field] ? (
    <td data-cy="created-by-field">
      <span>{props.dataItem[props.field]}</span>
    </td>
  ) : (
    <td data-cy="created-by-field">
      <span>System</span>
    </td>
  )
}

export const renderTrueOrFalse = (props: any) => {
  return (
    <td style={{ textAlign: 'left' }}>
      {convertToTrueOrFalse(props.dataItem[props.field])}
    </td>
  )
}

export const renderListJoined = (
  list: any[],
  showDefaultText: boolean,
  defaultText: string
) => {
  return <td>{showDefaultText ? defaultText : list.join(', ')}</td>
}
