import { gql } from '@apollo/client'

export const GET_SYSTEM_LOGS = gql`
  query GetSystemLogs(
    $pageSize: Int!
    $pageNumber: Int!
    $from: DateTime!
    $to: DateTime!
    $filterByText: String
  ) {
    getSystemLogs(
      pageSize: $pageSize
      pageNumber: $pageNumber
      from: $from
      to: $to
      filterByText: $filterByText
    ) {
      numberOfItems
      totalNumber
      systemLogs {
        id
        text
        level
        exception
        timeStamp
        userDisplayName
        userEmail
        userId
      }
    }
  }
`

export const GET_API_LOGS = gql`
  query GetApiLogs(
    $from: String!
    $to: String!
    $filterByMethod: String
    $filterByUserName: String
    $kendoPagination: String
  ) {
    getApiLogs(
      from: $from
      to: $to
      filterByMethod: $filterByMethod
      filterByUserName: $filterByUserName
      kendoPagination: $kendoPagination
    ) {
      numberOfItems
      totalNumber
      apiLogs {
        id
        message
        messageTemplate
        level
        timeStamp
        exception
        logEvent
        applicationName
        elapsed
        requestId
        username
        userId
        businessInfo
        endpointName
        queryString
        body
        host
        contentType
        filesQty
      }
    }
  }
`
export const GET_EMAIL_LOGS = gql`
  query GetEmailLogs(
    $pageSize: Int!
    $pageNumber: Int!
    $from: DateTime!
    $to: DateTime!
    $filterByReceiver: String
    $filterBySender: String
    $filterBySubject: String
  ) {
    getEmailLogs(
      pageSize: $pageSize
      pageNumber: $pageNumber
      from: $from
      to: $to
      filterByReceiver: $filterByReceiver
      filterBySender: $filterBySender
      filterBySubject: $filterBySubject
    ) {
      numberOfItems
      totalNumber
      emailLogs {
        id
        dateCreated
        environment
        sourceApp
        direction
        toAddress
        fromAddress
        subject
        htmlContent
        textContent
      }
    }
  }
`
