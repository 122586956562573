/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useMemo, useState } from 'react'
import {
  Typography,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import { useLazyQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { PeopleOutline, MoreVert } from '@mui/icons-material'

import DynamicTable from 'src/components/DynamicTable'
import { GET_ALL_ROLES } from 'src/graphql/operations/queries/managePermissions'
import { RoleListResponse } from 'src/graphql/models/managePermissions'
import { MANAGE_PERMISSIONS_ROLE } from 'src/routes'
import AvatarName from 'src/components/AvatarName'
import { useRemoveRole } from 'src/graphql/operations/mutations/managePermissions'
import { ModalDialog } from 'everchain-uilibrary'

function descriptionsCell(props: any) {
  return (
    props?.cell?.value && (
      <Box display="flex" alignItems="center" maxWidth={600}>
        <Typography variant="body2">{props.cell.value}</Typography>
      </Box>
    )
  )
}

interface RoleTableProps {
  onAddOrUpdateRole: (
    roleId: string | null | undefined,
    roleName: string | null | undefined,
    roleDescription: string | null | undefined,
    roleTenantId: string | null | undefined,
    actionType: string
  ) => void
  selectedTenantId: string
}

const pageSize = 25
const RoleTable: React.FC<RoleTableProps> = ({
  onAddOrUpdateRole,
  selectedTenantId,
}: RoleTableProps) => {
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)
  const [selectedRoleName, setSelectedRoleName] = useState('')
  const [selectedRoleId, setSelectedRoleId] = useState('')

  const getRoleQueryVariables = (tenantId: string, pageNumber: number): {} => {
    if (tenantId === '') {
      return {
        roleListRequest: {
          pagination: {
            pageNumber,
            pageSize,
          },
        },
      }
    }

    return {
      roleListRequest: {
        tenantIdFilter: tenantId,
        pagination: {
          pageNumber,
          pageSize,
        },
      },
    }
  }

  const [getRoles, { data, loading, fetchMore }] = useLazyQuery<any>(
    GET_ALL_ROLES,
    {
      fetchPolicy: 'cache-and-network',
      variables: getRoleQueryVariables(selectedTenantId, 0),
    }
  )

  const deleteRole = (roleId: string, roleName: string) => {
    setSelectedRoleName(roleName)
    setSelectedRoleId(roleId)
    setOpenDeleteConfirm(true)
  }
  const handleClose = () => {
    setSelectedRoleName('')
    setOpenDeleteConfirm(false)
  }
  const { removeRole } = useRemoveRole()
  const handleDeleteRole = () => {
    removeRole({
      variables: {
        roleId: selectedRoleId,
      },
      refetchQueries: ['GetAllRoles'],
    })
    setSelectedRoleName('')
    setOpenDeleteConfirm(false)
  }

  const rolesData = data?.getAllRoles as RoleListResponse

  const rolesColumns = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: (props: any) => {
        return (
          props.cell.value && (
            <Button
              to={`${MANAGE_PERMISSIONS_ROLE}/${props.row.original.id}/permission/${selectedTenantId}`}
              component={Link}
            >
              <AvatarName title={props.cell.value} />
            </Button>
          )
        )
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: (props: any) => descriptionsCell(props),
    },
    {
      Header: 'Members',
      accessor: 'numberOfMembers',
      Cell: (props: any): React.ReactNode => {
        return (
          <Button
            to={`${MANAGE_PERMISSIONS_ROLE}/${props.row.original.id}/members/${selectedTenantId}`}
            component={Link}
          >
            <Box display="flex">
              <Box pr={1}>
                <Typography variant="body2">{props.cell.value}</Typography>
              </Box>
              <PeopleOutline fontSize="small" />
            </Box>
          </Button>
        )
      },
    },
    {
      Header: 'Actions',
      accessor: '',
      Cell: (props: any): React.ReactNode => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
        const open = Boolean(anchorEl)

        const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
          setAnchorEl(event.currentTarget)
        }

        const handleClose = (): void => {
          setAnchorEl(null)
        }
        const menus = useMemo(
          () => (
            <div>
              <IconButton
                aria-label="more"
                aria-controls="action-menus"
                aria-haspopup="true"
                onClick={handleClick}
                style={{ position: 'relative', zIndex: 100 }}
                size="small"
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem
                  disabled={selectedTenantId === ''}
                  onClick={() => {
                    onAddOrUpdateRole(
                      props.row.original.id,
                      props.row.original.name,
                      props.row.original.description,
                      props.row.original.tenantId,
                      'clone'
                    )
                    handleClose()
                  }}
                >
                  Clone
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onAddOrUpdateRole(
                      props.row.original.id,
                      props.row.original.name,
                      props.row.original.description,
                      props.row.original.tenantId,
                      'edit'
                    )
                    handleClose()
                  }}
                >
                  Edit
                </MenuItem>
                {props.row.original.tenantId !== null &&
                  props.row.original.tenantId?.toLowerCase() !==
                    'ADE44E98-9ED6-4AF6-916A-3A21FBED7351'.toLowerCase() && (
                    <MenuItem
                      onClick={() => {
                        handleClose()
                        deleteRole(
                          props.row.original.id,
                          props.row.original.name
                        )
                      }}
                    >
                      Delete
                    </MenuItem>
                  )}
              </Menu>
            </div>
          ),
          [anchorEl, open, props.row.original]
        )
        return <>{menus}</>
      },
    },
  ]

  useEffect(() => {
    if (selectedTenantId !== '') {
      getRoles()
    }
  }, [getRoles, selectedTenantId])

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={4}
      >
        <Box display="flex" alignItems="center">
          <Typography>Total</Typography>
          <Box pl={2}>
            <Chip label={rolesData?.totalCount || 0} size="small" />
          </Box>
        </Box>
        <Button
          disabled={selectedTenantId === ''}
          type="button"
          variant="contained"
          color="primary"
          onClick={
            (): void => onAddOrUpdateRole(null, null, null, null, 'create')
            // eslint-disable-next-line react/jsx-curly-newline
          }
        >
          New Role
        </Button>
      </Box>
      <Box p={2}>
        <DynamicTable
          id="tbRoleTable"
          data={rolesData?.roles || []}
          columns={rolesColumns}
          loading={loading}
          totalItems={rolesData?.totalCount}
          pageSize={pageSize}
          onChangePagination={(_, pageNumber: number) => {
            const newPageNumber = pageNumber - 1
            if (fetchMore) {
              fetchMore({
                variables: getRoleQueryVariables(
                  selectedTenantId,
                  newPageNumber
                ),
                updateQuery: (prev, { fetchMoreResult }): RoleListResponse => {
                  if (!fetchMoreResult) return prev
                  return fetchMoreResult
                },
              })
            }
          }}
        />
      </Box>
      <ModalDialog
        isOpen={openDeleteConfirm}
        header="Delete Role"
        buttonCancelText="Cancel"
        buttonOkText="Continue"
        onClose={handleClose}
        onContinue={handleDeleteRole}
      >
        <Box>
          <Typography variant="body1" color="textSecondary">
            {`Are you sure want to delete role ${selectedRoleName}?`}
          </Typography>
        </Box>
      </ModalDialog>
    </>
  )
}

export default RoleTable
