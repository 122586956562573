import React from 'react'
import { useSnackbar } from 'notistack'
import { useQuery } from '@apollo/client'
import { GET_BUSINESS_NAME_HISTORY } from 'src/graphql/operations/queries/sellerBusiness'
import { useChangeBusinessName } from 'src/graphql/operations/mutations/businesses'
import { GetBusinessNameHistories } from 'src/graphql/models/sellerBusiness'
import { refetchQueriesWrapper } from 'src/infra/react-query-wrapper'
import { useQueryClient } from '@tanstack/react-query'
import NameHistory from './NameHistory'

interface BusinessNameHistoryProps {
  businessId: string
  refetchQueries?: string[]
  refetchQueriesRest?: string
}

const BusinessNameHistory = ({
  businessId,
  refetchQueries = [],
  refetchQueriesRest = '',
}: BusinessNameHistoryProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const onSubmit = (newName: string) => {
    changeBusinessName({
      variables: {
        businessId,
        newName,
      },
      refetchQueries: [...refetchQueries, 'GetBusinessNameHistory'],
    })
  }

  const { data: businessNameHistory, loading: nameHistoryLoading } =
    useQuery<GetBusinessNameHistories>(GET_BUSINESS_NAME_HISTORY, {
      variables: {
        businessId,
      },
    })

  const handleChangeNameCompleted = (data: any) => {
    if (data) {
      refetchQueriesWrapper([refetchQueriesRest, businessId], queryClient)
      enqueueSnackbar('New name added successful', {
        variant: 'success',
      })
    }
  }

  const { changeBusinessName, loading: changeNameLoading } =
    useChangeBusinessName(handleChangeNameCompleted)

  return (
    <NameHistory
      nameHistoryData={businessNameHistory?.getBusinessNameHistory}
      onSubmit={onSubmit}
      nameHistoryLoading={nameHistoryLoading}
      changeNameLoading={changeNameLoading}
    />
  )
}

BusinessNameHistory.defaultProps = {
  refetchQueries: [],
  refetchQueriesRest: '',
}

export default BusinessNameHistory
