import styled from 'styled-components'

export const ClientBankAccountsWrapper = styled.div`
  .banck-item {
    background-color: ${({ theme }) => theme.debtColors.debtGreyClean};
    border-radius: 3px;
  }
`
export const ClientBusinessWrapper = styled.div`
  .business-item {
    background-color: ${({ theme }) => theme.debtColors.debtGreyClean};
    border-radius: 3px;
  }
`
