import React, { useContext } from 'react'
import { GET_OPERATING_PLACEMENT_STAGE } from 'src/graphql/operations/queries/business'
import TransferList from 'src/components/TransferList'
import { BusinessOperatingPlacementStageType } from 'src/graphql/models/businessRecovery'
import {
  useAddBusinessOperatingPlacementStage,
  useRemoveBusinessOperatingPlacementStage,
} from 'src/graphql/operations/mutations/businesses'
import { useSnackbar } from 'notistack'
import { useQuery } from '@apollo/client'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'

const BusinessOperatingPlacementStage = (businessId: string | any) => {
  const ability = useContext(AbilityContext)
  const { enqueueSnackbar } = useSnackbar()

  const { data: getBusinessOperatingPlacementStage } =
    useQuery<BusinessOperatingPlacementStageType>(
      GET_OPERATING_PLACEMENT_STAGE,
      {
        variables: {
          businessId: businessId.businessId,
        },
        skip: !businessId,
      }
    )

  const mapItems = (array: string[]) => array.map((e) => ({ id: e, name: e }))

  const { addBusinessOperatingPlacementStage } =
    useAddBusinessOperatingPlacementStage((data: any) => {
      if (data) {
        enqueueSnackbar('Placement stages updated successfully', {
          variant: 'success',
        })
      } else {
        enqueueSnackbar('Error while trying to update the placement stages', {
          variant: 'warning',
        })
      }
    })

  const { removeBusinessOperatingPlacementStage } =
    useRemoveBusinessOperatingPlacementStage((data: any) => {
      if (data) {
        enqueueSnackbar('Placement stages updated successfully', {
          variant: 'success',
        })
      } else {
        enqueueSnackbar('Error while trying to update the placement stages', {
          variant: 'warning',
        })
      }
    })

  const handleSendLeftColumn = (data: any[]) => {
    const operatingPlacementStages = data.map((e) => e.name)
    removeBusinessOperatingPlacementStage({
      variables: {
        request: {
          businessId: businessId.businessId,
          operatingPlacementStages,
        },
      },
      refetchQueries: ['GetBusinessOperatingPlacementStage'],
    })
  }

  const handleSendRightColumn = (data: any[]) => {
    const operatingPlacementStages = data.map((e) => e.name)
    addBusinessOperatingPlacementStage({
      variables: {
        request: {
          businessId: businessId.businessId,
          operatingPlacementStages,
        },
      },
      refetchQueries: ['GetBusinessOperatingPlacementStage'],
    })
  }

  return (
    <TransferList
      leftData={mapItems(
        getBusinessOperatingPlacementStage?.businessOperatingPlacementStage
          ?.availableOperatingPlacementStages || []
      )}
      rightData={mapItems(
        getBusinessOperatingPlacementStage?.businessOperatingPlacementStage
          ?.operatingPlacementStages || []
      )}
      onSendLeftColumn={handleSendLeftColumn}
      onSendRightColumn={handleSendRightColumn}
      readonly={
        !ability.can(PermissionCodeAccess.CLIENT_BASICINFO_PERMISSION, 'any')
      }
    />
  )
}

export default BusinessOperatingPlacementStage
