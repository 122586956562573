import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

import Header from 'src/components/Header'
import { BusinessBuyerResponseTypes } from 'src/graphql/models/buyer'
import { GET_BUYER } from 'src/graphql/operations/queries/buyer'
import BuyerBusinessTab from './components/BuyerBusinessTab'
import { CLIENT_PROFILES_MANAGE } from 'src/routes'

const BuyerBusinessManage = () => {
  const { buyerBusinessId, clientId } = useParams<any>()

  const { data: getBuyerData, loading: buyerLoading } =
    useQuery<BusinessBuyerResponseTypes>(GET_BUYER, {
      variables: {
        buyerId: buyerBusinessId,
      },
      skip: !buyerBusinessId,
    })

  return (
    <>
      <Box p={5}>
        <Header
          title={
            getBuyerData?.getBuyer
              ? getBuyerData.getBuyer.name
              : 'Create a New Buyer'
          }
          customBackUrl={`${CLIENT_PROFILES_MANAGE}/${clientId}`}
        />
      </Box>
      <Box p={5}>
        <BuyerBusinessTab
          clientId={clientId}
          buyerBusinessId={buyerBusinessId}
          buyerData={getBuyerData?.getBuyer}
          buyerLoading={buyerLoading}
        />
      </Box>
    </>
  )
}

export default BuyerBusinessManage
