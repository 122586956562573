import { Box } from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'

import Header from 'src/components/Header'
import { useCustomQuery } from 'src/infra/react-query-wrapper'
import { getAgencyDetails } from 'src/infra/api/services/agency'
import { BusinessAgencyResponseType } from 'src/infra/api/models/agency'
import AgencyBusinessTab from './components/AgencyBusinessTab'
import { CLIENT_PROFILES_MANAGE } from 'src/routes'

const AgencyBusinessManage = () => {
  const { agencyBusinessId, clientId } = useParams<any>()

  const { data: getAgencyData, isFetching: agencyLoading } =
    useCustomQuery<BusinessAgencyResponseType>(
      ['getAgencyDetails', agencyBusinessId],
      async () => {
        return getAgencyDetails(agencyBusinessId)
      },
      {
        enabled: !!agencyBusinessId,
      }
    )

  return (
    <>
      <Box p={5}>
        <Header
          title={getAgencyData ? getAgencyData.name : 'Create a New Agency'}
          customBackUrl={`${CLIENT_PROFILES_MANAGE}/${clientId}`}
        />
      </Box>
      <Box p={5}>
        <AgencyBusinessTab
          clientId={clientId}
          agencyBusinessId={agencyBusinessId}
          agencyData={getAgencyData}
          agencyLoading={agencyLoading}
        />
      </Box>
    </>
  )
}

export default AgencyBusinessManage
