import React, { useMemo, useReducer, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'

import Header from 'src/components/Header'
import { GET_ROLE } from 'src/graphql/operations/queries/managePermissions'
import { Role } from 'src/graphql/models/managePermissions'
import RoleControlTabs from './components/RoleControlTabs'
import FormMemberRole from './components/FormMemberRole'

interface RouteParams {
  roleId: string
  tabType?: string
  selectedTenantId?: string
}

const ManagePermissionsRole = () => {
  const [openDrawer, setOpenDrawer] = useReducer(
    (state: any, action: any) => ({ ...state, ...action }),
    { open: false }
  )

  const { roleId, tabType, selectedTenantId } = useParams<RouteParams>()

  const { data: getRoleData, loading: roleLoading } = useQuery<{
    getRole: Role
  }>(GET_ROLE, {
    variables: {
      roleId,
      tenantId: selectedTenantId || null,
    },
    fetchPolicy: 'cache-and-network',
  })

  const handleOpenDrawer = () => {
    setOpenDrawer({ open: true })
  }

  const handleCloseDrawer = useCallback(() => {
    setOpenDrawer({ open: false })
  }, [])

  return (
    <div>
      <Box p={5}>
        {useMemo(
          () => (
            <Header
              title={getRoleData?.getRole?.name}
              subtitle={
                getRoleData
                  ? `${getRoleData?.getRole?.description} - ${getRoleData?.getRole?.clientName}`
                  : ''
              }
            />
          ),
          [getRoleData]
        )}
      </Box>
      <Box p={5}>
        {useMemo(
          () => (
            <RoleControlTabs
              tabType={tabType}
              roleId={roleId}
              roleLoading={roleLoading}
              role={getRoleData?.getRole}
              selectedTenantId={selectedTenantId}
              onAddNewUsers={handleOpenDrawer}
            />
          ),
          [getRoleData, roleId, roleLoading, tabType, selectedTenantId]
        )}
      </Box>
      {openDrawer.open && (
        <FormMemberRole
          open={openDrawer.open}
          roleId={roleId}
          selectedTenantId={selectedTenantId}
          onClose={handleCloseDrawer}
        />
      )}
    </div>
  )
}

export default React.memo(ManagePermissionsRole)
