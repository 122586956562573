/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable no-return-assign */
import { State } from '@progress/kendo-data-query'
import React, { useState } from 'react'
import AuditInfo from 'src/components/Audit/AuditInfo'
import { AuditResponse } from 'src/infra/api/models/audit'
import { getCustomerChanges } from 'src/infra/api/services/audit'
import { useCustomQuery } from 'src/infra/react-query-wrapper'

interface ClientAuditInfoProps {
  id: string | number
  tableName: 'client' | 'business' | undefined
}

const CustomerAuditInfo: React.FC<ClientAuditInfoProps> = ({
  id,
  tableName,
}) => {
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const { data: clientAuditData, isFetching: loadingClientAudit } =
    useCustomQuery<AuditResponse>(
      ['getCustomerChanges', id],
      async () =>
        getCustomerChanges(id.toString(), tableName, JSON.stringify(gridState)),
      { enabled: true, cacheTime: 0 }
    )

  return (
    <AuditInfo
      data={clientAuditData?.auditTableResponses}
      totalDataSize={clientAuditData?.total}
      isLoading={loadingClientAudit}
      gridState={gridState}
      onDataStateChange={(e) => setGridState(e.dataState)}
    />
  )
}

export default CustomerAuditInfo
