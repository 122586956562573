import React, { useContext } from 'react'
import UserList from 'src/components/User/UserList'
import { PermissionCodeAccess } from 'src/utils/constants'
import { AbilityContext } from 'src/context/Can'
import { Box, Grid, Typography } from '@mui/material'
import { Redirect } from 'react-router-dom'
import { ACCESSDENIED } from 'src/routes'

const InternalUsers = () => {
  const ability = useContext(AbilityContext)
  const hasPermission =
    ability.can(PermissionCodeAccess.CLIENT_INTERNAL_USER_PERMISSION, 'any') ||
    ability.can(PermissionCodeAccess.MANAGE_INTERNAL_PERMISSION, 'any')

  if (!hasPermission) {
    return <Redirect to={ACCESSDENIED} />
  }

  return (
    <Box>
      <Box p={5}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h1" color="secondary">
              Internal Users
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <UserList
              tenantId="ADE44E98-9ED6-4AF6-916A-3A21FBED7351"
              clientId={0}
              isInternalUsers={true}
              internalUserGrid={true}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default InternalUsers
