export const removeDuplicateArrayById = (
  listDuplicated: any[] = [],
  listCompare: any[] = []
) => {
  if (listCompare.length > 0) {
    const listCompareMapper = listCompare.reduce((acc, item) => {
      const ac = { ...acc }
      if (item && item.id) {
        ac[item.id] = item.id
      }
      return ac
    }, {})
    const listDuplacatedRemoved = listDuplicated.reduce((acc, item) => {
      const ac = [...acc]
      if (!listCompareMapper[item.id]) {
        ac.push(item)
      }
      return ac
    }, [])
    return listDuplacatedRemoved
  }
  return listDuplicated
}
