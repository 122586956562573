/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { useQuery } from '@apollo/client'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import AuthService from 'src/configs/AuthService'
import { CheckPermissionValues } from 'src/graphql/models/permissions'
import { permissionMutation } from 'src/graphql/operations/mutations'
import { CHECK_PERMISSION_VALUES } from 'src/graphql/operations/queries/permission'
import {
  CLIENT_INFO,
  INTERNAL,
  SELLER,
  IS_RESELLER,
  TENANT_TYPE,
  BUYER,
  BUSINESS_INFO,
  PERMISSION_INFO_000,
  PermissionCodeAccessForApps,
  PERMISSION_INFO_002,
} from 'src/utils/constants'

export interface UserPermissions {
  isReseller: boolean
  type: 'internal' | 'Buyer' | 'Seller' | 'Servicer'
  marketPlacePermission: boolean
  cmsPermission: boolean
}
export interface Business {
  ClientId: number
  Country: string
  Id: number
  Name: string
  Status: string
  Type: string
}
interface IAuthContext {
  isAuthenticated: Boolean
  user?: Oidc.User | any
  userPermissions: UserPermissions
  profileBusiness: [] | any
  profileClient: [] | any
  loadedPermission: Boolean
  login: () => void
  logout: () => void
  renewToken: () => void
  isAuth: () => boolean
  setLoadedPermission: () => void
}

export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  userPermissions: {
    type: 'Buyer',
    isReseller: false,
    marketPlacePermission: false,
    cmsPermission: false,
  },
  loadedPermission: false,
  profileBusiness: [],
  profileClient: [],
  login: () => {},
  logout: () => {},
  renewToken: () => {},
  setLoadedPermission: () => {},
  isAuth: (): boolean => false,
})

export const useAuth = (): IAuthContext => useContext(AuthContext)
interface PermissionReduce {
  [field: string]: boolean
}
export type Permissions = {
  code: string
  value: boolean
}
const serv = new AuthService()
const permissionReduce = (
  permissionValues: Permissions[] = []
): PermissionReduce =>
  permissionValues.reduce(
    (acc: any, item: Permissions) => ({ ...acc, [item.code]: item.value }),
    {}
  )

if (
  window.location.href.indexOf('signin-callback') === -1 &&
  window.location.pathname &&
  window.location.pathname.length > 1
) {
  localStorage.setItem('originationURI', window.location.href)
}

interface AuthProviderProps {
  children: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [loadedPermission, setLoadedPermission] = useState<boolean>(false)
  const [user, setUser] = useState<any>(undefined)
  const [permissionCodes, setPermissionCodes] = useState<any[]>([])
  const [encodedPermissions, setEncodedPermissions] = useState('')
  const [profileBusiness, setProfileBusiness] = useState([])
  const [profileClient, setProfileClient] = useState<any[]>([])
  const [userPermissions, setUserPermissions] = useState<UserPermissions>({
    type: 'Buyer',
    isReseller: false,
    marketPlacePermission: false,
    cmsPermission: false,
  })
  const [authService, setAuthService] = useState<AuthService>()
  const { updateUserType } = permissionMutation

  const { data: permissionData } = useQuery<CheckPermissionValues>(
    CHECK_PERMISSION_VALUES,
    {
      variables: {
        permissionCodes,
        encodedPermissions,
      },
      fetchPolicy: 'cache-and-network',
      skip: !permissionCodes.length && !encodedPermissions,
    }
  )
  const managePermission = permissionData?.checkPermissionValues.permissions
  const managePermissionReduce = permissionReduce(managePermission)
  useEffect(() => {
    const { profile }: any = user || {}
    if (user && profile) {
      const permissionCode: any[] = Object.entries(
        PermissionCodeAccessForApps
      ).map(([pName, pCode]) => pCode)
      setPermissionCodes(permissionCode)
      setEncodedPermissions(profile[PERMISSION_INFO_000])
    }
  }, [user])

  useEffect(() => {
    if (managePermissionReduce) {
      setUserPermissions({
        ...userPermissions,
        marketPlacePermission:
          managePermissionReduce[PermissionCodeAccessForApps.MARKET_PLACE],
        cmsPermission: user?.profile[PERMISSION_INFO_002] !== '',
      })
    }
  }, [managePermission])

  const getAuthServices = useCallback((): AuthService => {
    if (authService === undefined) {
      throw new Error('Authentication services not available')
    }
    return authService
  }, [authService])

  const forceArray = (inputObj: any) => {
    const isArray =
      Object.prototype.toString.call(inputObj) === '[object Array]'
    if (inputObj && isArray) {
      const inputObjParse = inputObj.map((item: any) => {
        const itemParse = JSON.parse(item || null)
        return itemParse
      })
      return inputObjParse
    }
    if (inputObj && !isArray) {
      const inputObjArray: any = []
      inputObjArray.push(JSON.parse(inputObj))
      return inputObjArray
    }
    return []
  }

  const w = window as any
  w.hsConversationsOnReady = [() => {}]

  //   useEffect(() => {
  //     if (user && user?.profile[TENANT_TYPE]?.Type !== INTERNAL) {
  //       const url = `${authUrl}/account/HubspotToken?userId=${user.profile[USER_ID]}`
  //       fetch(url, {
  //         method: 'GET',
  //       })
  //         .then((res) => {
  //           if (res.ok) return res.json()
  //           const err = new Error(res.statusText)
  //           throw err
  //         })
  //         .then((body) => {
  //           if (body && body !== '' && body !== undefined && body['token']) {
  //             w.hsConversationsSettings = {
  //               identificationEmail: user.profile[EMAIL],
  //               identificationToken: body['token'],
  //             }
  //           }
  //         })
  //         .catch((err) => console.warn('Unable to retrieve hubspot token'))
  //     }
  //   }, [user, w])

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      const services = serv
      setAuthService(services)
      try {
        const userData: any = await services.getUser()
        if (userData) {
          setIsAuthenticated(true)
          const profileClientInfo = userData.profile[CLIENT_INFO] || null
          const profileBusinessInfo = userData.profile[BUSINESS_INFO] || null
          const profileTenantType = userData.profile[TENANT_TYPE]
          setProfileBusiness(forceArray(profileBusinessInfo))
          if (profileTenantType.Type !== INTERNAL) {
            setProfileClient(forceArray(profileClientInfo))
          }
          if (userData) {
            setUser(userData)
          }
          localStorage.removeItem('originationURI')
        }
      } catch (error) {
        console.log(error)
      }
    }

    initAuth()
  }, [])

  useEffect(() => {
    if (!user) return
    const profileTenantType = user.profile[TENANT_TYPE]

    const isReseller = user.profile[IS_RESELLER]
      ? JSON.parse(user.profile[IS_RESELLER]?.toLowerCase())
      : false

    const type =
      profileClient && profileClient.length > 0
        ? profileClient[0]['Type']
        : profileTenantType
    setUserPermissions({
      type,
      isReseller,
      marketPlacePermission: false,
      cmsPermission: false,
    })

    if (INTERNAL === type) {
      updateUserType(INTERNAL)
    } else if (SELLER === type) {
      updateUserType(type)
    } else if (BUYER === type) {
      updateUserType(type)
    }
    setIsAuthenticated(true)
  }, [profileClient, updateUserType, user])

  const values = useMemo(
    () => ({
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      profileClient,
      loadedPermission,
      login: (): void => {
        getAuthServices().login()
      },
      logout: (): void => {
        window.localStorage.removeItem('dt.managepermissions.selectedTenantId')
        getAuthServices().logout()
      },
      renewToken: (): void => {
        getAuthServices().renewToken()
      },
      isAuth: (): boolean => {
        return serv.isAuth()
      },
      setLoadedPermission: (): void => {
        setLoadedPermission(true)
      },
    }),
    [
      isAuthenticated,
      user,
      userPermissions,
      profileBusiness,
      getAuthServices,
      profileClient,
    ]
  )

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}
